import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Select,
  DatePicker,
  Row,
  Button,
  InputNumber,
  Form,
  Radio,
  Space
} from "antd";
import agros from "../../../../../const/api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getOptions, notify } from "../../../../../redux/actions";
import { whiteSpace, noWhitespace } from "../../../../../utils/rules";
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const { Option } = Select;
const { TextArea } = Input;

const ChooseAmount = (props) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [date, setDate] = useState(undefined);
  const [medicines, setMedicines] = useState([]);
  const [crops, setCrops] = useState([]);
  const [reserves, setReserves] = useState([]);
  const [tools, setTools] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [value, setValue] = useState(1);


  useEffect(() => {
    // form.resetFields();
    // getOptions(
    //   [
    //     "mainIngredients",
    //     "fertilizerKinds",
    //     "fertilizers",

    //     "todos",
    //     "positions",

    //     "cropCategories",
    //     "crops",
    //     "cropSorts",

    //     "tools",
    //     "reserves",
    //   ],
    //   props.options,
    //   i18n.language
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const onChange = (e) => {
    setValue(e.target.value);
    // agros
    //   .post("workplan/createtask", [{ ...values, WorkPlanId: props.plan,startdate:values.Date[0],enddate:values.Date[1] }])
    //   .then(() => {
    //     notify("", true);
    //     props.triggerFetch();
    //     props.setVisibleAddNewTask(false);
    //   });
  };

  return (
    <div className='chooseAmount'>
    <Radio.Group onChange={onChange} value={value}>
    <Space direction="vertical">
      <Radio value={1}>Yuxarı qiymət çıxarışı</Radio>
      <Radio value={2}>Orta qiymət çıxarışı</Radio>
      <Radio value={3}>Aşağı qiymət çıxarışı</Radio>
    </Space>
  </Radio.Group>
  </div>
  );
};


export default ChooseAmount;

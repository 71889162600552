import { CloseCircleOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import React, { useEffect, useState } from 'react'

export default function Measurement({ visible, measuredArea, handleDrawNewArea, geometryEngine ,close}) {

    const [measureType, setMeasureType] = useState("polygon")
    const [unit, setUnit] = useState("square-kilometers")
    const [lenght, setLenght] = useState(0)

    const handleMeasureArea = () => {
        console.log(measuredArea)
        if (measuredArea) {
            let lenght_ = 0
            if (measureType == "polygon") {
                lenght_ = geometryEngine?.geodesicArea(measuredArea?.geometry, unit)
            } else {
                lenght_ = geometryEngine?.geodesicLength(measuredArea?.geometry, unit);
            }

            setLenght(lenght_.toFixed(2))
        }

    }

    const handleChangeMeasureType = (type) => {
        setLenght(0)
        setMeasureType(type)
        setUnit("square-kilometers")
        if (type == "polyline") {
            setUnit("kilometers")
        }
    }

    const handleClose=()=>{
        setLenght(0)
        close()
    }

    const createNewMeasure=(measureType)=>{
        setLenght(0)
        handleDrawNewArea(measureType)
    }



    useEffect(() => {
        handleMeasureArea()
    }, [measuredArea, unit])

    return (
        <div className={`${visible ? 'scale-100' : 'scale-0'} absolute flex flex-col  w-72 h-72  bg-white rounded-lg bottom-[2rem] left-5 shadow-lg p-1 `} >
            <div className='flex w-full justify-between px-1'>
                <span>Ölçü aləti</span>
                <div className='flex'>
                    <CloseCircleOutlined onClick={handleClose} size={20} className='cursor-pointer hover:text-red-600' />
                </div>
            </div>

            <div className='flex px-1 mt-1 w-full items-center justify-between' >
                <div onClick={() => handleChangeMeasureType("polyline")} className={` ${measureType == "polygon" ? '' : 'bg-teal-600 text-white'} flex w-1/2 border p-1  justify-center hover:bg-teal-600 hover:text-white cursor-pointer`}>
                    Məsafə
                </div>
                <div onClick={() => handleChangeMeasureType("polygon")} className={` ${measureType == "polyline" ? '' : 'bg-teal-600 text-white'} flex w-1/2 border p-1  justify-center hover:bg-teal-600 hover:text-white cursor-pointer`}>
                    Ərazi
                </div>
            </div>
            <div className='flex w-full px-1 pt-1'>
                <Select className='w-full' value={unit} onChange={setUnit} >
                    {
                        measureType == "polygon" ? <>
                            <Select.Option value="square-kilometers">km<sup>2</sup></Select.Option>
                            <Select.Option value="square-meters" >m<sup>2</sup></Select.Option>
                            <Select.Option value="hectares">ha</Select.Option>
                        </> :
                            <>
                                <Select.Option value="kilometers">km</Select.Option>
                                <Select.Option value="meters" >metr</Select.Option>
                                <Select.Option value="miles">mil</Select.Option>
                            </>
                    }

                </Select>
            </div>
            <div className=' flex w-full h-full justify-center items-center' >
                <span className='text-teal-600 text-xl'>{lenght}</span>
            </div>
            <div className='flex p-1 w-full' >
                <div onClick={() => createNewMeasure(measureType)} className='flex w-full border bg-teal-600 hover:bg-teal-700 p-1 justify-center cursor-pointer text-white' >
                    Yeni ölçü
                </div>
            </div>

        </div>
    )
}

import { Modal, Spin, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';

export default function StatisticModal({ open, onClose, areaId = 36, stats, statLoading }) {

	const [spin, setSpin] = useState(false)
	const [statsArray, setStatsArray] = useState()
	let area37 = {
		options: {
			chart: {
				id: 'basic-line',
				toolbar: {
					show: false
				}
			},
			xaxis: {
				categories: statsArray?.intervals
			},
			legend: {
				position: 'top'
			},
			yaxis: {
				tickAmount: 6,
				style: {
					fontSize: '12px',
					fontFamily: 'Helvetica, Arial, sans-serif',
					fontWeight: 400,
					cssClass: 'apexcharts-yaxis-label',
				},


			},

			stroke: {
				curve: 'smooth' // yumru hale getirilmiş çizgi
			},
			markers: {
				size: 4, // işaretçi boyutu
				strokeWidth: 0, // işaretçi kenar kalınlığı
				hover: {
					size: 6 // fare üzerine gelindiğinde işaretçi boyutu
				}
			},

		},
		series: [
			{
				name: 'NDVI',
				data: statsArray?.data
			},
		]
	}
	useEffect(() => {
		console.log(stats)
		if (stats?.data) {
			let statArray = { intervals: [], data: [] }
			stats["data"].map(d => {
				statArray.intervals.push(d.interval.from.toString().substring(0, 10))
				statArray.data.push(d.outputs.default.bands.B0.stats.mean.toString().substring(0, 5))
				console.log(statArray)
			})
			setStatsArray(statArray)
		}

	}, [open, statLoading])
	return (
		<Modal open={open} onCancel={onClose} title="Bitki vegetasiyası" footer={null}  >
			<Spin spinning={statLoading}>
				<Chart options={area37?.options} series={area37?.series} type="line" />
			</Spin>

		</Modal>
	)
}

import React, { useState, useEffect } from 'react';
import { Col, Row, List, Checkbox, InputNumber, Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import Images from "../../../../assets/img/Images/Images";
import { DownOutlined, UpOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
function SelectModuls(props) {
    const { t } = useTranslation();
    const [taskmOne, setTaskmOne] = useState(false);
    const [taskmTwo, setTaskmTwo] = useState(false);
    const [storageSum, setStorageSum] = useState(1)
    const [user, setUser] = useState(props?.data?.user?.userCount || 1)
    const [vegetation, setVegetation] = useState(props?.data?.vegetationIndex?.count || 1)
    const [diseasesRisks, setDiseasesRisks] = useState(props?.data?.diseasesRisks?.count || 1)
    const [price, setPrice] = useState(0)
    const [memoryPrice, setMemoryPrice] = useState(0)
    const [vegetationPrice, setVegetationPrice] = useState(0)
    const [diseasesRisksPrice, setDiseasesRisksPrice] = useState(0)
    const [sum, setSumm] = useState(0)
    const [edv, setEdv] = useState(0)
    const [trigger, setTrigger] = useState(1)
    const [chanegUser, setChangeUser] = useState(1)
    const [chanegMemory, setChangeMemory] = useState(1)
    const [chanegVegatation, setChanegVegatation] = useState(1)
    const [changeDiseasesRisk, setChangeDiseasesRisk] = useState(1)
    const [firstDescription, setFirstDescription] = useState(false)
    const [secondDescription, setSecondDescription] = useState(false)
    const [description, setDescription] = useState(false)
    const [notify, setNotify] = useState(false)

    const showSumUser = (value) => {
        setUser(value >= props?.data?.user?.userCount ? value : props?.data?.user?.userCount)
        setChangeUser(value - props?.data?.user?.userCount)
        setPrice((props?.data?.user?.userPrice + (value - props?.data?.user?.userCount) * props?.data?.user?.costUserPrice).toFixed(2))
        setTrigger(trigger + 1)
    }
    const showSumIndex = (value) => {
        setVegetation(value >= props?.data?.vegetationIndex?.count ? value : props?.data?.vegetationIndex?.count)
        setChanegVegatation(value - props?.data?.vegetationIndex?.count)
        setVegetationPrice((props?.data?.vegetationIndex?.price + (value - props?.data?.vegetationIndex?.count) * props?.data?.vegetationIndex?.costPrice).toFixed(2))
        setTrigger(trigger + 1)
    }

    const showSumDisase = (value) => {
        setDiseasesRisks(value >= props?.data?.diseasesRisks?.count ? value : props?.data?.diseasesRisks?.count)
        setChangeDiseasesRisk(value - props?.data?.diseasesRisks?.count)
        setDiseasesRisksPrice((props?.data?.diseasesRisks?.price + (value - props?.data?.diseasesRisks?.count) * props?.data?.diseasesRisks?.costPrice).toFixed(2))
        setTrigger(trigger + 1)
    }

    const showSumMemory = (value) => {
        setStorageSum(value >= props?.data?.memory?.memoryCount ? value : props?.data?.memory?.memoryCount)
        setChangeMemory(value - props?.data?.memory?.memoryCount)
        setMemoryPrice((props?.data?.memory?.memoryPrice + (value - props?.data?.memory?.memoryCount) * props?.data?.memory?.costMemoryPrice).toFixed(2))
        setTrigger(trigger + 1)
    }
    const finished = () => {
        if (chanegUser > 0 && chanegMemory > 0 && chanegVegatation > 0 && changeDiseasesRisk > 0) {
            props.setCurrent(1);
            props.setModul({
                UserCount: user,
                Memory: storageSum,
                Agriculture: true,
                TotalPrice: Number(sum),
                vegetationIndex: taskmOne,
                diseasesRisks: taskmTwo,
                vegetationIndexCount:taskmOne ? Number(vegetation) : 0,
                diseasesRisksCount:taskmTwo ? Number(diseasesRisks) : 0,
            });
        } else {
            setChangeUser(1)
            setChangeMemory(1)
            setChanegVegatation(1)
            setChangeDiseasesRisk(1)
        }
    }
    useEffect(() => {
        setUser(props?.data?.user?.userCount);
        setPrice(props?.data?.user?.userPrice);
        setStorageSum(props?.data?.memory?.memoryCount);
        setMemoryPrice(props?.data?.memory?.memoryPrice);
        setChangeMemory(props?.data?.user?.userCount);
        setVegetation(props?.data?.vegetationIndex?.count);
        setVegetationPrice(props?.data?.vegetationIndex?.price);
        setDiseasesRisks(props?.data?.diseasesRisks?.count);
        setDiseasesRisksPrice(props?.data?.diseasesRisks?.price);
    }, [props?.data])
    useEffect(() => {
        const finalSum = trigger > 1 ? Number(price) + Number(memoryPrice) + props?.data?.agriculture?.price + (taskmOne ? Number(vegetationPrice) : 0) + (taskmTwo ? Number(diseasesRisksPrice) : 0) : props?.data?.user?.userPrice + props?.data?.memory?.memoryPrice + Number(props?.data?.agriculture?.price) + (taskmOne ? Number(vegetationPrice) : 0) + (taskmTwo ? Number(diseasesRisksPrice) : 0)
        setEdv((finalSum * 18 / 100).toFixed(2))
        setSumm((finalSum + finalSum * 18 / 100).toFixed(2))
    }, [trigger, props?.data, taskmOne, taskmTwo])

    return (
        <div className='selectModule animated fadeIn'>
            <Row gutter={[48, 48]}>
                <Col xl={14} sm={24} >
                    {
                        props?.data?.agriculture?.hideShowStatus &&
                        <div className="card white mb-20">
                            <Row gutter={[8, 16]} className='flex flex-align-center flex-around ' >
                                <Col lg={2} sm={2} span={3}>
                                    <Checkbox
                                        checked={true}
                                        disabled={true}
                                    />
                                </Col>
                                <Col lg={8} sm={8} span={20}><span className='name'>{props?.data?.agriculture?.name}</span></Col>
                                <Col lg={6} sm={6} span={16} className='haNumber'>
                                    <span>
                                        <InputNumber type="number" disabled={true} value={props?.data?.agriculture?.agricultureSubModuls.length} />
                                        <DeploymentUnitOutlined />
                                    </span>
                                </Col>
                                <Col sm={6} span={8}>
                                    <div>
                                        <div className='quantity'>
                                            <div className='flex flex-align-center gap-2'>{props?.data?.agriculture?.price} <sup><img src={Images.manatgreen} alt="" /></sup>/ Ay</div>
                                        </div>
                                    </div>
                                </Col>


                                {
                                    description
                                        ?
                                        <UpOutlined style={{ color: "#89ca33" }} className='arrow-up-pack' onClick={() => setDescription(false)} />
                                        :
                                        <DownOutlined style={{ color: "#89ca33" }} className='arrow-up-pack' onClick={() => setDescription(true)} />
                                }
                            </Row>
                            {
                                description &&
                                <div className='ml-20 mt-20 flex gap-4 '>
                                    <div className='subTitleDiv'>
                                        <div className='flex flex-wrap gap-3'>
                                            {props?.data?.agriculture?.agricultureSubModuls?.map((item, index) => {
                                                return (
                                                    <span className='subtitle' key={index}>{item.name}</span>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div style={{ width: '60%' }}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: props?.data?.agriculture?.description
                                            }} />
                                    </div>
                                </div>
                            }

                        </div>
                    }
                    {
                        props?.data?.vegetationIndex?.hideShowStatus &&
                        <div className="card white mb-20">
                            <Row gutter={[8, 16]} className='flex flex-align-center flex-around ' >
                                <Col lg={2} sm={2} span={2} >
                                    <Checkbox
                                        checked={taskmOne}
                                        onChange={(e) => { setTaskmOne(e.target.checked); setNotify(e.target.checked) }}
                                    />
                                </Col>
                                <Col lg={8} sm={8} span={20}><span className='name '>{props?.data?.vegetationIndex?.name}</span></Col>
                                <Col lg={6} sm={6} span={16} className='haNumber'>
                                    <div className='flex flex-align-center'>
                                        <InputNumber type="number" min={props?.data?.vegetationIndex.count} value={vegetation} onChange={(value) => showSumIndex(value)} />
                                        <p className='mr-10 ml-15 '>{t('Ha')}</p>
                                    </div>
                                </Col>

                                <Col sm={6} span={7}>
                                    <div>
                                        <div className='quantity'>
                                            <div className='flex flex-align-center gap-2'>{vegetationPrice} <sup><img src={Images.manatgreen} alt="" /></sup>/ Ay</div>
                                        </div>
                                    </div>
                                </Col>
                                {
                                    firstDescription
                                        ?
                                        <UpOutlined style={{ color: "#89ca33" }} className='arrow-up-pack' onClick={() => setFirstDescription(false)} />
                                        :
                                        <DownOutlined style={{ color: "#89ca33" }} className='arrow-up-pack' onClick={() => setFirstDescription(true)} />

                                }
                            </Row>
                            {
                                firstDescription &&
                                <div className='ml-20 mt-20 flex gap-10 '>
                                    <div className='subTitleDiv'>
                                        <div className='flex flex-wrap gap-3'>
                                            {props?.data?.vegetationIndex?.vegetationIndexSubTitles?.map((item, index) => {
                                                return (

                                                    <span className='subtitle' key={index}>{item.name}</span>

                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div style={{ width: '60%' }}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: props?.data?.vegetationIndex?.description
                                            }} />
                                    </div>
                                </div>
                            }

                        </div>
                    }
                    {
                        props?.data?.diseasesRisks?.hideShowStatus &&
                        <div className="card white ">
                            <Row gutter={[8, 16]} className='flex flex-align-center flex-around ' >
                                <Col lg={2} sm={2} span={2}  >
                                    <Checkbox
                                        checked={taskmTwo}
                                        onChange={(e) => { setTaskmTwo(e.target.checked); setNotify(e.target.checked) }}
                                    />
                                </Col>
                                <Col lg={8} sm={8} span={20}><span className='name'>{props?.data?.diseasesRisks?.name}</span></Col>
                                <Col lg={6} sm={6} span={16} className='haNumber'>
                                    <div className='flex flex-align-center'>
                                        <InputNumber type="number" min={props?.data?.diseasesRisks?.count} value={diseasesRisks} onChange={(value) => showSumDisase(value)} />
                                        <p className='mr-10 ml-15 ' >{t('Say')}</p>
                                    </div>
                                </Col>

                                <Col sm={6} span={7}>
                                    <div>
                                        <div className='quantity'>
                                            <div className='flex flex-align-center gap-2'>{diseasesRisksPrice} <sup><img src={Images.manatgreen} alt="" /></sup>/ Ay</div>
                                        </div>
                                    </div>
                                </Col>
                                {
                                    secondDescription
                                        ?
                                        <UpOutlined style={{ color: "#89ca33" }} className='arrow-up-pack' onClick={() => setSecondDescription(false)} />
                                        :
                                        <DownOutlined style={{ color: "#89ca33" }} className='arrow-up-pack' onClick={() => setSecondDescription(true)} />
                                }
                            </Row>
                            {
                                secondDescription &&
                                <div className='ml-20 mt-20 flex gap-4 '>
                                    <div className='subTitleDiv'>
                                        <div className='flex flex-wrap gap-3'>
                                            {props?.data?.diseasesRisks?.diseasesRisksSubTitles?.map((item, index) => {
                                                return (
                                                    <span className='subtitle' key={index}>{item.name}</span>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div style={{ width: '60%' }}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: props?.data?.diseasesRisks?.description
                                            }} />
                                    </div>
                                </div>
                            }

                        </div>
                    }
                    <div className="card white mt-15">
                        <List
                            size="small"
                        >
                            <List.Item>
                                <div className="w-100">
                                    <Row gutter={[8, 8]} className='flex flex-align-center  ' >
                                        <Col lg={12} md={13}><span className='name'>{t('humanResources')}</span></Col>
                                        <Col lg={12} md={11}>
                                            <div className="flex flex-align-center flex-between">
                                                <div className='flex flex-align-center'>
                                                    <InputNumber type="number" min={props?.data?.user?.userCount} value={user} onChange={(value) => showSumUser(value)} />
                                                    <p className='mr-10 ml-15 '>{t('user')}</p>
                                                </div>
                                                <div className='quantity'>
                                                    <div className='flex flex-align-center gap-2'>{price} <sup><img src={Images.manatgreen} alt="" /></sup>/ Ay</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </List.Item>
                            <List.Item>
                                <div className="w-100">
                                    <Row gutter={[8, 8]} className='flex flex-align-center flex-between' >
                                        <Col lg={12} md={13}><span className='name'>{t('storage')}</span></Col>
                                        <Col lg={12} md={11}>
                                            <div className="flex flex-align-center flex-between ">
                                                <div className='flex flex-align-center'>
                                                    <InputNumber type="number" min={props?.data?.memory?.memoryCount} value={storageSum} onChange={(value) => showSumMemory(value)} />
                                                    <p className='font-weight-bold ml-15 f-10'>GB</p>
                                                </div>
                                                <div className='quantity'>
                                                    <div className='flex flex-align-center gap-2'>{memoryPrice} <sup><img src={Images.manatgreen} alt="" /></sup>/ Ay</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </List.Item>
                        </List>
                    </div>

                </Col>
                <Col xl={10} sm={24} >
                    <div className="card green">
                        <div>
                            <List
                                size="large"
                                className='mt-15'
                            >
                                <List.Item>
                                    <div className="w-100">
                                        <Row gutter={[8, 8]} className='flex flex-align-center flex-between ' >
                                            <Col lg={14} md={8} xs={15}><span className='heading'>{t('userCost')}:</span></Col>
                                            <Col lg={10} md={16} xs={9}>
                                                <div className="flex all-center">
                                                    <div className="quantity flex end-btn">
                                                        <div className='flex flex-align-center gap-2'>
                                                            {price}
                                                            <sup>
                                                                <img src={Images.manatwhite} alt="" />
                                                            </sup>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div className="w-100">
                                        <Row gutter={[8, 8]} className='flex flex-align-center ' >
                                            <Col lg={14} md={8} xs={15}><span className='heading'>{t('storageCost')}:</span></Col>
                                            <Col lg={10} md={16} xs={9}>
                                                <div className="flex all-center">
                                                    <div className="quantity flex end-btn">
                                                        <div className='flex flex-align-center gap-2'>{memoryPrice}
                                                            <sup>
                                                                <img src={Images.manatwhite} alt="" />
                                                            </sup>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={14} md={8} xs={15}><span className='heading'>{t('packCost')}:</span></Col>
                                            <Col lg={10} md={16} xs={9}>
                                                <div className="flex all-center">
                                                    <div className="quantity flex end-btn">
                                                        <div className='flex flex-align-center gap-2'>{props?.data?.agriculture?.price + (taskmOne ? Number(vegetationPrice) : 0) + (taskmTwo ? Number(diseasesRisksPrice) : 0)}
                                                            <sup>
                                                                <img src={Images.manatwhite} alt="" />
                                                            </sup>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={14} md={8} xs={15}><span className='heading'>{t('additionalCost')}:</span></Col>
                                            <Col lg={10} md={16} xs={9}>
                                                <div className="flex all-center">
                                                    <div className="quantity flex end-btn">
                                                        <div className='flex flex-align-center gap-2'>{edv}
                                                            <sup>
                                                                <img src={Images.manatwhite} alt="" />
                                                            </sup>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                </List.Item>
                                <List.Item>
                                    <div className="w-100">
                                        <Row gutter={[8, 0]} className='flex flex-align-center ' >
                                            {/* <Col  md={2} xs={5}>
                                    <Checkbox
                                        checked={taskmTwo}
                                        onChange={(e) => { setTaskmTwo(e.target.checked); setNotify(e.target.checked) }}
                                    />
                                </Col> */}
                                            <Col md={9} xs={10}><span className='heading'>{t('finalCost')}:</span></Col>
                                            <Col md={15} xs={14}>
                                                <div className="flex all-center">
                                                    <div className="quantity flex end-btn">
                                                        <div className='flex flex-align-center gap-2'>{sum}
                                                            <sup>
                                                                <img src={Images.manatwhite} alt="" />
                                                            </sup>
                                                            / Ay
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Row gutter={[8, 0]} className='flex flex-align-center ' >
                                        <Col  md={2} xs={5}>
                                    <Checkbox
                                        checked={taskmTwo}
                                        onChange={(e) => { setTaskmTwo(e.target.checked); setNotify(e.target.checked) }}
                                    />
                                </Col>
                                            <Col md={9} xs={15}><span className='heading'>{t('finalCost')}(illik alınırsa):</span></Col>
                                            <Col md={13} xs={9}>
                                                <div className="flex all-center">
                                                    <div className="quantity flex end-btn">
                                                        <div className='flex flex-align-center' style={{textDecoration:'line-through'}}>{sum *12}
                                                            <sup>
                                                                <img src={Images.manatwhite} alt="" />
                                                            </sup>
                                                            / Ay
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row> */}
                                    </div>
                                </List.Item>
                            </List>
                        </div>
                        <div className="flex mt-20 pt-15 all-center">
                            <button
                                onClick={() => finished()}
                                className="btm-warning  shadow">
                                {t('continue')}
                            </button>
                        </div>
                        <div className="list">
                            <img src={Images.list11} alt="" />
                            <img src={Images.list12} alt="" />
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal
                title="Xəbərdarlıq"
                centered
                className="logoutModal"
                visible={notify}
                onOk={() => setNotify(false)}
                onCancel={() => setNotify(false)}
            >
                <div className="flex dir-column">
                    <p>{t("'Xəstəliklər və zərərvericilər' modulunu istifadə etmək üçün əkin sahələrinizdə müvafiq sensorlara malik (temperatur, rütubət, yağıntı, külək, günəş işığı, torpağın nəmliyi və pH və s.) Meteorologiya stansiyanız olmalıdır. Əks təqdirdə bu modul işləməyəcəkdir.")}</p>
                    <div
                        className="flex flex-end w-100 mt-20 mb-10"
                    >
                        <Button type="primary" onClick={() => setNotify(false)}
                            className="ml-10" htmlType="submit">
                            {t("Tamam")}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}


export default SelectModuls;
import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Col,
  Input,
  InputNumber,
  Form,
  Row,
  Select,
  Button,
  Spin,
} from "antd";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import agros from "../../../const/api";
import { noWhitespace, whiteSpace } from "../../../utils/rules";
import { getOptions, notify } from "../../../redux/actions";
import dayjs from "dayjs";
const { Option } = Select;

const EditArea = (props) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [sectors, setSectors] = useState([{ id: 0 }]);
  const [showTree, setShowtree] = useState(true)
  const [workers, setWorkers] = useState([]);
  const [keys, setKeys] = useState({});
  const [sectorsSum, setSectorsSum] = useState(0);
  const [loading, setLoading] = useState(true)

  const [names, setNames] = useState([]);

  const { getOptions, notify } = props;
  console.log(props);
  const options = props.options[props.lang];
  console.log(options);
  const handleKeyChange = (e, key) => {
    const all = { ...keys };
    console.log(all)
    all[key] = e;
    setKeys(all);
    sectors.map((s, index) => {
      form.resetFields(["ParcelSectors", index, "CropSortIds"])
      form.resetFields(["ParcelSectors", index, "productId"])
    })

  };


  const handleKeyChangeIndex = (e, index, key) => {
    const all = [...sectors];
    sectors[index][key] = e;
    setSectors(all);
  };
  const onCancel = () => {
    props.setVisibleAddArea(false)
    if (!props.editing) {
      form.resetFields()
      setSectors([])
      setSectorsSum(0)
    }

  }

  const getInit = async () => {
    await getOptions(
      [
        "cropCategories",
        "cropSorts",
        "crops",
        "positions",
        "parcelRegions",
        "parcelCategories",
      ],
      props.options,
      i18n.language
    )
    setTimeout(() => {
      setLoading(false)
      if (props.editing) {
        const areaInfo = props.areaInfo
        form.setFieldsValue({
          fieldName: areaInfo.sektorun_adi,
          parcelRegionId: areaInfo.region_id,
          parcelCategoryId: parseInt(areaInfo.erazi_kategoriyasi),
          area: areaInfo.sektorun_sahesi,
          TreeCount: areaInfo.agac_sayi,
          createdCompany: areaInfo.sirket,
          createdDate: areaInfo.yaranma_tarixi ? moment(parseInt(areaInfo.yaranma_tarixi)) : moment()
        })
      }
    }, 300);

  }

  useEffect(() => {
    getInit()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, props.editing, props?.areaInfo?.objectid]);

  const onProfessionChange = async (e) => {
    setWorkers([]);
    await agros.get("data/workers/" + e).then((res) => {
      let all = { ...workers };
      all = res.data;
      setWorkers(all);
      form.setFieldsValue({ respondedUser: undefined });
    });
  };

  const addSector = () => {
    const all = [...sectors];
    const id = all.length ? all[all.length - 1].id + 1 : 0;
    all.push({ id });
    console.log(all)
    setSectors(all);
  };

  const removeSector = (index) => {
    const all = [...sectors];
    all.splice(index, 1);
    setSectors(all);
  };

  const setSectorArea = (e, index) => {
    const all = [...sectors];
    all[index]["area"] = +e;
    setSectors(all);
    calculateSectorsSum();
  };

  const selectedParcelCategory = (value) => {
    value === 1 ? setShowtree(false) : setShowtree(true)
    // handleKeyChange(value, "parcelCategoryId")
    form.setFieldsValue({ cropCategoryId: undefined });
  }

  const calculateSectorsSum = () => {
    let sum = 0;
    sectors.forEach((element) => {
      sum += element.area;
    });
    form.setFieldsValue({ sectorsTotal: sum });
    setSectorsSum(sum);
    console.log(sectorsSum);
  };

  const saveItem = async (values) => {
    if (props.editing) {
      props.handleUpdateArea({ ...values, name: values.fieldName, WithoutSector: false, ParcelSectors: values.ParcelSectors ? values.ParcelSectors : [] })
    } else {
      props.handleSaveArea({ ...values, name: values.fieldName, WithoutSector: false, ParcelSectors: values.ParcelSectors ? values.ParcelSectors : [] })
    }

  };

  return (
    <Spin spinning={loading} >
      <Form form={form} onFinish={saveItem} layout="vertical">
        <Row gutter={[8, 8]}>
          <Col md={6} sm={12} xs={24}>
            <Form.Item
              label={t("region")}
              validateTrigger="onChange"
              name="parcelRegionId"
              rules={[noWhitespace(t("regionMustSelectError"))]}
            >
              <Select>
                {options.parcelRegions.map((pr, index) => {
                  return (
                    <Option key={index} value={pr.id}>
                      {pr.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Form.Item
              label="Ərazinin kateqoriyası"
              validateTrigger="onChange"
              name="parcelCategoryId"
              rules={[noWhitespace(t("categoryMustSelectError"))]}
            >
              <Select onChange={(value) => { selectedParcelCategory(value) }}>
                {options.parcelCategories.map((pr, index) => {
                  return (
                    <Option key={index} value={pr.id}>
                      {pr.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Form.Item
              label="Ərazinin adı"
              validateTrigger="onChange"
              name="fieldName"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>


          <Col md={4} sm={12} xs={24}>
            <div className="form-lang">
              <Form.Item
                label="Ərazi"
                validateTrigger="onChange"
                name="area"
                rules={[noWhitespace(t("inputError"))]}
              >
                <InputNumber className="w-100" />
              </Form.Item>
              <span className="input-lang btm">
                ha
              </span>
            </div>
          </Col>




          <Col md={6} sm={12} xs={24}>
            <Form.Item
              label="Cavabdeh şəxsin vəzifəsi"
              validateTrigger="onChange"
              name="profession"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Select onChange={onProfessionChange}>
                {options.positions.map((pr, index) => {
                  return (
                    <Option key={index} value={pr.id}>
                      {pr.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={6} sm={12} xs={24}>
            <Form.Item
              label={t("respondentPeople")}
              validateTrigger="onChange"
              name="respondentId"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Select>
                {workers.map((pr, index) => {
                  return (
                    <Option key={index} value={pr.id}>
                      {pr.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={4} sm={12} xs={24}>
            <Form.Item
              label={t("createdDate")}
              validateTrigger="onChange"
              name="createdDate"
              rules={[noWhitespace(t("inputError"))]}
            >
              <DatePicker className="w-100" format="YYYY-MM-DD" />
            </Form.Item>
          </Col>

          {/*sektor*/}
          <Col className="mt-10" xs={24}>


            <Row  gutter={[8, 8]}>

              {showTree && <Col md={4} sm={12} xs={24}>
                <Form.Item
                  label={t("treeNumber")}
                  validateTrigger="onChange"
                  name={"TreeCount"}
                  rules={[noWhitespace(t("inputError"))]}
                >
                  <InputNumber className="w-100" />
                </Form.Item>
              </Col>
              }
              {/* <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("product")}
                      name={"productId"}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <Select
                        onChange={(e) =>
                          handleKeyChangeIndex(e, index, "productId")
                        }
                      >
                        {options.crops
                          .filter((c) => c.categoryId === keys.categoryId)
                          .map((c, index) => {
                            return (
                              <Option key={index} value={c.id}>
                                {c.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col> */}
              {/* <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label="Məhsul növləri"
                      name={"CropSortIds"}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <Select mode="multiple" allowClear>
                        {options.cropSorts
                          .filter((c) => c.categoryId === s.productId)
                          .map((c, index) => {
                            console.log(c)
                            return (
                              <Option key={index} value={c.id}>
                                {c.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col> */}
              <Col md={8} sm={12} xs={24}>
                <Form.Item
                  label={t("company")}
                  validateTrigger="onChange"
                  name={["createdCompany"]}
                  rules={[whiteSpace(t("inputError"))]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <div
          className="modalButtons flex w-full flex-row-reverse"
        // style={{ position: "absolute", bottom: "20px", right: "40px" }}
        >

          <Button loading={props.saveLoading} type="primary" className="ml-10" htmlType="submit">
            {t("save")}
          </Button>
          <Button
            onClick={() => {
              onCancel()
            }}
            className="ml-4"
          >
            {t("cancel")}
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

const mapStateToProps = ({ options, lang, user }) => {
  return { options, lang, perms: user.data.userPermissions };
};

export default connect(mapStateToProps, { getOptions, notify })(EditArea);

import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import agros from '../../../const/api';

const { Option } = Select;

function Deneme() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [data, setData] = useState([]);
useEffect(() => {
    agros.get("ParcelRegion").then((res) => {
        console.log(res.data)
        setData(res?.data);
        }
    );
    }
, [selectedCategory])
  const handleSelectChange = (value) => {
    setSelectedCategory(value);
  };

  const handleInputChange = (e) => {
    setSelectedCategory(null); // Başka bir inputa değer girdiğinizde, seçili kategoriyi sıfırlayın
  };

  return (
    <div>
     <Select onChange={handleInputChange}>
              {data.map((pr, index) => {
                return (
                  <Option key={index} value={pr.id}>
                    {pr.name}
                  </Option>
                );
              })}
            </Select>
 <Select
        value={selectedCategory} // Seçili değeri kontrol ediyoruz
        onChange={handleSelectChange}
        style={{ width: 200 }}
      >
        {data.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default Deneme;
import React, {useEffect, useState} from "react";
import {
  Button,
  Col,
  InputNumber,
  Row,
  Select,
  Form,
} from "antd";
import agros from "../../../../../const/api";
import { connect } from "react-redux";
import {getOptions, notify} from "../../../../../redux/actions";
import { useTranslation } from "react-i18next";
import {noWhitespace} from "../../../../../utils/rules";

const { Option } = Select;

const ExportModal = (props) => {
  const [form] = Form.useForm();
  const {notify} = props;
  const { t } = useTranslation();
  const [silos, setSilos] = useState([]);

  const getSilos = async () =>{
    await agros.get('Silo').then(res =>{
      setSilos(res.data)
    })
    .catch((err) => {
      notify(err.response, false);
    });
  }


  useEffect(() => {
    getSilos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trigger]);

  const hideModal = () => {
    form.resetFields();
    props.setVisibleExport(false);
  };


  const addReserve = (values) => {
    agros
        .post(`Silostock/Export`, {
          ...values,
        })
        .then(() => {
          notify("", true);
          props.triggerFetch();
        })
        .catch((err) => {
          notify(err.response, false);
        })
        .finally(()=>{
          hideModal();
        });
  };

  return (
      <Form onFinish={addReserve} layout="vertical" form={form}>
        <div className="commontask">
          <Row gutter={[16, 16]}>
            <Col sm={24} xs={24}>
              <Form.Item
                  label={'Silo'}
                  name="SiloId"
                  validateTrigger="onChange"
                  rules={[noWhitespace('Silo seçilməlidir')]}
              >
                <Select>
                  {silos.map((w, index) => {
                    return (
                        <Option key={index} value={w.id}>
                          {w.name}
                        </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                  label={t("quantityOf")}
                  name="Count"
                  validateTrigger="onChange"
                  rules={[noWhitespace(t("inputError"))]}
              >
                <InputNumber className="w-100" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div
            className="modalButtons"
            style={{ position: "absolute", bottom: "20px", right: "25px" }}
        >
          <Button onClick={hideModal}>{t("cancel")}</Button>
          <Button type="primary" className="ml-10" htmlType="submit">
            {t("save")}
          </Button>
        </div>
      </Form>
  );
};

const mapStateToProps = ({ options, lang }) => {
  return { options, lang };
};

export default connect(mapStateToProps, { getOptions , notify })(ExportModal);

import React, { useEffect, useState } from "react";
import {
  Col,
  Select,
  DatePicker,
  Button,
  Row,
  Input,
  InputNumber,
  Form,
  notification,
  Modal,
  Switch,
  Upload
} from "antd";
import { whiteSpace, noWhitespace } from "../../../../../utils/rules";
import { getOptions, notify } from "../../../../../redux/actions";
import { connect } from "react-redux";
import { FrownOutlined, SmileOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import agros from "../../../../../const/api";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

const NewPlan = (props) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [confirmDemand, setConfirmDemand] = useState(false);
  const [demandValues, setDemandValues] = useState([]);
  const [lastDate, setLastDate] = useState(false);
  const [params, setParams] = useState({});
  const [workPlanTasks, setWorkPlanTasks] = useState([]);
  const [period, setPeriod] = useState([]);
  const [cropMeasure, setCropMeasure] = useState('');
  const [fileList,setFileList] = useState([])
  const [file,setFile] = useState(null)
  const [image,setImage] = useState([])
  const [tasks, setTasks] = useState([
    { id: 1, medicines: [], workers: [], reserves: [], tools: [], crops: [], equipments: [], },
  ]);

  const [parcelCategoryId, setParcelCategoryId] = useState(undefined);
  // const [parcel, setParcel] = useState(undefined);
  const [parcelSectors, setParcelSectors] = useState([]);
  const [parcel, setParcel] = useState([]);
  const [sector, setSector] = useState([]);
  const [area, setArea] = useState(0);
  const [date, setDate] = useState(undefined);
  const [date2, setDate2] = useState(undefined);
  const [previewImage,setPreviewImage] = useState('')
  const [previewVisible,setPreviewVisible] = useState(false)
  const { getOptions, notify } = props;
  const options = props.options[props.lang];
  
  useEffect(() => {
    getOptions(
      [
        "parcels",
        "parcelSectors",
        "parcelCategories",

        "annualWorkPlans",
        "todos",
        "positions",

        "fertilizers",
        "fertilizerKinds",
        "mainIngredients",

        "cropCategories",
        "crops",
        "cropSorts",

        "reserves",
        "tools",
      ],
      props.options,
      i18n.language
    );
    agros.get(`Data/AnnualWorkPlans`).then((res) => {
      setWorkPlanTasks(res.data)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    form.resetFields();
    setArea(0);
    setLastDate(false);
    setTasks([
      { id: 1, medicines: [], workers: [], reserves: [], tools: [], crops: [], equipments: [], },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trigger]);
  const createDemandCancelled = () => {
    setConfirmDemand(false);
  };

  const createDemandConfirmed = () => {
    const vals = form.getFieldsValue();
    agros
      .post("demand", {
        name: `"${vals.name}" adlı günlük plan üçün üçün tələb`,
        demandProduct: demandValues,
      })
      .then((res) => {
        notification.info({
          message: "Əməliyyat uğurlu oldu",
          description: "Yeni tələb yaradıldı",
          icon: <SmileOutlined />,
        });
        form.resetFields();
        props.setVisibleAddNewPlan(false);
        setConfirmDemand(false);
      })
      .catch((err) => {
        notify(err.response, false);
      });
  };

  const addTask = (index) => {
    const all = [...tasks];
    const id = tasks.length ? tasks[tasks.length - 1].id + 1 : 0;
    all.push({
      id,
      medicines: [],
      workers: [],
      reserves: [],
      tools: [],
      crops: [],
      equipments: [],
    });
    form.resetFields([["WorkPlanTasks", index + 1, "startdate"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "parcelId"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "parcelSectorId"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "enddate"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "Description"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "position"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "respondentId"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "ManWorkerCount"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "AnnualPlanPeriodId"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "ToDoId"]]);
    setTasks(all);
  };

  const deleteTask = (index) => {
    console.log(index);
    form.resetFields([["WorkPlanTasks", index, "startdate"]]);
    form.resetFields([["WorkPlanTasks", index, "parcelId"]]);
    form.resetFields([["WorkPlanTasks", index, "parcelSectorId"]]);
    form.resetFields([["WorkPlanTasks", index, "enddate"]]);
    form.resetFields([["WorkPlanTasks", index, "Description"]]);
    form.resetFields([["WorkPlanTasks", index, "position"]]);
    form.resetFields([["WorkPlanTasks", index, "respondentId"]]);
    form.resetFields([["WorkPlanTasks", index, "ManWorkerCount"]]);
    form.resetFields([["WorkPlanTasks", index, "AnnualPlanPeriodId"]]);
    form.resetFields([["WorkPlanTasks", index, "ToDoId"]]);
    console.log(index);
    console.log(tasks);
    const all = [...tasks];
    all.splice(index, 1);
    console.log(all);
    setTasks(all);
  };
  const disabledDate1 = (current) => {
    const date = new Date()
    date.setDate(date.getDate() - 1)
    return !date || current < date;
  };

  const addMedicine = (index) => {
    const all = [...tasks];
    const id = all[index].medicines.length
      ? all[index].medicines[all[index].medicines.length - 1].id + 1
      : 0;
    all[index].medicines.push({
      id,
      mainIngredientId: undefined,
    });
    tasks.map((p, index) => {
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "mainIngredientId",
      ]]);
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "productId",
      ]])
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "fertilizerKindId",
      ]])
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "Amount",
      ]])
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "Quantity",
      ]])
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "Sum",
      ]])
    }
    )

    setTasks(all);
  };

  const deleteMedicine = (index, bindex) => {
    const all = [...tasks];
    all[index].medicines.splice(bindex, 1);
    setTasks(all);
  };

  const addEquipent = (index) => {
    const all = [...tasks];
    const id = all[index].equipments.length
      ? all[index].equipments[all[index].equipments.length - 1].id + 1
      : 0;
    all[index].equipments.push({ id });
    tasks.map((p, index) => {
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskEquipments",
        p.equipments.length - 1,
        "equipmentCategory",
      ]]);
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskEquipments",
        p.equipments.length - 1,
        "approximatedFuel",
      ]]);
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskEquipments",
        p.equipments.length - 1,
        "PlannedFuel",
      ]]);
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskEquipments",
        p.equipments.length - 1,
        "PlateNumer",
      ]]);
    }
    )
    setTasks(all);
  };

  const deleteEquipment = (index, bindex) => {
    const all = [...tasks];
    all[index].equipments.splice(bindex, 1);
    setTasks(all);
  };

  const addReserve = (index) => {
    const all = [...tasks];
    const id = all[index].reserves.length
      ? all[index].reserves[all[index].reserves.length - 1].id + 1
      : 0;
    all[index].reserves.push({ id });
    tasks.map((p, index) => {
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskReserves",
        p.reserves.length - 1,
        "productId",
      ]]);
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskReserves",
        p.reserves.length - 1,
        "quantity",
      ]])
    }
    )
    setTasks(all);
  };

  const deleteReserve = (index, bindex) => {
    const all = [...tasks];
    all[index].reserves.splice(bindex, 1);
    setTasks(all);
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return !date || current < date[0] || current > date[2];
  };
  const savePlan = (values) => {
    let obj = {}
    obj = {
      ...values,
      startdate: values.Date ? values.Date[0] : values.StartDate,
      enddate: values.Date ? values.Date[1] : null,
      WorkPlanTasks: values.WorkPlanTasks.map(elem => ({ ...elem, startdate: elem.startdate[0], enddate: elem.startdate[1], TaskParcels: elem.parcelId.map((b) => { return { ParcelId: b, } }) })),
    }
    agros
      .post("WorkPlan", obj)
      .then((res) => {
        if (res.data && res.data.demands.length) {
          setConfirmDemand(true);
          setDemandValues(res.data.demands);
          notification.info({
            message: "Əməliyyat uğursuz oldu",
            description:
              "Çatışmayan resurslar var. Onları əldə etmək üçün tələb yarada bilərsiniz.",
            icon: <FrownOutlined />,
          });
        } else {
          notify("Plan əlavə olundu", true);
          form.resetFields();
          props.setVisibleAddNewPlan(false);
          props.triggerFetch();
        }
      })
      .catch((err) => {
        console.log(err);
        notify(err.response, false);
      });
  };

  const handleParcelCategoryChange = (e) => {
    form.setFieldsValue({
      parcelId: undefined,
      parcelSectorId: undefined,
    });
    setParcelCategoryId(e);
  };
  const onChange = (date, dateString) => {
    if (date && Array.isArray(date)) {
      const nextDay = new Date(date[1]);
      nextDay.setDate(nextDay.getDate() + 1);
      const date2 = [...date, nextDay]
      setDate(date2)
      props.setDate(date2)
    }
    if (date && !Array.isArray(date)) {
      const nextDay = new Date(date);
      nextDay.setDate(nextDay.getDate() + 1);
      const date2 = [date, nextDay]
      setDate(date2)
      props.setDate(date2)
    }
  }
  const onChange2 = (date, dateString) => {
    if (date) {
      const nextDay = new Date(date?.[1]);
      nextDay.setDate(nextDay.getDate() + 1);
      const date2 = [...date, nextDay]
      setDate2(date2)
      props.setDate2(date2)
    }

  }
  const handleParcelChange = (e, index) => {
    const all = [...tasks];
    all[index].parcelId = e;
    setTasks(all);
    const sects = options.parcels.filter((s) => e.includes(s.id));
    let sum = 0;
    sects.forEach((sec) => {
      sum += sec.area;
    });
    form.setFieldValue("total", sum.toFixed(3));


  };


  const handlePositionChange = (e, index) => {
    const all = [...tasks];
    let values = form.getFieldsValue();
    values.WorkPlanTasks[index].worker = undefined;
    form.setFieldsValue({ ...values });
    agros.get(`data/workers/${e}`).then((res) => {
      all[index].workers = res.data;
      setTasks(all);
    });
  };

  const handleKeyChange = (e, index, bindex, key, key2) => {
    if (key2 === "fertilizerKindId") {
      // tasks.map((p, index) => {
      //   p.medicines.map((m, bindex) => {
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        bindex,
        "mainIngredientId",
      ]]);
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        bindex,
        "productId",
      ]])
      // }
      //   )
      // })

    }
    if (key2 === "mainIngredientId") {
      // tasks.map((p, index) => {
      //   p.medicines.map((m, bindex) => {
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        bindex,
        "productId",
      ]])
      //   }
      //   )
      // })

    }
    if (key2 === "cropCategoryId") {
      form.resetFields("cropsId");
      form.resetFields("cropSortId")
    }
    if (key2 === "cropsId") {
      form.resetFields("cropSortId")
    }
    const all = [...tasks];
    all[index][key][bindex][key2] = e;
    setTasks(all);
  };
  

  const handleCropChange = (e, key) => {
    if (key === "cropCategoryId") {
      form.setFieldsValue({ cropsId: undefined })
      form.setFieldsValue({ cropSortId: undefined })
    }
    if (key === "cropsId") {
      form.setFieldsValue({ cropSortId: undefined })
    }

    let all = { ...params };
    if (key === "cropCategoryId") {
      all["cropCategoryId"] = e;
    }
    if (key === "cropsId") {
      all["cropsId"] = e;
    }
    setParams(all);
  };

  const handleAmountChange = (e, index, bindex) => {
    const values = form.getFieldsValue();
    let val = +e * area;
    values.WorkPlanTasks[index].WorkPlanTaskFertilizers[bindex].Quantity = val;

    const all = [...tasks];
    all[index].medicines[bindex]["quantity"] = val;
    setTasks(all);
    form.setFieldsValue({ ...values });
  };
  const handleAnnualPlanChange = (e) => {
   
    workPlanTasks.map((r) => {
      let area = 0;
      if (r.id === e) {
        setPeriod(r.annualWorkPlanPeriod);
        const at = r.parcels.map(elem => elem.parcelId)
        console.log(at);
        agros.get("Data/Parcels").then((res) => {
         
          setParcel(res.data.filter((elem) => at.includes(elem.id)))
        }
        )
        
      
        setCropMeasure(r.crop.measurementUnit)
        const parcelId = r.parcels?.map((a) => {
          return a.parcelId;
        }
        )
        parcelId.forEach((a) => {
          area += options.parcels.find((p) => p.id === a).area;
        });
        setArea(area);
        //setArea(options.parcels.find((p) => e.includes(p.id)).area);
        // setSector(r.parcels.flatMap(a => {
        //   return a.parcelSectorIdList;
        // }))

        tasks.map((p, index) => {
          form.resetFields(["WorkPlanTasks", index, "RespondentId"]);
        })

        form.resetFields(["parcelId"]);
        form.setFieldsValue({
          cropCategoryId: r.crop.cropCategory,
          cropsId: r.crop.crop,
          cropSortId: r.crop.name,
          quantity: r.crop.quantity,
          parcelId: r.parcels.map((a) => {
            return a.parcelId;
          }),
          parcelCategoryId: (options.parcelCategories.find((elem) => elem.id == options.parcels.filter((s) => parcelId.includes(s.id))[0].parcelCategoryId)).id,

        });
      }
    });

  };
  console.log(parcel);
  const filterOption = (input, option) => (option?.children.toLowerCase().includes(input.toLowerCase()))
console.log(fileList);
  return (
    <>
      <Form onFinish={savePlan} layout="vertical" form={form}>
        <div className="commontask">
          <Row gutter={[8, 16]}>
            <Col lg={8} md={8} sm={12} xs={24}>
              <Form.Item
                label={t("yearly")}
                name="AnnualWorkPlanId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("mustSelectWorkPlan"))]}
              >
                <Select onChange={(e) => handleAnnualPlanChange(e)}>
                  {workPlanTasks.map((w, index) => {
                    return (
                      <Option key={index} value={w.id}>
                        {w.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={8} md={8} sm={12} xs={24}>
              <Form.Item
                label={t("WorkPlanName")}
                name="name"
                validateTrigger="onChange"
                rules={[whiteSpace(t("inputError"))]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
        
            {
              lastDate ?
                <Col lg={8} md={8} sm={12} xs={24}>
                  <Form.Item
                    label={<div className="flex flex-between w-100 flex-align-center">
                      {t("date")}
                      <div className="flex flex-align-center">
                        <Switch checked={lastDate} onChange={(e) => {
                          setLastDate(e);
                          tasks.map((p, index) => {
                            form.resetFields(["WorkPlanTasks", index, "startdate"]);
                          })
                        }} />
                        <span className="ml-10">Bitmə tarixi</span>
                      </div>
                    </div>
                    }
                    name="Date"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("dateError"))]}
                  >
                    <RangePicker style={{ width: '100%' }} disabledDate={disabledDate1} onChange={onChange} placeholder={['Başlama Tarixi', 'Bitmə Tarixi']}
                      format="DD-MM-YYYY"
                    />
                  </Form.Item>
                </Col>
                :
                <Col lg={8} md={8} sm={12} xs={24} >
                  <Form.Item
                    label={<div className="flex flex-between w-100 flex-align-center">
                      {t("startDate")}
                      <div className="flex flex-align-center">
                        <Switch checked={lastDate} onChange={(e) => setLastDate(e)} />
                        <span className="ml-10">Bitmə tarixi</span>
                      </div>
                    </div>
                    }
                    name={"StartDate"}
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("dateError"))]}
                  >
                    <DatePicker
                      placeholder={t("selectDate")}
                      className="w-100"
                      onChange={onChange}
                      disabledDate={disabledDate1}
                    />
                  </Form.Item>
                </Col>

           } 
            <Col lg={6} md={8} sm={12} xs={24}>
              <Form.Item
                label={t("areaType")}
                name="parcelCategoryId"
                validateTrigger="onChange"

              >
                <Select onChange={handleParcelCategoryChange} disabled={true}>
                  {options.parcelCategories.map((w, index) => {
                    return (
                      <Option key={index} value={w.id}>
                        {w.name}
                      </Option>
                    );
                  })}
                </Select>
                {/* bu her zaman disabled olacaq gelen data oturacaq bura ve deyisdire bilmeyecek client */}
              </Form.Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <Form.Item
                label={t("sector")}
                name="parcelId"
                validateTrigger="onChange"
              >
                <Select mode="multiple" disabled={true}>
                   { parcel.map((w, index) => {
                        console.log(w);
                        return (
                          <Option key={index} value={w.id}>
                            {w.name}
                          </Option>
                        );
                      })}
                </Select>
                {/* bu her zaman disabled olacaq gelen data oturacaq bura ve deyisdire bilmeyecek client */}
              </Form.Item>
            </Col>
            <Col className="pt-15" lg={12} md={24} sm={24} xs={24}>
              <h3
                className="bold mt-20 flex all-center"
                style={{ height: "40px" }}
              >
                {t("totalArea")} {area}
                <span>
                  {/*M<sup>2</sup>*/}
                  ha
                </span>
              </h3>
            </Col>
            <Col lg={6} md={6} sm={12} xs={24}>
              <Form.Item
                label={t("productCategory")}
                name="cropCategoryId"
                validateTrigger="onChange"
              >
                <Select
                  disabled={true}
                  onChange={(e) =>
                    handleCropChange(
                      e,
                      "cropCategoryId"
                    )
                  }
                >
                  {options.cropCategories.map((f, findex) => {
                    return (
                      <Option key={findex} value={f.id}>
                        {f.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={6} md={6} sm={12} xs={24}>
              <Form.Item
                label={t("product")}
                name="cropsId"
                validateTrigger="onChange"
              >
                <Select
                  disabled={true}
                  onChange={(e) =>
                    handleCropChange(
                      e,
                      "cropsId"
                    )
                  }
                >
                  {options.crops
                    .filter(
                      (p) => params?.cropCategoryId == p.categoryId
                    )
                    .map((f, findex) => {
                      return (
                        <Option key={findex} value={f.id}>
                          {f.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={6} md={6} sm={12} xs={24}>
              <Form.Item
                label={t("productType")}
                name="cropSortId"
                validateTrigger="onChange"
              >
                <Select
                  disabled={true}
                  onChange={(e) =>
                    handleCropChange(
                      e,
                      "cropSortId"
                    )
                  }
                >
                  {options.cropSorts
                    .filter(
                      (p) => params?.cropsId == p.categoryId
                    )
                    .map((f, findex) => {
                      return (
                        <Option key={findex} value={f.id}>
                          {f.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={6} md={6} sm={12} xs={24}>
              <div className="form-lang">
                <Form.Item

                  label={t("quantity")}
                  name="quantity"
                  validateTrigger="onChange"
                >

                  <InputNumber className="w-100" size="large" disabled={true} />


                </Form.Item>
                <span className="input-lang btm">
                  {cropMeasure}
                </span>
              </div>
            </Col>
          </Row>
          {tasks.map((d, index) => {
            return (
              <div key={index} className="task1 border mt-15 p-1">
                <Row gutter={[16, 16]}>

                  <Col xs={24}>
                    <div className="w-100  flex-align-center flex flex-between">
                      <h3>
                        {t("task")} {index + 1}
                      </h3>
                      <div className="flex">
                        {tasks.length > 1 ? (
                          <Button
                            className="mr5-5 btn-danger"
                            onClick={() => deleteTask(index)}
                          >
                            {t("delete")}
                          </Button>
                        ) : null}
                        {index === tasks.length - 1 ? (
                          <Button type="primary" onClick={() => addTask(index)}>
                            {t("addtask")}
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("Dövr")}
                      name={["WorkPlanTasks", index, "AnnualPlanPeriodId"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("mustSelectWorkPeriod"))]}
                    >
                      <Select >
                        {period.map((w, index) => {
                          return (
                            <Option key={index} value={w.id}>
                              {w.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={'Ediləcək işin növü'}
                      name={["WorkPlanTasks", index, "ToDoId"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <Select>
                        {options.todos.map((pc) => {
                          return (
                            <Option key={pc.id} value={pc.id}>
                              {pc.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("date")}
                      name={["WorkPlanTasks", index, "startdate"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("dateError"))]}
                    >
                      <RangePicker disabledDate={disabledDate} onChange={onChange2} placeholder={['Başlama Tarixi', 'Bitmə Tarixi']}
                        format="DD-MM-YYYY"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <Form.Item
                      label={t("sector")}
                      name={["WorkPlanTasks", index, "parcelId"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("selectCrop"))]}
                    >
                      <Select mode="multiple" onChange={(e) => handleParcelChange(e, index)} >
                        {
                          parcel.map((w, index) => {

                            console.log(w);
                            return (
                              <Option key={index} value={w.id}>
                                {w.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      label={t("endDate")}
                      name={["WorkPlanTasks", index, "enddate"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("dateError"))]}
                    >
                      <DatePicker
                        placeholder={t("selectDate")}
                        className="w-100"
                      />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24}>
                    <Form.Item
                      name={["WorkPlanTasks", index, "Description"]}
                      validateTrigger="onChange"
                      rules={[whiteSpace(t("inputError"))]}
                    >
                      <TextArea
                        placeholder={t("additionalNote")}
                        allowClear
                        rows={4}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      label={t("Məsul şəxsin vəzifəsi")}
                      name={["WorkPlanTasks", index, "position"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("positionMustSelectError"))]}
                    >
                      <Select onChange={(e) => handlePositionChange(e, index)}>
                        {options.positions.map((w, index) => {
                          return (
                            <Option key={index} value={w.id}>
                              {w.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      label={t("Məsul şəxsin adı")}
                      name={["WorkPlanTasks", index, "respondentId"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("personMustSelect"))]}
                    >
                      <Select>
                        {d.workers.map((w, index) => {
                          return (
                            <Option key={index} value={w.id}>
                              {w.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      label={t("manWorkerNumber")}
                      name={["WorkPlanTasks", index, "ManWorkerCount"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("numberMustAdd"))]}
                    >
                      <InputNumber size='large' className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      label={t("womanWorkerNumber")}
                      name={["WorkPlanTasks", index, "WomanWorkerCount"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("numberMustAdd"))]}
                    >
                      <InputNumber size='large' className="w-100" />
                    </Form.Item>
                  </Col>
                </Row>
                {/*medicine*/}
                {d.medicines.map((m, bindex) => {
                  return (
                    <Col span={24} key={bindex}>
                      <div className="medicine1 p-1 mt-5 border">
                        <Row gutter={[8, 16]}>
                          <Col xs={24}>
                            <div className="w-100  flex-align-center flex flex-between flex-wrap">
                              <h3>
                                {t("medicine")} {bindex + 1}
                              </h3>
                              <div className="flex">
                                <Button
                                  className="mr5-5 btn-danger"
                                  onClick={() => deleteMedicine(index, bindex)}
                                >
                                  {t("delete")}
                                </Button>
                                {bindex === d.medicines.length - 1 ? (
                                  <Button
                                    onClick={() => addMedicine(index)}
                                    className="w-100 flex all-center"
                                    type="primary"
                                  >
                                    Dərman əlavə et
                                  </Button>
                                ) : null}
                              </div>
                            </div>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label={t("typeOf")}
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskFertilizers",
                                bindex,
                                "fertilizerKindId",
                              ]}
                              validateTrigger="onChange"
                              rules={[noWhitespace(t("inputError"))]}
                            >
                              <Select
                                onChange={(e) => {
                                  handleKeyChange(
                                    e,
                                    index,
                                    bindex,
                                    "medicines",
                                    "fertilizerKindId"
                                  );
                                }}
                              >
                                {options.fertilizerKinds.map((w, index) => {
                                  return (
                                    <Option key={index} value={w.id}>
                                      {w.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label={t("activeSubstance")}
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskFertilizers",
                                bindex,
                                "mainIngredientId",
                              ]}
                              validateTrigger="onChange"
                              rules={[noWhitespace(t("inputError"))]}
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={(e) => {
                                  handleKeyChange(
                                    e,
                                    index,
                                    bindex,
                                    "medicines",
                                    "mainIngredientId"
                                  );
                                }}
                              >
                                {options.mainIngredients.map((w, index) => {
                                  return (
                                    <Option key={index} value={w.id}>
                                      {w.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label={t("name")}
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskFertilizers",
                                bindex,
                                "productId",
                              ]}
                              validateTrigger="onChange"
                              rules={[noWhitespace(t("inputError"))]}
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={(e) => {
                                  handleKeyChange(
                                    e,
                                    index,
                                    bindex,
                                    "medicines",
                                    "fertilizerId"
                                  );
                                }}
                              >
                                {m.mainIngredientId &&
                                  options.fertilizers
                                    .filter(
                                      (f) =>
                                        f.mainIngredientId ===
                                        m.mainIngredientId &&
                                        f.fertilizerKindId ===
                                        m.fertilizerKindId
                                    )
                                    .map((w, index) => {
                                      return (
                                        <Option key={index} value={w.id}>
                                          {w.name}
                                        </Option>
                                      );
                                    })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label={t("quantity")}
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskFertilizers",
                                bindex,
                                "Amount",
                              ]}
                              validateTrigger="onChange"
                              rules={[noWhitespace(t("inputError"))]}
                            >
                              <div className="form-lang">
                                <InputNumber
                                  onChange={(e) =>
                                    handleAmountChange(e, index, bindex)
                                  }
                                  // size='large'
                                  className="w-100"
                                />
                                <span className="input-lang">
                                  {
                                    options.fertilizers.find(
                                      (f) => f.id === m.fertilizerId
                                    )?.measurementUnit
                                  }{" "}
                                  / m<sup>2</sup>
                                </span>
                              </div>
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label={t("finalAmount")}
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskFertilizers",
                                bindex,
                                "Quantity",
                              ]}
                            >
                              <div className="form-lang">
                                <InputNumber
                                  value={m.quantity}
                                  className="w-100"
                                  // size='large'
                                  disabled={true}
                                />
                                <span className="input-lang">
                                  {
                                    options.fertilizers.find(
                                      (f) => f.id === m.fertilizerId
                                    )?.measurementUnit
                                  }
                                </span>
                              </div>
                            </Form.Item>
                          </Col>

                          {/* <Col md={6} sm={12} xs={24}>
                            <Form.Item
                              label={t("Təxmini ümumi qiymət")}
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskFertilizers",
                                bindex,
                                "Sum",
                              ]}
                              validateTrigger="onChange"
                              rules={[
                                noWhitespace(t("inputError")),
                              ]}
                            >
                              <div className="form-lang border-red">
                                <InputNumber className="w-100" disabled={true} />
                                <span className="input-lang">
                                azn
                                </span>
                              </div>
                            </Form.Item>
                          </Col> */}
                        </Row>
                      </div>
                    </Col>
                  );
                })}
                {/*reserves*/}
                {d.reserves.map((r, dindex) => {
                  return (
                    <Col xs={24} key={dindex}>
                      <div className="border p-1 mt-5">
                        <Row gutter={[16, 16]}>
                          <Col xs={24}>
                            <div className="w-100  flex-align-center flex flex-between flex-wrap">
                              <h3>
                                {t("reserv")} {dindex + 1}
                              </h3>
                              <div className="flex">
                                <Button
                                  onClick={() => deleteReserve(index, dindex)}
                                  className="mr5-5 btn-danger"
                                >
                                  {t("delete")}
                                </Button>
                                {dindex === d.reserves.length - 1 ? (
                                  <Button
                                    onClick={() => addReserve(index)}
                                    className="w-100 flex all-center"
                                    type="primary"
                                  >
                                    Ehtiyat əlavə et
                                  </Button>
                                ) : null}
                              </div>
                            </div>
                          </Col>

                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label={'Ehtiyat kateqoriyası'}
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskReserves",
                                dindex,
                                "productId",
                              ]}
                              validateTrigger="onChange"
                              rules={[noWhitespace(t("inputError"))]}
                            >
                              <Select
                                onChange={(e) =>
                                  handleKeyChange(
                                    e,
                                    index,
                                    dindex,
                                    "reserves",
                                    "reserveId"
                                  )
                                }
                              >
                                {options.reserves.map((f, findex) => {
                                  return (
                                    <Option key={findex} value={f.id}>
                                      {f.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label={t("quantity")}
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskReserves",
                                dindex,
                                "quantity",
                              ]}
                              validateTrigger="onChange"
                              rules={[noWhitespace(t("inputError"))]}
                            >
                              <div className="form-lang">
                                <InputNumber className="w-100" size='large' />
                                <span className="input-lang">
                                  {
                                    options.reserves.find(
                                      (f) => f.id === r.reserveId
                                    )?.measurementUnit
                                  }
                                </span>
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  );
                })}
                {/*reserves*/}
                {d.equipments.map((e, eindex) => {
                  return (
                    <Col xs={24} key={eindex}>
                      <div className="border p-1">
                        <Row gutter={[16, 16]}>
                          <Col xs={24}>
                            <div className="w-100  flex-align-center flex flex-between flex-wrap">
                              <h3>Texnika {eindex + 1}</h3>
                              <div className="flex">
                                <Button
                                  onClick={() => deleteEquipment(index, eindex)}
                                  className="mr5-5 btn-danger"
                                >
                                  {t("delete")}
                                </Button>
                                {d.equipments.length &&
                                  eindex === d.equipments.length - 1 ? (
                                  <Button
                                    onClick={() =>
                                      addEquipent(index)
                                    }
                                    type="primary"
                                  >
                                    Texnika əlavə et
                                  </Button>
                                ) : null}
                              </div>
                            </div>
                          </Col>

                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="Texnikanın kateqoriyası"
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskEquipments",
                                eindex,
                                "equipmentCategory",
                              ]}
                              validateTrigger="onChange"
                              rules={[
                                noWhitespace(t("inputError")),
                              ]}
                            >
                              <Select>
                                {options.tools.map((f, findex) => {
                                  return (
                                    <Option
                                      key={findex}
                                      value={f.id}
                                    >
                                      {f.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          {/* <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="Texnikanın markası"
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskEquipments",
                                eindex,
                                "equipmentBrand",
                              ]}
                              validateTrigger="onChange"
                              rules={[
                                noWhitespace(t("inputError")),
                              ]}
                            >
                              <Select>
                                {options.tools.map((f, findex) => {
                                  return (
                                    <Option
                                      key={findex}
                                      value={f.id}
                                    >
                                      {f.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="Texnikanın növü"
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskEquipments",
                                eindex,
                                "equipmentType",
                              ]}
                              validateTrigger="onChange"
                              rules={[
                                noWhitespace(t("inputError")),
                              ]}
                            >
                              <Select>
                                {options.tools.map((f, findex) => {
                                  return (
                                    <Option
                                      key={findex}
                                      value={f.id}
                                    >
                                      {f.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="Texnikanın modeli"
                              name={[
                                "WorkPlanTasks",
                                index,
                                "WorkPlanTaskEquipments",
                                eindex,
                                "equipmentModel",
                              ]}
                              validateTrigger="onChange"
                              rules={[
                                noWhitespace(t("inputError")),
                              ]}
                            >
                              <Select>
                                {options.tools.map((f, findex) => {
                                  return (
                                    <Option
                                      key={findex}
                                      value={f.id}
                                    >
                                      {f.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col> */}

                          <Col md={8} sm={12} xs={24}>
                            <div className="form-lang">
                              <Form.Item
                                label={'Təxmini yanacaq'}
                                name={[
                                  "WorkPlanTasks",
                                  index,
                                  "WorkPlanTaskEquipments",
                                  eindex,
                                  "approximatedFuel",
                                ]}
                                validateTrigger="onChange"
                                rules={[
                                  noWhitespace(t("inputError")),
                                ]}
                              >
                                <InputNumber size='large' className="w-100" />
                              </Form.Item>
                              <div className="input-lang btm">litr</div>
                            </div>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <div className="form-lang">
                              <Form.Item
                                className="mb-5"
                                label={'Planda verilən yanacaq'}
                                name={[
                                  "WorkPlanTasks",
                                  index,
                                  "WorkPlanTaskEquipments",
                                  eindex,
                                  "PlannedFuel",
                                ]}
                                validateTrigger="onChange"
                                rules={[
                                  noWhitespace(t("inputError")),
                                ]}
                              >
                                <InputNumber size='large' className="w-100" />
                              </Form.Item>
                              <div className="input-lang btm">litr</div>
                            </div>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <div className="form-lang">
                              <Form.Item
                                className="mb-5"
                                label={'Dövlət nişanı'}
                                name={[
                                  "WorkPlanTasks",
                                  index,
                                  "WorkPlanTaskEquipments",
                                  eindex,
                                  "PlateNumer",
                                ]}
                                validateTrigger="onChange"
                                rules={[
                                  noWhitespace(t("inputError")),
                                ]}
                              >
                                <Input size={'large'} className="w-100" />
                              </Form.Item>
                            </div>
                          </Col>

                        </Row>
                      </div>
                    </Col>
                  );
                })}

                <Col xs={24}>
                  <Row gutter={8}>
                    {!d.medicines.length ? (
                      <Col md={5} sm={12} xs={24}>
                        <div>
                          <Button
                            onClick={() => addMedicine(index)}
                            className="w-100 flex all-center formButton"
                            type="primary"
                          >
                            {t("addMedicine")}
                          </Button>
                        </div>
                      </Col>
                    ) : null}
                    {!d.reserves.length ? (
                      <Col md={5} sm={12} xs={24}>
                        <div>
                          <Button
                            onClick={() => addReserve(index)}
                            className="w-100 flex all-center formButton"
                            type="primary"
                          >
                            {t("addReserve")}
                          </Button>
                        </div>
                      </Col>
                    ) : null}
                    {!d.equipments.length ? (
                      <Col md={5} sm={12} xs={24}>
                        <div>
                          <Button
                            onClick={() => addEquipent(index)}
                            className="w-100 flex all-center formButton"
                            type="primary"
                          >
                            Texnika əlavə et
                          </Button>
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </div>
            );
          })}
          <div
            className="modalButtons"
            style={{ position: "absolute", bottom: "20px", right: "40px" }}
          >
            <Button onClick={() => {
              props.setVisibleAddNewPlan(false);
              form.resetFields();
              setArea(0);
              setLastDate(false);
              setTasks([
                { id: 1, medicines: [], workers: [], reserves: [], tools: [], crops: [], equipments: [], },
              ]);
            }}>
              {t("cancel")}
            </Button>
            <Button type="primary" className="ml-10" htmlType="submit">
              {t("save")}
            </Button>
          </div>
        </div>
      </Form>

      <Modal
        title="Tələb yarat"
        visible={confirmDemand}
        onOk={createDemandConfirmed}
        okText="Bəli"
        cancelText="Xeyr"
        onCancel={createDemandCancelled}
      >
        <p>
          Günlük plan yaratmaq üçün çatışmayan resurslar var. Bu resurslar üçün
          tələb yaratmaq istəyirsiniz? Tələb üzrə satınalmalar gerçəkləşdikdən
          sonra plan yarada biləcəksiz.
        </p>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ options, lang }) => {
  return { options, lang };
};

export default connect(mapStateToProps, { getOptions, notify })(NewPlan);

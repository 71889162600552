import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

export default function LayerContent({ mapServer, handleChange, lakes,kanallar }) {
    const [status, setStatus] = useState(false)

    const handleChangeMapServer = (id) => {
        let layer = mapServer.sublayers.items[id]
        layer.visible = !layer.visible
        setStatus(!status)
    }

    const handleSubItemVisibleChange = (index, subIndex) => {
        let layer = mapServer.sublayers.items[index]
        let subLayer = layer.sublayers.items[subIndex]
        let visible = subLayer?.visible
        mapServer.sublayers.items.map((layer, index) => {
            if (layer?.sublayers?.length > 0) {
                for (let i = 0; i < layer.sublayers.items.length; i++) {
                    layer.sublayers.items[i].visible = 0
                }
            }
        })
        subLayer.visible = !visible
        handleChange()
        setStatus(!status)
    }

    const handleChangeLakes = () => {
        lakes.visible = !lakes.visible
        setStatus(!status)
    }

    const handleChangeKanals = () => {
        kanallar.visible = !kanallar.visible
        setStatus(!status)
    }

    useEffect(() => {
    }, [status])

    return (
        <div className='flex flex-col w-80 bg-white h-96 overflow-scroll' >
            {
                mapServer?.sublayers?.items.map((item, index) => {
                    return (
                        item?.sublayers?.length > 0 ?
                            item?.sublayers?.items.map((subItem, subIndex) => {
                                return (
                                    <div key={subIndex} className='flex w-full justify-between items-center p-1' >
                                        <span className='flex w-60 overflow-hidden'>{subItem?.title}</span>
                                        <Button key={index} onClick={() => handleSubItemVisibleChange(index, subIndex)} icon={subItem?.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />} />
                                    </div>
                                )
                            })
                            :
                            <div key={index} className='flex w-full justify-between items-center p-1 ' >
                                <span>{item.title}</span>
                                <Button onClick={() => handleChangeMapServer(index)} icon={item?.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />} />
                            </div>
                    )
                })
            }
            <div className='flex w-full justify-between items-center p-1 ' >
                <span>Göllər və süni sututarlar</span>
                <Button onClick={handleChangeLakes} icon={lakes?.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />} />
            </div>
            <div className='flex w-full justify-between items-center p-1 ' >
                <span>Kanallar</span>
                <Button onClick={handleChangeKanals} icon={kanallar?.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />} />
            </div>
        </div>
    )
}
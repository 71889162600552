import React, { useEffect, useState } from "react";
import { Tabs, Button, Tooltip, Table, Modal, Col, Form, Row, Input, DatePicker, Select, Progress, Popconfirm } from "antd";
import {
  UnorderedListOutlined,
  FileSearchOutlined,
  EditFilled,
  FileAddOutlined,
  ClearOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  EyeFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import APurchase from "./Modals/APurchase";
import WPurchase from "./Modals/WPurchase";
import PPurchase from "./Modals/PPurchase";
import agros from "../../../../const/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import { getOptions, notify } from "../../../../redux/actions";
import { noWhitespace } from "../../../../utils/rules";
import PurchaseList from "./Elements/PurchaseList";
import ViewPurchase from "./Modals/ViewPurchase";
const { TabPane } = Tabs;
const { Option } = Select;


const getInitialState = () => {
  return {
    waiting: {
      url: "purchase/waiting",
      data: [],
      selectedObject: null,
      fetched: false,
    },
    approved: {
      url: "purchase/approved",
      data: [],
      selectedObject: null,
      fetched: false,
    },
    preparing: {
      url: "purchase",
      data: [],
      selectedObject: null,
      fetched: false,
    },
    closed: {
      url: "purchase/closed",
      data: [],
      selectedObject: null,
      fetched: false,
    },
  };
};

const Purchases = (props) => {
  const { getOptions, notify } = props;
  const [isClosed, setIsClosed] = useState(false);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const perms = props.perms.warehouse.subs.purchases.subs;
  const [visibleWPurchase, setVisibleWPurchase] = useState(false);
  const [visibleAPurchase, setVisibleAPurchase] = useState(false);
  const [index, setIndex] = useState(0)
  let [trigger, setTrigger] = useState(0);
  const [visiblePPurchase, setVisiblePPurchase] = useState(false);
  const [tables, setTables] = useState(getInitialState());
  const [activeTab, setActiveTab] = useState("waiting");
  const [openP, setOpenP] = useState(false);
  const [demandId, setDemandId] = useState(null)
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "name", value: t("name"), con: true },
    { key: "demandNumber", value: t("demmandNo"), con: true },
    { key: "createDate", value: t("createdDate"), con: true },
    { key: "customerName", value: t("supplier"), con: true },
    { key: "customerPhone", value: t("supplierConnection"), con: true },
    { key: "purchuseApprovedDate", value: t("DateOfApproval"), con: true },
    { key: "approvedWorkerName", value: t("approvedPerson"), con: true },
    { key: "demandPurchaseList", value: '', con: false },
    { key: "index", value: "", con: false },
    { key: "percent", value: "", con: false },
    { key: "allowfinish", value: "", con: false },
    { key: "key", value: "", con: false },
  ];
  console.log(perms.waiting.perms.update);
  const removePurchase = (id) => {
    // setIsClosed(!isClosed);
    agros.put(`Purchase/Finish/${id}`).then((res) => {
      notify("", true);
      triggerFetch()
    }).catch(err => {
      notify(err.response, false);
    });
  };
  useEffect(() => {
    getOptions(
      [
        "users",
        "customers",
      ],
      props.options,
      i18n.language
    );

    const getTableData = () => {
      agros.get(tables[activeTab].url).then((res) => {
        console.log(res.data);
        const all = { ...tables };
        all[activeTab].data = res.data.map((r, index) => {
          console.log(r);
          return {
            ...r,
            key: index + 1,
            index,
            allowfinish: {
              finish: r.allowFinish, id: r.id,
              valueAndIndex: { value: r.estimatedValue, index },
            },
            tableIndex: index + 1,
            createDate:
              r.createDate && moment(r.createDate).format("DD-MM-YYYY, hh:mm"),
            approvedDate:
              r.approvedDate &&
              moment(r.approvedDate).format("DD-MM-YYYY, hh:mm"),
            purchuseApprovedDate:
              r.purchuseApprovedDate &&
              moment(r.purchuseApprovedDate).format("DD-MM-YYYY, hh:mm"),
            customerName: r.customer && r.customer.name,
            customerPhone: r.customer && r.customer.phone,
            percent: getPercent(r.demandProducts)
          };
        });
        all[activeTab].fetched = true;
        setTables(all);
        console.log(all)
      });
    };

    if (!tables[activeTab].fetched) {
      console.log('slaa')
      getTableData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tables, t, trigger]);
  console.log(tables.approved.data);
  const getPercent = (demandProducts) => {
    let length = demandProducts?.length
    console.log(demandProducts)
    if (length) {
      let falseDemandLength = 0
      for (let d of demandProducts) {
        if (d.status === false) {
          falseDemandLength += 1
        }
        else {
          falseDemandLength += d.orderQuantity / d.quantity
        }
      }
      let totalpercent = (falseDemandLength / length) * 100
      return totalpercent.toFixed(1)
    }
  }
  console.log(tables.approved.data);
  const openPurchase = (i) => {
    console.log(i);
    setOpenP(true);
    setDemandId(i);
  }

  const options = props.options[props.lang];
  const getIcon = (p) => {
    console.log(p.record);
    setIndex(p.record.id)
    return p.expanded ? (
      <MinusCircleOutlined
        className="expandingIcon"
        onClick={(e) => p.onExpand(p.record, e)}
      />
    ) : (
      <PlusCircleOutlined
        className="expandingIcon"
        onClick={(e) => p.onExpand(p.record, e)}
      />
    )
  };
  const commonColumns = [
    {
      title: "#",
      key: 1,
      dataIndex: "tableIndex",
      width: window.innerWidth > 991 ? 60 : '100%',
    },
    {
      title: t("name"),
      key: 2,
      dataIndex: "name",
    },
    {
      title: t("demmandNo"),
      key: 3,
      dataIndex: "demandNumber",
    },
    {
      title: t("createdDate"),
      key: 4,
      dataIndex: "createDate",
    },
  ];
  console.log(tables.preparing);
  const waitingTableColumns = commonColumns.concat([
    {
      title: t("status"),
      key: 6,
      dataIndex: "key",
      render: (i) => {
        return (
          <div className="flex statusTd">
            <p>{t("status")}</p>
            <span>
              <Button className="border-none flex all-center  btn-warning" shape="round">
                {t("onWait")}
              </Button>
            </span>
          </div>
        );
      },
    },
    {
      title: "",
      key: 7,
      dataIndex: "index",
      render: (i) => {
        return (
          <div className="flex flex-end">
            {
              perms.waiting.perms.update &&
              <Tooltip className="ml-5" title={t("changeStatus")}>
                <Button
                  onClick={() => viewPurchaseDetails(i, "waiting", 1)}
                  className="border-none"
                  type="text"
                  shape="circle"
                >
                  <EditFilled />
                </Button>
              </Tooltip>
            }

          </div>
        );
      },
    },
  ]);

  const approvedTableColums = commonColumns.concat([
    {
      title: t("status"),
      key: 6,
      dataIndex: "key",
      width: window.innerWidth > 991 ? '' : 30,
      render: (i) => {
        return (
          <div className="flex statusTd">
            <p>{t("status")}</p>
            <span>
              <Button className="border-none flex flex-align-center" type="primary" shape="round">
                {t("accepted")}
              </Button>
            </span>
          </div>
        );
      },
    },
    {
      title: "Bitmə dərəcəsi",
      width: '150px',
      key: 8,
      dataIndex: "percent",

      render: (i) => {
        return (
          <div className={'flex all-center progress'}>
            <Progress percent={i} size="small" />
          </div>
        );
      },
    },
    {
      title: "",
      key: 7,
      dataIndex: "allowfinish",
      render: (i) => {
        return (
          <div className="flex flex-end">
            {
              i.finish ?
                <>
                  <Popconfirm
                    placement="bottomRight"
                    title={'Bitirmək istədiyinizə əminsinizmi?'}
                    okText={t("yes")}
                    cancelText={t("no")}
                    onConfirm={() => removePurchase(i.id)}
                  >
                    <Tooltip placement="left"
                      className="ml-5" title="Tələbdəki məhsullar alınmışdır. Tələbi bitir.">
                      <Button className="border-none red" type="text" shape="circle">
                        <CloseCircleFilled />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </>
                :
                <>
                  {perms.approved.perms.createPurchaseDocument &&
                    props.purchaseLimit > i.valueAndIndex.value ? (
                    <Tooltip className="ml-5" title={t("createPurchaseDocument")}>
                      {/*<Button*/}
                      {/*    onClick={() => viewPurchaseDetails(i.valueAndIndex.index, "approved", 3)}*/}
                      {/*    className="border-none"*/}
                      {/*    type="text"*/}
                      {/*    shape="circle"*/}
                      {/*>*/}
                      {/*  <FileAddOutlined />*/}
                      {/*</Button>*/}
                    </Tooltip>
                  ) : null}
                </>
            }
          </div>

        );
      },
    },
  ]);

  const finishedColumns = commonColumns.concat([
    {
      title: "",
      key: 7,
      dataIndex: "index",
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Tooltip className="ml-5" title={t("demand")}>
              <Button
                onClick={() => { openPurchase(i) }}
                className="border-none"
                type="text"
                shape="circle"
              >
                <FileAddOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ]);


  const preparingColumns = [
    {
      title: "#",
      key: 1,
      dataIndex: "tableIndex",
    },
    {
      title: t("supplier"),
      key: 2,
      dataIndex: "customerName",
    },
    {
      title: t("supplierConnection"),
      key: 3,
      dataIndex: "customerPhone",
    },
    {
      title: t("DateOfApproval"),
      key: 4,
      dataIndex: "purchuseApprovedDate",
    },
    {
      title: t("approvedPerson"),
      key: 5,
      dataIndex: "approvedWorkerName",
    },
    {
      title: "",
      key: 6,
      dataIndex: "index",
      width: '10px',
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Tooltip className="ml-5" title={t("viewPurchaseDocument")}>
              <Button
                onClick={() => viewPurchaseDetails(i, "preparing", 2)}
                className="border-none"
                type="text"
                shape="circle"
              >
                <FileSearchOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  console.log(index);
  console.log(tables.approved.data.filter((elem) => elem.id === index));
  const viewPurchaseDetails = (index, key, method) => {
    const all = { ...tables };
    all[key].selectedObject = all[key].data[index];
    setTables(all);
    switch (method) {
      case 1:
        setVisibleWPurchase(true);
        break;
      case 2:
        setVisiblePPurchase(true);
        break;
      case 3:
        setVisibleAPurchase(true);
        break;
      default:
        break;
    }
  };

  const handleTabChange = (e) => {
    setActiveTab(e);
  };

  const triggerFetch = () => {
    let all = { ...tables };
    all.waiting.fetched = false;
    all.approved.fetched = false;
    all.preparing.fetched = false;
    setTables(all);
  };

  const onSearch = (values) => {
    console.log(values)
    let obj = {}
    // eslint-disable-next-line no-unused-expressions
    values.EndDate?._d ? obj['EndDate'] = values.EndDate?._d : null
    // eslint-disable-next-line no-unused-expressions
    values.StartDate?._d ? obj['StartDate'] = values.StartDate?._d : null
    // eslint-disable-next-line no-unused-expressions
    values.Name ? obj['Name'] = values.Name : null
    // eslint-disable-next-line no-unused-expressions
    values.WorkerId ? obj['WorkerId'] = values.WorkerId : null
    // eslint-disable-next-line no-unused-expressions
    values.CustomerId ? obj['CustomerId'] = values.CustomerId : null

    agros.get(tables[activeTab].url, { params: obj }).then((res) => {
      const all = { ...tables };
      all[activeTab].data = res.data.map((r, index) => {
        return {
          ...r,
          key: index + 1,
          index,
          valueAndIndex: { value: r.estimatedValue, index },
          tableIndex: index + 1,
          createDate:
            r.createDate && moment(r.createDate).format("DD-MM-YYYY, hh:mm"),
          approvedDate:
            r.approvedDate &&
            moment(r.approvedDate).format("DD-MM-YYYY, hh:mm"),
          purchuseApprovedDate:
            r.purchuseApprovedDate &&
            moment(r.purchuseApprovedDate).format("DD-MM-YYYY, hh:mm"),
          customerName: r.customer && r.customer.name,
          customerPhone: r.customer && r.customer.phone,
        };
      });
      all[activeTab].fetched = true;
      setTables(all);
    });
  }


  const clearFilter = () => {
    setTrigger(++trigger)
    triggerFetch()
    form.resetFields()
  }
  console.log(tables.closed.data);

  return (
    <div>
      <Row gutter={[8, 8]}>
      <Col xs={24}>
          <div className="border page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
            <UnorderedListOutlined className="f-20 mr5-15" />
          <span className="f-20 bold">{t("purchases")}</span>
            </div>
            {
          perms.approved.perms.createPurchaseDocument &&
            <Button
              onClick={() => viewPurchaseDetails(null, "approved", 3)}
              type={'primary'} >Sənəd yarat</Button>
        }
          </div>
        </Col>
        </Row>
      <div className="position-relative mt-5">
        <div className="position-absolute w-100 purchase-tabs tab-section">
          <div className={'w-100'}>
            <Form onFinish={onSearch} layout="vertical" form={form}>
              <div className="commontask bg-white px-2 pt-15">
                <Row className={'mt-5'} gutter={[16, 16]}>
                  {activeTab !== 'preparing' &&
                    <Col md={5} sm={12} xs={24}>
                      <Form.Item
                        name="Name"
                        className={'mb-0'}
                      // rules={[whiteSpace(t("inputError"))]}
                      >
                        <Input placeholder={t("demandName")} size={'large'} />
                      </Form.Item>
                    </Col>
                  }

                  <Col md={5} sm={12} xs={24}>
                    <Form.Item
                      name={'StartDate'}
                      className={'mb-0'}
                    // rules={[noWhitespace(t("dataError"))]}
                    >
                      <DatePicker
                        format={"DD-MM-YYYY"}
                        placeholder={'Tarixdən'}
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>

                  <Col md={5} sm={12} xs={24}>
                    <Form.Item
                      name={'EndDate'}
                      className={'mb-0'}
                    // rules={[noWhitespace(t("dataError"))]}
                    >
                      <DatePicker
                        format={"DD-MM-YYYY"}
                        placeholder={'Tarixə'}
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>

                  {activeTab === 'preparing' &&
                    <>
                      <Col md={4} sm={12} xs={24}>
                        <Form.Item
                          name={'WorkerId'}
                          validateTrigger="onChange"
                        >
                          <Select
                            placeholder={'İşçi'}
                            showSearch
                            notFoundContent={null}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {options.users.map((h, index) => {
                              return (
                                <Option key={index} value={h.id}>
                                  {h.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={4} sm={12} xs={24}>
                        <Form.Item
                          name="CustomerId"
                          validateTrigger="onChange"
                        >
                          <Select
                            showSearch
                            notFoundContent={null}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                            placeholder={t('Təchizatçı')}
                          >
                            {options.customers.filter((c) => { return c.positionStatus === 2 }).map((c, index) => {
                              return (
                                <Option key={index} value={c.id}>
                                  {c.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  }

                  <Col md={activeTab !== 'preparing' ? 4 : 3} sm={12} xs={24}>
                    <Form.Item
                      name={" "}
                    >
                      <Button type="primary" size={'large'} className="w-100 f-13" htmlType="submit">
                        {t("search")}
                      </Button>
                    </Form.Item>
                  </Col>

                  <Col md={activeTab !== 'preparing' ? 4 : 3} sm={12} xs={24}>
                    <Form.Item
                      name={" "}
                    >
                      <Button type="primary" size={'large'} onClick={() => { clearFilter() }} className="w-100" htmlType="submit">
                        <ClearOutlined />
                      </Button>
                    </Form.Item>
                  </Col>

                </Row>
              </div>
            </Form>
          </div>


          <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            <TabPane tab={t("onWait")} key="waiting">
              <div className="w-100">
                <Table
                  size="small"
                  className="bg-white"
                  dataSource={convertColumns(tables.waiting.data, cols)}
                  columns={waitingTableColumns}
                  pagination={{
                    pageSize: 10,
                    current_page: 1,
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                  }}
                />
              </div>
            </TabPane>
            <TabPane tab={t("approveds")} key="approved">
              <div className="w-100 approveTable">
                <Table
                  size="small"
                  className="bg-white"
                  columns={approvedTableColums}
                  dataSource={convertColumns(tables.approved.data, cols)}
                  expandedRowRender={(record) => (
                    record.demandPurchaseList?.length > 0 ?
                      <PurchaseList
                        list={record.demandPurchaseList}
                        data={record}

                      /> :
                      <div >
                        Bu satınalma üçün sənəd yoxdur
                      </div>
                  )}


                  expandable={{}}
                  expandIcon={(props) => getIcon(props)}
                  pagination={{
                    pageSize: 7,
                    current_page: 1,
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                  }}
                />
              </div>
            </TabPane>

            <TabPane tab={'Bitmiş tələblər'} key="closed">
              <div className="w-100 approveTable">
                <Table
                  size="small"
                  className="bg-white"
                  columns={finishedColumns}
                  dataSource={convertColumns(tables.closed.data, cols)}
                  expandedRowRender={(record, i) => (
                    tables.closed.data[i].demandPurchaseList?.length > 0 ?
                      <PurchaseList
                        list={tables.closed.data[i].demandPurchaseList}
                        data={tables.closed.data[i]}
                      /> :
                      <>
                        Bu satınalma üçün sənəd yoxdur
                      </>
                  )}
                  expandIcon={(props) => getIcon(props)}
                  pagination={{
                    pageSize: 10,
                    current_page: 1,
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                  }}
                />
              </div>
            </TabPane>

            <TabPane tab={t("preparedDocuments")} key="preparing">
              <div className="w-100">
                <Table
                  size="small"
                  className="bg-white"
                  columns={preparingColumns}
                  dataSource={convertColumns(tables.preparing.data, cols)}
                  pagination={{
                    pageSize: 10,
                    current_page: 1,
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                  }}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>

      <Modal
        title={t("detailedInfo")}
        centered
        className="addTaskModal padModal"
        onOk={() => setVisibleWPurchase(false)}
        onCancel={() => setVisibleWPurchase(false)}
        visible={visibleWPurchase}
        footer={null}
      >
        <WPurchase
          purchase={tables.waiting.selectedObject}
          visibleWPurchase={visibleWPurchase}
          setVisibleWPurchase={setVisibleWPurchase}
          triggerFetch={triggerFetch}
        />
      </Modal>


      <Modal
        title={
          <div className="status">
            <div className="flex flex-align-center flex-between">
              {tables.closed.data[demandId] ? (
                <>
                  <h3>
                    {tables.closed.data[demandId].name} / №
                    {tables.closed.data[demandId].demandNumber}
                  </h3>
                </>
              ) : null}
            </div>
          </div>
        }
        centered
        className="addTaskModal padModal"
        onOk={() => setOpenP(false)}
        onCancel={() => setOpenP(false)}
        visible={openP}
        footer={null}
      >
        <ViewPurchase
          demand={tables.closed.data[demandId]}
          openP={openP}
          setOpenp={setOpenP}
          triggerFetch={triggerFetch}
        />
      </Modal>

      <Modal
        title={t("createNewPurchaseDocument")}
        centered
        className="mediumModal padModal"
        onOk={() => {
          setVisibleAPurchase(false)
          setTrigger(++trigger)
        }}
        onCancel={() => {
          setVisibleAPurchase(false)
          setTrigger(++trigger)
        }}
        visible={visibleAPurchase}
        footer={null}
      >
        <APurchase
          purchase={tables.approved.selectedObject}
          visibleAPurchase={visibleAPurchase}
          setVisibleAPurchase={setVisibleAPurchase}
          triggerFetch={triggerFetch}
          trigger={trigger}
        />
      </Modal>

      <Modal
        title={t("detailedInfo")}
        centered
        className="addTaskModal"
        onOk={() => setVisiblePPurchase(false)}
        onCancel={() => setVisiblePPurchase(false)}
        visible={visiblePPurchase}
        footer={null}
      >
        <PPurchase
          purchase={tables.preparing.selectedObject}
          visiblePPurchase={visiblePPurchase}
          setVisiblePPurchase={setVisiblePPurchase}
          triggerFetch={triggerFetch}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ user, options, lang }) => {
  return {
    perms: user.data.userPermissions,
    purchaseLimit: user.data.purchaseLimit,
    options,
    lang
  };
};
export default connect(mapStateToProps, { getOptions, notify })(Purchases);
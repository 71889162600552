import React, { useEffect, useState } from "react";
import {
  Col,
  Button,
  Row,
  Input,
  Select,
  InputNumber,
  DatePicker,
  notification,
  Form,
  Modal,
  Switch
} from "antd";
import { useTranslation } from "react-i18next";
import { getOptions, notify } from "../../../../../redux/actions";
import { connect } from "react-redux";
import { whiteSpace, noWhitespace } from "../../../../../utils/rules";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import agros from "../../../../../const/api";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

const NewTask = (props) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [confirmDemand, setConfirmDemand] = useState(false);
  const [demandValues, setDemandValues] = useState([]);
  const [errorMessage, setErrorMessage] = useState(true);
  const [parcelData, setParcelData] = useState([])
  const [lastDate, setLastDate] = useState([])
  const [periods, setPeriods] = useState([
    {
      id: 0,
      status: true,
      lastDate: false,
      annualWorkPlanPeriodTask: [
        {
          id: 0,
          status: true,
          annualWorkPlanTaskFertilizer: [],
          sectors: [{ id: 0 }],
          crops: [],
          annualWorkPlanTaskReserves: [],
          tools: [],

          equipments: [],
        },
      ],
    },
  ]);
  const [params, setParams] = useState({});
  const [names, setNames] = useState([]);
  const [name, setName] = useState("");
  const { getOptions, notify } = props;
  const options = props.options[props.lang];

  const createDemandCancelled = () => {
    setConfirmDemand(false);
  };

  const createDemandConfirmed = () => {
    agros
      .post("demand", {
        name: `"${name}" adlı illik plan üçün üçün tələb`,
        demandProduct: demandValues,
      })
      .then((res) => {
        notification.info({
          message: "Əməliyyat uğurlu oldu",
          description: "Yeni tələb yaradıldı",
          icon: <SmileOutlined />,
        });
        form.resetFields();
        props.setVisibleAddNewTask(false);
        setConfirmDemand(false);
      })
      .catch((err) => {
        notify(err.response, false);
      });
  };
  const handleThirdKeyChange = (e, index, second_index, third_index, key) => {
    if (key === "fertilizerKindId") {
      form.resetFields([[
        "annualWorkPlanPeriod",
        index,
        "annualWorkPlanPeriodTask",
        second_index,
        "annualWorkPlanTaskFertilizer",
        third_index,
        "mainIngredientId",
      ]]);
      form.resetFields([[
        "annualWorkPlanPeriod",
        index,
        "annualWorkPlanPeriodTask",
        second_index,
        "annualWorkPlanTaskFertilizer",
        third_index,
        "productId",
      ]])
    }
    if (key === "mainIngredientId") {
      form.resetFields([[
        "annualWorkPlanPeriod",
        index,
        "annualWorkPlanPeriodTask",
        second_index,
        "annualWorkPlanTaskFertilizer",
        third_index,
        "productId",
      ]])
    }
    const all = [...periods];
    all[index]["annualWorkPlanPeriodTask"][second_index]["annualWorkPlanTaskFertilizer"][third_index][key] = e;
    console.log(all);
    setPeriods(all);
  };

  const handleFertilizerAmountChange = (
    e,
    index,
    second_index,
    third_index
  ) => {
    const all = form.getFieldsValue();
    let sum =
      all.annualWorkPlanPeriod[index].annualWorkPlanPeriodTask[second_index][
      "total"
      ] * e;
    all.annualWorkPlanPeriod[index].annualWorkPlanPeriodTask[
      second_index
    ].annualWorkPlanTaskFertilizer[third_index]["final"] = sum;
    form.setFieldsValue(all);
    const all2 = [...periods];
    all2[index]["annualWorkPlanPeriodTask"][second_index]["annualWorkPlanTaskFertilizer"][third_index][
      "final"
    ] = sum.toFixed(3);
    setPeriods(all2);
  };

  const handleThirdCropChange = (e, key) => {
    let all = { ...params };
    if (key === "cropCategoryId") {
      all["cropCategoryId"] = e;
    }
    if (key === "cropsId") {
      all["cropsId"] = e;
    }
    setParams(all);
    const all2 = [...periods];
    setPeriods(all2);
    if (key === "cropCategoryId") {
      form.setFieldValue("cropsId", undefined);
      form.setFieldValue("cropSortId", undefined);
    }
    if (key === "cropsId") {
      form.setFieldValue("cropSortId", undefined);
    }
    // const all = [...periods];
    // all[index]["annualWorkPlanPeriodTask"][second_index]["crops"][third_index][key] = e;
    // setPeriods(all);
  };
  useEffect(() => {
    form.resetFields();
    if (props.task) {
      setTimeout(() => {
        agros.get(`annualworkplan/${props.task}`).then((res) => {
          const parcelId = res.data.annualWorkPlanParcels?.map((a) => {
            return a.parcelId;
          }
          )
          const pars = { ...params };
          pars["parcelCategoryId"] = (options.parcelCategories.find((elem) => elem.id == options.parcels.filter((s) => parcelId.includes(s.id))[0].parcelCategoryId)).id;
          pars['parcelId'] = parcelId

          pars['cropsId'] = (options.crops.find((elem) => elem.id == (options.cropSorts.filter((s) => res.data.cropSortId == s.id))[0]?.categoryId)).id

          pars['cropCategoryId'] = (options.cropCategories.find((elem) => elem.id == (options.crops.filter((s) => pars['cropsId'] == s.id))[0]?.categoryId)).id
          setParams(pars)
          const sects = options.parcels.filter((s) => parcelId.includes(s.id));
          let sum = 0;
          sects.forEach((sec) => {
            sum += sec.area;
          });
          form.setFieldValue("total", sum.toFixed(3));
          setLastDate(res.data.annualWorkPlanParcels)
          let formField = {
            parcelId: res.data.annualWorkPlanParcels.map((a) => {
              return a.parcelId;
            }),
            cropSortId: res.data.cropSortId,
            cropsId: (options.crops.find((elem) => elem.id == (options.cropSorts.filter((s) => res.data.cropSortId == s.id))[0]?.categoryId)).id,
            parcelCategoryId: options.parcelCategories.find((elem) => elem.id == options.parcels.filter((s) => parcelId.includes(s.id))[0].parcelCategoryId).id,
            cropCategoryId: (options.cropCategories.find((elem) => elem.id == (options.crops.filter((s) => pars['cropsId'] == s.id))[0]?.categoryId)).id,
            id: res.data.id,
            name: res.data.name,
            quantity: res.data.quantity,
            price: res.data.price,
            annualWorkPlanPeriod: res.data.annualWorkPlanPeriod.map((a) => {
              return {
                ...a,
                endDate: a.endDate ? moment(a.endDate) : null,
                startDate: moment(a.startDate),
              };
            }),
          };
          form.setFieldsValue(formField);
          res.data.annualWorkPlanParcels.map((p) => {
            options.parcels.map((pc) => {
              if (pc.id === p.parcelId) {
                pc.selectedId = p.id
              }
            });
          });
          const all = [];
          res.data.annualWorkPlanPeriod.forEach((el) => {
            const period = {
              id: el.id,
              status: true,
              lastDate: el.endDate ? true : false,
              annualWorkPlanPeriodTask: el.annualWorkPlanPeriodTask.map((el2) => {
                return {
                  id: el2.id,
                  status: true,
                  annualWorkPlanTaskFertilizer: el2.annualWorkPlanTaskFertilizer?.map((el3) => {
                    return {
                      id: el3.id,
                      status: true,
                      mainIngredientId: el3.mainIngredientId,
                      fertilizerKindId: el3.fertilizerKindId,
                      productId: el3.productId,
                      quantity: el3.quantity,
                      price: el3.price
                    };
                  }),
                  annualWorkPlanTaskReserves: el2?.annualWorkPlanTaskReserves?.map((el6) => {
                    return { id: el6.id, status: true, };
                  }),
                  tools: [],
                  equipments: [],
                };
              }),
            }
            all.push(period);

          });
          setPeriods(all);
        });
      }, 1000)

    }
    getOptions(
      [
        "fertilizerKinds",
        "parcelCategories",
        "parcels",
        "mainIngredients",
        "todos",
        "fertilizers",
        "cropCategories",
        "crops",
        "cropSorts",
        "reserves",
        "tools",
        // "equipments",
      ],
      props.options,
      i18n.language
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trigger, props.task]);
  useEffect(() => {
    form.resetFields();
    setPeriods([
      {
        id: 0,
        status: true,
        annualWorkPlanPeriodTask: [
          {
            id: 0,
            status: true,
            annualWorkPlanTaskFertilizer: [],
            sectors: [{ id: 0 }],
            crops: [],
            annualWorkPlanTaskReserves: [],
            tools: [],
            equipments: [],
          },
        ],
      },
    ]);
    setParams({});
  }, [props.trigger]);

  const addPeriod = (index) => {
    console.log(index);
    const all = [...periods];
    const id = periods.length ? periods[periods.length - 1].id + 1 : 0;

    all.push({ id, status: true, annualWorkPlanPeriodTask: [] });
    const id2 = all[index + 1].annualWorkPlanPeriodTask.length
      ? all[index].annualWorkPlanPeriodTask[all[index].annualWorkPlanPeriodTask.length - 1].id + 1
      : 0;
    console.log(id2);
    form.resetFields([[
      "annualWorkPlanPeriod",
      index + 1,
      "name",
    ]]);
    form.resetFields([[
      "annualWorkPlanPeriod",
      index + 1,
      "startDate",
    ]]);
    form.resetFields([[
      "annualWorkPlanPeriod",
      index + 1,
      "endDate",
    ]]);
    form.resetFields([[
      "annualWorkPlanPeriod",
      index + 1,
      "startDate",
    ]]);
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      id2,
      "annualWorkPlanTaskFertilizer",
      "fertilizerKindId",
    ]])
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      id2,
      "annualWorkPlanTaskFertilizer",
      "productId",
    ]])
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      id2,
      "annualWorkPlanTaskFertilizer",
      "amount",
    ]])
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      id2,
      "annualWorkPlanTaskFertilizer",
      "quantity",
    ]])
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      id2,
      "annualWorkPlanTaskFertilizer",
      "sum",
    ]])

    all[index + 1].annualWorkPlanPeriodTask.push({
      id2,
      status: true,
      annualWorkPlanTaskFertilizer: [],
      sectors: [],
      crops: [],
      annualWorkPlanTaskReserves: [],
      tools: [],
      equipments: []
    });

    setPeriods(all);
  };
  console.log(periods,'addPeriod');
  const deletePeriod = (index) => {
    const all = [...periods];
    if (props.task) {
      all[index].status = false
      form.setFieldValue(["annualWorkPlanPeriod",
        index,
        "status"], false);
    } else {
      all.splice(index, 1);
    }
    setPeriods(all);
  };
  console.log(periods,'deletePeriod');
  const addPlan = (index) => {
    const all = [...periods];
    const id = all[index].annualWorkPlanPeriodTask.length
      ? all[index].annualWorkPlanPeriodTask[all[index].annualWorkPlanPeriodTask.length - 1].id + 1
      : 0;
    all[index].annualWorkPlanPeriodTask.push({
      id,
      status: true,
      annualWorkPlanTaskFertilizer: [],
      sectors: [],
      crops: [],
      annualWorkPlanTaskReserves: [],
      tools: [],
      equipments: []
    });
    setPeriods(all);
  };
  const deletePlan = (index, pindex) => {
    const all = [...periods];
    if (props.task) {
      all[index].annualWorkPlanPeriodTask[pindex].status = false
      form.setFieldValue(["annualWorkPlanPeriod",
        index,
        "annualWorkPlanPeriodTask",
        pindex,
        "status"], false);
    } else {
      all[index].annualWorkPlanPeriodTask.splice(pindex, 1);
    }
    setPeriods(all);
  };
  const addMedicine = (index, pindex, mindex) => {
    console.log(index);
    console.log(pindex);
    const all = [...periods];
    const id = all[index].annualWorkPlanPeriodTask[pindex].length
      ? all[index].annualWorkPlanPeriodTask[pindex].annualWorkPlanTaskFertilizer[
        all[index].annualWorkPlanPeriodTask[pindex].annualWorkPlanTaskFertilizer.length - 1
      ].id + 1
      : 0;
    all[index].annualWorkPlanPeriodTask[pindex].annualWorkPlanTaskFertilizer.push({ id, status: true });
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskFertilizer",
      mindex,
      "mainIngredientId",
    ]]);
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskFertilizer",
      mindex,
      "fertilizerKindId",
    ]])
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskFertilizer",
      mindex,
      "productId",
    ]])
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskFertilizer",
      mindex,
      "amount",
    ]])
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskFertilizer",
      mindex,
      "quantity",
    ]])
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskFertilizer",
      mindex,
      "sum",
    ]])

    setPeriods(all);
  };
  const deleteMedicine = (index, pindex, mindex) => {
    const all = [...periods];
    if (props.task) {
      all[index].annualWorkPlanPeriodTask[pindex].annualWorkPlanTaskFertilizer[mindex].status = false;

      form.setFieldValue(["annualWorkPlanPeriod",
        index,
        "annualWorkPlanPeriodTask",
        pindex,
        "annualWorkPlanTaskFertilizer",
        mindex,
        "status"], false);
    } else {
      all[index].annualWorkPlanPeriodTask[pindex].annualWorkPlanTaskFertilizer.splice(mindex, 1);

    }

    setPeriods(all);
  };
  const addReserve = (index, pindex, mindex) => {
    const all = [...periods];
    const id = all[index].annualWorkPlanPeriodTask[pindex].length
      ? all[index].annualWorkPlanPeriodTask[pindex].annualWorkPlanTaskReserves[
        all[index].annualWorkPlanPeriodTask[pindex].annualWorkPlanTaskReserves.length - 1
      ].id + 1
      : 0;
    all[index].annualWorkPlanPeriodTask[pindex].annualWorkPlanTaskReserves.push({ id, status: true });
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskReserves",
      mindex,
      "productId",
    ]]);
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskReserves",
      mindex,
      "quantity",
    ]])
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskReserves",
      mindex,
      "sum",
    ]])

    // })
    setPeriods(all);
  };
  const deleteReserve = (index, pindex, mindex) => {
    const all = [...periods];
    if (props.task) {
      all[index].annualWorkPlanPeriodTask[pindex].annualWorkPlanTaskReserves[mindex].status = false;

      form.setFieldValue(["annualWorkPlanPeriod",
        index,
        "annualWorkPlanPeriodTask",
        pindex,
        "annualWorkPlanTaskReserves",
        mindex,
        "status"], false);
    }
    else {
      all[index].annualWorkPlanPeriodTask[pindex].annualWorkPlanTaskReserves.splice(mindex, 1);
    }
    setPeriods(all);
  };
  const addEquipent = (index, pindex, mindex) => {
    const all = [...periods];
    const id = all[index].annualWorkPlanPeriodTask[pindex].length
      ? all[index].annualWorkPlanPeriodTask[pindex].crops[
        all[index].annualWorkPlanPeriodTask[pindex].equipments.length - 1
      ].id + 1
      : 0;
    all[index].annualWorkPlanPeriodTask[pindex].equipments.push({ id });
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskEquipments",
      mindex,
      "equipmentCategory",
    ]]);
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskEquipments",
      mindex,
      "approximatedFuel",

    ]])
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskEquipments",
      mindex,
      "PlannedFuel",

    ]])
    form.resetFields([[
      "annualWorkPlanPeriod",
      index,
      "annualWorkPlanPeriodTask",
      pindex,
      "annualWorkPlanTaskEquipments",
      mindex,
      "sum",
    ]])
    // })
    setPeriods(all);
  };
  const deleteEquipment = (index, pindex, eindex) => {
    const all = [...periods];
    all[index].annualWorkPlanPeriodTask[pindex].equipments.splice(eindex, 1);
    setPeriods(all);
  };
  const changeLastDate = (e, index) => {
    const all = [...periods];
    all[index].lastDate = e;
    setPeriods(all);
  }
  const handleParcelCategoryChange = (e) => {
    let all = { ...params };
    all["parcelCategoryId"] = e;
    setParams(all);
    form.setFieldsValue({ parcelId: undefined });
  };
  const handleParcelChange = (e) => {
    let all = { ...params };
    all["parcelId"] = e;
    setParams(all);
    const all2 = [...periods];
    const sects = options.parcels.filter((s) => e.includes(s.id));
    let sum = 0;
    sects.forEach((sec) => {
      sum += sec.area;
    });
    form.setFieldValue("total", sum.toFixed(3));
    setPeriods(all2);
  };

  const saveItem = (values) => {
    console.log(values, 'values');
    const parcelId = values.parcelId.map((b) => {
      return { ParcelId: b }
    })
    const at = lastDate.filter((s) => !values.parcelId.includes(s.parcelId)).map((s) => s)
    at.map((s) => {
      s.status = false
    })
    // if (props.task) {
    //   const selectedId = options.parcels.filter((s) => values.parcelId.includes(s.id)).map((s) => s)
    //   const parcelIdUpdate = selectedId.map((b) => {
    //     return { id: b.selectedId ? b.selectedId : 0, status: true, ParcelId: b.id }
    //   }
    //   )
    //   parcelIdUpdate.push(...at)
    //   let obj = {
    //     id: props.task,
    //     ...values,
    //     AnnualWorkPlanParcels: parcelIdUpdate,
    //     annualWorkPlanPeriod: values.annualWorkPlanPeriod,
    //   }
    //   obj.annualWorkPlanPeriod.map((p) => {
    //     p.annualWorkPlanPeriodTask.map((t) => {
    //       t.annualWorkPlanTaskReserves = t.annualWorkPlanTaskReserves ? t.annualWorkPlanTaskReserves : []
    //       t.annualWorkPlanTaskFertilizer = t.annualWorkPlanTaskFertilizer ? t.annualWorkPlanTaskFertilizer : []
    //     });
    //   });
    //     agros
    //       .put("annualworkplan/" + props.task, obj)
    //       .then(() => {
    //         notify("Planda düzəliş edildi", true);
    //         form.resetFields();
    //         setPeriods([{ id: 0, status: true, annualWorkPlanPeriodTask: [] }]);
    //         props.setVisibleAddNewTask(false);
    //         props.triggerFetch();
    //       })
    //       .catch((err) => {
    //         notify(err.response, false);
    //       });
    // } else {
    //     agros
    //       .post("annualworkplan", { ...values, AnnualWorkPlanParcels: parcelId, })
    //       .then((res) => {
    //         if (res.data && res.data.demands.length) {
    //           setName(values.fieldName);
    //           setConfirmDemand(true);
    //           setDemandValues(res.data.demands);
    //           notification.info({
    //             message: "Əməliyyat uğurlu oldu",
    //             description:
    //               "Lakin çatışmayan resurslar var. Onları əldə etmək üçün tələb yarada bilərsiniz.",
    //             icon: <FrownOutlined />,
    //           });
    //         } else {
    //           notify(t("planIsAdded"), true);
    //         }
    //         form.resetFields();
    //         setPeriods([{ id: 0, status: true, annualWorkPlanPeriodTask: [] }]);
    //         props.setVisibleAddNewTask(false);
    //         props.triggerFetch();
    //       })
    //       .catch((err) => {
    //         notify(err.response, false);
    //       });
    // }
  };
  const handleNameChange = (e) => {
    let names = options.todos.filter((c) =>
      c.name.toUpperCase().includes(e.toUpperCase())
    );
    names.unshift({ id: null, name: e });
    setNames(names);
  };
  const lastTrue = (array) => {
    let lastIndex = -1;

    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i].status === true) {
        lastIndex = i;
        break;
      }
    }
    return lastIndex;
  }
  const filterOption = (input, option) => (option?.children.toLowerCase().includes(input.toLowerCase()))
  return (
    <>
      <Form onFinish={saveItem} form={form} layout="vertical">
        <div className="commontask">
          <Row gutter={[16, 16]}>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("yearly")}
                validateTrigger="onChange"
                name="name"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("areaType")}
                name="parcelCategoryId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select onChange={handleParcelCategoryChange}>
                  {options.parcelCategories.map((pc) => {
                    return (
                      <Option key={pc.id} value={pc.id}>
                        {pc.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("sector")}
                name="parcelId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select
                  disabled={!params.parcelCategoryId}
                  onChange={handleParcelChange}
                  mode="multiple"

                >
                  {options.parcels
                    .filter(
                      (p) => p.parcelCategoryId === params.parcelCategoryId
                    )
                    .map((pc) => {
                      return (
                        <Option key={pc.id} value={pc.id}>
                          {pc.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col md={6} sm={12} xs={24}>
            <div className="form-lang">
              <Form.Item
                label={t("sector")}
                name={"parcelSectors"}
                validateTrigger="onChange"
                 rules={[noWhitespace(t("inputError"))]}
              >
                <Select
                  onChange={(e) =>
                    handleParcelSectorChange(e)
                  }
                  disabled={!params.parcelId}
                  mode="multiple"
                >
                  {options.parcelSectors?.filter(
                    (p) => params?.parcelId?.includes(p.parcelId)
                  )?.map((pc, index) => {
                    return (
                      <Option
                        key={index}
                        value={pc.id}
                      >
                        {pc.name + " " + "(" + options.parcels.filter(elem => elem.id === pc.parcelId)[0].name + ")" }
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {
                  !errorMessage ? <p style={{color:'red'}}>{t('Seçtiyiniz sahənin sektorunu seçin')}</p> : null
                }
              </div>
            </Col> */}
            <Col md={6} sm={12} xs={24}>
              <div className="form-lang">
                <Form.Item
                  label="Ümumi sahə"
                  name={"total"}
                >
                  <Input size={'large'} value={100} disabled={true} />
                </Form.Item>
                <span className="input-lang btm">ha</span>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("productCategory")}
                name={"cropCategoryId"}
                validateTrigger="onChange"
                rules={[
                  noWhitespace(t("inputError")),
                ]}
              >
                <Select
                  onChange={(e) =>
                    handleThirdCropChange(
                      e,
                      "cropCategoryId"
                    )
                  }
                >
                  {options.cropCategories.map(
                    (f, findex) => {
                      return (
                        <Option
                          key={findex}
                          value={f.id}
                        >
                          {f.name}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("product")}
                name={"cropsId"}
                validateTrigger="onChange"
                rules={[
                  noWhitespace(t("inputError")),
                ]}
              >
                <Select
                  disabled={!params.cropCategoryId}
                  onChange={(e) =>
                    handleThirdCropChange(
                      e,
                      "cropsId"
                    )
                  }
                >
                  {options.crops
                    .filter(
                      (p) => params?.cropCategoryId == p.categoryId
                    )
                    .map((f, findex) => {
                      return (
                        <Option
                          key={findex}
                          value={f.id}
                        >
                          {f.name}
                        </Option>
                      );
                    })}
                  {/* {options.crops
                                .filter(
                                  (f) =>
                                    f.categoryId ===
                                    m.cropCategoryId
                                )
                                .map((f, findex) => {
                                  return (
                                    <Option
                                      key={findex}
                                      value={f.id}
                                    >
                                      {f.name}
                                    </Option>
                                  );
                                })} */}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("productType")}
                name={"cropSortId"}
                validateTrigger="onChange"
                rules={[
                  noWhitespace(t("inputError")),
                ]}
              >
                <Select
                  disabled={!params.cropsId}
                  onChange={(e) =>
                    handleThirdCropChange(
                      e,
                      "cropSortId"
                    )
                  }
                >
                  {options.cropSorts
                    .filter(
                      (p) => params?.cropsId == p.categoryId
                    )
                    .map((f, findex) => {
                      return (
                        <Option
                          key={findex}
                          value={f.id}
                        >
                          {f.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24} >
              <Form.Item
                label={t("quantity")}
                name={"quantity"}
                validateTrigger="onChange"
                rules={[
                  noWhitespace(t("inputError")),
                ]}
              >
                <InputNumber className="w-100" />
              </Form.Item>
            </Col>
            <Col md={9} sm={12} xs={24}>
              <div className="form-lang">
                <Form.Item
                  label={t("Məhsulun təxmini ümumi qiyməti")}
                  name={"price"}
                  validateTrigger="onChange"
                  rules={[
                    noWhitespace(t("inputError")),
                  ]}
                >
                  <InputNumber className="w-100" />
                </Form.Item>
                <span className="input-lang btm">
                  azn
                </span>
              </div>
            </Col>
          </Row>

          {!periods.length ? (
            <Button onClick={addPeriod} type="primary">
              {t("addPeriod")}
            </Button>
          ) : null}

          {/*add task*/}
          {periods.map((p, index) => {
            const trueIndex = periods.filter(p => p.status === true).indexOf(p);
            return (
              <div key={index} className="task1 border mt-15 p-1" style={{ display: p.status ? 'block' : 'none' }}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <div className="w-100  flex-align-center flex flex-between">
                      {
                        p.status &&
                        (
                          <h3 className="periods">
                            {t("period")} {trueIndex + 1}
                          </h3>
                        )

                      }
                      {/* <h3 >Dövr {index + 1}</h3> */}
                      <div className="flex">
                        {periods.length > 1 ? (
                          <Button
                            onClick={() => deletePeriod(index)}
                            className="mr5-5 btn-danger"
                          >
                            {t("delete")}
                          </Button>
                        ) : null}
                        {lastTrue(periods) === index ? (
                          <Button onClick={() => addPeriod(index)} type="primary">
                            {t("addPeriod")}
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  <Form.Item
                    label={t("")}
                    validateTrigger="onChange"
                    name={["annualWorkPlanPeriod", index, "status"]}
                    style={{ display: 'none' }}
                  //rules={[noWhitespace(t("inputError"))]}
                  >
                    <Input size="large" />
                  </Form.Item>
                  <Form.Item
                    label={t("")}
                    validateTrigger="onChange"
                    name={["annualWorkPlanPeriod", index, "id"]}
                    style={{ display: 'none' }}
                  //rules={[noWhitespace(t("inputError"))]}
                  >
                    <Input size="large" />
                  </Form.Item>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      label={t("Dövr adı")}
                      validateTrigger="onChange"
                      name={["annualWorkPlanPeriod", index, "name"]}
                    //rules={[noWhitespace(t("inputError"))]}
                    >
                      <Input size="large" />
                    </Form.Item>

                  </Col>
                  <Col md={10} sm={12} xs={24} >
                    <Form.Item
                      label={<div className="flex flex-between w-100 flex-align-center">
                        {t("startDate")}
                        <div className="flex flex-align-center">
                          <Switch checked={p.lastDate} onChange={(e) => changeLastDate(e, index)} />
                          <span className="ml-10">Bitmə tarixi</span>
                        </div>
                      </div>
                      }
                      name={["annualWorkPlanPeriod", index, "startDate"]}
                      validateTrigger="onChange"
                      rules={p.status && [noWhitespace(t("inputError"))]}
                    >
                      <DatePicker
                        placeholder={t("selectDate")}
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>
                  {
                    p.lastDate &&
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label={t(" ")}
                        name={["annualWorkPlanPeriod", index, "endDate"]}
                        validateTrigger="onChange"
                        rules={p.status && [noWhitespace(t("inputError"))]}
                      >
                        <DatePicker
                          placeholder={t("selectDate")}
                          className="w-100"
                        />
                      </Form.Item>
                    </Col>

                  }


                  {p.annualWorkPlanPeriodTask.map((pl, pindex) => {
                    const trueTaskIndex = p.annualWorkPlanPeriodTask.filter(p => p.status === true).indexOf(pl);
                    return (
                      <Col xs={24} key={pindex} style={{ display: pl.status ? 'block' : 'none' }}>
                        <div className={window.innerWidth > 991 ? "medicine1 m-10 p-1 border" : "medicine1 m-5 p-1 border"}>
                          <Row gutter={[16, 16]}>
                            <Col xs={24}>
                              <div className="w-100  flex-align-center flex-wrap flex flex-between">
                                <h3>
                                  {t("workPlanWillDone")} {trueTaskIndex + 1}
                                </h3>
                                <div className="flex">
                                  {p.annualWorkPlanPeriodTask.length > 1 ? (
                                    <Button
                                      onClick={() => deletePlan(index, pindex)}
                                      className="mr5-5 btn-danger"
                                    >
                                      {t("delete")}
                                    </Button>
                                  ) : null}
                                  {p.annualWorkPlanPeriodTask.length &&
                                    lastTrue(p.annualWorkPlanPeriodTask) === pindex ? (
                                    <Button
                                      onClick={() => addPlan(index)}
                                      type="primary"
                                    >
                                      {t("addWorkWillDonw")}
                                    </Button>
                                  ) : null}
                                </div>
                              </div>
                            </Col>
                            <Form.Item
                              label={t("")}
                              validateTrigger="onChange"
                              name={[
                                "annualWorkPlanPeriod",
                                index,
                                "annualWorkPlanPeriodTask",
                                pindex,
                                "status",
                              ]}
                              style={{ display: 'none' }}
                            //rules={[noWhitespace(t("inputError"))]}
                            >
                              <Input size="large" />
                            </Form.Item>
                            <Form.Item
                              label={t("")}
                              validateTrigger="onChange"
                              name={[
                                "annualWorkPlanPeriod",
                                index,
                                "annualWorkPlanPeriodTask",
                                pindex,
                                "id",
                              ]}
                              style={{ display: 'none' }}
                            //rules={[noWhitespace(t("inputError"))]}
                            >
                              <Input size="large" />
                            </Form.Item>
                            <Col md={8} xs={24}>
                              <Form.Item
                                label={'Ediləcək işin növü'}
                                name={[
                                  "annualWorkPlanPeriod",
                                  index,
                                  "annualWorkPlanPeriodTask",
                                  pindex,
                                  "toDoId",
                                ]}
                                validateTrigger="onChange"
                                rules={pl.status && [noWhitespace(t("inputError"))]}
                              >
                                <Select>
                                  {options.todos.map((pc) => {
                                    return (
                                      <Option key={pc.id} value={pc.id}>
                                        {pc.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label={t("manWorkerNumber")}
                                name={[
                                  "annualWorkPlanPeriod",
                                  index,
                                  "annualWorkPlanPeriodTask",
                                  pindex,
                                  "manCount",
                                ]}
                                validateTrigger="onChange"
                                rules={pl.status && [noWhitespace(t("inputError"))]}
                              >
                                <InputNumber className="w-100" />
                              </Form.Item>
                            </Col>

                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label={t("womanWorkerNumber")}
                                name={[
                                  "annualWorkPlanPeriod",
                                  index,
                                  "annualWorkPlanPeriodTask",
                                  pindex,
                                  "womanCount",
                                ]}
                                validateTrigger="onChange"
                                rules={pl.status && [noWhitespace(t("inputError"))]}
                              >
                                <InputNumber className="w-100" />
                              </Form.Item>
                            </Col>

                            <Col md={8} sm={12} xs={24}>
                              <div className="form-lang">
                                <Form.Item
                                  label={t("İşçilərin təxmini ümumi maaşı")}
                                  name={[
                                    "annualWorkPlanPeriod",
                                    index,
                                    "annualWorkPlanPeriodTask",
                                    pindex,
                                    "priceOfWorkerWork",
                                  ]}
                                  validateTrigger="onChange"
                                  rules={pl.status && [noWhitespace(t("inputError"))]}
                                >

                                  <InputNumber className="w-100" />


                                </Form.Item>
                                <span className="input-lang btm">
                                  {/* {
                                  options.cropSorts.find(
                                    (c) => c.id === m.cropSortId
                                  )?.measurementUnit
                                } */}azn
                                </span>
                              </div>
                            </Col>

                            <Col xs={24}>
                              <Form.Item
                                name={[
                                  "annualWorkPlanPeriod",
                                  index,
                                  "annualWorkPlanPeriodTask",
                                  pindex,
                                  "description",
                                ]}
                                validateTrigger="onChange"
                                rules={pl.status && [noWhitespace(t("inputError"))]}
                              >
                                <TextArea
                                  placeholder={t("additionalNote")}
                                  allowClear
                                  rows={4}
                                />
                              </Form.Item>
                            </Col>

                            {pl.annualWorkPlanTaskFertilizer.map((m, mindex) => {
                              const trueFertilizerIndex = pl.annualWorkPlanTaskFertilizer.filter(p => p.status === true).indexOf(m);
                              return (
                                <Col xs={24} key={mindex} style={{ display: m.status ? 'block' : 'none' }}>
                                  <div className="border p-1">
                                    <Row gutter={[16, 16]}>
                                      <Col xs={24}>
                                        <div className="w-100  flex-align-center flex-wrap flex flex-between">
                                          <h3>
                                            {t("medicine")} {trueFertilizerIndex + 1}
                                          </h3>
                                          <div className="flex">
                                            <Button
                                              onClick={() =>
                                                deleteMedicine(
                                                  index,
                                                  pindex,
                                                  mindex
                                                )
                                              }
                                              className="mr5-5 btn-danger"
                                            >
                                              {t("delete")}
                                            </Button>
                                            {pl.annualWorkPlanTaskFertilizer.length &&
                                              (
                                                lastTrue(pl.annualWorkPlanTaskFertilizer) === mindex ? (
                                                  <Button
                                                    onClick={() =>
                                                      addMedicine(index, pindex, mindex)
                                                    }
                                                    type="primary"
                                                  >
                                                    {t("addMedicine")}
                                                  </Button>
                                                ) : null)}
                                          </div>
                                        </div>
                                      </Col>
                                      <Form.Item
                                        label={t("")}
                                        validateTrigger="onChange"
                                        name={[
                                          "annualWorkPlanPeriod",
                                          index,
                                          "annualWorkPlanPeriodTask",
                                          pindex,
                                          "annualWorkPlanTaskFertilizer",
                                          mindex,
                                          "status",
                                        ]}
                                        style={{ display: 'none' }}
                                      //rules={[noWhitespace(t("inputError"))]}
                                      >
                                        <Switch checked={m.status} />
                                      </Form.Item>
                                      <Form.Item
                                        label={t("")}
                                        validateTrigger="onChange"
                                        name={[
                                          "annualWorkPlanPeriod",
                                          index,
                                          "annualWorkPlanPeriodTask",
                                          pindex,
                                          "annualWorkPlanTaskFertilizer",
                                          mindex,
                                          "id",
                                        ]}
                                        style={{ display: 'none' }}
                                      //rules={[noWhitespace(t("inputError"))]}
                                      >
                                        <Input size="large" />
                                      </Form.Item>
                                      <Col md={6} sm={12} xs={24}>
                                        <Form.Item
                                          label={t("typeOf")}
                                          name={[
                                            "annualWorkPlanPeriod",
                                            index,
                                            "annualWorkPlanPeriodTask",
                                            pindex,
                                            "annualWorkPlanTaskFertilizer",
                                            mindex,
                                            "fertilizerKindId",
                                          ]}
                                          validateTrigger="onChange"
                                          rules={m.status && [noWhitespace(t("inputError"))]}
                                        >
                                          <Select

                                            onChange={(e) =>
                                              handleThirdKeyChange(
                                                e,
                                                index,
                                                pindex,
                                                mindex,
                                                "fertilizerKindId"
                                              )
                                            }
                                          >
                                            {options.fertilizerKinds.map(
                                              (f) => {
                                                return (
                                                  <Option
                                                    key={f.id}
                                                    value={f.id}
                                                  >
                                                    {f.name}
                                                  </Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col md={6} sm={12} xs={24}>
                                        <Form.Item
                                          label={t("activeSubstance")}
                                          name={[
                                            "annualWorkPlanPeriod",
                                            index,
                                            "annualWorkPlanPeriodTask",
                                            pindex,
                                            "annualWorkPlanTaskFertilizer",
                                            mindex,
                                            "mainIngredientId",
                                          ]}
                                          validateTrigger="onChange"
                                          rules={m.status && [noWhitespace(t("inputError"))]}
                                        >
                                          <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            onChange={(e) =>
                                              handleThirdKeyChange(
                                                e,
                                                index,
                                                pindex,
                                                mindex,
                                                "mainIngredientId"
                                              )
                                            }
                                          >
                                            {options.mainIngredients.map(
                                              (mi, miindex) => {
                                                return (
                                                  <Option
                                                    key={miindex}
                                                    value={mi.id}
                                                  >
                                                    {mi.name}
                                                  </Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col md={6} sm={12} xs={24}>
                                        <Form.Item
                                          label={t("name")}
                                          name={[
                                            "annualWorkPlanPeriod",
                                            index,
                                            "annualWorkPlanPeriodTask",
                                            pindex,
                                            "annualWorkPlanTaskFertilizer",
                                            mindex,
                                            "productId",
                                          ]}
                                          validateTrigger="onChange"
                                          rules={m.status && [noWhitespace(t("inputError"))]}
                                        >
                                          <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            onChange={(e) =>
                                              handleThirdKeyChange(
                                                e,
                                                index,
                                                pindex,
                                                mindex,
                                                "fertilizerId"
                                              )
                                            }
                                          >
                                            {options.fertilizers
                                              .filter(
                                                (f) =>
                                                  f.mainIngredientId ===
                                                  m.mainIngredientId &&
                                                  f.fertilizerKindId ===
                                                  m.fertilizerKindId
                                              )
                                              .map((f, findex) => {
                                                return (
                                                  <Option
                                                    key={findex}
                                                    value={f.id}
                                                  >
                                                    {f.name}
                                                  </Option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col md={6} sm={12} xs={24}>
                                        <div className="form-lang">
                                          <Form.Item
                                            label={t("quantity")}
                                            name={[
                                              "annualWorkPlanPeriod",
                                              index,
                                              "annualWorkPlanPeriodTask",
                                              pindex,
                                              "annualWorkPlanTaskFertilizer",
                                              mindex,
                                              "quantity",
                                            ]}
                                            validateTrigger="onChange"
                                            rules={m.status && [noWhitespace(t("inputError"))]}
                                          >

                                            <InputNumber
                                              className="w-100"
                                              onChange={(e) =>
                                                handleFertilizerAmountChange(
                                                  e,
                                                  index,
                                                  pindex,
                                                  mindex,
                                                  "amount"
                                                )
                                              }
                                            />

                                          </Form.Item>
                                          <span className="input-lang btm">
                                            {
                                              options.fertilizers.find(
                                                (f) => f.id === m.fertilizerId
                                              )?.measurementUnit
                                            }
                                          </span>

                                        </div>
                                      </Col>
                                      <Col md={6} sm={12} xs={24} >
                                        <div className="form-lang">
                                          <Form.Item
                                            label={t("finalAmount")}
                                            name={[
                                              "annualWorkPlanPeriod",
                                              index,
                                              "annualWorkPlanPeriodTask",
                                              pindex,
                                              "annualWorkPlanTaskFertilizer",
                                              mindex,
                                              "final",
                                            ]}
                                          >

                                            <InputNumber
                                              value={m.quantity}
                                              className="w-100"
                                              disabled={true}
                                            />


                                          </Form.Item>
                                          <span className="input-lang btm">
                                            {
                                              options.fertilizers.find(
                                                (f) => f.id === m.fertilizerId
                                              )?.measurementUnit
                                            }
                                          </span>
                                        </div>
                                      </Col>
                                      <Col md={8} sm={12} xs={24}>
                                        <div className="form-lang">
                                          <Form.Item
                                            label={t("Dərmanın təxmini ümumi qiyməti")}
                                            name={[
                                              "annualWorkPlanPeriod",
                                              index,
                                              "annualWorkPlanPeriodTask",
                                              pindex,
                                              "annualWorkPlanTaskFertilizer",
                                              mindex,
                                              "price",
                                            ]}
                                            validateTrigger="onChange"
                                            rules={m.status && [noWhitespace(t("inputError"))]}
                                          >

                                            <InputNumber className="w-100" />


                                          </Form.Item>
                                          <span className="input-lang btm">azn</span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              );
                            })}

                            {pl.annualWorkPlanTaskReserves.map((m, mindex) => {
                              const trueReservesIndex = pl.annualWorkPlanTaskReserves.filter(p => p.status === true).indexOf(m);
                              return (
                                <Col xs={24} key={mindex} style={{ display: m.status ? 'block' : 'none' }}>
                                  <div className="border p-1">
                                    <Row gutter={[16, 16]}>
                                      <Col xs={24}>
                                        <div className="w-100  flex-align-center flex-wrap flex flex-between">
                                          <h3>
                                            {t("reserv")} {trueReservesIndex + 1}
                                          </h3>
                                          <div className="flex">
                                            <Button
                                              onClick={() =>
                                                deleteReserve(
                                                  index,
                                                  pindex,
                                                  mindex
                                                )
                                              }
                                              className="mr5-5 btn-danger"
                                            >
                                              {t("delete")}
                                            </Button>
                                            {pl.annualWorkPlanTaskReserves.length &&
                                              lastTrue(pl.annualWorkPlanTaskReserves) === mindex ? (
                                              <Button
                                                onClick={() =>
                                                  addReserve(index, pindex, mindex)
                                                }
                                                type="primary"
                                              >
                                                {t("addReserve")}
                                              </Button>
                                            ) : null}
                                          </div>
                                        </div>
                                      </Col>
                                      <Form.Item
                                        label={t("")}
                                        validateTrigger="onChange"
                                        name={[
                                          "annualWorkPlanPeriod",
                                          index,
                                          "annualWorkPlanPeriodTask",
                                          pindex,
                                          "annualWorkPlanTaskReserves",
                                          mindex,
                                          "status",
                                        ]}
                                        style={{ display: 'none' }}
                                      //rules={[noWhitespace(t("inputError"))]}
                                      >
                                        <Input size="large" />
                                      </Form.Item>
                                      <Form.Item
                                        label={t("")}
                                        validateTrigger="onChange"
                                        name={[
                                          "annualWorkPlanPeriod",
                                          index,
                                          "annualWorkPlanPeriodTask",
                                          pindex,
                                          "annualWorkPlanTaskReserves",
                                          mindex,
                                          "id",
                                        ]}
                                        style={{ display: 'none' }}
                                      //rules={[noWhitespace(t("inputError"))]}
                                      >
                                        <Input size="large" />
                                      </Form.Item>
                                      <Col md={8} sm={12} xs={24} >
                                        <Form.Item
                                          label={'Ehtiyat növü'}
                                          name={[
                                            "annualWorkPlanPeriod",
                                            index,
                                            "annualWorkPlanPeriodTask",
                                            pindex,
                                            "annualWorkPlanTaskReserves",
                                            mindex,
                                            "productId",
                                          ]}
                                          validateTrigger="onChange"
                                          rules={m.status && [noWhitespace(t("inputError"))]}
                                        >
                                          <Select
                                          // onChange={(e) =>
                                          //   handleThirdReserveChange(
                                          //     e,
                                          //     index,
                                          //     pindex,
                                          //     mindex,
                                          //     "reserveId"
                                          //   )
                                          // }
                                          >
                                            {options.reserves.map(
                                              (f, findex) => {
                                                return (
                                                  <Option
                                                    key={findex}
                                                    value={f.id}
                                                  >
                                                    {f.name}
                                                  </Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </Form.Item>
                                      </Col>

                                      <Col md={8} sm={12} xs={24} >
                                        <div className="form-lang">
                                          <Form.Item
                                            label={t("quantity")}
                                            name={[
                                              "annualWorkPlanPeriod",
                                              index,
                                              "annualWorkPlanPeriodTask",
                                              pindex,
                                              "annualWorkPlanTaskReserves",
                                              mindex,
                                              "quantity",
                                            ]}
                                            validateTrigger="onChange"
                                            rules={m.status && [noWhitespace(t("inputError"))]}
                                          >

                                            <InputNumber className="w-100" />


                                          </Form.Item>
                                          <span className="input-lang btm">
                                            {
                                              options.reserves.find(
                                                (f) => f.id === m.reserveId
                                              )?.measurementUnit
                                            }
                                          </span>
                                        </div>
                                      </Col>

                                      <Col md={8} sm={12} xs={24} >
                                        <div className="form-lang ">
                                          <Form.Item
                                            label={t("Ehtiyatın təxmini ümumi qiyməti")}
                                            name={[
                                              "annualWorkPlanPeriod",
                                              index,
                                              "annualWorkPlanPeriodTask",
                                              pindex,
                                              "annualWorkPlanTaskReserves",
                                              mindex,
                                              "price",
                                            ]}
                                            validateTrigger="onChange"
                                            rules={m.status && [noWhitespace(t("inputError"))]}
                                          >
                                            <InputNumber className="w-100" />
                                          </Form.Item>
                                          <span className="input-lang btm">azn</span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              );
                            })}

                            {pl.equipments.map((e, eindex) => {
                              const trueequipmentsIndex = pl.equipments.filter(p => p.status === true).indexOf(e);
                              return (
                                <Col xs={24} key={eindex}>
                                  <div className="border p-1">
                                    <Row gutter={[16, 16]}>
                                      <Col xs={24}>
                                        <div className="w-100  flex-align-center flex-wrap flex flex-between">
                                          <h3>Texnika {trueequipmentsIndex + 1}</h3>
                                          <div className="flex">
                                            <Button
                                              onClick={() =>
                                                deleteEquipment(
                                                  index,
                                                  pindex,
                                                  eindex
                                                )
                                              }
                                              className="mr5-5 btn-danger"
                                            >
                                              {t("delete")}
                                            </Button>
                                            {pl.equipments.length &&
                                              eindex === pl.equipments.length - 1 ? (
                                              <Button
                                                onClick={() =>
                                                  addEquipent(index, pindex)
                                                }
                                                type="primary"
                                              >
                                                Texnika əlavə et
                                              </Button>
                                            ) : null}
                                          </div>
                                        </div>
                                      </Col>

                                      <Col md={8} sm={12} xs={24}>
                                        <Form.Item
                                          label="Texnikanın kateqoriyası"
                                          name={[
                                            "annualWorkPlanPeriod",
                                            index,
                                            "annualWorkPlanPeriodTask",
                                            pindex,
                                            "annualWorkPlanTaskEquipments",
                                            eindex,
                                            "equipmentCategory",
                                          ]}
                                          validateTrigger="onChange"
                                          rules={e.status && [noWhitespace(t("inputError"))]}
                                        >
                                          <Select>
                                            {options.tools.map((f, findex) => {
                                              return (
                                                <Option
                                                  key={findex}
                                                  value={f.id}
                                                >
                                                  {f.name}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </Form.Item>
                                      </Col>

                                      <Col md={8} sm={12} xs={24}>
                                        <div className="form-lang">
                                          <Form.Item
                                            label={'Təxmini yanacaq'}
                                            name={[
                                              "annualWorkPlanPeriod",
                                              index,
                                              "annualWorkPlanPeriodTask",
                                              pindex,
                                              "annualWorkPlanTaskEquipments",
                                              eindex,
                                              "approximatedFuel",
                                            ]}
                                            validateTrigger="onChange"
                                            rules={e.status && [noWhitespace(t("inputError"))]}
                                          >
                                            <Select>
                                              <Option
                                                key={1}
                                                value={1}
                                              >
                                                dizel
                                              </Option>
                                            </Select>
                                          </Form.Item>
                                        </div>
                                      </Col>

                                      <Col md={8} sm={12} xs={24}>
                                        <div className="form-lang">
                                          <Form.Item
                                            className="mb-5"
                                            label={'Planda verilən yanacaq'}
                                            name={[
                                              "annualWorkPlanPeriod",
                                              index,
                                              "annualWorkPlanPeriodTask",
                                              pindex,
                                              "annualWorkPlanTaskEquipments",
                                              eindex,
                                              "PlannedFuel",
                                            ]}
                                            validateTrigger="onChange"
                                            rules={e.status && [noWhitespace(t("inputError"))]}
                                          >
                                            <InputNumber className="w-100" />
                                          </Form.Item>
                                          <div className="input-lang btm">litr</div>
                                        </div>
                                      </Col>

                                      <Col md={8} sm={12} xs={24}>
                                        <Form.Item
                                          label={t("Texnikanın təxmini ümumi qiyməti")}
                                          name={[
                                            "annualWorkPlanPeriod",
                                            index,
                                            "annualWorkPlanPeriodTask",
                                            pindex,
                                            "annualWorkPlanTaskEquipments",
                                            eindex,
                                            "sum",
                                          ]}
                                          validateTrigger="onChange"
                                          rules={e.status && [noWhitespace(t("inputError"))]}
                                        >
                                          <div className="form-lang">
                                            <InputNumber className="w-100" />
                                            <span className="input-lang btm">
                                              {/* {
                                  options.cropSorts.find(
                                    (c) => c.id === m.cropSortId
                                  )?.measurementUnit
                                } */}azn
                                            </span>
                                          </div>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              );
                            })}

                            <Col xs={24}>
                              <Row gutter={8}>
                                {!pl.annualWorkPlanTaskFertilizer.filter(elem => elem.status === true).length ? (
                                  <Col md={6} sm={12} xs={24}>
                                    <div>
                                      <Button
                                        onClick={() =>
                                          addMedicine(index, pindex)
                                        }
                                        className="w-100 flex all-center formButton"
                                        type="primary"
                                      >
                                        {t("addMedicine")}
                                      </Button>
                                    </div>
                                  </Col>
                                ) : null}
                                {!pl.annualWorkPlanTaskReserves.filter(elem => elem.status === true).length ? (
                                  <Col md={6} sm={12} xs={24}>
                                    <div>
                                      <Button
                                        onClick={() =>
                                          addReserve(index, pindex)
                                        }
                                        className="w-100 flex all-center formButton"
                                        type="primary"
                                      >
                                        {t("addReserve")}
                                      </Button>
                                    </div>
                                  </Col>
                                ) : null}
                                {!pl.equipments.filter(elem => elem.status === true).length ? (
                                  <Col md={6} sm={12} xs={24}>
                                    <div>
                                      <Button
                                        onClick={() => addEquipent(index, pindex)}
                                        className="w-100 flex all-center formButton"
                                        type="primary"
                                      >
                                        Texnika əlavə et
                                      </Button>
                                    </div>
                                  </Col>
                                ) : null}
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    );
                  })}

                  {/*work plan*/}
                  {!p.annualWorkPlanPeriodTask.length ? (
                    <Col md={6} sm={12} xs={24}>
                      <div>
                        <Button
                          onClick={() => addPlan(index)}
                          className="w-100 flex all-center formButton"
                          type="primary"
                        >
                          {t("addWorkWillDonw")}
                        </Button>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </div>
            );
          })}

          <div
            className="modalButtons"
            style={{ position: "absolute", bottom: "20px", right: "30px" }}
          >
            <Button onClick={() => {
              form.resetFields();
              setPeriods([
                {
                  id: 0,
                  status: true,
                  annualWorkPlanPeriodTask: [
                    {
                      id: 0,
                      status: true,
                      medicannualWorkPlanTaskFertilizerines: [],
                      sectors: [{ id: 0 }],
                      crops: [],
                      annualWorkPlanTaskReserves: [],
                      tools: [],
                      equipments: [],
                    },
                  ],
                },
              ])
              props.setVisibleAddNewTask(false);
              setErrorMessage(true);
            }}>{t("cancel")}</Button>
            <Button type="primary" className="ml-10" htmlType="submit">
              {t("save")}
            </Button>
          </div>
        </div>
      </Form>

      <Modal
        title="Tələb yarat"
        visible={confirmDemand}
        onOk={createDemandConfirmed}
        okText="Bəli"
        cancelText="Xeyr"
        onCancel={createDemandCancelled}
      >
        <p>
          Plan yaratmaq üçün çatışmayan resurslar var. Bu resurslar üçün tələb
          yaratmaq istəyirsiniz? Tələb üzrə satınalmalar gerçəkləşdikdən sonra
          plan yarada biləcəksiz.
        </p>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ options, lang }) => {
  return { options, lang };
};

export default connect(mapStateToProps, { getOptions, notify })(NewTask);
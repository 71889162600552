import React, { useEffect, useState } from "react";
import {Tabs, Button, Table, Modal, Tooltip, Form, Row, Col, Select} from "antd";
import {
  UnorderedListOutlined,
  FilePptOutlined,
  ExclamationCircleOutlined, ExportOutlined, EditFilled, ClearOutlined,
} from "@ant-design/icons";
import ImportModal from "./Modals/ImportModal";
import ImportExportModal from "./Modals/ImportExportModal";
import ExportModal from "./Modals/ExportModal";
import SalesModal from "./Modals/SalesModal";
import excell from "../../../../assets/img/excel.svg";
import { useTranslation } from "react-i18next";
import agros from "../../../../const/api";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import {notify, getStock, getOptions} from "../../../../redux/actions";
import TransferModal from "./Modals/TransferModal";
import {noWhitespace} from "../../../../utils/rules";
import moment from "moment";
const { Option } = Select;

const { TabPane } = Tabs;
const Products = (props) => {
  const { getOptions } = props;
  const options = props.options[props.lang];
  const { notify } = props;
  const { t , i18n} = useTranslation();
  const perms = props.perms.warehouse.subs.productsWarehouse;
  const [visibleExport, setVisibleExport] = useState(false);
  const [visibleImport, setVisibleImport] = useState(false);
  const [visibleSales, setVisibleSales] = useState(false);
  const [visibleTransfer, setVisibleTransfer] = useState(false);
  const [visibleView, setvisibleView] = useState(false);
  const [modalType, setModalType] = useState('')
  const [parcelCategory , setParcelCategory] = useState(undefined)
  const [parcel , setParcel] = useState(undefined)
  const [parcelSectors , setParcelSectors] = useState([])
  const [workPlanId, SetWorkPlanId] = useState(undefined);
  const [id , setId] = useState(false);
  const [quantity , setQuantity] = useState(undefined)
  const [form] = Form.useForm();

  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "barcode", value: t("barCode"), con: true },
    { key: "categoryName", value: t("category"), con: true },
    { key: "cropName", value: t("productName"), con: true },
    { key: "cropSortName", value: t("productType"), con: true },
    { key: "reproductionName", value: t("reproduction"), con: true },
    { key: "quantity", value: t("quantity"), con: true },
    { key: "warn", value:'', con: false },
  ];

  const cols2 = [
    { key: "tableIndex", value: "#", con: true },
    { key: "cropCategoryName", value: t("productCategory"), con: true },
    { key: "productName", value: t("productType"), con: true },
    { key: "reproductionName", value: t("reproduction"), con: true },
    { key: "warehouse", value: t("warehouse"), con: true },
    { key: "cropName", value: t("productName"), con: true },
    { key: "quantity", value: t("quantity"), con: true },
    { key: "crop", value: t("productName"), con: true },
    { key: "measurementUnit", value: t("measurementUnit"), con: true },
    { key: "operation", value: "", con: false },
    { key: "index", value: "", con: false },
    { key: "id", value: "", con: false },
  ];
//measurementUnit
  const [tables, setTables] = useState({
    crops: { data: [], url: "cropstock" },
    docs: { data: [], url: "cropstock/documents/0", selectedIndex: null },
    tasks: { data: [], url: "cropstock/tasks", selectedIndex: null },
  });

  let [trigger, setTrigger] = useState(0);

  const [tabKey, setTabKey] = useState("crops");
  const [vals, setVals] = useState({});
  const setValues = (e, key) => {
    const all = { ...vals };
    all[key] = e;
    setVals(all);
    if (key === "cropCategoryId") {
      form.setFieldsValue({ cropId: undefined, productId: undefined });
    }
  };

  useEffect(() => {


    getOptions(
        [
          "cropCategories",
          "cropSorts",
          "crops",
        ],
        props.options,
        i18n.language
    );

    const getData = () => {
      agros.get(tables[tabKey].url).then((res) => {
        const all = { ...tables };
        all[tabKey].data = res.data.map((r, index) => {
          return {
            ...r,
            key: index + 1,
            index,
            tableIndex: index + 1,
            quantity:
              r.quantity + (r.measurementUnit && " " + r.measurementUnit),
          };
        });
        setTables(all);
      });
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey, t, trigger]);

  const handleTabChange = (key) => {
    setTabKey(key);
  };

  const columns = [
    {
      title: "#",
      key: 1,
      dataIndex: "tableIndex",
      width: window.innerWidth > 991 ?  60 : '100%',
    },
    {
      title: t("category"),
      key: 3,
      dataIndex: "categoryName",
    },
    {
      title: t("product"),
      key: 4,
      dataIndex: "cropName",
    },
    {
      title: t("productType"),
      key: 5,
      dataIndex: "cropSortName",
    },
    {
      title: t("reproduction"),
      key: 6,
      dataIndex: "reproductionName",
    },
    {
      title: t("quantityOf"),
      key: 7,
      dataIndex: "quantity",
    },
    {
      title: "",
      key: 8,
      dataIndex: "productId",
      width:'5px',
      render: (e) => (
          perms.subs.products.perms.update &&
          <Tooltip className="ml-5" title={t("edit")} placement="topRight">
            <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => openExport(e , 'edit')}
            >
              <EditFilled />
            </Button>
          </Tooltip>
      )
    },
    {
      title: "",
      key: 9,
      dataIndex: "warn",
      render: (e) => (
        <span>
          {e !== 0 ? (
            <Tooltip
              placement="leftBottom"
              className="ml-5"
              title={
                e === 1
                  ? "Anbardakı miqdar bu məhsul üçün nəzərdə tutulan minimal miqdara yaxındır"
                  : "Anbarda bu məhsuldan qalmayıb"
              }
            >
              <ExclamationCircleOutlined
                className={`pointer ${e === 2 ? "red" : ""}`}
              />
            </Tooltip>
          ) : null}
        </span>
      ),
    },
  ];

  const columnsIncomeExpense = [
    {
      title: "#",
      key: 1,
      dataIndex: "tableIndex",
      width: 60,
    },
    {
      title: t("productCategory"),
      key: 2,
      dataIndex: "cropCategoryName",
    },
    {
      title: t("productName"),
      key: 3,
      dataIndex: "cropName",
    },
    {
      title: t("productType"),
      key: 4,
      dataIndex: "productName",
    },
    {
      title: t("reproduction"),
      key: 5,
      dataIndex: "reproductionName",
    },
    {
      title: t("quantity"),
      key: 6,
      dataIndex: "quantity",
    },
    {
      title: t("warehouse"),
      key: 7,
      dataIndex: "warehouse",
    },
    {
      title: t("operation"),
      dataIndex: "operation",
      key: 8,
      render: (i) => {
        return (
          <div className="flex statusTd">
            <p>{t("operation")}</p>
            {i === 1 ? (
              <Button
                size="small"
                type="primary"
                className="f-12 border-none"
                shape="round"
              >
                {t("import")}
              </Button>
            ) : null}

            {i === 2 ? (
              <Button className="f-12" size="small" type="danger" shape="round">
                {t("export")}
              </Button>
            ) : null}

            {i === 3 ? (
              <Button className="f-12" size="small" type="danger" shape="round">
                Satış
              </Button>
            ) : null}
            {i === 4 ? (
              <Button className="f-12" size="small" type="danger" shape="round">
                Transfer
              </Button>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "index",
      key: 9,
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Tooltip
              placement="leftBottom"
              className="ml-5"
              title={t("import") + "/" + t("export")}
            >
              <Button
                onClick={() => viewDoc(i)}
                className="border-none"
                shape="circle"
              >
                <FilePptOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const columnsTasks = [
    {
      title: "#",
      key: 1,
      dataIndex: "tableIndex",
      width: window.innerWidth > 991 ?  60 : '100%',
    },
    {
      title: t("productName"),
      key: 3,
      dataIndex: "crop",
    },
    {
      title: t("productType"),
      key: 4,
      dataIndex: "productName",
    },
    {
      title: t("quantity"),
      key: 6,
      dataIndex: "quantity",
    },
    {
      title: t("measurementUnit"),
      key: 7,
      dataIndex: "measurementUnit",
    },

    {
      title: "",
      key: 8,
      dataIndex: "id",
      width:'5px',
      render: (e) => (
          perms.subs.tasks.perms.export &&
          <Tooltip className="ml-5" title={t("export")} placement="topRight">
            <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => openExport(e , 'selected')}
            >
              <ExportOutlined />
            </Button>
          </Tooltip>
      )
    },
  ];

  const exportExcel = (url) => {
    agros
      .get(url, {
        headers: {
          "Content-Type": "blob",
        },
        responseType: "blob",
      })
      .then((res) => {
        const filename = "report";
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(res.data, filename);
        } else {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = window.URL.createObjectURL(res.data);
          a.download = filename;
          a.target = "_blank";
          a.click();
          a.remove();
        }
      })
      .catch(() => {
        notify("", true);
      });
  };

  const viewDoc = (index) => {
    const all = { ...tables };
    all.docs.selectedIndex = index;
    setTables(all);
    setvisibleView(true);
  };
  const clearFilter = () => {
    triggerFetch()
    form.resetFields();
  }

  const triggerFetch = () => {
    props.getStock();
    setTrigger(++trigger);
  };

  const openExport = (id,type) =>{
    setVisibleExport(true)
    setModalType(type)
    if(type === 'selected') {
      setParcelSectors(tables.tasks.data.find((t)=>{return t.id === id}).parcelSectorIds)
      setId(tables.tasks.data.find((t)=>{return t.id === id}).productId)
      setParcelCategory(tables.tasks.data.find((t)=>{return t.id === id}).parcelCategoryId)
      setParcel(tables.tasks.data.find((t)=>{return t.id === id}).parcelId)
      setQuantity(tables.tasks.data.find((t)=>{return t.id === id}).quantity)
      SetWorkPlanId(tables.tasks.data.find((t)=>{return t.id === id}).workPlanId)
    }
    if (type === 'edit') {
      setId(id)
    }
  }

  const onSearch = (values) =>{
    let obj = {}
    // eslint-disable-next-line no-unused-expressions
    values.cropId ? obj['cropId'] = values.cropId : null
    // eslint-disable-next-line no-unused-expressions
    values.CropSortId ? obj['CropSortId'] = values.CropSortId : null

    agros.get('CropStock'  , { params: obj}).then((res) => {
      const all = { ...tables };
      console.log(res.data)
      all['crops'].data = res.data.map((r, index) => {
        return { ...r ,
          key: index + 1,
          index,
          tableIndex: index + 1,
          quantity:
              r.quantity + (r.measurementUnit && " " + r.measurementUnit),
        };
      })
      console.log(all)
      setTables(all)
    });
  }

  const triggerExcel = () => {
    let url = "";
    switch (tabKey) {
      case "crops":
        url = "ExselExport/CropStock";
        break;
      case "docs":
        url = "ExselExport/CropSortDocument/1";
        break;
      default:
        url = "";
        break;
    }
    if (url.length) {
      exportExcel(url);
    }
  };

  return (
    <div>
      <div className="border page-heading flex-align-center flex p-2 mt-0 bg-white">
        <div className="page-name">
          <UnorderedListOutlined className="f-20 mr5-15" />
          <span className="f-20 bold">{t("productWarehouse")}</span>
        </div>
        <div className="flex flex-align-center justify-center flex-wrap gap-4">
          {(tabKey === "crops" && perms.subs.products.perms.excelExport) ||
          (tabKey === "docs" &&
            perms.subs.importExportAndDocs.perms.excelExport) ? (
            <Tooltip placement="left" title="Excell export">
              <Button
                shape="circle"
                className="mr-10 border-none excel"
                onClick={triggerExcel}
              >
                <img src={excell} alt="" />
              </Button>
            </Tooltip>
          ) : null}
          {perms.perms.import && (
            <Button type="primary" onClick={() => setVisibleImport(true)}>
              {t("import")}
            </Button>
          )}
          {perms.perms.export && (
            <Button className="ml-10" onClick={() => openExport(null ,'default')}>
              {t("export")}
            </Button>
          )}
          {perms.perms.sale && (
            <Button className="ml-10" onClick={() => setVisibleSales(true)}>
              {t("sale")}
            </Button>
          )}
          {perms.perms.transfer && (
            <Button className="ml-10" onClick={() => setVisibleTransfer(true)}>
              Transfer
            </Button>
          )}
        </div>
      </div>
      <div className="position-relative mt-15">
        <div className="position-absolute w-100 tab-section">
          <Tabs
            animated={true}
            defaultActiveKey="crops"
            className="bg-white w-100"
            onChange={handleTabChange}
          >
            {perms.subs.products.perms.read && (
              <TabPane tab={t("productWarehouse")} key="crops">

                <Form onFinish={onSearch} layout="vertical" form={form}>
                  <div className="commontask bg-white px-2 pt-15">
                    <Row className={'mt-5'} gutter={[16, 16]}>

                      <Col md={9} sm={12} xs={24}>
                        <Form.Item
                            name="cropId"
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("inputError"))]}
                        >
                          <Select placeholder={t("product")} onChange={(e) => setValues(e, "cropId")}>
                            {options.crops
                                .map((c, index) => {
                                  return (
                                      <Option key={index} value={c.id}>
                                        {c.name}
                                      </Option>
                                  );
                                })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={9} sm={12} xs={24}>
                        <Form.Item
                            name="CropSortId"
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("inputError"))]}
                        >
                          <Select placeholder={t("productType")}>
                            {options.cropSorts
                                .filter((c) => c.categoryId === vals.cropId)
                                .map((c, index) => {
                                  return (
                                      <Option key={index} value={c.id}>
                                        {c.name}
                                      </Option>
                                  );
                                })}
                          </Select>
                        </Form.Item>
                      </Col>


                      <Col md={3} sm={12} xs={24}>
                        <Form.Item
                            name={" "}
                        >
                          <Button type="primary" size={'large'}  className="w-100 f-13" htmlType="submit">
                            {t("search")}
                          </Button>
                        </Form.Item>
                      </Col>

                      <Col md={3} sm={12} xs={24}>
                        <Form.Item
                            name={" "}
                        >
                          <Button type="primary" size={'large'}  onClick={()=>{clearFilter()}} className="w-100" htmlType="submit">
                            <ClearOutlined />
                          </Button>
                        </Form.Item>
                      </Col>

                    </Row>
                  </div>
                </Form>


                <Table
                  size="small"
                  className="bg-white   w-100"
                  columns={columns}
                  dataSource={convertColumns(tables.crops.data, cols)}
                  // dataSource={tables.crops.data}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
              </TabPane>
            )}
            {perms.subs.importExportAndDocs.perms.read && (
              <TabPane tab={t("importExportDocuments")} key="docs">
                <Table
                  size="small"
                  className="bg-white   w-100"
                  columns={columnsIncomeExpense}
                  // dataSource={tables.docs.data}
                  dataSource={convertColumns(tables.docs.data, cols2)}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
              </TabPane>
            )}
            {
              perms.subs.tasks.perms.read && (
                <TabPane tab={t("tasks")} key="tasks">
                <Table
                  size="small"
                  className="bg-white w-100"
                  columns={columnsTasks}
                  dataSource={convertColumns(tables.tasks.data, cols2)}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
              </TabPane>
              )
            }
           
          </Tabs>
        </div>
      </div>

      <Modal
        title={t("addNewProduct")}
        centered
        className="padModal mediumModal"
        visible={visibleImport}
        footer={null}
        onCancel={() => {
          setVisibleImport(false);
          setTrigger(++trigger);
        }}
      >
        <ImportModal
          setVisibleImport={setVisibleImport}
          visibleImport={visibleImport}
          triggerFetch={triggerFetch}
          trigger={trigger}
        />
      </Modal>

      <Modal
        title={t("newExoort")}
        centered
        className="padModal addTaskModal"
        onOk={() => setVisibleExport(false)}
        onCancel={() =>{
          setVisibleExport(false)
          setTrigger(++trigger);
        } }
        visible={visibleExport}
        footer={false}
      >
        <ExportModal
          quantity={quantity}
          id={id}
          parcelSectors={parcelSectors}
          parcel={parcel}
          workPlanId={workPlanId}
          parcelCategory={parcelCategory}
          modalType={modalType}
          setVisibleExport={setVisibleExport}
          visibleExport={visibleExport}
          triggerFetch={triggerFetch}
          trigger={trigger}
        />
      </Modal>

      <Modal
        title="Yeni satış"
        centered
        className="padModal addTaskModal"
        onOk={() => setVisibleSales(false)}
        onCancel={() => {
          setVisibleSales(false)
          setTrigger(++trigger);
        }}
        visible={visibleSales}
        footer={false}
      >
        <SalesModal
          setVisibleSales={setVisibleSales}
          visibleSales={visibleSales}
          triggerFetch={triggerFetch}
          trigger={trigger}
        />
      </Modal>

      <Modal
        title="Yeni transfer"
        centered
        className="padModal addTaskModal"
        onOk={() => setVisibleTransfer(false)}
        onCancel={() => {
          setVisibleTransfer(false);
          setTrigger(++trigger);
        }}
        visible={visibleTransfer}
        footer={false}
      >
        <TransferModal
          setVisibleTransfer={setVisibleTransfer}
          visibleSales={visibleTransfer}
          triggerFetch={triggerFetch}
          trigger={trigger}
        />
      </Modal>

      <Modal
        title={t("import") + "/" + t("export")}
        centered
        className="mediumModal"
        onOk={() => setvisibleView(false)}
        onCancel={() => setvisibleView(false)}
        visible={visibleView}
        footer={
          <div className="modalButtons">
            <Button onClick={() => setvisibleView(false)}>{t("close")}</Button>
          </div>
        }
      >
        <ImportExportModal
          doc={tables.docs.data[tables.docs.selectedIndex]}
          triggerFetch={triggerFetch}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ user  , options , lang}) => {
  return { perms: user.data.userPermissions , options , lang};
};
export default connect(mapStateToProps, { notify, getStock , getOptions })(Products);
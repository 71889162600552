import React, { useEffect, useState } from 'react'
import agros from '../../../const/api'
import { Spin } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

export default function AreasPanel({ visible, handleClickExpandArea,close,areas,zommedArea }) {

    

    useEffect(() => {
        console.log(zommedArea)
    }, [visible,zommedArea])

    return (
        <div className={`${visible ? 'scale-100' : 'scale-0'} absolute flex flex-col justify-center w-80  bg-white rounded-lg top-5 right-20 shadow-lg p-1  `}>
            <div className='' >
                <div className='flex  w-full justify-between p-1'>
                    <span>Ərazilər</span>
                    <div className='flex'>
                        <CloseCircleOutlined onClick={close} size={20} className='cursor-pointer hover:text-red-600' />
                    </div>
                </div>
            </div>
            <Spin spinning={!areas}>
                <div className='flex w-full flex-col max-h-96 overflow-scroll ' >
                    {
                        areas?.map((a, index) => {
                            return <div onClick={() => handleClickExpandArea(a.objectid)} className={`flex justify-between w-full p-2   border-gray-200 hover:bg-teal-600 cursor-pointer group last:border-none ${zommedArea==a.objectid?'border':'border-b '} `} key={index}>
                                <span className='text-teal-600 group-hover:text-white' >{a.sektorun_adi}</span>
                                <span className='text-teal-600 group-hover:text-white' >{a.sektorun_sahesi} ha</span>
                            </div>
                        })
                    }
                </div>
            </Spin>


        </div>
    )
}

import React, { useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import { Button, Col, Input, Row, Form } from "antd";
import { useTranslation } from "react-i18next";
import {whiteSpace} from "../../../../../utils/rules";
function ActiveIngredientsForm(props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // const admin = props.perms.administrator.subs
  useEffect(() => {
    if (props.editing) {
      form.setFieldsValue({ name: props.editing.name });
    } else {
      form.setFieldsValue({
        name: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editing, t]);

  const saveItem = (values) => {
    let obj = {
      name: values.name,
    };
    if (props.editing) {
      obj["id"] = props.editing.id;
    }
    props.saveItem(obj).then((res) => {
      form.resetFields();
    });
  };
  return (
    <Form onFinish={saveItem} form={form} layout="vertical">
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Form.Item
            className="mb-5"
            validateTrigger="onChange"
            name="name"
            label={<span>{t("activeSubstanceName")}</span>}
            rules={[whiteSpace(t("inputError"))]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <div className="flex  flex-between mt-15">
        <Button onClick={() => props.cancelEdit(props.name)}>
          {t("cancel")}
        </Button>
        <Form.Item>
            <Button htmlType="submit" type="primary">{t("save")}</Button>
        </Form.Item>
      </div>
    </Form>
  );
}

export default ActiveIngredientsForm;

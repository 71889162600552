import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Switch,
    Col,
    Card,
    Table,
    Button,
    Form,
    Tooltip,
    Input,
    Popconfirm, InputNumber, Select, DatePicker,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../../utils/columnconverter";
// notification
import { notify } from "../../../../../redux/actions";
import { connect } from "react-redux";
import agros from "../../../../../const/api";
import { useTranslation } from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import Authorize from "../../../../Elements/Authorize";
import moment from "moment";

const TrailTemplate = (props) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [positions, setPositions] = useState([]);
    const [responsible, setResponsible] = useState(false);
    const [editing, setEditing] = useState(null);
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "name", value: t("name"), con: true },
        { key: "id", value: "", con: false },
    ];
    const nameInput = useRef();

    // props
    const { notify } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Authorize
                            mainMenu={"administrator"}
                            page={["positions", "perms"]}
                            type={"delete"}
                        >
                            <Popconfirm
                                placement="topRight"
                                title={t("areYouSure")}
                                onConfirm={() => deletePosition(i)}
                                okText={t("yes")}
                                cancelText={t("no")}
                            >
                                <Tooltip className="ml-5" title={t("delete")}>
                                    <Button className="border-none" type="text" shape="circle">
                                        <DeleteFilled />
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        </Authorize>
                        <Authorize
                            mainMenu={"administrator"}
                            page={["positions", "perms"]}
                            type={"update"}
                        >
                            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                                <Button
                                    className="border-none"
                                    type="text"
                                    shape="circle"
                                    onClick={() => setEditingObject(i)}
                                >
                                    <EditFilled />
                                </Button>
                            </Tooltip>
                        </Authorize>
                    </div>
                );
            },
        },
    ];

    const setEditingObject = async (i) => {
        setEditing(i);
        await agros.get(`Trailer/${i}`).then((res) => {
            let names = res.data;
            let obj = {};
            form.setFieldsValue(
                {
                    ...res.data,
                    dateOfPurchase:moment(res.data.dateOfPurchase),
                    dateOfCommissioning:moment(res.data.dateOfCommissioning),
                    dateOfProduction:moment(res.data.dateOfProduction)
                }
            );
            nameInput.current.focus();
        });
    };

    const cancelEditing = () => {
        setEditing(null);
        setResponsible(false);
        form.resetFields();
    };

    const deletePosition = async (i) => {
        await agros
            .delete(`/Trailer/${i}`)
            .then(() => {
                // description
                notify("", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            ...values,
            DateOfPurchase:moment(values.dateOfPurchase).format("YYYY-MM-DD"),
            DateOfCommissioning:moment(values.dateOfCommissioning).format("YYYY-MM-DD"),
            DateOfProduction:moment(values.dateOfProduction).format("YYYY-MM-DD"),
        };
        if (!editing) {
            await agros
                .post("/Trailer", obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await agros
                .put(`/Trailer/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        await agros.get("Trailer").then((res) => {
            setPositions(
                res.data.map((p, index) => {
                    return { key: index + 1, ...p, index: index + 1 };
                })
            );
        });
    };

    useEffect(() => {
        getPositions();
    }, [t]);

    const onSwitchChange = () => {
        setResponsible(!responsible);
    };

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Aqreqatlar</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <Table
                    size="small"
                    className="bg-white"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")}>
                    <Form onFinish={savePosition} layout="vertical" form={form}>
                        <div className="commontask">
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <Form.Item
                                        className="mb-5"
                                        label={'Adı'}
                                        name="name"
                                        validateTrigger="onChange"
                                        rules={[whiteSpace(t("inputError"))]}
                                    >
                                        <Input ref={nameInput} size={'large'}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item
                                        className="mb-5"
                                        label={'İstehsal tarixi'}
                                        name="dateOfProduction"
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("dateError"))]}
                                    >
                                        <DatePicker placeholder={t("selectDate")} className="w-100" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item
                                        className="mb-5"
                                        label={t('dateToBuy')}
                                        name="dateOfPurchase"
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("dateError"))]}
                                    >
                                        <DatePicker placeholder={t("selectDate")} className="w-100" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item
                                        className="mb-5"
                                        label={'İstifadəyə verilmə tarixi'}
                                        name="dateOfCommissioning"
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("dateError"))]}
                                    >
                                        <DatePicker placeholder={t("selectDate")} className="w-100" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item
                                        className="mb-5"
                                        label={'İstifadə məqsədi'}
                                        name="purposeOfUse"
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </Col>




                            </Row>


                            <div
                                className="modalButtons flex flex-between"
                            >
                                <Button onClick={() =>{form.resetFields()}}>
                                    {t("cancel")}
                                </Button>
                                <Button type="primary" className="ml-10" htmlType="submit">
                                    {t("save")}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default connect(null, { notify })(TrailTemplate);



import React, {useEffect} from 'react';
import Images from "../../../assets/img/Images/Images";
import Product from "./ProductComponent/Product";
import agros from '../../../const/api';

function ProductPage() {
    const [data, setData] = React.useState([]);
    useEffect(() => {
        const getData = () => {
          agros.get("root/OurProduct").then((res) => {
            setData(res.data);
          });
        };
        getData();
      }, []);
    return (
        <div className="animated fadeIn products">
           <div className="content">
               <div className="mainBg flex all-center">
                   <img src={Images.product} alt=""/>
               </div>
           </div>
           {
            data.map((item, index) => (
                <Product
                number={index + 1}
                direction={index % 2 === 0 ? 'left' : 'right'}
                name={item.name}
                about={item.text}
                image={item.imageUrl}
            />
            ))
        } 

        </div>
    );
}

export default ProductPage;

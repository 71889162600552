import React, { useEffect, useState } from "react";
import {Tabs, Button, Table, Modal, Tooltip, Checkbox, Col, Form, Row, Select} from "antd";
import {
  UnorderedListOutlined,
  FilePptOutlined,
  ExclamationCircleOutlined, ClearOutlined, EditFilled, SwapOutlined, ExportOutlined,
} from "@ant-design/icons";
import ImportModal from "./Modals/ImportModal";
import ImportExportModal from "./Modals/ImportExportModal";
import ExportModal from "./Modals/ExportModal";
import excell from "../../../../assets/img/excel.svg";
import agros from "../../../../const/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import { notify, getStock, getOptions} from "../../../../redux/actions";
import Authorize from "../../../Elements/Authorize";
import Edit from "./Modals/Edit";
const { TabPane } = Tabs;
const { Option } = Select;

const DragAndFertilizers = (props) => {
  const perms = props.perms.warehouse.subs.drugAndFertilizers;
  const { t , i18n } = useTranslation();
  const [form] = Form.useForm();
  const [modalType , setModalType]= useState('')
  const { getOptions } = props;
  const options = props.options[props.lang];
  const { notify } = props;
  const [tables, setTables] = useState({
    reserves: { data: [], url: "medicalstock", selected: null },
    inouts: { data: [], url: "medicalstock/documents", selected: null },
    tasks: { data: [], url: "medicalstock/tasks", selected: null },
  });
  const [forceFetch, setForceFetch] = useState(false);
  const [tabKey, setTabkey] = useState("reserves");
  let [trigger, setTrigger] = useState(0);
  const [mainIngridientId , setMainIngridientId] = useState(undefined)
  const [fertlizerKindId , setFertlizerKindId] = useState(undefined)
  const [parcelCategory , setParcelCategory] = useState(undefined)
  const [parcel , setParcel] = useState(undefined)
  const [quantity , setQuantity] = useState(undefined)
  const [unit , setUnit] = useState(undefined)
  const [workPlanId, SetWorkPlanId] = useState(undefined);
  const [visibleEdit , setVisibleEdit] = useState(false)
  const [id , setId] = useState(false);

console.log(perms);
  const handleKeyChange = (e) =>{
    form.setFieldsValue({
      ProductId: undefined,
    })
    setMainIngridientId(e)
  }

  const clearFilter = () =>{
    agros.get('medicalstock').then((res) => {
      const all = { ...tables };
      all["reserves"].data = res.data.map((r, index) => {
        return {
          ...r,
          key: index + 1,
          index,
          quantity:
              r.quantity + (r.measurementUnit ? " " + r.measurementUnit : null),
          expireDate:
              r.expireDate && moment(r.expireDate).format("DD-MM-YYYY"),
          documentNumber: r.documentNumber !== null ? r.documentNumber : "-",
        };
      });
      setTables(all);
      setForceFetch(false);
    });
   // form.resetFields()
   form.setFieldsValue({
      FertilizerKindId : undefined,
      MainIngredientId : undefined,
      ProductId: undefined,
    })
    setFertlizerKindId(undefined)
    setMainIngridientId(undefined)
  }

  useEffect(() => {
    const getData = () => {
      agros.get(tables[tabKey].url).then((res) => {
        const all = { ...tables };
        all[tabKey].data = res.data.map((r, index) => {
          return {
            ...r,
            key: index + 1,
            index,
            quantity:
              r.quantity + (r.measurementUnit ? " " + r.measurementUnit : null),
            quantityNumber: r.quantity,
            measurementUnit: r.measurementUnit,
            expireDate:
              r.expireDate && moment(r.expireDate).format("DD-MM-YYYY"),
            documentNumber: r.documentNumber !== null ? r.documentNumber : "-",
          };
        });
        setTables(all);
        setForceFetch(false);
      });
    };
    getOptions(
        [
          "fertilizers",
          "fertilizerKinds",
          "mainIngredients",
          "customers",
        ],
        props.options,
        i18n.language
    );
    // if (!tables[tabKey].data.length || forceFetch) {
    //   getData();
    // }
    getData()
  }, [tabKey, t, forceFetch , trigger]);

  const triggerFetch = () => {
    setTrigger(++trigger)
    setForceFetch(true);
    props.getStock();
  };

  const editCrop  = (id , type) =>{
    if (type !== 'export') {
      setId(id)
    }
    if(type === 'export') {
      setId(tables.tasks.data.find((t)=>{return t.id === id}).productId)
      setParcelSectors(tables.tasks.data.find((t)=>{return t.id === id}).parcelSectorIds)
      setParcelCategory(tables.tasks.data.find((t)=>{return t.id === id}).parcelCategoryId)
      setParcel(tables.tasks.data.find((t)=>{return t.id === id}).parcelId)
      setQuantity(tables.tasks.data.find((t)=>{return t.id === id}).quantityNumber)
      setUnit(tables.tasks.data.find((t)=>{return t.id === id}).measurementUnit)
      SetWorkPlanId(tables.tasks.data.find((t)=>{return t.id === id}).workPlanId)
    }
    setVisibleEdit(true);
    setModalType(type)
  }

  const [visibleExport, setVisibleExport] = useState(false);
  const [visibleImport, setVisibleImport] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [parcelSectors , setParcelSectors] = useState([])
  const [checked, setChecked] = useState(false);
  const [showCheck, setShowCheck] = useState(true);
  const cols = [
    { key: "productName", value: t("name"), con: true },
    { key: "fertilizerKind", value: t("typeOf"), con: true },
    { key: "mainIngredient", value: t("mainIngridient"), con: true },
    { key: "quantity", value: t("quantityOf"), con: true },
    { key: "price", value: t("price"), con: true },
    { key: "expireDate", value: t("expirationDate"), con: true },
    { key: "documentNumber", value: t("documentNumber"), con: true },
    { key: "warehouse", value: t("warehouse"), con: true },
    { key: "warn", value: '', con: false },
    { key: "quantity", value: t("quantity"), con: true },
    { key: "operation", value: t("operation"), con: false },
    { key: "productName", value: t("productName"), con: true },
    { key: "productId", value:'', con: false },
    { key: "takenPerson", value: t("person"), con: true },
    { key: "usedStatus", value: "", con: false },
    { key: "index", value: "", con: false },
    { key: "id", value: "", con: false },
  ];

  function handleTabChange(key) {
    setTabkey(key);
    setShowCheck(key === "reserves");
  }
  const viewDoc = (index) => {
    const all = { ...tables };
    console.log(all);
    all.inouts.selected = index;
    setTables(all);
    setVisibleView(true);
  };
  const reserviInitial = [
    {
      title: t("name"),
      key: 1,
      dataIndex: "productName",
    },
    {
      title: t("typeOf"),
      key: 2,
      dataIndex: "fertilizerKind",
    },

    {
      title: t("activeSubstance"),
      key: 3,
      dataIndex: "mainIngredient",
    },
    {
      title: t("quantityOf"),
      key: 4,
      dataIndex: "quantity",
    },
  ];

  const reservesColumns = [
    {
      title: t("finalPrice"),
      key: 5,
      dataIndex: "totalPrice",
      render: (p) => <span>{p} azn</span>,
    },
    {
      title: t("expirationDate"),
      key: 6,
      dataIndex: "expireDate",
    },
  ];

  const inoutsColumns = [
    {
      title: t("type"),
      key: 2,
      dataIndex: "fertilizerKind",
    },
    {
      title: t("mainIngridient"),
      key: 3,
      dataIndex: "mainIngredient",
    },
    {
      title: t("productName"),
      key: 4,
      dataIndex: "productName",
    },
    {
      title: t("quantity"),
      key: 5,
      dataIndex: "quantity",
    },
    {
      title: t("warehouse"),
      key: 6,
      dataIndex: "warehouse",
    },
    {
      title: t("operation"),
      key: 7,
      dataIndex: "operation",
      render: (i) => {
        return (
          <div className="flex statusTd">
            <p>{t("operation")}</p>
            {i === 'Mədaxil' ? (
              <Button
                size="small"
                type="primary"
                className="f-12 border-none"
                shape="round"
              >
                {t("import")}
              </Button>
            ) : null}

            {i === 'Məxaric' ? (
              <Button className="f-12" size="small" type="danger" shape="round">
                {t("export")}
              </Button>
            ) : null}

          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "index",
      key: 9,
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Tooltip
              placement="leftBottom"
              className="ml-5"
              title={t("import") + "/" + t("export")}
            >
              <Button
                onClick={() => viewDoc(i)}
                className="border-none"
                shape="circle"
              >
                <FilePptOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
console.log(perms);
  const warnColumn = [
    {
      title: "",
      key: 8,
      dataIndex: "productId",
      width:'5px',
      render: (e) => (
        
          perms.subs.reserves.perms.transfer &&  
          <Tooltip className="ml-5" title={t("Transfer")} placement="topRight">
          <Button
              className="border-none"
              type="text"
              shape="circle"
              onClick={() => editCrop(e , 'transfer')}
          >
            <SwapOutlined />
          </Button>
        </Tooltip>
        
      )
    },
    {
      title: "",
      key: 8,
      dataIndex: "productId",
      width:'5px',
      render: (e) => (
        perms.subs.reserves.perms.update &&  
          <Tooltip className="ml-5" title={t("edit")} placement="topRight">
            <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => editCrop(e , 'edit')}
            >
              <EditFilled />
            </Button>
          </Tooltip>
      )
    },
    {
      title: "",
      key: 8,
      dataIndex: "warn",
      width:'10px',
      render: (e) => (
 <span className={'flex flex-align-center'}>
          {e !== 0 ? (
            <Tooltip
              placement="leftBottom"
              className="ml-5"
              title={
                e === 1
                  ? "Anbardakı miqdar bu məhsul üçün nəzərdə tutulan minimal miqdara yaxındır"
                  : "Anbarda bu məhsuldan qalmayıb"
              }
            >
              <ExclamationCircleOutlined
                className={`pointer ${e === 2 ? "red" : ""}`}
              />
            </Tooltip>
          ) : null}
        </span>
      )
    },
  ];

  const viewInout = (index) => {
    const all = { ...tables };
    all.inouts.selected = index;
    setTables(all);
    setVisibleView(true);
  };

  const tasksColumns = [
    {
      title: t("type"),
      key: 2,
      dataIndex: "fertilizerKind",
    },
    {
      title: t("mainIngridient"),
      key: 3,
      dataIndex: "mainIngredient",
    },
    {
      title: t("name"),
      key: 4,
      dataIndex: "productName",
    },
    {
      title: t("quantityOf"),
      key: 5,
      dataIndex: "quantity",
    },
    {
      title: t("person"),
      key: 5,
      dataIndex: "takenPerson",
    },
    {
      title: "",
      key: 8,
      dataIndex: "id",
      width:'5px',
      render: (e) => (
          perms.subs.tasks.perms.export &&
          <Tooltip className="ml-5" title={t("export")} placement="topRight">
            <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => editCrop(e , 'export')}
            >
              <ExportOutlined />
            </Button>
          </Tooltip>
      )
    },
  ];

  const fullColumns = checked
    ? reserviInitial.concat(reservesColumns).concat(warnColumn)
    : reserviInitial.concat(warnColumn);

  const handleCheckBoxChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleFertilizerChange = (e) => {
    setFertlizerKindId(e);
    form.setFieldsValue({
      MainIngredientId : undefined,
      ProductId: undefined,
    })
  };

  const onSearch = (values) =>{
    let obj = {}
    // eslint-disable-next-line no-unused-expressions
    values.FertilizerKindId ? obj['FertilizerKindId'] = values.FertilizerKindId : null
    // eslint-disable-next-line no-unused-expressions
    values.MainIngredientId ? obj['MainIngredientId'] = values.MainIngredientId : null
    // eslint-disable-next-line no-unused-expressions
    values.ProductId ? obj['ProductId'] = values.ProductId : null
    agros.get('MedicalStock'  , { params: obj}).then((res) => {
        const all = { ...tables };
        all['reserves'].data = res.data.map((r, index) => {
          return { ...r, key: index + 1, index, tableIndex: index + 1 ,
            quantity:
                r.quantity + (r.measurementUnit ? " " + r.measurementUnit : null),
            expireDate:
                r.expireDate && moment(r.expireDate).format("DD-MM-YYYY"),
            documentNumber: r.documentNumber !== null ? r.documentNumber : "-",
          };
        })
      console.log(all)
        setTables(all)
    });
  }

  const exportExcel = (url) => {
    agros
      .get(url, {
        headers: {
          "Content-Type": "blob",
        },
        responseType: "blob",
      })
      .then((res) => {
        const filename = "report";
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(res.data, filename);
        } else {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = window.URL.createObjectURL(res.data);
          a.download = filename;
          a.target = "_blank";
          a.click();
          a.remove();
        }
      })
      .catch(() => {
        notify("", false);
      });
  };

  const triggerExcel = () => {
    let url = "";
    switch (tabKey) {
      case "reserves":
        url = "ExselExport/MedicalStock";
        break;
      case "inouts":
        url = "ExselExport/MedicalDocuments/1";
        break;
      default:
        url = "";
        break;
    }
    if (url.length) {
      exportExcel(url);
    }
  };
console.log(visibleExport);
console.log(trigger);
  return (
    <div>
      <div className="border page-heading flex p-2 mt-0 bg-white">
        <div className="page-name">
          <UnorderedListOutlined className="f-20 mr-15" />
          <span className="f-20 bold">{t("drugAndFertilizers")}</span>
        </div>
        <div className="flex flex-align-center justify-center flex-wrap gap-4">
          {tabKey !== "tasks" ? (
            <>
              {(tabKey === "reserves" &&
                perms.subs.reserves.perms.excelExport) ||
              (tabKey === "inouts" &&
                perms.subs.importAndExport.perms.excelExport) ? (
                <Tooltip placement="left" title="Excel export">
                  <Button
                    shape="circle"
                    className="mr-10 border-none excel "
                    onClick={triggerExcel}
                  >
                    <img src={excell} alt="" />
                  </Button>
                </Tooltip>
              ) : null}
            </>
          ) : null}
          {perms.perms.import && (
            <Button type="primary" className={'mr-10 '} onClick={() => setVisibleImport(true)}>
              {t("import")}
            </Button>
          )}
          {perms.perms.export && (
              <Button
                  className="ml-10 mr-10 "
                  onClick={() => setVisibleExport(true)}
              >
                {t("export")}
              </Button>
          )}
          <Checkbox
            checked={checked}
            onChange={handleCheckBoxChange}
          >
            {t("detailedInfo")}
          </Checkbox>
        </div>
      </div>
      <div className="position-relative mt-5">

        <div className="position-absolute w-100 tab-section purchase-tabs">
          <Tabs
            animated="true"
            centered={true}
            defaultActiveKey={tabKey}
            className="bg-white w-100"
            onChange={handleTabChange}
          >
            {perms.subs.reserves.perms.read && (
              <TabPane tab={t("inReserves")} key="reserves">
                <div  className={'w-100'}>
                  <Form onFinish={onSearch} layout="vertical" form={form}>
                    <div className="commontask bg-white px-2 pt-15">
                      <Row className={'mt-5'} gutter={[16, 16]}>

                        <Col md={6} sm={12} xs={24}>
                          <Form.Item
                              name="FertilizerKindId"
                              validateTrigger="onChange"
                          >
                            <Select
                                showSearch
                                notFoundContent={null}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={(e)=>{handleFertilizerChange(e)}}
                                placeholder={t("productType")}
                            >
                              {options.fertilizerKinds.map((c, index) => {
                                return (
                                    <Option key={index} value={c.id}>
                                      {c.name}
                                    </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>


                        <Col md={6} sm={12} xs={24}>
                          <Form.Item
                              name="MainIngredientId"
                              validateTrigger="onChange"
                          >
                            <Select
                                showSearch
                                notFoundContent={null}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={(e)=>{handleKeyChange(e)}}
                                placeholder={t("activeSubstance")}
                            >
                              {options.mainIngredients
                                  .filter((f) => { return f.categoryId === fertlizerKindId})
                                  .map((c, index) => {
                                return (
                                    <Option key={index} value={c.id}>
                                      {c.name}
                                    </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>


                        <Col md={6} sm={12} xs={24}>
                          <Form.Item
                              name="ProductId"
                              validateTrigger="onChange"
                          >
                            <Select
                                showSearch
                                notFoundContent={null}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                placeholder={t("productName")}
                            >
                              {options.fertilizers.filter((m)=>{return mainIngridientId && m.mainIngredientId === mainIngridientId }).map((c, index) => {
                                return (
                                    <Option key={index} value={c.id}>
                                      {c.name}
                                    </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>


                        <Col md={3} sm={12} xs={24}>
                          <Form.Item
                              name={" "}
                          >
                            <Button type="primary" size={'large'}  className="w-100 f-13" htmlType="submit">
                              {t("search")}
                            </Button>
                          </Form.Item>
                        </Col>

                        <Col md={3} sm={12} xs={24}>
                          <Form.Item
                              name={" "}
                          >
                            <Button type="primary" size={'large'}  onClick={()=>{clearFilter()}} className="w-100" htmlType="submit">
                              <ClearOutlined />
                            </Button>
                          </Form.Item>
                        </Col>

                      </Row>
                    </div>
                  </Form>
                </div>

                <Table
                  size="small"
                  className="bg-white   w-100"
                  columns={fullColumns}
                  dataSource={convertColumns(tables.reserves.data, cols)}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
              </TabPane>
            )}
            {perms.subs.importAndExport.perms.read && (
              <TabPane tab={t("importExportDocuments")} key="inouts">
                <Table
                  size="small"
                  className="bg-white   w-100"
                  columns={inoutsColumns}
                  dataSource={convertColumns(tables.inouts.data, cols)}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
              </TabPane>
            )}
            {perms.subs.tasks.perms.read && (
              <TabPane tab={t("tasks")} key="tasks">
                <Table
                  size="small"
                  className="bg-white   w-100"
                  columns={tasksColumns}
                  dataSource={convertColumns(tables.tasks.data, cols)}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>

      <Modal
        title={t("addNewProduct")}
        centered
        className="padModal mediumModal"
        onOk={() => {
          setVisibleImport(false)
          triggerFetch()
        }}
        onCancel={() => {
          setVisibleImport(false)
          triggerFetch()
        }}
        visible={visibleImport}
        footer={false}
      >
        <ImportModal
          triggerFetch={triggerFetch}
          setVisibleImport={setVisibleImport}
          visibleImport={visibleImport}
          trigger={trigger}
        />
      </Modal>

      <Modal
        title={t("newExoort")}
        centered
        className="padModal addTaskModal"
        onOk={() => {
          setVisibleExport(false)
          triggerFetch()
        }}
        onCancel={() => {
          setVisibleExport(false)
          triggerFetch()
        }}
        visible={visibleExport}
        footer={false}
      >
        <ExportModal
          triggerFetch={triggerFetch}
          setVisibleExport={setVisibleExport}
          visibleExport={visibleExport}
          trigger={trigger}
        />
      </Modal>

      <Modal
        title={
          modalType === 'export' ? t('export')
              : modalType === 'transfer' ? t('transfer')
              : modalType === 'edit' ? t("edit") : ''
        }
        centered
        className="padModal addTaskModal"
        onOk={() => setVisibleEdit(false)}
        onCancel={() => setVisibleEdit(false)}
        visible={visibleEdit}
        footer={false}
      >
        <Edit
          quantity={quantity}
          unit={unit}
          parcelSectors={parcelSectors}
          parcelCategory={parcelCategory}
          parcel={parcel}
          workPlanId={workPlanId}
          modalType={modalType}
          triggerFetch={triggerFetch}
          setVisibleEdit={setVisibleEdit}
          visibleEdit={visibleEdit}
          id={id}
        />
      </Modal>

      <Modal
        title={
          <div className="flex flex-align-center">
            <p>{t("import") + "/" + t("export")}</p>
            <div className="ml-20">
              {/* <Tooltip title={t("print")}>
                <Button shape="circle">
                  <PrinterFilled />
                </Button>
              </Tooltip> */}
            </div>
          </div>
        }
        centered
        className="mediumModal"
        onOk={() => setVisibleView(false)}
        onCancel={() => setVisibleView(false)}
        visible={visibleView}
        footer={[
          <div key="0" className="modalButtons">
            <Button onClick={() => setVisibleView(false)}>{t("close")}</Button>
          </div>,
        ]}
      >
        <ImportExportModal
          triggerFetch={triggerFetch}
          inout={tables.inouts.data[tables.inouts.selected]}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ user, options , lang }) => {
  return { perms: user.data.userPermissions, options , lang};
};

export default connect(mapStateToProps, { notify, getStock , getOptions})(
  DragAndFertilizers
);
import React, { useEffect, useState } from "react";
import {  Button, Table, Modal, Tooltip } from "antd";
import {
  UnorderedListOutlined,
} from "@ant-design/icons";
import ImportModal from "./Modals/ImportModal";
import ExportModal from "./Modals/ExportModal";
import { useTranslation } from "react-i18next";
import agros from "../../../../const/api";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import { notify, getStock } from "../../../../redux/actions";

const SiloWarehouse = (props) => {
  const { notify } = props;
  const { t } = useTranslation();
  const [visibleExport, setVisibleExport] = useState(false);
  const [visibleImport, setVisibleImport] = useState(false);

  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "cropName", value: t("typeOf"), con: true },
    { key: "cropSort", value: t("sortOf"), con: true },
    { key: "quantity", value: t("quantity"), con: true },
    { key: "count", value: t("quantityOf"), con: false },
    { key: "siloName", value: t("Silo"), con: true },
  ];

  const cols2 = [
    { key: "cropCategoryName", value: t("productCategory"), con: true },
    { key: "productName", value: t("productSorts"), con: true },
    { key: "reproductionName", value: t("reproduction"), con: true },
    { key: "warehouse", value: t("warehouse"), con: true },
    { key: "cropName", value: t("productName"), con: true },
    { key: "count", value: t("quantity"), con: true },
    { key: "operation", value: "", con: false },
    { key: "index", value: "", con: false },
  ];

  const [tables, setTables] = useState([]);

  let [trigger, setTrigger] = useState(0);


  useEffect(() => {
    const getData = () => {
      agros.get('Silostock').then((res) => {
        console.log(res)
        const all = res.data.map((r , index) => {
          return {
            ...r,
            key: index + 1,
            index,
            tableIndex: index + 1,
            quantity:  r.siloCapasity + 'kq',
          };
        })

        setTables(all);
      });
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, trigger]);

  const columns = [
    {
      title: "#",
      key: 1,
      dataIndex: "tableIndex",
      width: 70,
    },
    {
      title: 'Silo',
      key: 3,
      dataIndex: "siloName",
    },
    {
      title: t("typeOf"),
      key: 4,
      dataIndex: "cropName",
    },
    {
      title: t("sortOf"),
      key: 5,
      dataIndex: "cropSort",
    },
    {
      title: t("quantityOf"),
      key: 7,
      dataIndex: "count",
      render(i){
        return   <div className="flex statusTd">
        <p>{t("quantityOf")}</p>
        <div>
          {i} kq
        </div>
      </div>
      
      }
    },
    {
      title: 'Həcm',
      key: 7,
      dataIndex: "quantity",
    },
  ];




  const triggerFetch = () => {
    props.getStock();
    setTrigger(++trigger);
  };


  return (
    <div>
      <div className="border page-heading flex-align-center flex p-2 mt-0 bg-white">
        <div className="page-name">
          <UnorderedListOutlined className="f-20 mr5-15" />
          <span className="f-20 bold">
            Silolar
          </span>
        </div>
        <div>
          <Button type="primary" onClick={() => setVisibleImport(true)}>
            {t("import")}
          </Button>
          <Button className="ml-10" onClick={() => setVisibleExport(true)}>
            {t("export")}
          </Button>
        </div>
      </div>
      <div className="position-relative mt-15">
        <div className="position-absolute w-100 tab-section">
                <Table
                  size="small"
                  className="bg-white   w-100"
                  columns={columns}
                  dataSource={convertColumns(tables, cols)}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
        </div>
      </div>

      <Modal
        title={t("addNewProduct")}
        centered
        className="padModal mediumModal"
        visible={visibleImport}
        footer={null}
        onOk={() => {
          setVisibleImport(false)
          setTrigger(++trigger)
        }}
        onCancel={() => {
          setVisibleImport(false)
          setTrigger(++trigger)
        }}
      >
        <ImportModal
          setVisibleImport={setVisibleImport}
          visibleImport={visibleImport}
          triggerFetch={triggerFetch}
          trigger={trigger}
        />
      </Modal>

      <Modal
        title={t("newExoort")}
        centered
        className="padModal"
        onOk={() => {
          setVisibleExport(false)
          setTrigger(++trigger)
        }}
        onCancel={() => {
          setVisibleExport(false)
          setTrigger(++trigger)
        }}
        visible={visibleExport}
        footer={false}
      >
        <ExportModal
          setVisibleExport={setVisibleExport}
          visibleExport={visibleExport}
          triggerFetch={triggerFetch}
          trigger={trigger}
        />
      </Modal>

    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return { perms: user.data.userPermissions };
};
export default connect(mapStateToProps, { notify, getStock })(SiloWarehouse);

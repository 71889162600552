import React from 'react';
import Images from "../../../../assets/img/Images/Images";
import { Col, Row } from "antd";

function Product(props) {
    return (
        <div className={`product`}>
            <div className={`heading  ${props.direction}`}>
                <div className="circle">
                    <img src={Images.circle} alt="" />
                    <span>{props.number}</span>
                </div>
                <div className='name'>
                    <p>
                        {props.name}
                    </p>
                </div>
                <div className='list'>
                    <img src={Images.list11} alt="" />
                    <img src={Images.list12} alt="" />
                </div>
            </div>
            <div className="content">
                <Row gutter={[16, 16]}>
                    {
                        props.direction === 'left' ?
                            <>
                                <Col sm={17} md={15} >
                                    <span 
                                        dangerouslySetInnerHTML={{
                                            __html: props?.about
                                        }} />
                                </Col>
                                <Col sm={7} md={9}>
                                    <div className="flex all-center">
                                        <img src={props.image} alt="" />
                                    </div>
                                </Col>
                            </> :
                            <>
                                <Col sm={7} md={9}>
                                    <div className="flex all-center">
                                        <img src={props.image} alt="" />
                                    </div>
                                </Col>
                                <Col sm={15} md={15} >
                                <span 
                                        dangerouslySetInnerHTML={{
                                            __html: props?.about
                                        }} />
                                </Col>
                            </>
                    }
                </Row>
            </div>
        </div>
    );
}

export default Product;

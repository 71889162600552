import React from 'react';
import agros from '../../../const/api';
import SelectModuls from "./steps/SelectModuls";
import RegisterForm from "./steps/RegisterForm";

function Packs() {
    const [current , setCurrent] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [modul, setModul] = React.useState(null);
    React.useEffect(() => {
        const getData = () => {
          agros.get("root/Modul").then((res) => {
            setData(res?.data);
          });
        };
        getData();
      }, []);
    const steps = [
        {
            content: <SelectModuls current={current} data={data}  setCurrent={setCurrent} setModul={setModul} />
        },
        {
            content: <RegisterForm current={current}  setCurrent={setCurrent} modul={modul} setModul={setModul} />
        }
    ];

    return (
        <div className="content">
           <div className="packs">
               <div className="stepdots">
                   <div onClick={()=>{setCurrent(0)}} className={`dot ${current === 0 ?  'active': ' ' }`}></div>
                   <div onClick={()=>{setCurrent(1)}} className={`dot ${current === 1  ? 'active': ' ' }`}></div>
               </div>
               <div className="steps-content">{steps[current].content}</div>
           </div>
        </div>
    );
}

export default Packs;

import React, { useState } from 'react';
import { Row, Spin, Tabs, Col, Table, Select } from "antd";
import Images from "../../../../assets/img/Images/Images";
import { Gauge, Liquid } from '@ant-design/plots';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { convertColumns } from '../../../../utils/columnconverter';
const { Option } = Select;
const { TabPane } = Tabs;
function Charts({ data, getStationData, setTabkey, stansions, hash }) {
    const array = [data]
    const { t } = useTranslation()
    const [spin, setSpin] = useState(false)
    const stateSoil = {
        series: [data?.davis_current_observation?.soil_moisture_2], // Verileri derece cinsinden girin
        options: {
            chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: "#e7e7e7",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels

                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: '#999',
                            opacity: 1,
                            blur: 2
                        }
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: -2,
                            fontSize: '22px',
                            // Gerçek veriyi göstermek için formatter kullanın
                            formatter: function (val) {
                                console.log(val);
                                return data?.davis_current_observation?.soil_moisture_2 + ' cb'; // Değeri doğrudan döndürün
                            }
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    shadeIntensity: 0.4,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 53, 91]
                },
                colors: ['#000'],
            },
            labels: ['Average Results'],
            // Maksimum değeri belirleyin
            max: data?.davis_current_observation?.soil_moisture_2_day_high,

        },
    };
    const stateTemp = {
        series: [((5 / 9) * (data?.davis_current_observation?.temp_soil_1 - 32))], // Verileri derece cinsinden girin
        options: {
            chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: "#e7e7e7",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels

                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: '#999',
                            opacity: 1,
                            blur: 2
                        }
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: -2,
                            fontSize: '22px',
                            // Gerçek veriyi göstermek için formatter kullanın
                            formatter: function (val) {
                                return ((5 / 9) * (data?.davis_current_observation?.temp_soil_1 - 32)).toFixed(1) + '°C'; // Değeri doğrudan döndürün
                            }
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    shadeIntensity: 0.4,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 53, 91]
                },
                colors: ['#04a89c'],
            },
            labels: ['Average Results'],
            // Maksimum değeri belirleyin
            max: ((5 / 9) * (data?.davis_current_observation?.temp_soil_1_day_high - 32)),
            min: ((5 / 9) * (data?.davis_current_observation?.temp_soil_1_day_low - 32)),

        },
    };
    const state = {
        series: [{
            name: 'Et',
            data: [data?.davis_current_observation?.et_day ? data?.davis_current_observation?.et_day : 0, data?.davis_current_observation?.et_month ? data?.davis_current_observation?.et_month : 0, data?.davis_current_observation?.et_year ? data?.davis_current_observation?.et_year : 0]
        }],
        options: {
            chart: {
                height: 1050,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    borderRadius: 1,
                    columnWidth: '50%',
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 0
            },
            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            xaxis: {
                labels: {
                    rotate: -45
                },
                categories: ['Gün', 'Ay', 'İl'],
                tickPlacement: 'on'
            },
            yaxis: {
                title: {
                    text: 'ET (mm)',
                },
                labels: {
                    formatter: (val) => {
                        return val.toFixed(2)
                    }
                }

            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: 0.25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [50, 0, 100]
                },
            }
        },

    };
    const stateData = {
        series: [{
            data: data.temp_c && data.windchill_c && data.heat_index_c && data.dewpoint_c ? [data.temp_c, data.windchill_c, data.heat_index_c, data.dewpoint_c] : []
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                }
            },
            colors: ['#c42728', '#3088c2', '#e97f24', '#36916d'],
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            yaxis: {
                labels: {
                    formatter: (val) => {
                        return val + '°C'
                    }
                }
            },
            xaxis: {
                categories: [
                    'Temperatur',
                    'Küləyin nəzəri istiliyi',
                    'Istilik indeksi',
                    'Şeh nöqtəsi',
                ],
                labels: {
                    style: {
                        colors: ['#c42728', '#3088c2', '#e97f24', '#36916d'],
                        fontSize: '12px'
                    }
                },
            }
        }

    };
    stateSoil.series = stateSoil.series.map(value => Math.round((value / stateSoil.options.max) * 100));
    stateTemp.series = stateTemp.series.map(value => Math.round((value / stateTemp.options.max) * 100));
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "name", value: t("heading"), con: true },
        { key: "startDate", value: t("startDate"), con: true },
        { key: "endDate", value: t("endDate"), con: true },
        { key: "workStatus", value: "Status", con: true },
        { key: "parcelName", value: "Parcel", con: true },
        { key: "cropName", value: t("Məhsul"), con: true },
        { key: "cropSortName", value: t("Sort"), con: true },
        { key: "id", value: "", con: false },
    ];
    const config = {
        percent: parseInt(data?.davis_current_observation?.solar_radiation) / parseInt(data?.davis_current_observation?.solar_radiation_year_high),
        range: {
            color: '#30BF78',
        },
        animation: true,
        indicator: {
            pointer: {
                style: {
                    stroke: '#D0D0D0',
                    strokeOpacity: 0.5,
                },
            },
            pin: {
                style: {
                    stroke: '#D0D0D0',
                    strokeOpacity: 0.5,
                },
            },
        },
        axis: {
            label: {
                formatter(v) {
                    return Number(v) * 100;
                },
                style: {
                    fontSize: 6
                }
            },
            subTickLine: {
                count: 3,
            },
        },
        statistic: {
            content: {
                formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%   ${data?.davis_current_observation?.solar_radiation} W/m2`,
                style: {
                    color: 'rgba(0,0,0,0.65)',
                    fontSize: 10,
                    fontWeight: 600,
                    opacity: 1
                },
            },
        },
    };
    const col1 = [
        {
            title: 'Barometr (mbar)',
            dataIndex: 'pressure_mb',
            key: 1,
        },
        {
            title: 'Temperatur (°C)',
            dataIndex: 'temp_c',
            key: 2,
        },
        {
            title: 'ET (mm)',
            dataIndex: 'davis_current_observation',
            key: 3,
            render: (data) => {
                return data?.et_day
            }
        },
        {
            title: 'Rütubət (%)',
            dataIndex: 'relative_humidity',
            key: 4,
        },

        {
            title: 'İstilik indeksi (°C)',
            dataIndex: 'heat_index_c',
            key: 5,
        }
    ]
    const datsa = [
        {
            name: 'G2',
            star: 10371,
        },
        {
            name: 'G6',
            star: 7380,
        },
        {
            name: 'F2',
            star: 7414,
        },
        {
            name: 'L7',
            star: 2140,
        },
        {
            name: 'X6',
            star: 660,
        },
        {
            name: 'AVA',
            star: 885,
        },
        {
            name: 'G2Plot',
            star: 1626,
        },
    ];
    const config3 = {
        data: datsa.map((d) => ({ ...d, star: Math.sqrt(d.star) })),
        xField: 'name',
        yField: 'star',
        appendPadding: [0, 10, 0, 10],
        meta: {
            star: {
                alias: 'star ',
                min: 0,
                nice: true,
                formatter: (v) => Number(v).toFixed(2),
            },
        },
        xAxis: {
            tickLine: null,
        },
        yAxis: {
            label: false,
            grid: {
                alternateColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
        point: {
            size: 2,
        },
        area: {},
    };
    const colDay1 = [
        {
            title: 'Barometr (mbar)',
            dataIndex: 'davis_current_observation',
            key: 1,
            render: (data, res) => {
                console.log(((Number(res.pressure_mb) - Number(data.pressure_day_low_in)).toFixed(2)));
                console.log(((Number(res.pressure_mb) + Number(data.pressure_day_high_in)).toFixed(2)));
                console.log((((Number(res.pressure_mb) - Number(data.pressure_day_low_in)).toFixed(2)) + ((Number(res.pressure_mb) + Number(data.pressure_day_high_in)).toFixed(2))));
                return <div className='flex dir-column gap-3'>
                    <span>Min: {(Number(res.pressure_mb) - Number(data.pressure_day_low_in)).toFixed(2)}</span>
                    <span>Max: {(Number(res.pressure_mb) + Number(data.pressure_day_high_in)).toFixed(2)}</span>
                    <span>Orta: {((Number((Number(res.pressure_mb) - Number(data.pressure_day_low_in)).toFixed(2)) + Number((Number(res.pressure_mb) + Number(data.pressure_day_high_in)).toFixed(2))) / 2).toFixed(2)}</span>
                </div>
            }
        },
        {
            title: 'Temperatur (°C)',
            dataIndex: 'davis_current_observation',
            key: 2,
            render: (data) => {
                console.log(data.temp_day_low_f);
                return <div className='flex dir-column gap-3'>
                    <span>Min: {((5 / 9) * (data?.temp_day_low_f - 32)).toFixed(2)}</span>
                    <span>Max: {((5 / 9) * (data?.temp_day_high_f - 32)).toFixed(2)}</span>
                    <span>Orta: {((((5 / 9) * (data?.temp_day_low_f - 32)) + ((5 / 9) * (data?.temp_day_high_f - 32))) / 2).toFixed(2)}</span>
                </div>
            }
        },
        {
            title: 'ET (mm)',
            dataIndex: 'davis_current_observation',
            key: 3,
            render: (data) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {data.et_day}</span>
                    <span>Max: {data.et_month}</span>
                    <span>Orta: {((Number(data.et_month) + Number(data.et_day)) / 2).toFixed(2)}</span>
                </div>
            }
        },
        {
            title: 'Rütubət (%)',
            dataIndex: 'davis_current_observation',
            key: 4,
            render: (data) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {data.relative_humidity_day_low}</span>
                    <span>Max: {data.relative_humidity_day_high}</span>
                    <span>Orta: {((Number(data.relative_humidity_day_low) + Number(data.relative_humidity_day_high)) / 2)}</span>
                </div>
            }
        },

        {
            title: 'İstilik indeksi (°C)',
            dataIndex: 'davis_current_observation',
            key: 5,
            render: (data, res) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {res.heat_index_c}</span>
                    <span>Max: {((5 / 9) * (data?.heat_index_day_high_f - 32)).toFixed(2)}</span>
                    <span>Orta: {((Number(res.heat_index_c) + ((5 / 9) * (data?.heat_index_day_high_f - 32))) / 2).toFixed(2)}</span>
                </div>
            }
        }
    ]
    const col2 = [
        {
            title: 'Yağış (mm)',
            dataIndex: 'davis_current_observation',
            key: 1,
            render: (data) => {
                return Number(data?.rain_day_in).toFixed(2)
            }
        },
        {
            title: 'Yağış dərəcəsi (mm/h)',
            dataIndex: 'davis_current_observation',
            key: 2,
            render: (data) => {
                return Number(data?.rain_rate_day_high_in_per_hr).toFixed(2)
            }
        },
        {
            title: 'Şeh nöqtəsi (°C)',
            dataIndex: 'dewpoint_c',
            key: 3,
        },
        {
            title: 'Günəş radiyasiyası (W/m2)',
            dataIndex: 'davis_current_observation',
            key: 4,
            render: (data) => {
                return data?.solar_radiation
            }
        },

        {
            title: 'Uv indeksi',
            dataIndex: 'davis_current_observation',
            key: 5,
            render: (data) => {
                return data?.uv_index_day_high
            }
        }
    ]
    const colDay2 = [
        {
            title: 'Yağış (mm)',
            dataIndex: 'davis_current_observation',
            key: 1,
            render: (data) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {Number(data?.rain_day_in).toFixed(2)}</span>
                    <span>Max: {Number(data?.rain_month_in).toFixed(2)}</span>
                    <span>Orta: {((Number(data.rain_day_in) + Number(data.rain_month_in)) / 2).toFixed(2)}</span>
                </div>
            }
        },
        {
            title: 'Yağış dərəcəsi (mm/h)',
            dataIndex: 'davis_current_observation',
            key: 2,
            render: (data) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {Number(data.rain_rate_day_high_in_per_hr).toFixed(2)}</span>
                    <span>Max: {Number(data.rain_rate_month_high_in_per_hr).toFixed(2)}</span>
                    <span>Orta: {Number((Number(data.rain_rate_day_high_in_per_hr) + Number(data.rain_rate_month_high_in_per_hr)) / 2).toFixed(2)}</span>
                </div>
            }
        },
        {
            title: 'Şeh nöqtəsi (°C)',
            dataIndex: 'davis_current_observation',
            key: 3,
            render: (data) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {((5 / 9) * (data?.dewpoint_day_low_f - 32)).toFixed(2)}</span>
                    <span>Max: {((5 / 9) * (data?.dewpoint_day_high_f - 32)).toFixed(2)}</span>
                    <span>Orta: {((((5 / 9) * (data?.dewpoint_day_low_f - 32)) + ((5 / 9) * (data?.dewpoint_day_high_f - 32))) / 2).toFixed(2)}</span>
                </div>
            }
        },
        {
            title: 'Günəş radiyasiyası (W/m2)',
            dataIndex: 'davis_current_observation',
            key: 4,
            render: (data) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {data.solar_radiation}</span>
                    <span>Max: {data.solar_radiation_day_high}</span>
                    <span>Orta: {(Number(data.solar_radiation) + Number(data.solar_radiation_day_high)) / 2}</span>
                </div>
            }
        },

        {
            title: 'Uv indeksi',
            dataIndex: 'davis_current_observation',
            key: 5,
            render: (data) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {data.uv_index_day_high}</span>
                    <span>Max: {data.uv_index_month_high}</span>
                    <span>Orta: {(Number(data.uv_index_day_high) + Number(data.uv_index_month_high)) / 2}</span>
                </div>
            }
        }
    ]
    const col3 = [
        {
            title: 'Yarpaq nəmliyi (cb)',
            dataIndex: 'davis_current_observation',
            key: 2,
            render: (data) => {
                return data?.leaf_wetness_1
            }
        },
        {
            title: 'Torpaq nəmliyi 1 (cb)',
            dataIndex: 'davis_current_observation',
            key: 2,
            render: (data) => {
                return data?.soil_moisture_1
            }
        },
        {
            title: 'Torpaq nəmliyi 2 (cb)',
            dataIndex: 'davis_current_observation',
            key: 3,
            render: (data) => {
                return data?.soil_moisture_2
            }
        },
        {
            title: 'Torpaq nəmliyi 3 (cb)',
            dataIndex: 'davis_current_observation',
            key: 4,
            render: (data) => {
                return data?.soil_moisture_3
            }
        },
        {
            title: 'Küləyin nəzəri istilik hissi (°C)',
            dataIndex: 'windchill_c',
            key: 5,
        },
    ]
    const colDay3 = [
        {
            title: 'Yarpaq nəmliyi (cb)',
            dataIndex: 'davis_current_observation',
            key: 2,
            render: (data) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {data.leaf_wetness_1_day_low}</span>
                    <span>Max: {data.leaf_wetness_1_day_high}</span>
                    <span>Orta: {(Number(data.leaf_wetness_1_day_high) + Number(data.leaf_wetness_1_day_low)) / 2}</span>
                </div>
            }
        },
        {
            title: 'Torpaq nəmliyi 1 (cb)',
            dataIndex: 'davis_current_observation',
            key: 2,
            render: (data) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {data.soil_moisture_1_day_low}</span>
                    <span>Max: {data.soil_moisture_1_day_high}</span>
                    <span>Orta: {(Number(data.soil_moisture_1_day_low) + Number(data.soil_moisture_1_day_high)) / 2}</span>
                </div>
            }
        },
        {
            title: 'Torpaq nəmliyi 2 (cb)',
            dataIndex: 'davis_current_observation',
            key: 3,
            render: (data) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {data.soil_moisture_2_day_low}</span>
                    <span>Max: {data.soil_moisture_2_day_high}</span>
                    <span>Orta: {(Number(data.soil_moisture_2_day_low) + Number(data.soil_moisture_2_day_high)) / 2}</span>
                </div>
            }
        },
        {
            title: 'Torpaq nəmliyi 3 (cb)',
            dataIndex: 'davis_current_observation',
            key: 4,
            render: (data) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {data.soil_moisture_3_day_low}</span>
                    <span>Max: {data.soil_moisture_3_day_high}</span>
                    <span>Orta: {(Number(data.soil_moisture_3_day_low) + Number(data.soil_moisture_3_day_high)) / 2}</span>
                </div>
            }
        },
        {
            title: 'Küləyin nəzəri istilik hissi (°C)',
            dataIndex: 'davis_current_observation',
            key: 5,
            render: (data, res) => {
                return <div className='flex dir-column gap-3'>
                    <span>Min: {Number(((5 / 9) * (data?.windchill_day_low_f - 32)).toFixed(2))}</span>
                    <span>Max: {res?.windchill_c}</span>
                    <span>Orta: {(((Number((5 / 9) * (data?.windchill_day_low_f - 32))) + res?.windchill_c) / 2).toFixed(2)}</span>
                </div>
            }
        },
    ]

    const configliquid = {
        percent: data?.davis_current_observation?.relative_humidity_in / data?.davis_current_observation?.relative_humidity_year_high,
        outline: {
            border: 1,
            color: 'F0EEEE',
            distance: 0,
            style: {
                stroke: '#F0EEEE',
                strokeOpacity: 0.7,
            },
        },
        animation: true,
        wave: {
            length: 50,
        },
        theme: {
            styleSheet: {
                brandColor: '#00C2FF',
            },
        },
    };
    const configwind = {
        percent: parseInt(data?.davis_current_observation?.wind_day_high_mph) / parseInt(data?.davis_current_observation?.wind_year_high_mph),
        range: {
            color: '#30BF78',
        },
        animation: true,
        indicator: {
            pointer: {
                style: {
                    stroke: '#D0D0D0',
                    strokeOpacity: 0.5,
                },
            },
            pin: {
                style: {
                    stroke: '#D0D0D0',
                    strokeOpacity: 0.5,
                },
            },
        },
        axis: {
            label: {
                formatter(v) {
                    return Number(v) * 100;
                },
                style: {
                    fontSize: 6
                }
            },
            subTickLine: {
                count: 3,
            },
        },
        statistic: {
            content: {
                formatter: ({ percent }) => `${(percent * 100).toFixed(0)}% ${data.davis_current_observation?.wind_day_high_mph} mph`,
                style: {
                    color: 'rgba(0,0,0,0.65)',
                    fontSize: 10,
                    fontWeight: 600,
                    opacity: 1
                },
            },
        },
    };
    const config2 = {
        percent: configliquid.percent,
        range: {
            ticks: [0, 1 / 3, 2 / 3, 1],
            color: ['#F4664A', '#FAAD14', '#30BF78'],
        },
        animation: true,
        indicator: {
            pointer: {
                style: {
                    stroke: '#D0D0D0',
                    strokeOpacity: 0.5,
                },
            },
            pin: {
                style: {
                    stroke: '#D0D0D0',
                },
            },
        },
        axis: {
            label: {
                formatter(v) {
                    return Number(v) * 100;
                },
                style: {
                    fontSize: 6
                }
            },
            subTickLine: {
                count: 3,
            },
        },
        statistic: {
            content: {

                style: {
                    color: 'rgba(0,0,0,0.65)',
                    fontSize: 10,
                    fontWeight: 600,
                    opacity: 1
                },
            },
        },
    };
    return (
        <div className={'mt-15 w-100'}>
            <Row className={'mt-15 '} gutter={[10, 10]}>
                <Col lg={24}>
                    <div className="card bg-white animated zoomIn">
                        <Select className='stansion' defaultValue={hash ? Number(hash) : 1} onChange={(e) => { getStationData(e); setTabkey(e) }}>
                            {stansions.map((pr, index) => {
                                return (
                                    <Option key={index} value={pr.id}>
                                        {pr.name}
                                    </Option>
                                );
                            })}
                        </Select>
                        <Row className={'mt-15 flex flex-align-center'}  gutter={[16, 8]}>
                            {/* <div className='flex flex-wrap chart-divs flex-between flex-align-center mb-10'> */}
                            <Col lg={6}>
                            <div className="text-center">
                                    <div className='gauge-inf'>
                                        <Gauge className='gauge' {...configwind} />
                                        <span>Max: {data?.davis_current_observation?.wind_year_high_mph} mph</span>
                                    </div>
                                    <div className="mt-10">
                                        <p className='mr-5'>Küləyin sürəti</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="text-center">
                                    <div className='gauge-inf'>
                                        <Gauge className='gauge' {...config} />
                                        <span>Max: {data?.davis_current_observation?.solar_radiation_year_high} W/m <sup>2</sup> </span>
                                    </div>
                                    <div className="mt-10">
                                        <p className='mr-5'>Günəş radiyasiyası</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className=" text-center ">
                                    <div className="text-center">
                                        <div className='gauge-inf'>
                                            <Gauge className='gauge' {...config2} />
                                            <span>{(configliquid.percent * 100).toFixed(2)} %</span>
                                        </div>
                                        <div className="mt-10">
                                            <p className='mr-5'>Nəm nisbəti</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                            <div className="text-center">
                                    <div className='gauge-inf'>
                                        <div >
                                            <ReactApexChart options={stateData.options} series={stateData.series} type="bar" height={350} />
                                        </div>
                                    </div>
                                    <div>
                                        <p className='mr-5'>Temperatur</p>
                                    </div>
                                </div>
                        
                            </Col>

                            <Col lg={6}>
                                <div className='flex dir-column gap-10'>             
                                <div className="text-center">
                                    <div className='gauge-inf'>
                                        <Gauge className='gauge' {...configwind} />
                                        <span>Max: {data?.davis_current_observation?.wind_year_high_mph} mph</span>
                                    </div>
                                    <div className="mt-10">
                                        <p className='mr-5'>Havanın təzyiqi</p>
                                    </div>
                                </div>
                                <div className="flex-column text-center gauge-inf">
                                <div className='flex dir-column flex-align-center mt-20'>
                                    <img src={Images.Weather} alt="" />
                                    <strong>{data.temp_c}°C</strong>
                                </div>
                                <div>
                                    <p className='mr-5'>Havanın istiliyi</p>
                                </div>
                            </div>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="text-center mb-115  " >
                                    <div className='gauge-inf'>
                                        <div id="chart">
                                            <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
                                        </div>
                                    </div>
                                    <div >
                                        <p className='mr-5'>ET</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='flex dir-column flex-align-center gap-20'>
                                <div className="text-center">
                                    <div className='gauge-inf'>
                                        <div >
                                            <ReactApexChart options={stateSoil.options} series={stateSoil.series} type="radialBar" />
                                        </div>
                                        <span>Max: {data?.davis_current_observation?.soil_moisture_2_day_high} cb</span>
                                    </div>
                                    <div className="mt-10">
                                        <p className='mr-5'>Torpaq nəmliyi 1</p>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <div className='gauge-inf'>
                                        <div >
                                            <ReactApexChart options={stateTemp.options} series={stateTemp.series} type="radialBar" />
                                        </div>
                                        <div className='flex dir-column'>
                                            <span>Max: {stateTemp.options.max.toFixed(1)} °C </span>
                                            <span>Min: {stateTemp.options.min.toFixed(1)} °C </span>
                                        </div>
                                    </div>
                                    <div className="mt-10">
                                        <p className='mr-5'>Torpaq temperaturu</p>
                                    </div>
                                </div>
                                </div>
                            </Col>

                            {/* </div> */}
                            {/* <div className='flex flex-wrap chart-divs flex-between flex-align-center mb-10'>
                            <div className="text-center">
                                <div className='gauge-inf'>
                                    <div >
                                        <ReactApexChart options={stateTemp.options} series={stateTemp.series} type="radialBar" />
                                    </div>
                                    <div className='flex dir-column'>
                                        <span>Max: {stateTemp.options.max.toFixed(1)} °C </span>
                                        <span>Min: {stateTemp.options.min.toFixed(1)} °C </span>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <p className='mr-5'>Torpaq temperaturu</p>
                                </div>
                            </div>
                            <div className="text-center">
                                <div className='gauge-inf'>
                                    <div >
                                        <ReactApexChart options={stateTemp.options} series={stateTemp.series} type="radialBar" />
                                    </div>
                                    <div className='flex dir-column'>
                                        <span>Max: {stateTemp.options.max.toFixed(1)} °C </span>
                                        <span>Min: {stateTemp.options.min.toFixed(1)} °C </span>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <p className='mr-5'>Torpaq temperaturu</p>
                                </div>
                            </div>
                            <div className="text-center">
                                <div className='gauge-inf'>
                                    <div >
                                    <ReactApexChart options={stateData.options} series={stateData.series} type="bar" height={350} />
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <p className='mr-5'>Torpaq temperaturu</p>
                                </div>
                            </div>
                       
                        </div> */}
                        </Row>

                        <div>

                        </div>
                    </div>
                </Col>
                
            </Row>
            <Spin spinning={spin} className={'bg-white p-3 mt-15'}>
                <div className="position-relative mt-15">
                    <div className=" w-100 purchase-tabs tab-section">
                        <Tabs defaultActiveKey="1"  >
                            <TabPane tab="Son ölçü göstəricisi" key="1">
                                <div className='flex dir-column gap-5'>
                                    <Table
                                        size="small"
                                        className="bg-white w-100 risk-table"
                                        columns={col1}
                                        dataSource={array}
                                        pagination={false}
                                        bordered
                                    />
                                    <Table
                                        size="small"
                                        className="bg-white w-100 risk-table"
                                        columns={col2}
                                        dataSource={convertColumns(array, cols)}
                                        pagination={false}
                                        bordered
                                    />
                                    <Table
                                        size="small"
                                        className="bg-white w-100 risk-table"
                                        columns={col3}
                                        dataSource={convertColumns(array, cols)}
                                        pagination={false}
                                        bordered
                                    />
                                </div>

                            </TabPane>
                            <TabPane tab="Son gün göstəricisi" key="2">
                                <div className='flex dir-column gap-5'>
                                    <Table
                                        size="small"
                                        className="bg-white w-100 risk-table"
                                        columns={colDay1}
                                        dataSource={convertColumns(array, cols)}
                                        pagination={false}
                                        bordered
                                    />
                                    <Table
                                        size="small"
                                        className="bg-white w-100 risk-table"
                                        columns={colDay2}
                                        dataSource={convertColumns(array, cols)}
                                        pagination={false}
                                        bordered
                                    />
                                    <Table
                                        size="small"
                                        className="bg-white w-100 risk-table"
                                        columns={colDay3}
                                        dataSource={convertColumns(array, cols)}
                                        pagination={false}
                                        bordered
                                    />

                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </Spin>
        </div>
    );
};


export default Charts;

import React, { useState, useEffect } from "react";
import { Button, Col, Input, List, Row, Table, Form, Select, InputNumber } from "antd";
import moment from "moment";
import agros from "../../../../../const/api";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../../utils/columnconverter";
import { getOptions, notify } from "../../../../../redux/actions";
import { noWhitespace, whiteSpace } from "../../../../../utils/rules";

const { Option } = Select;

const ExportModal = (props) => {
  const { t, i18n } = useTranslation();

  const cols = [
    { key: "productName", value: t("name"), con: true },
    { key: "fertilizerKind", value: t("typeOf"), con: true },
    { key: "mainIngredient", value: t("activeSubstance"), con: true },
    { key: "quantity", value: t("quantityOf"), con: true },
    { key: "price", value: t("price"), con: true },
    { key: "documentNumber", value: t("documentNumber"), con: true },
    { key: "warehouseName", value: t("warehouse"), con: true },
    { key: "quantity", value: t("quantity"), con: true },
    { key: "operation", value: t("operation"), con: true },
    { key: "productName", value: t("productName"), con: true },
    { key: "takenPerson", value: t("person"), con: true },
    { key: "expireDate", value: t("expirationDate"), con: false },
    { key: "usedStatus", value: "", con: false },
    { key: "index", value: "", con: false },
  ];

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { notify } = props;
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([])
  const [price, setPrice] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [quantity, setQuantity] = useState(0);
  const [params, setParams] = useState({
    productId: undefined,
    mainIngredientId: undefined,
  });
  const [stockId, setStockId] = useState(null);
  const mapData = (data) => {
    let formData = [];
    let prods = [];
    console.log(data);
    setQuantity(data.editedQuantity)
    for (const d of data) {
      console.log(d);
      const existingEntry = formData.find(o => o.ProductId === d.productId);
      console.log(existingEntry);
      if (existingEntry) {
        existingEntry['quantity'] = parseInt(existingEntry.quantity) + parseInt(d.editedQuantity)
        existingEntry['maxValue'] = parseInt(existingEntry.maxValue) + parseInt(d.editedQuantity)
        form.setFieldsValue({ quantity: formData[0].quantity });
        console.log(formData);
        //setProducts(formData);
      }
      else {
        console.log(formData);
        let obj = {
          quantity: d.editedQuantity,
          maxValue: d.quantity,
        };

        prods.push({
          quantity: d.editedQuantity,
          maxValue: d.quantity,
        });
        formData.push(obj);
        form.setFieldsValue({ quantity: formData[0].quantity });
        console.log(formData);
        //setProducts(formData);
      }
    }
  };
  const setStock = (selectedRows, selectedRowKeys) => {
    console.log('salam');
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
    mapData(selectedRows)
  };

  const OnValueChange = (value, id) => {
    const arr = [...selectedRows]
    console.log(arr);
    let selectedData = arr.find((d) => d.id === id);
    console.log(selectedData);
    selectedData['editedQuantity'] = value
    mapData(arr)
    console.log(arr);
  }
  const [vals, setVals] = useState({});
 const cancel = () => {
    props.setVisibleExport(false)
    form.resetFields();
    form2.resetFields();
    setData([]);
    setSelectedRowKeys([])
    setSelectedRows([])
    params.productId = undefined;
    params.mainIngredientId = undefined;
    setParams(params);
  }
  const handleKeyChange = (e, key) => {
    console.log(key);
    if (key === "parcelCategoryId") {
      form.setFieldsValue({ parcelId: undefined });
      form.setFieldsValue({ parcelSectorId: undefined });
    }
    if (key === "parcelId") {
      form.setFieldsValue({ parcelSectorId: undefined });
    }
    const all = { ...vals };
    all[key] = e;
    setVals(all);
  };

  const { getOptions } = props;
  const options = props.options[props.lang];

  const handleParamChange = (e, key) => {
    console.log(e);
    console.log(key);
    if (key === "fertilizerKindId") {
      form2.resetFields(["mainIngredientId"]);
      form2.resetFields(["productId"]);
    }
    if (key === "mainIngredientId") {
      form2.resetFields(["productId"]);
    }
    let par = { ...params };
    par[key] = e;
    setParams(par);
  };
  useEffect(() => {
    getOptions(
      [
        "fertilizerKinds",
        "mainIngredients",
        "fertilizers",
        "parcels",
        "parcelCategories",
        "parcelSectors",
      ],
      props.options,
      i18n.language
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  useEffect(() => {
    form.resetFields();
    form2.resetFields();
    setData([]);
    setSelectedRowKeys([])
    setSelectedRows([])
    params.productId = undefined;
    params.mainIngredientId = undefined;
    setParams(params);
  }, [props.trigger]);


  // table data columns
  const columns = [
    {
      title: t("name"),
      key: 1,
      dataIndex: "productName",
    },
    {
      title: t("typeOf"),
      key: 2,
      dataIndex: "fertilizerKind",
    },
    {
      title: t("activeSubstance"),
      key: 3,
      dataIndex: "mainIngredient",
    },
    {
      title: t("quantityOf"),
      key: 4,
      dataIndex: "fullQuantity",
    },
    {
      title: t("expirationDate"),
      key: 5,
      dataIndex: "expireDate",
      render: (d) => <span>{moment(d).format("DD-MM-YYYY")}</span>,
    },
    {
      title: t("price"),
      key: 6,
      dataIndex: "price",
    },
    {
      title: t("warehouse"),
      key: 7,
      dataIndex: "warehouseName",
    },
    {
      title: t("status"),
      key: 8,
      dataIndex: "usedStatus",
      render: (i) => {
        return (
          <div className="flex statusTd">
            <p>{t("status")}</p>
            {i ? (
              <p className="text-primary">{t("isUsed")}</p>
            ) : (
              <p className="text-danger">{t("isNotUsed")}</p>
            )}
          </div>
        );
      },
    },
  ];

  const getData = () => {
    agros
      .get(
        `medicalstock/export?productId=${params.productId}&&mainIngredientId=${params.mainIngredientId}`
      )
      .then((res) => {
        setData(
          res.data.map((r, index) => {
            return {
              ...r,
              index,
              key: index + 1,
              fullQuantity: r.quantity + ' ' + r.measurementUnit,
              editedQuantity: r.quantity,
              quantity: r.quantity,

            };
          })
        );
      });
  };

  const saveExport = (values) => {
    console.log(values);
    let id = []
    selectedRows.forEach((row) => {
      id.push(row.id)
    })
    console.log(id);
    let obj = {
      ...values,
      ParcelSectorIds: values.parcelSectorId,
      StockInfo: selectedRows.map((r) => ({
        StockId: r.id,
        Quantity: r.editedQuantity
      }))
    }
    if (selectedRows.length > 0) {
      agros
        .post("medicalstock/export", obj)
        .then((res) => {
          notify("", true);
          form.resetFields();
          form2.resetFields();
          props.setVisibleExport(false);
          props.triggerFetch();
        })
        .catch((err) => {
          notify("", false);
        });
    } else {
      notify("Məhsulu seçin", false);
    }
  };
  console.log(selectedRows);
  return (
    <>
    <div className="searchForm">
      <Form form={form2}>
        <Row gutter={[17, 17]}>
          <Col span={6}>
            <Form.Item name="fertilizerKindId">
              <Select
                onChange={(e) => handleParamChange(e, "fertilizerKindId")}
                placeholder={t("Dərman/Gübrə")}
              >
                {options.fertilizerKinds.map((c, cindex) => {
                  return (
                    <Option key={cindex} value={c.id}>
                      {c.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="mainIngredientId">
              <Select
              placeholder={t("activeSubstance")}
                onChange={(e) => handleParamChange(e, "mainIngredientId")}
              >
                {options.mainIngredients.map((c, cindex) => {
                  return (
                    <Option key={cindex} value={c.id}>
                      {c.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="productId">
              <Select placeholder={t("productName")} onChange={(e) => handleParamChange(e, "productId")}>
                {options.fertilizers
                  .filter(
                    (c) =>
                      c.fertilizerKindId === params.fertilizerKindId &&
                      c.mainIngredientId === params.mainIngredientId
                  )
                  .map((c, cindex) => {
                    return (
                      <Option key={cindex} value={c.id}>
                        {c.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Button className="w-100 h-100" onClick={getData} type="primary">
              {t("search")}
            </Button>
          </Col>
        </Row>
      </Form>
      </div>
      <Row>
        <Col className="mt-20" span={24}>
          <Table
            size="small"
            className="w-100"
            columns={columns}
            rowSelection={{
              type: "checkbox",
              onChange: (selectedRowKeys, selectedRows) => {
                setStock(selectedRows, selectedRowKeys);
              },
              selectedRowKeys,
              preserveSelectedRowKeys: true,
              hideSelectAll: true,
            }}
            dataSource={convertColumns(data, cols)}
            pagination={{ pageSize: 5, current_page: 1 }}
          />
        </Col>
        {/* <Col xs={24}>
            <Table
                size="small"
                className="w-100"
                columns={columns}
                rowSelection={rowSelection}
                dataSource={convertColumns(selectData, cols)}
                pagination={{ pageSize: 10, current_page: 1 }}
            />
          </Col> */}
      </Row>

      <Form form={form} layout="vertical" onFinish={saveExport}>
        <div className="mt-5">
          <Row gutter={[16, 8]}>
            <Col xs={24}>
              <Row gutter={[16, 16]}>
                {selectedRows.map((p, i) => (
                  <Col key={i} lg={12} md={24}>
                    <div className={'animated zoomIn'}>
                      <List
                        header={
                          <div className={'flex w-100 flex-between'}>
                            <p>{t('warehouseName') + ': '}</p>
                            <p>{p.warehouseName} </p>
                          </div>
                        }
                        footer={false}
                        bordered
                        className={'w-100'}
                        dataSource={data}
                      >
                        <List.Item>
                          <div className="flex w-100 flex-align-center flex-between">
                            <div className={'mr-15'}>
                              {t('quantity')} : {p.quantity}
                            </div>
                            <div className={'ml-15'}>
                              <div className="form-lang">
                                <Form.Item

                                  validateTrigger="onChange"
                                  name={[
                                    "PurchaseDemandList",
                                    i,
                                    "OneOrderQuantity",
                                  ]}
                                >
                                  <InputNumber
                                    min={0}
                                    defaultValue={p.quantity}
                                    onChange={(e) => { OnValueChange(e, p.id) }}
                                    max={p.quantity} className="w-100"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </List.Item>
                      </List>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Form.Item
                label={t("areaCategory")}
                validateTrigger="onChange"
                name="parcelCategoryId"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select
                  onChange={(e) => handleKeyChange(e, "parcelCategoryId")}
                >
                  {options.parcelCategories.map((pc, index) => {
                    return (
                      <Option key={index} value={pc.id}>
                        {pc.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Form.Item
                label={t("area")}
                validateTrigger="onChange"
                name="parcelId"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select onChange={(e) => handleKeyChange(e, "parcelId")}>
                  {options.parcels
                    .filter((p) => p.parcelCategoryId === vals.parcelCategoryId)
                    .map((pc, index) => {
                      return (
                        <Option key={index} value={pc.id}>
                          {pc.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={8} sm={12} xs={24}>
              <Form.Item
                label={t("areasSector")}
                validateTrigger="onChange"
                name="parcelSectorId"

                rules={[noWhitespace(t("inputError"))]}
              >
                <Select mode="multiple">

                  {options.parcelSectors
                    .filter((p) => p.parcelId === vals.parcelId)
                    .map((pc, index) => {
                      return (
                        <Option key={index} value={pc.id}>
                          {pc.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={8} sm={12} xs={24}>
              <Form.Item
                label={t("quantityOf")}
                validateTrigger="onChange"
                name="quantity"
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Form.Item
                label={t("acceptedPerson")}
                validateTrigger="onChange"
                name="handingPerson"
                rules={[whiteSpace(t("inputError"))]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Form.Item
                label={t("acceptedCarNumber")}
                validateTrigger="onChange"
                name="handingCarNumber"
                rules={[whiteSpace(t("inputError"))]}
              >
                <Input size="large"/>
              </Form.Item>
            </Col>
          </Row>
          <div
            className="modalButtons"
            style={{ position: "absolute", bottom: "20px", right: "25px" }}
          >
            <Button onClick={() => {
               cancel()
            }}>
              {t("cancel")}
            </Button>
            <Button type="primary" className="ml-10" htmlType="submit">
              {t("save")}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = ({ options, lang }) => {
  return { options, lang };
};

export default connect(mapStateToProps, { getOptions, notify })(ExportModal);
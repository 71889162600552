import "@ant-design/compatible/assets/index.css";
import React, { useEffect, useState } from "react";
import {Tabs, Button, Table, Select, Form, Tooltip, Modal} from "antd";
import {
  UnorderedListOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import IncomeModal from "./Modals/IncomeModal";
import PurchaseDocument from "./Modals/APurchase";

import agros from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify } from "./../../../../redux/actions";
import moment from "moment";
import { connect } from "react-redux";

const { TabPane } = Tabs;
const { Option } = Select;

const GarageWarehouse = (props) => {
  const { t } = useTranslation();
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "productName", value: t("productName"), con: true },
    { key: "quantity", value: t("productQuantity"), con: true },
    { key: "documentNumber", value: t("documentNumber"), con: true },
    { key: "measurementUnit", value: t("measurementUnit"), con: true },
    { key: "warehouse", value: t("warehouse"), con: true },
    { key: "acceptDate", value: t("acceptedDate"), con: true },
    { key: "operation", value: "", con: false },
  ];

  const [taskDemands, setTaskDemands] = useState([]);
  const [tasks, setTasks] = useState([]);
  let [trigger, setTrigger] = useState(1);
  const [reservesFetched, setReservedFetched] = useState(false);
  const [visibleAPurchase , setVisibleAPurchase] = useState(false);
  const [doc , setDoc] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [type, setType] = useState(0);
  const [isIncome, setIsIncome] = useState(undefined);

  const [form] = Form.useForm();

  useEffect(() => {
    const getTaskDemands = () => {
      agros.get("TechnicalStock/TaskDemands").then((res) => {
        setTaskDemands(
          res.data.map((d, index) => {
            return {
              ...d,
              key: index + 1,
              tableIndex: index + 1,
              docs: {
                ...d
              }
            };
          })
        );
      });
    };
    const getDocuments = () => {
      agros.get(`TechnicalStock`).then((res) => {
        setDocuments(res.data);
      });
    };
    const getTasks = () => {
      agros.get(`TechnicalDemand`).then((res) => {
        const t = res.data.map((r, index) => {
          return {
            ...r,
            key: index + 1,
            docs: {
              ...r
            }
          };
        });
        setTasks(t);
      });
    };

      getTaskDemands();
      getDocuments();
      getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t , trigger]);

  const columns = [
    {
      title: 'Texnika',
      key: 1,
      dataIndex: "technique",
      render(i) {
        return <>{i.name}</>;
      }
    },
    {
      title: 'Ehtiyat hissəsi',
      key: 1,
      dataIndex: "sparePart",
      render(i) {
        return <>{i.name}</>;
      }
    },
    {
      title: t("quantityOf"),
      key: 3,
      dataIndex: "count",
    },
    {
      key: 3,
      dataIndex: "docs",
      render(i){
        return (
            <div><Button onClick={()=>{ openAddModal(i) }}>Anbar tələbi yarat</Button></div>
        )
      }
    },
  ];

  const columnsIncomeExpense = [
    {
      title: 'Ehtiyyat hissəsi',
      key: 1,
      dataIndex: "techniqueSpareParts",
    },
    {
      title: t("quantityOf"),
      key: 3,
      dataIndex: "count",
    },
    {
      title: 'Vin',
      key: 3,
      dataIndex: "vin",
    },
  ];

  const [visibleIncomeModal, setVisibleIncomeModal] = useState(false);

  function selectHandleChange(value) {
    setType(+value);
  }

  const openAddModal = (income) => {
    setIsIncome(income);
    setVisibleIncomeModal(true);
  };

  const openPurchaseModal = (doc) => {
    setVisibleAPurchase(true)
    setDoc(doc)
  };



  const triggerFetch = () => {
    setTrigger(++trigger)
    setReservedFetched(false);
    setVisibleAPurchase(false)
  };

  const columnsTasks = [
    {
      title: 'Ehtiyyat hissəsi',
      key: 1,
      dataIndex: "techniqueSpareParts",
    },
    {
      title: t("quantityOf"),
      key: 3,
      dataIndex: "count",
    },
    {
      title: 'Vin',
      key: 3,
      dataIndex: "vin",
    },
    {
      title: 'Statusu',
      key: 3,
      dataIndex: "haveOrdered",
      render(i){
        return (
            <div>{i ?  'Satınalma sənədi var' : 'Satınalma sənədi yaradilmayib'}</div>
        )
      }
    },
    {
      key: 3,
      dataIndex: "docs",
      render(i){
        return (
            <div>
              {!i.haveOrdered  ?
                <Button onClick={()=>{openPurchaseModal(i)}}>
                  Satınalma sənədi yarat
                </Button> : ''
              }
            </div>
        )
      }
    },
  ];

  return (
    <div>
      <div className="border page-heading flex p-2 mt-0 bg-white">
        <div className="page-name">
          <UnorderedListOutlined className="f-20 mr5-15" />
          <span className="f-20 bold">Qaraj anbar | satınalma</span>
        </div>
      </div>
      <div className="position-relative mt-15">
        <div className="position-absolute w-100 tab-section">
          <Tabs animated={true} defaultActiveKey="1" className="w-100">
              <TabPane tab={'Ehtiyyat hissə tələbləri'} key="1">
                <Table
                  size="small"
                  className="bg-white   w-100"
                  columns={columns}
                  dataSource={convertColumns(taskDemands, cols)}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
              </TabPane>
              <TabPane tab={'Qaraj anbarı'} key="2">
                <Table
                  size="small"
                  className="bg-white   w-100"
                  columns={columnsIncomeExpense}
                  dataSource={convertColumns(documents, cols)}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
              </TabPane>
            )}
            <TabPane tab={'Qarajın anbarı tələbi və satınalma'} key="3">
              <Table
                size="small"
                className="bg-white   w-100"
                columns={columnsTasks}
                dataSource={convertColumns(tasks, cols)}
                pagination={{
                  pageSizeOptions: ["10", "15", "20", "25", "30"],
                  showSizeChanger: true,
                  current_page: 1,
                }}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>

      <IncomeModal
        data={isIncome}
        triggerFetch={triggerFetch}
        visibleIncomeModal={visibleIncomeModal}
        setVisibleIncomeModal={setVisibleIncomeModal}
      />

      <Modal
          title={t("createNewPurchaseDocument")}
          centered
          className="mediumModal padModal"
          onOk={() => setVisibleAPurchase(false)}
          onCancel={() => setVisibleAPurchase(false)}
          visible={visibleAPurchase}
          footer={null}
      >
        <PurchaseDocument
            purchase={doc}
            visibleAPurchase={visibleAPurchase}
            setVisibleAPurchase={setVisibleAPurchase}
            triggerFetch={triggerFetch}
        />
      </Modal>

    </div>
  );
};


export default connect(null, { notify })(GarageWarehouse);

import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Table,
    Button,
    Tooltip
} from "antd";
import {
    UnorderedListOutlined,
    ArrowRightOutlined
} from "@ant-design/icons";
import {convertColumns} from "../../../../utils/columnconverter";
// notification
import {notify} from "../../../../redux/actions";
import { connect } from "react-redux";
import agros from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function ProductsDisease() {
    const { t } = useTranslation();
    const [positions, setPositions] = useState([]);
    const history = useHistory();
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "crop", value: t("product"), con: true },
        { key: "crop_Category", value: t("productCategory"), con: true },
        { key: "cropSort", value: t("productType"), con: true },
        { key: "name", value: t("sector"), con: true },
        { key: "id", value: "", con: false },
    ];


    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: t("product"),
            key: "2",
            dataIndex: "crop",
        },
        {
            title: t("productCategory"),
            key: "3",
            dataIndex: "crop_Category",
        },
        {
            title: t("productType"),
            key: "4",
            dataIndex: "cropSort",
        },
        {
            title: t("sector"),
            key: "6",
            dataIndex: "name",
        },
        {
            title: "",
            key: "8",
            dataIndex: "crop",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                            <Tooltip className="ml-5" title={t("Xəstəlik riski")} placement="topRight">
                                <Button
                                    className="border-none"
                                    type="text"
                                    shape="circle"
                                    onClick={() => history.push(`/integratons/risk/disease?name=${i}`)}
                                >
                                    <ArrowRightOutlined />
                                </Button>
                            </Tooltip>
                    </div>
                );
            },
        },
    ];

    const getPositions = async () => {
        await agros.get("PlantMet/ParcelCrop").then((res) => {
            setPositions(
                res.data.map((r, index) => {
                    return {
                        ...r,
                        index: index + 1,
                    };
                })
            );
        });
    };

    useEffect(() => {
        getPositions();
    }, [t]);


    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">{t("products")}</span>
                </div>
            </Col>
            <Col  xs={24}>
                <Table
                    size="small"
                    className="bg-white"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 20,
                        current_page: 1,
                        total:positions.length,
                    }}
                />
            </Col>
        </Row>
        
    );
}

export default connect(null, { notify })(ProductsDisease);

import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select} from "antd";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getOptions, notify} from "../../../../../redux/actions";
import agros from "../../../../../const/api";
import moment from "moment";
const { Option } = Select;

function AddModal(props) {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const {getTech, getOptions, notify , setVisibleAddModal , id , editing , editingData} = props;
    const options = props.options[props.lang];

    useEffect(() => {
        getOptions(
            ["countries"],
            props.options,
            i18n.language
        );
        if (editing) {
            setTimeout(() => {
                form.setFieldsValue(
                    {
                        ...editingData,
                        dateOfPurchase:moment(editingData.dateOfPurchase),
                        dateOfCommissioning:moment(editingData.dateOfCommissioning),
                        dateOfProduction:moment(editingData.dateOfProduction)
                    }
                );
            },0)
        }
        else{
            form.resetFields();
        }
    },[t, editing])

    const submitForm = (values) =>{
        if(!editing){
            agros.post(
                "Technique", {
                    ...values,
                    DateOfPurchase:moment(values.DateOfPurchase).format("YYYY-MM-DD"),
                    DateOfCommissioning:moment(values.DateOfCommissioning).format("YYYY-MM-DD"),
                    DateOfProduction:moment(values.DateOfProduction).format("YYYY-MM-DD"),
                    TechniqueCategoryId:id
                }
            ).then(()=>{
                setVisibleAddModal(false);
                form.resetFields();
                notify("", true);
                getTech()
            }).catch((err) => {
                notify(err.response, false);
            } )
        }
        else{
            agros.put(
                `Technique/${editing}`, {
                    ...values,
                    DateOfPurchase:moment(values.dateOfPurchase).format("YYYY-MM-DD"),
                    DateOfCommissioning:moment(values.dateOfCommissioning).format("YYYY-MM-DD"),
                    DateOfProduction:moment(values.dateOfProduction).format("YYYY-MM-DD"),
                    TechniqueCategoryId:id,
                    id:editing
                }
            ).then(()=>{
                setVisibleAddModal(false);
                form.resetFields();
                notify("", true);
                getTech()
            }).catch((err) => {
                notify(err.response, false);
            } )
        }
    }

    return (
        <div className='w-100'>
            <Form onFinish={submitForm} layout="vertical" form={form}>
                <div className="commontask">
                    <Row gutter={[16, 16]}>
                        <Col lg={6} md={12}>
                            <Form.Item
                                label={'Markası'}
                                name="mark"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Input size={'large'}/>
                            </Form.Item>
                        </Col>
                        <Col lg={6} md={12}>
                            <Form.Item
                                label={'Modeli'}
                                name="model"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Input size={'large'}/>
                            </Form.Item>
                        </Col>
                        <Col lg={6} md={12}>
                            <Form.Item
                                label={'Dövlət nişanı'}
                                name="stateRegistrationPlate"
                                validateTrigger="onChange"
                                rules={[whiteSpace(t("inputError"))]}
                            >
                                <Input size={'large'}/>
                            </Form.Item>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="form-lang">
                                <Form.Item
                                    label={'Moto saat'}
                                    name="engineSent"
                                    validateTrigger="onChange"
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <InputNumber/>
                                </Form.Item>
                                <div className="input-lang btm">km/saat</div>
                            </div>

                        </Col>
                        <Col md={6} sm={12}>
                            <Form.Item
                                label={t("originCountry")}
                                name={"producingCountry"}
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Select
                                    showSearch
                                    notFoundContent={null}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {options.countries.map((c, index) => {
                                        return (
                                            <Option key={index} value={c.name}>
                                                {c.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={12}>
                            <Form.Item
                                label={'İstehsal tarixi'}
                                name="dateOfProduction"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("dateError"))]}
                            >
                                <DatePicker placeholder={t("selectDate")} className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={12}>
                            <Form.Item
                                label={'İstehsal edən şirkət'}
                                name="producingCompany"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Input size={'large'}/>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={12}>
                            <Form.Item
                                label={t('dateToBuy')}
                                name="dateOfPurchase"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("dateError"))]}
                            >
                                <DatePicker placeholder={t("selectDate")} className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={12}>
                            <Form.Item
                                label={'İstifadə məqsədi'}
                                name="purposeOfUse"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Input size={'large'}/>
                            </Form.Item>
                        </Col>

                        <Col md={6} sm={12}>
                            <Form.Item
                                label={'Vin kod'}
                                name="vin"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Input size={'large'}/>
                            </Form.Item>
                        </Col>

                        <Col md={6} sm={12}>
                            <Form.Item
                                label={'İstifadəyə verilmə tarixi'}
                                name="dateOfCommissioning"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("dateError"))]}
                            >
                                <DatePicker placeholder={t("selectDate")} className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="form-lang">
                                <Form.Item
                                    label={'Çəkmə gücü'}
                                    name="tractionPower"
                                    validateTrigger="onChange"
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <InputNumber/>
                                </Form.Item>
                                <div className="input-lang btm">a.g</div>
                            </div>
                        </Col>
                        <Col md={6} sm={12}>
                            <Form.Item
                                label={'İstifadə etdiyi yanacaq növü '}
                                name={"fuelType"}
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Select>
                                    <Option key={1} value={'Benzin'}>
                                        Benzin
                                    </Option>
                                    <Option key={2} value={'Dizel'}>
                                        Dizel
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>


                    </Row>



                    <div
                        className="modalButtons"
                        style={{
                            position: "absolute",
                            bottom: "20px",
                            right: "40px",
                        }}
                    >
                        <Button onClick={() =>{props.setVisibleAddModal(false) ; form.resetFields()}}>
                            {t("cancel")}
                        </Button>
                        <Button type="primary" className="ml-10" htmlType="submit">
                            {t("save")}
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
}


const mapStateToProps = ({ options, lang }) => {
    return { options, lang };
};

export default connect(mapStateToProps, { getOptions, notify })(AddModal);

import React, { useState } from 'react';
import {
    Input,
    Row,
    DatePicker,
    Col,
    Form,
    Select
} from "antd";
import { noWhitespace, whiteSpace } from "../../../../utils/rules";
import { useTranslation } from "react-i18next";
import Images from "../../../../assets/img/Images/Images";
import agros from '../../../../const/api';
import { connect } from "react-redux";
import { notify } from '../../../../redux/actions';
const { Option } = Select;
function RegisterForm(props) {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [message, setMessage] = useState('')
    const [error, setError] = useState(false)
    const { notify } = props;
    const saveItem = (values) => {
        if (values.Password !== values.PasswordTwo) {
            setMessage('Şifrələr eyni deyil')
        } else {
            setMessage('')
            const obj = {
                ...values,
                DateofBirth: values.DateofBirth.format("YYYY-MM-DD"),
                Phone: '+994' + values.Phone,
                PhoneOfCompany: '+994' + values.PhoneOfCompany,
                Gender: Number(values.Gender),
                ...props.modul
            }
            if (props.modul) {
                agros.post('Registration', obj).then((res) => {
                    form.resetFields();
                    setError(false)
                    notify("Qeydiyyatdan keçildi", true);
                    props.setModul(null)

                }).catch((err) => {
                    console.log(err)
                    setError(true)
                    notify("Bu username artıq istifadə olunub", false);
                }
                )
            }
            else {
                notify("Paket doldurulmayıb", false);

            }
        }

    }
    return (
        <div className='animated fadeIn registerForm'>
            <div className="content pt-1">

                <Form
                    form={form}
                    onFinish={saveItem}
                    className="form"
                    layout="vertical"
                >
                    <div>
                        <Row gutter={[16, 8]}>
                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    validateTrigger="onChange"
                                    name="FullNameOfUser"
                                    rules={[whiteSpace(t("inputError"))]}
                                >
                                    <Input placeholder={'Ad, Soyad, Ata adı'} />
                                </Form.Item>
                            </Col>
                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    validateTrigger="onChange"
                                    name="Username"
                                    rules={[whiteSpace(t("inputError"))]}
                                >
                                    <Input className={error && 'errorInput'} placeholder={t('username')} />

                                </Form.Item>
                            </Col>
                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    validateTrigger="onChange"
                                    name="PINofUser"
                                    rules={
                                        [
                                            whiteSpace(t("inputError")),
                                            {
                                                max: 7,
                                                message: '7 simvol olmalıdır',
                                            },
                                            {
                                                min: 7,
                                                message: '7 simvol olmalıdır',
                                            },
                                            {
                                                pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/,
                                                message: 'Rəqəm və hərf olmalıdır',
                                            }
                                        ]

                                    }
                                >
                                    <Input placeholder={t('fin')} />
                                </Form.Item>
                            </Col>

                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    // label={t("agreementDocumentDate")}
                                    name="DateofBirth"
                                    validateTrigger="onChange"
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <DatePicker placeholder={t('birthdate')} className="w-100" />
                                </Form.Item>
                            </Col>

                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    validateTrigger="onChange"
                                    name="Email"
                                    rules={
                                        [
                                            whiteSpace(t("inputError")),
                                            {
                                                type: 'email',
                                                message: 'Doğru email daxil edin',
                                            }
                                        ]

                                    }
                                >
                                    <Input placeholder={'Şəxsin emaili'} type='email' />
                                </Form.Item>
                            </Col>

                            <Col lg={8} sm={12} xs={24}>
                                <div className='flex'>
                                    <Input
                                        className='num'
                                        disabled
                                        placeholder="+994"
                                    />
                                    <Form.Item
                                        validateTrigger="onChange"
                                        name="Phone"
                                        className='w-full'
                                        rules={
                                            [
                                                whiteSpace(t("inputError")),
                                                {
                                                    pattern: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
                                                    message: 'Doğru telefon daxil edin',
                                                }
                                            ]

                                        }
                                    >
                                        <Input placeholder={'Şəxsin əlaqə nömrəsi'} className='w-full' type='phone' />
                                    </Form.Item>
                                </div>
                            </Col>

                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    validateTrigger="onChange"
                                    name="Gender"
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <Select placeholder={t('sex')}>
                                        <Option value="1">{t('man')}</Option>
                                        <Option value="2">{t('woman')}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>


                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    validateTrigger="onChange"
                                    name="Password"
                                    rules={[
                                        whiteSpace(t("inputError")),
                                        {
                                            min: 7,
                                            message: 'Şifrənin uzunluğu minimum 7 olmalıdır'
                                        }]}
                                    className='passForm'
                                >
                                    <Input.Password placeholder={t('password')} />

                                </Form.Item>
                                <span className='error'>{message}</span>
                            </Col>
                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    validateTrigger="onChange"

                                    name="PasswordTwo"
                                    rules={[
                                        whiteSpace(t("inputError")),
                                        {
                                            min: 7,
                                            message: 'Şifrənin uzunluğu minimum 7 olmalıdır'
                                        }
                                    ]}
                                    className='passForm'

                                >
                                    <Input.Password placeholder={'Təkrar şifrə'} />
                                </Form.Item>
                                <span className='error'>{message}</span>
                            </Col>
                            <Col span={24}>
                                <div className="companyInfo flex all-center">
                                    <img src={Images.listshadow} alt="" />
                                    <p>{t('companyInfo')}</p>
                                </div>
                            </Col>
                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    validateTrigger="onChange"
                                    name="NameOfCompany"
                                    rules={[whiteSpace(t("inputError"))]}
                                >
                                    <Input placeholder={t('companyName')} />

                                </Form.Item>
                            </Col>
                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    validateTrigger="onChange"
                                    name="EmailOfCompany"
                                    rules={
                                        [
                                            whiteSpace(t("inputError")),
                                            {
                                                type: 'email',
                                                message: 'Doğru email daxil edin',
                                            }
                                        ]

                                    }
                                >
                                    <Input placeholder={t('companyEmail')} />
                                </Form.Item>
                            </Col>
                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    validateTrigger="onChange"
                                    name="TIN"
                                    rules={
                                        [
                                            whiteSpace(t("inputError")),
                                            {
                                                max: 10,
                                                message: '10 simvol olmalıdır',
                                            },
                                            {
                                                min: 10,
                                                message: '10 simvol olmalıdır',
                                            },
                                            {
                                                pattern: /^-?\d+(\.\d+)?$/,
                                                message: 'Yalnız rəqəm olmalıdır',
                                            }
                                        ]

                                    }
                                >
                                    <Input placeholder='VÖEN' />
                                </Form.Item>
                            </Col>
                            <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                    validateTrigger="onChange"
                                    name="Adress"
                                    rules={[whiteSpace(t("inputError"))]}
                                >
                                    <Input placeholder={t('adress')} />
                                </Form.Item>
                            </Col>
                            <Col lg={8} sm={12} xs={24}>
                                <div className='flex'>
                                    <Input
                                        className='num'
                                        disabled
                                        placeholder="+994"
                                    />
                                    <Form.Item
                                        validateTrigger="onChange"
                                        name="PhoneOfCompany"
                                        className='w-full'
                                        rules={
                                            [
                                                whiteSpace(t("inputError")),
                                                {
                                                    pattern: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
                                                    message: 'Doğru telefon daxil edin',
                                                }
                                            ]

                                        }
                                    >
                                        <Input placeholder={'Şirkətin əlaqə nömrəsi'} className='w-full' type='phone' />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="list">
                                    <img src={Images.list31} alt="" />
                                    <img src={Images.list32} alt="" />
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="flex mt-20 pt-15 all-center">
                                    <button
                                        // onClick={()=>{props.setCurrent(2)}}
                                        className="btm-warning  shadow">
                                        {t('continue')}
                                    </button>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Form>

            </div>
        </div>
    );
}


export default connect(null, { notify })(RegisterForm);
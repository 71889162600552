import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBroom, faClose, faDrawPolygon } from '@fortawesome/free-solid-svg-icons'
import { faCircle, faSquare } from '@fortawesome/free-regular-svg-icons'
import { Tooltip } from "antd/lib";

export default function DrawingPanel({sketcBar,drawedArea,handleSketchOp,saveGraphic,handleCloseSketchBar}) {
    return (
        <div className={`${sketcBar ? 'absolute' : 'hidden'} bottom-10  m-auto left-0 right-0   bg-teal-700 opacity-90 w-96 h-14 rounded-xl overflow-hidden`}>
            <div className='flex w-full h-full items-center'>
                <Tooltip mouseEnterDelay={0.7} title="Polygon">
                    <div
                        onClick={() => handleSketchOp('polygon')}
                        className='flex h-full w-1/6 items-center justify-center hover:bg-teal-600 cursor-pointer '  >
                        <FontAwesomeIcon icon={faDrawPolygon} className='text-white text-2xl ' />
                    </div>
                </Tooltip>
                <Tooltip mouseEnterDelay={0.7} title="Dairə">
                    <div
                        onClick={() => handleSketchOp('circle')}
                        className='flex h-full w-1/6 items-center justify-center hover:bg-teal-600 cursor-pointer '  >
                        <FontAwesomeIcon icon={faCircle} className='text-white text-2xl' />
                    </div>
                </Tooltip>
                <Tooltip mouseEnterDelay={0.7} title="Kvadrat">
                    <div
                        onClick={() => handleSketchOp('rectangle')}
                        className='flex h-full w-1/6 items-center justify-center hover:bg-teal-600 cursor-pointer '  >
                        <FontAwesomeIcon icon={faSquare} className='text-white text-2xl' />
                    </div>
                </Tooltip>
                <div
                    onClick={saveGraphic}
                    className={` ${drawedArea ? 'hover:bg-teal-600' : 'bg-gray-300'} flex h-full w-2/6 items-center justify-center  cursor-pointer  border-teal-600 group p-2 border-x `}  >
                    <span className='text-white group-hover:text-white text-sm'>Qeyd et</span>
                </div>
                <Tooltip mouseEnterDelay={0.7} title="Təmizlə">
                    <div
                        onClick={() => handleSketchOp("clear")}
                        className='flex h-full w-1/6 items-center justify-center hover:bg-teal-600 cursor-pointer '  >
                        <FontAwesomeIcon icon={faBroom} className='text-white text-xl' />
                    </div>
                </Tooltip>
                <Tooltip mouseEnterDelay={0.7} title="Bağla">
                    <div
                        onClick={() => handleCloseSketchBar()}
                        className='flex h-full w-1/6 items-center justify-center hover:bg-teal-600 cursor-pointer '  >
                        <FontAwesomeIcon icon={faClose} className='text-red-500 text-2xl' />
                    </div>
                </Tooltip>
            </div>
        </div>
    )
}

let perms = {
  administrator: {
    perms: { read: true },
    subs: {
      positions: {
        perms: { read: true },
      },
      permissions: {
        perms: { read: true},
      },
      users: {
        perms: { read: true },
      },
      warehouseSettings: {
        perms: { read: true },
      },
      landParcels: {
        perms: {
          read: true,
        },
      },
      typesAndConditions: {
        perms: { read: true },
      },
      clientAndConsumers: {
        perms: { read: true },
      },
      todos: {
        perms: { read: true }
      },
    },
  },
  hr: {
    perms: { read: true },
    subs: {
      workers: {
        perms: {
          read: true,
          create: true,
          update: true,
          delete: true,
          //new
          readSalaryHistory: true,
          createSalaryHistory: true,
          /////
        },
      },
    },
  },
  warehouse: {
    perms: { read: true },
    subs: {
      demands: {
        // must add database
        perms: { allReadDemands: true },
      },
      purchases: {
        perms: { read: true },
        subs: {
          waiting: {
            perms: { update: true },
          },
          approved: {
            perms: { createPurchaseDocument: true },
          },
        },
      },
      purchasesOnWait: {
        perms: { read: true, addToReserves: true },
      },
      drugAndFertilizers: {
        perms: { read: true, import: true, export: true },
        subs: {
          reserves: { perms: { read: true, excelExport: true,update:true,transfer:true } },
          importAndExport: { perms: { read: true, excelExport: true } },
          tasks: { perms: { read: true, export:true } },
        },
      },
      productsWarehouse: {
        perms: { read: true, import: true, export: true, sale: true, transfer: true },
        subs: {
          products: {
            perms: { read: true, excelExport: true, update: true },
          },
          importExportAndDocs: {
            perms: { read: true, excelExport: true },
          },
          tasks:{
            perms: { read: true, export:true }
          }
        },
      },
      reservesWarehouse: {
        perms: { read: true, import: true, export: true },
        subs: {
          reserves: { perms: { read: true,update:true,transfer:true } },
          importExportAndDocs: { perms: { read: true } },
          tasks: {perms: {read:true,export:true}}
        },
      },
    },
  },
  workplan: {
    perms: { read: true },
    subs: {
      daily: {
        perms: { read: true, create: true, addTask: true },
        subs: {
          activeWorkPlans: {
            perms: { read: true },
          },
          doneWorkPlans: {
            perms: { read: true },
          },
        },
      },
      annual: {
        perms: { read: true, create: true, update: true,delete:true },
      },
      statistics: {
        perms: { read: true },
        subs: {
          drugAndFertilizers: {
            perms: { read: true },
          },
          products: {
            perms: { read: true },
          },
          reserves: {
            perms: { read: true },
          },
          workers: {
            perms: { read: true },
          },
          todos: {
            perms: { read: true },
          },
          technique: {
            perms: { read: true },
          },
        },
      },

    },
  },
  map: {
    perms: { operations: true },
  },
};

export const mapPermissions = (perms, backend) => {
  let newPerms = {};
  Object.keys(perms).forEach((key) => {
    newPerms[key] = {};
    if (backend && backend[key]) {
      newPerms[key]["perms"] = {};
      Object.keys(perms[key]["perms"]).forEach((key2) => {
        newPerms[key]["perms"][key2] =
          backend[key]["perms"][key2] === undefined
            ? perms[key]["perms"][key2]
            : backend[key]["perms"][key2];
      });
      if (perms[key]["subs"]) {
        newPerms[key]["subs"] = mapPermissions(
          perms[key]["subs"],
          backend[key]["subs"]
        );
      }
    } else {
      newPerms[key] = perms[key];
    }
  });
  return newPerms;
};

export default perms;

import React, {useEffect} from 'react';
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Table, Tooltip} from "antd";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import {useTranslation} from "react-i18next";
import {convertColumns} from "../../../../../utils/columnconverter";

const {Option} = Select;

function Export(props) {

    const cols = [
        {key: "tableIndex", value: "#", con: true},
        {key: "brand", value: "Markası", con: true},
        {key: "expirationDate", value: "Son istifadə tarixi", con: true},
        {key: "quantity", value: "Miqdar", con: true},
    ];
    const commonColumns = [
        {
            title: "#",
            key: "index",
            dataIndex: "tableIndex",
        },
        {
            title: 'Markası',
            key: "brand",
            dataIndex: "brand",
        },
        {
            title: 'Son istifadə tarixi',
            key: "expirationDate",
            dataIndex: "expirationDate",
        },
        {
            title: 'Miqdar',
            key: "quantity",
            dataIndex: "quantity",
        },
    ];

    const fakedata = []

    const {t} = useTranslation();
    const [form] = Form.useForm();
    const submitForm = () => {
        console.log('super')
    }
    useEffect(() => {
          form.resetFields()
    }
    , [props.trigger]);
    return (
        <div className='w-100'>
            <Form onFinish={submitForm} layout="vertical" form={form}>
                <div className="commontask">
                    <Row gutter={[16, 16]}>
                        <Col span={24} className='h-100'>
                            <Form layout="vertical" form={form}>
                                <div className="filter p-1 border mt-15 mb-15 bg-white">
                                    <Row gutter={[16, 4]}>
                                        <Col lg={10} md={24}>
                                            <Form.Item
                                                label={'Yanacağın növü'}
                                                name="fuelType"
                                                validateTrigger="onChange"
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <Select
                                                    // onChange={handleSectorChange}
                                                >
                                                    <Option key={1}>növü</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col lg={10} md={24}>
                                            <Form.Item
                                                label={'Yanacağın markası'}
                                                name="fuelBrand"
                                                validateTrigger="onChange"
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <Select
                                                    // onChange={handleSectorChange}
                                                >
                                                    <Option key={1}>Marka</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col lg={4}>
                                            <Form.Item
                                                label={' '}
                                            >
                                                <Button type="primary" size={'large'} className="w-100  h-100"
                                                        htmlType="submit">
                                                    {t("search")}
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </Col>
                        <Col lg={24} md={24}>
                            <Table
                                size="small"
                                className="bg-white"
                                dataSource={convertColumns(fakedata, cols)}
                                columns={commonColumns}
                                pagination={{
                                    pageSize: 10,
                                    current_page: 1,
                                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                                }}
                            />
                        </Col>

                        <Col span={24} className='h-100'>
                            <Form layout="vertical" form={form}>
                                <div className="filter p-1 border mt-15 mb-15 bg-white">
                                    <Row gutter={[16, 4]}>
                                        <Col lg={12}>
                                            <Form.Item
                                                label={'Dövlət nömrə nişanı'}
                                                name="byPlateNumber"
                                                validateTrigger="onChange"
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <Input size={'large'}/>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Item
                                                label={'Verilmə tarixi'}
                                                name="date"
                                                validateTrigger="onChange"
                                                rules={[noWhitespace(t("dateError"))]}
                                            >
                                                <DatePicker placeholder={t("selectDate")} className="w-100"/>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8} md={24}>
                                            <div className="form-lang">
                                                <Form.Item
                                                    label={'Planda olan miqdar'}
                                                    name="quantity"
                                                    validateTrigger="onChange"
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <InputNumber/>
                                                </Form.Item>
                                                <div className="input-lang btm">l</div>
                                            </div>

                                        </Col>
                                        <Col lg={8} md={24}>
                                            <div className="form-lang">
                                                <Form.Item
                                                    label={'Faktiki miqdar'}
                                                    name="quantity2"
                                                    validateTrigger="onChange"
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <InputNumber/>
                                                </Form.Item>
                                                <div className="input-lang btm">l</div>
                                            </div>

                                        </Col>
                                        <Col lg={8} md={24}>
                                            <div className="form-lang">
                                                <Form.Item
                                                    label={'Qəbul edən şəxs'}
                                                    name="person"
                                                    validateTrigger="onChange"
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input size='large'/>
                                                </Form.Item>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </Col>



                    </Row>

                    <div
                        className="modalButtons"
                        style={{
                            position: "absolute",
                            bottom: "20px",
                            right: "40px",
                        }}
                    >
                        <Button onClick={() => {
                            props.setExportModal(false)
                            form.resetFields()
                        }}>
                            {t("cancel")}
                        </Button>
                        <Button type="primary" className="ml-10" htmlType="submit">
                            {t("save")}
                        </Button>
                    </div>
                </div>

            </Form>
        </div>
    );
}

export default Export;

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Form,
  Select,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../../utils/columnconverter";
import { getOptions, notify } from "../../../../../redux/actions";
import { connect } from "react-redux";
import agros from "../../../../../const/api";
import { noWhitespace, whiteSpace } from "../../../../../utils/rules";
const { Option } = Select;

const SalesModal = (props) => {
  const { t, i18n } = useTranslation();
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "productCategory", value: t("productCategory"), con: true },
    { key: "product", value: t("product"), con: true },
    { key: "productSorts", value: t("productType"), con: true },
    { key: "reproductionName", value: t("reproduction"), con: true },
    { key: "quantity", value: t("quantity"), con: true },
    { key: "price", value: t("price"), con: true },
    { key: "warehouse", value: t("warehouse"), con: true },
  ];
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    type:  'checkbox' ,
    onChange: (selectedRowKeys, selectedRows) => {
      setStock(selectedRows, selectedRowKeys);
    },
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    hideSelectAll: true,
  }
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { notify } = props;
  const [data, setData] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [params, setParams] = useState({
    cropCategoryId: undefined,
    cropId: undefined,
    cropSortId: undefined,
  });

  const [stockId, setStockId] = useState(null);
  const mapData = (data) => {
    let formData = [];
    let prods = [];
    console.log(data.editedQuantity);
    setQuantity(data.editedQuantity)
    let total  = 0;
    for (const d of data) {
      total = total + parseInt(d.editedQuantity)
      console.log(total);
        let obj = {
          quantity: total
        };

        prods.push({
          quantity: total,
        });
       
        formData.push(obj);
        console.log(formData);
        form.setFieldsValue({ quantity: formData[1]?.quantity });

        //setProducts(formData);
      
    }
  };
  const OnValueChange = (value, id) => {
    const arr = [...selectedRows]
    console.log(arr);
    let selectedData = arr.find((d) => d.id === id);
    console.log(selectedData);
    selectedData['editedQuantity'] = value
    mapData(arr)
    console.log(arr);
  }
  const handleParamChange = (e, key) => {
    if (key === "cropCategoryId") {
      form2.resetFields(["cropId"]);
      form2.resetFields(["cropSortId"]);
    }
    if (key === "cropId") {
      form2.resetFields(["cropSortId"]);
    }
    let par = { ...params };
    par[key] = e;
    setParams(par);
  };

  const { getOptions } = props;
  const options = props.options[props.lang];
console.log(options);
  // table data columns
  const columns = [
    {
      title: "#",
      key: 1,
      dataIndex: "key",
      width: window.innerWidth > 991 ? 70 : '100%',
    },
    {
      title: t("productCategory"),
      key: 2,
      dataIndex: "cropCategory",
    },
    {
      title: t("product"),
      key: 3,
      dataIndex: "crop",
    },
    {
      title: t("productType"),
      key: 4,
      dataIndex: "cropSortName",
    },
    {
      title: t("reproduction"),
      key: 6,
      dataIndex: "reproduction",
    },
    {
      title: t("quantity"),
      key: 7,
      dataIndex: "quantity",
    },
    {
      title: t("price"),
      key: 8,
      dataIndex: "price",
    },
    {
      title: t("warehouse"),
      key: 9,
      dataIndex: "warehouseName",
    },
  ];

  useEffect(() => {
    getOptions(
      [
        "cropCategories",
        "cropSorts",
        "crops",
        "parcelCategories",
        "parcels",
        "customers",
        "parcelSectors",
      ],
      props.options,
      i18n.language
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
useEffect(() => {

    form.resetFields();
    form2.resetFields();
    setStockId(null);
    setData([]);
    params.cropCategoryId = undefined;
    params.cropId = undefined;
    params.cropSortId = undefined;
    setSelectedRowKeys([])
    setSelectedRows([])
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.trigger]);
  const getData = () => {
    agros.get(`cropstock/export`, { params }).then((res) => {
      setData(
        res.data.map((r, index) => {
          return { ...r, key: index + 1, editedQuantity: r.quantity,   quantityNumber: r.quantity, index: index };
        })
      );
    });
  };

  const setStock = (selectedRows, selectedRowKeys) => {
    console.log('salam');
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
    mapData(selectedRows)
  };

  const saveExport = (values) => {
    if (selectedRows.length > 0) {
      agros
        .post("cropstock/sales", { ...values, StockInfo: selectedRows.map((r) => ({
          StockId: r.id,
          Quantity: r.editedQuantity
        })) })
        .then((res) => {
          notify("", true);
          form.resetFields();
          form2.resetFields();
          props.triggerFetch();
          setStockId(null);
          setData([]);
          props.setVisibleSales(false);
        })
        .catch((err) => {
          notify("", false);
        });
    } else {
      notify("Məhsulu seçin", false);
    }
  };

  return (
    <>
      <Form form={form2} onFinish={getData}>
        <Row gutter={[10, 10]}>
          <Col md={6} sm={12} xs={24}>
            <Form.Item
              name="cropCategoryId"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Select placeholder={t('productCategory')} onChange={(e) => handleParamChange(e, "cropCategoryId")}>
                {options.cropCategories.map((c, cindex) => {
                  return (
                    <Option key={cindex} value={c.id}>
                      {c.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <Form.Item  name="cropId" rules={[noWhitespace(t("inputError"))]}>
              <Select placeholder={t('product')} onChange={(e) => handleParamChange(e, "cropId")}>
                {options.crops
                  .filter((c) => c.categoryId === params.cropCategoryId)
                  .map((c, cindex) => {
                    return (
                      <Option key={cindex} value={c.id}>
                        {c.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <Form.Item name="cropSortId">
              <Select
                placeholder={t('productType')}
                onChange={(e) => handleParamChange(e, "cropSortId")}
                allowClear
              >
                {options.cropSorts
                  .filter((c) => c.categoryId === params.cropId)
                  .map((c, cindex) => {
                    return (
                      <Option key={cindex} value={c.id}>
                        {c.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Button
              className="w-100"
              size="large"
              type="primary"
              htmlType="submit"
            >
              {t("search")}
            </Button>
          </Col>
        </Row>
      </Form>
      <Form form={form} layout="vertical" onFinish={saveExport}>
        <Row gutter={[16, 0]}>
          <Col className="mt-20" span={24}>
            <Table
              size="small"
              rowSelection={rowSelection}
              className="bg-white  mb-10 w-100"
              columns={columns}
              dataSource={convertColumns(data, cols)}
              pagination={{ pageSize: 5, current_page: 1 }}
            />
          </Col>
          <Col span={24}>
                <Row gutter={[16, 16]}>
                  {selectedRows.map((p, i) => (
                    <Col key={i} lg={4} md={12}>
                      <div className={'animated zoomIn p-2 border'}>
                        <Row className={'flex-a'} gutter={[16, 8]}>
                          <Col lg={24} sm={12} xs={24}>
                            <Form.Item
                              className={'d-none'}
                              initialValue={p.stockId}
                              validateTrigger="onChange"
                              name={[
                                "StockInfo",
                                i,
                                "stockId",
                              ]}
                            >
                              <Input type={'hidden'} className="w-100" size="large" />
                            </Form.Item>
                            <div className="form-lang">
                              <p className="flex mb-5 flex-between">
                                <p> {t("quantityOf")}</p>
                                <p>{p.warehouseName}</p>
                              </p>
                              <Form.Item
                                initialValue={p.quantity}
                                validateTrigger="onChange"
                                name={[
                                  "StockInfo",
                                  i,
                                  "Quantity",
                                ]}
                                rules={[noWhitespace(t("inputError"))]}
                              >
                                <InputNumber
                                  onChange={(e) => { OnValueChange(e, p.id) }}
                                  max={p.quantity} className="w-100" />
                              </Form.Item>
                              <span className="input-lang btm">
                                {p.measurementUnit}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
          <Col lg={8} sm={12} xs={24}>
            <Form.Item
              label={t("client")}
              validateTrigger="onChange"
              name="customerId"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Select>
                {options.customers.map((pc, pindex) => (
                  pc.positionStatus === 2 &&
                    <Option key={pindex} value={pc.id}>
                      {pc.name}
                    </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} sm={12} xs={24}>
            <Form.Item
              label={t("quantityOf")}
              validateTrigger="onChange"
              name="quantity"
              rules={[noWhitespace(t("inputError"))]}
            >
              <InputNumber disabled/>
            </Form.Item>
          </Col>
          <Col lg={8} sm={12} xs={24}>
            <Form.Item
              label={t("price")}
              validateTrigger="onChange"
              name="price"
              rules={[noWhitespace(t("inputError"))]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col lg={8} sm={12} xs={24}>
            <Form.Item
              label={t("acceptedPerson")}
              validateTrigger="onChange"
              name="HandingPerson"
              rules={[whiteSpace(t("inputError"))]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col lg={8} sm={12} xs={24}>
            <Form.Item
              label={t("acceptedCarNumber")}
              validateTrigger="onChange"
              name="HandingCarNumber"
              rules={[whiteSpace(t("inputError"))]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <div
          className="modalButtons"
          style={{ position: "absolute", bottom: "20px", right: "25px" }}
        >
          <Button onClick={() => props.setVisibleSales(false)}>
            {t("cancel")}
          </Button>
          <Button type="primary" className="ml-10" htmlType="submit">
            {t("save")}
          </Button>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = ({ options, lang }) => {
  return { options, lang };
};

export default connect(mapStateToProps, { getOptions, notify })(SalesModal);
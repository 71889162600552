import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
function ImportExportModal(props) {
  const { t } = useTranslation();
  const { inout } = props;
  console.log(inout)
  // acceptDate: "2020-11-19T19:16:00.5690755"
  const newKeys = {
    // type: "type",
    fertilizerKind:"Dərman/Gübrə",
    mainIngredient: "activeSubstance",
    productName: "productName",
    quantity: "quantity",
    price:'price',
    warehouse: "warehouse",
    // parcelSectorName: "areasSector",
    accepter: "acceptedPerson",
    respondentName:'respondentPeople',//null
    handingPerson: "sendedPerson",//nulll
    handingCarNumber: "acceptedCarNumber"//null
  }
  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} xs={24}>
        <table className="customtable">
          <tbody>
          {inout.operation &&
          <tr>
            <td>{t("operation")}</td>
            <td>{inout.operation}</td>
          </tr>
          }
          {
            Object.keys(newKeys).map(key => {
              return (
                  <>
                    {
                      inout[key] !== null &&
                      <tr>
                        <td>{t(newKeys[key])}</td>
                        <td>{inout[key]}</td>
                      </tr>
                    }
                  </>
              )
            })
          }


          {inout.acceptDate &&
          <tr>
            <td>{t("acceptedDate")}</td>
            <td>{  moment(inout.acceptDate).format("DD-MM-YYYY")}</td>
          </tr>
          }
          </tbody>
        </table>
      </Col>
    </Row>
  );
}

export default ImportExportModal;
import React, { useEffect, useState } from 'react'
import agros from '../../../const/api'
import { AimOutlined, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Modal, Spin } from 'antd'
//import RiskModal from '../İntegrations/detailRisk'
import pivot1 from '../../../assets/img/demo_map_images/pivot1.jpg'
import pivot2 from '../../../assets/img/demo_map_images/pivot2.webp'
import noData from '../../../assets/img/demo_map_images/no-image.jpg'
import wineyard from '../../../assets/img/demo_map_images/wineyard.webp'
import appleyard from '../../../assets/img/demo_map_images/appleyard.jpeg'

export default function SectorDetailsPanel({
  selectedArea,
  visible,
  close,
  handleZoomArea,
  handleEditSector,
  editing,
  handleCloseSectorEditing,
  handleSubmitSectorEditing,
  handleDeleteSector,
  handleAddNDVi,
  getStatistics,
  stats
}) {
  const [parcel, setParcel] = useState()
  const [loading, setLoading] = useState(true)
  const [detailSick, setDetailSick] = useState(false)
  const [vegetationModal, setVegetationModal] = useState(false)
  const [image, setImage] = useState(noData)
  const [areaId, setAreaId] = useState()

  const getInit = async () => {
    setLoading(true)
    await agros.get("Parcel/SectorDetails/" + selectedArea?.area_id)
      .then(res => {
        console.log(res.data)
        setParcel(res.data)
        setLoading(false)
        switch (selectedArea?.area_id) {
          case 24: setImage(pivot1)
            break;
          case 25: setImage(pivot2)
            break
          case 36: setImage(wineyard)
            break
          case 37: setImage(appleyard)
            break
          default: setImage(noData)
        }
      })
      .catch(ex => {

      })
  }

  const handleOpenVegetation = async () => {
    setAreaId(selectedArea?.area_id)
    await getStatistics()
  }

  const handleCloseVegetation = () => {
    setVegetationModal(false)
  }

  useEffect(() => {
    if (visible) {
      getInit()
    }

  }, [selectedArea?.area_id])
  return (
    <div className={`${visible ? 'scale-100' : 'scale-0'} absolute flex flex-col justify-center w-[25rem]  bg-white rounded-lg top-5 right-20 shadow-lg overflow-hidden shadow-lg `}>
      <div className='flex top-0  w-full h-44 ' >
        <img src={image} alt='no_image' />
      </div>
      <div className='flex flex-col p-1'>
        <div className='flex  w-full justify-between p-1 '>
          <span>Məlumatlar</span>
          <div className='flex justify-between w-14'>
            <AimOutlined onClick={() => handleZoomArea(selectedArea?.objectid)} size={20} className='cursor-pointer hover:text-teal-600 ' />
            <CloseCircleOutlined onClick={close} size={20} className='cursor-pointer hover:text-red-600' />
          </div>
        </div>
        <Spin spinning={loading} >
          <div>
            <div className='flex  w-full justify-between border-gray-200 border-b p-1'>
              <span className='text-teal-600'>Sektorun adı:</span>
              <span >{parcel?.parcelSectorV2Infos[0]?.name}</span>
            </div>
            <div className='flex w-full justify-between border-gray-200 border-b p-1'>
              <span className='text-teal-600'>Ölçüsü:</span>
              <span className=''>{parcel?.parcelSectorV2Infos[0]?.area} ha</span>
            </div>
            <div className='flex w-full justify-between border-gray-200 border-b p-1'>
              <span className='text-teal-600'>Şirkət:</span>
              <span className='' >{parcel?.parcelSectorV2Infos[0]?.createdCompany}</span>
            </div>
            <div className='flex w-full justify-between border-gray-200 border-b p-1'>
              <span className='text-teal-600'>Başlama vaxtı:</span>
              <span>{parcel?.parcelSectorV2Infos[0]?.startDate.slice(0, 10)}</span>
            </div>
            <div className='flex w-full justify-between border-gray-200 border-b p-1'>
              <span className='text-teal-600'>Ağac sayı:</span>
              <span>{parcel?.parcelSectorV2Infos[0]?.treeCount}</span>
            </div>
            {
              parcel?.parcelSectorV2Infos[0]?.crop?.name && <div className='flex w-full justify-between border-gray-200 border-b p-1'>
                <span className='text-teal-600'>Məhsul:</span>
                <span>{parcel?.parcelSectorV2Infos[0]?.crop?.name}</span>
              </div>
            }

            {
              parcel?.parcelSectorV2Infos[0]?.cropSortIds && <div className='flex flex-col w-full  border-gray-200 border-b p-1'>
                <span className='text-teal-600'>Məhsul növləri:</span>
                <div className='flex flex-col w-full'>{
                  parcel?.parcelSectorV2Infos[0]?.cropSortIds?.map((c, index) => {
                    return <span key={index} > {c.name} </span>
                  })
                }
                </div>
              </div>
            }

            <div className='flex flex-col w-full p-1 justify-between border-gray-200 border-b ' >
              <span className='text-teal-600'>Xəstəlik proqrnozu</span>
              <div className='flex w-full justify-between mt-1'>
                <Button size='small' id="ndvi_button" onClick={handleAddNDVi} >NDVI</Button>
                <Button size='small' onClick={handleOpenVegetation}>Vegetasiya indeksi</Button>
                <Button size='small' onClick={() => setDetailSick(true)}>Xəstəlik Praqnozu</Button>
              </div>
            </div>
            <div className='flex w-full justify-between border-gray-200  p-1'>
              <Button onClick={() => handleDeleteSector(parcel.id)} danger icon={<DeleteOutlined />} ></Button>
              {
                editing ?
                  <div>  <Button onClick={handleCloseSectorEditing} danger>Ləğv et</Button> <Button onClick={handleSubmitSectorEditing} >Təsdiqlə</Button> ̰ </div>
                  : <Button onClick={handleEditSector} >Dəyişiklik et</Button>
              }
            </div>
          </div>

        </Spin>
        {/* <Modal
          centered
          className="seeSickModal"
          open={detailSick}
          onOk={() => setDetailSick(false)}
          onCancel={() => setDetailSick(false)}
          footer={null}
        >
          <RiskModal id={detailSick} />
        </Modal> */}
      </div>
    </div>
  )
}

import React from 'react'

export default function BaseMapContent({ changeBaseMaps, selectedBaseMap }) {
    return (
        <div className='flex flex-col w-36'>

            <div onClick={() => changeBaseMaps({ map: "hybrid", title: 'Esri Hybrid' })} className={`${selectedBaseMap == 'Esri Hybrid' ? 'bg-teal-600 text-white' : ''} p-1 hover:bg-teal-600 group cursor-pointer `}>
                <span className='group-hover:text-white' >Hibrid</span>
            </div>
            <div onClick={() => changeBaseMaps({ map: "topo-vector", title: 'Esri Topo Vector' })} className={`${selectedBaseMap == 'Esri Topo Vector' ? 'bg-teal-600 text-white' : ''} p-1 hover:bg-teal-600 group cursor-pointer `}>
                <span className='group-hover:text-white' >Topo vector</span>
            </div>
            <div onClick={() => changeBaseMaps({ map: "google-satellite", title: 'Google 2024' })} className={` ${selectedBaseMap == 'Google 2024' ? 'bg-teal-600 text-white' : ''} p-1 hover:bg-teal-600 group cursor-pointer`}>
                <span className='group-hover:text-white' >Google  2024</span>
            </div>
        </div>
    )
}

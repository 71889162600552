import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from 'antd';
import Images from "../../../assets/img/Images/Images";
import { useEffect } from "react";
import agros from "../../../const/api";

const Home = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const getData = () => {
            agros
                .get("MainPageContent")
                .then((res) => {
                    setData(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        getData();
    }, []);
    return (
        <div className="content animated fadeIN home">
            <Carousel className="slider" autoplay={true} dotPosition={window.innerWidth > 1200 ? 'right' : 'top'}>
                {
                    data.map((item, index) => (
                        <div className="slide" key={index}>
                            <h1>
                                {item.title}
                            </h1>
                            <p className="p">
                                <span dangerouslySetInnerHTML={{
                                    __html: item.description,
                                }} />
                            </p>
                        </div>
                    ))

                }


            </Carousel>

            <div className="footer">
                <div className="social">
                    <div className='flex flex-align-center dir-column flex-between'>
                        <a href="/">
                            <img src={Images.fb} alt="" />
                        </a>
                        <a href="/">
                            <img src={Images.tw} alt="" />
                        </a>
                        <a href="/">
                            <img src={Images.yt} alt="" />
                        </a>
                    </div>
                </div>
                <div className="flex flex-align-center povered">
                    <span className='mr-10'>powered by</span>
                    <img src={Images.whiteTimesoft} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Home;

import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Col,
  Input,
  InputNumber,
  Form,
  Row,
  Select,
  Button,
  Modal,
} from "antd";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import agros from "../../../const/api";
import { noWhitespace, whiteSpace } from "../../../utils/rules";
import { getOptions, notify } from "../../../redux/actions";
const { Option } = Select;

const EditArea = (props) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [sectors, setSectors] = useState([{ id: 0 }]);
  const [showTree, setShowtree] = useState(true)
  const [workers, setWorkers] = useState([]);
  const [keys, setKeys] = useState({});
  const [sectorsSum, setSectorsSum] = useState(0);

  const [names, setNames] = useState([]);

  const { getOptions, notify } = props;
  // console.log(props);
  const options = props.options[props.lang];
  // console.log(options);
  const handleKeyChange = (e, key) => {
    const all = { ...keys };
    // console.log(all)
    all[key] = e;
    setKeys(all);
    sectors.map((s, index) => {
      form.resetFields(["ParcelSectors", index, "CropSortIds"])
      form.resetFields(["ParcelSectors", index, "productId"])
    })

  };


  const handleKeyChangeIndex = (e, index, key) => {
    const all = [...sectors];
    sectors[index][key] = e;
    setSectors(all);
  };
  const onCancel = () => {
    props.close()
    form.resetFields()
  }

  useEffect(() => {
    console.log("sector");
    if (!props.editing) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trigger]);
  useEffect(() => {
    console.log("sector2");
    getOptions(
      [
        "cropCategories",
        "cropSorts",
        "crops",
        "positions",
        "parcelRegions",
        "parcelCategories",
      ],
      props.options,
      i18n.language
    );

    form.resetFields();
    // console.log(options.cropSorts);
    if (props.editing) {
      agros.get(`parcel/${props.editing}`).then((res) => {
        // console.log(res);
        setNames([{ name: res.data.name }]);
        let obj = {
          ...res.data,
          fieldName: res.data.name,
          profession: res.data.professionId,
          createdDate: moment(res.data.createdDate),
        };
        // console.log(form);
        form.setFieldsValue(obj);
        onProfessionChange(res.data.professionId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, props.editing]);

  const onProfessionChange = async (e) => {
    setWorkers([]);
    await agros.get("data/workers/" + e).then((res) => {
      let all = { ...workers };
      all = res.data;
      setWorkers(all);
      form.setFieldsValue({ respondedUser: undefined });
    });
  };

  const setSectorArea = (e, index) => {
    const all = [...sectors];
    all[index]["area"] = +e;
    setSectors(all);
    calculateSectorsSum();
  };

  const selectedParcelCategory = (value) => {
    value === 2 ? setShowtree(false) : setShowtree(true)
    handleKeyChange(value, "parcelCategoryId")
    form.setFieldsValue({ cropCategoryId: undefined });
  }

  const calculateSectorsSum = () => {
    let sum = 0;
    sectors.forEach((element) => {
      sum += element.area;
    });
    form.setFieldsValue({ sectorsTotal: sum });
    setSectorsSum(sum);
    // console.log(sectorsSum);
  };

  const saveItem = async (values) => {
    props.handleSaveSector({...values,...values.ParcelSectors[0]})
  };

  return (
    <Modal open={props.open} onCancel={props.close} width={1000} title={'Sektor əlavə et'} footer={null} >
      <Form form={form} onFinish={saveItem} layout="vertical">
        <Row gutter={[8, 8]}>


          <Col md={6} sm={12} xs={24}>
            <Form.Item
              label="Ərazinin kateqoriyası"
              validateTrigger="onChange"
              name="parcelCategoryId"
              rules={[noWhitespace(t("categoryMustSelectError"))]}
            >
              <Select onChange={(value) => { selectedParcelCategory(value) }}>
                {options.parcelCategories.map((pr, index) => {
                  return (
                    <Option key={index} value={pr.id}>
                      {pr.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>


         

          {/*sektor*/}
          <Col className="mt-10" xs={24}>
            {!props.editing &&
              sectors.map((s, index) => {
                return (
                  <Row key={index} gutter={[8, 8]}>

                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label={t("sectorName")}
                        validateTrigger="onChange"
                        name={["ParcelSectors", index, "name"]}
                        rules={[whiteSpace(t("inputError"))]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>

                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label={t("sectorArea")}
                        validateTrigger="onChange"
                        name={["ParcelSectors", index, "Area"]}
                        rules={[noWhitespace(t("inputError"))]}
                      >
                        <div className="form-lang">
                          <InputNumber
                            onChange={(e) => setSectorArea(e, index)}
                            className="w-100"
                          />
                          <span className="input-lang">
                            {/*m<sup>2</sup>*/}
                            ha
                          </span>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col md={4} sm={12} xs={24}>
                      <Form.Item
                        label={t("treeNumber")}
                        validateTrigger="onChange"
                        name={["ParcelSectors", index, "TreeCount"]}
                        rules={[noWhitespace(t("inputError"))]}
                      >
                        <InputNumber className="w-100" />
                      </Form.Item>
                    </Col>


                    <Col md={showTree ? 4 : 8} sm={12} xs={24}>
                      <Form.Item
                        label={t("createdDate")}
                        validateTrigger="onChange"
                        name={["ParcelSectors", index, "CreatedDate"]}
                        rules={[noWhitespace(t("dateError"))]}
                      >
                        <DatePicker
                          placeholder={t("selectDate")}
                          className="w-100"
                        />
                      </Form.Item>
                    </Col>

                  
                  
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label={t("company")}
                        validateTrigger="onChange"
                        name={["ParcelSectors", index, "createdCompany"]}
                        rules={[whiteSpace(t("inputError"))]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })}
          </Col>


        </Row>
        <div
          className="modalButtons flex w-full flex-row-reverse"
        // style={{ position: "absolute", bottom: "20px", right: "40px" }}
        >

          <Button loading={props.saveLoading} type="primary" className="ml-10" htmlType="submit">
            {t("save")}
          </Button>
          <Button
            onClick={() => {
              onCancel()
            }}
            className="ml-4"
          >
            {t("cancel")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ options, lang, user }) => {
  return { options, lang, perms: user.data.userPermissions };
};

export default connect(mapStateToProps, { getOptions, notify })(EditArea);

import React, { useEffect, useState } from "react";
import { loadModules } from "esri-loader";
import { Button, Drawer, Form, Popover, Upload } from "antd";
import { CloudUploadOutlined, SwapOutlined, MenuOutlined, EditOutlined, EyeOutlined, AppstoreOutlined, CloseCircleOutlined, EyeInvisibleOutlined, ApartmentOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRuler } from '@fortawesome/free-solid-svg-icons'
import agros from "../../../const/api";
import { setAuthToken, requestAuthToken, BBox, CRS_EPSG3857, MimeTypes, S2L2ALayer, ApiType } from '@sentinel-hub/sentinelhub-js';

import StatisticModal from './StatisticModal'
import { Tooltip } from "antd/lib";
import AreaAddModal from "./AreaAddModal";
import DrawingPanel from "./DrawingPanel";
import { notify } from "../../../redux/actions";
import { useTranslation } from "react-i18next";
import AreaDetailsPanel from "./AreaDetailsPanel";
import SectorAddModal from "./SectorAddModal";
import SectorDetailsPanel from "./SectorDetailsPanel";
import Areas from "./AreasPanel";
import Footer from "./Footer";
import BaseMapContent from "./BaseMapContent";
import Measurement from "./Measurement";
import SwipePanel from "./SwipePanel";
import LayerContent from "./LayerContent";
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { template } from "lodash-es";
import DiseaseModal from "./DiseaseModal";
import { render } from "react-dom";


const UploadContent = ({ handleAddFile, view, graphicsLayer, map }) => {
    return (
        <Upload onChange={info => handleAddFile(info, view, graphicsLayer, map)} beforeUpload={() => { return false }} showUploadList={false} name="file" maxCount={1}>
            <Tooltip placement="left" mouseEnterDelay={0.5} title="Fayl əlavə et"> <Button icon={<CloudUploadOutlined />} ></Button></Tooltip>
        </Upload>
    )
}

const evalscripts = {
    ndvi: `//VERSION=3

    function setup() {
        return {
            input: ["B04", "B08", "SCL","dataMask"],
            output: [
                { id: "default", bands: 4 },
                { id: "index", bands: 1, sampleType: "FLOAT32" },
                { id: "eobrowserStats", bands: 2, sampleType: 'FLOAT32' },
                { id: "dataMask", bands: 1 }
            ]
          };
    }
    
    function evaluatePixel(samples) {
        let val = index(samples.B08, samples.B04);
        let imgVals = null;
        // The library for tiffs works well only if there is only one channel returned.
        // So we encode the "no data" as NaN here and ignore NaNs on frontend.
        const indexVal = samples.dataMask === 1 ? val : NaN;
      
        if (0.95 <=val && val<= 1.00) imgVals = [0,0.49,0.28,samples.dataMask];  	
        else if(0.90 <=val && val< 0.95) imgVals = [0,0.59,0.33,samples.dataMask];
        else if(0.85 <=val && val< 0.90) imgVals = [0.08,0.67,0.38,samples.dataMask]; 
        else if(0.80 <=val && val< 0.85) imgVals = [0.33,0.74,0.42,samples.dataMask];   	
        else if(0.75 <=val && val< 0.80) imgVals = [0.47,0.79,0.44,samples.dataMask];   	 
        else if(0.70 <=val && val< 0.75) imgVals = [0.61,0.85,0.45,samples.dataMask];   	 
        else if(0.65 <=val && val< 0.70) imgVals = [0.73,0.89,0.51,samples.dataMask];   	 
        else if(0.60 <=val && val< 0.65) imgVals = [0.84,0.94,0.58,samples.dataMask];   	 
        else if(0.55 <=val && val< 0.60) imgVals = [0.92,0.97,0.67,samples.dataMask];  
        // -- 	
        else if(0.50 <=val && val< 0.55) imgVals = [0.99,1,0.76,samples.dataMask];   	 
        else if(0.45 <=val && val< 0.50) imgVals = [1,0.94,0.67,samples.dataMask];   	 
        else if(0.40 <=val && val< 0.45) imgVals = [1,0.88,0.58,samples.dataMask];   	 
        else if(0.35 <=val && val< 0.40) imgVals = [1,0.78,0.49,samples.dataMask];   	 
        else if(0.30 <=val && val< 0.35) imgVals = [1,0.67,0.41,samples.dataMask];   	 
        else if(0.25 <=val && val< 0.30) imgVals = [1,0.55,0.35,samples.dataMask];   	 
        else if(0.20 <=val && val< 0.25) imgVals = [1,0.42,0.29,samples.dataMask];   	 
        else if(0.15 <=val && val< 0.20) imgVals = [0.94,0.3,0.23,samples.dataMask];   	 
        else if(0.10 <=val && val< 0.15) imgVals = [0.88,0.18,0.17,samples.dataMask];   	 
        else if(0.05 <=val && val< 0.10) imgVals = [0.77,0.008,0.16,samples.dataMask];   	 
        else if(-1 <=val && val< 0.05) imgVals = [0.68,0,0.16,samples.dataMask];   	 
          return {
          default: imgVals,
          index: [indexVal],
          eobrowserStats:[val,isCloud(samples.SCL)?1:0],
          dataMask: [samples.dataMask]
        };
    }
    
    function isCloud (scl) {
      if (scl == 3) { // SC_CLOUD_SHADOW
        return false;
      } else if (scl == 9) { // SC_CLOUD_HIGH_PROBA
        return true; 
      } else if (scl == 8) { // SC_CLOUD_MEDIUM_PROBA
        return true;
      } else if (scl == 7) { // SC_CLOUD_LOW_PROBA
        return false;
      } else if (scl == 10) { // SC_THIN_CIRRUS
        return true;
      } else if (scl == 11) { // SC_SNOW_ICE
        return false;
      } else if (scl == 1) { // SC_SATURATED_DEFECTIVE
        return false;
      } else if (scl == 2) { // SC_DARK_FEATURE_SHADOW
         return false;
      }
      return false;
    }`
}
export default function ArcgisMap() {
    const [form] = Form.useForm()
    const [panels, setPanel] = useState({ areas: false, areaDetails: false, sectorDetails: false })
    const [drawedArea, setDrawedArea] = useState()
    const [state, setState] = useState()
    const [areaAddModal, setAreaAddModal] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [selectedArea, setSelectedArea] = useState()
    const [selectedSector, setSelectedSector] = useState()
    const [sectorAddModal, setSectorAddModal] = useState(false)
    const [editing, setEditing] = useState(false)
    const [selectedBaseMap, setSelectedBaseMap] = useState("Esri Hybrid")
    const [scale, setScale] = useState()
    const [measuredArea, setMeasuredArea] = useState()
    const [measurePanel, setMeasurePanel] = useState({})
    const [layerVisible, setLayerVisible] = useState(true)
    const dateFormat = 'MM-DD-YYYY';
    const { t } = useTranslation()
    const [stats, setStats] = useState()
    const [statisticModal, setStatisticModal] = useState(false)
    const [statLoading, setStatLoading] = useState(false)
    const [zommedArea, setZommedArea] = useState(null)
    const getInit = async () => {

    }


    const handleAddFile = async (info, graphicsLayer) => {
        loadModules(["esri/request", "esri/Graphic", "esri/layers/KMLLayer"], { css: true }).then(
            async ([request, Graphic, KMLLayer]) => {

                let file = info.file
                let name = file.name.split(".");


                const portalUrl = "https://www.arcgis.com";


                const params = {
                    name: name[0],
                    targetSR: state.view.spatialReference,
                    maxRecordCount: 1000,
                    enforceInputFileSizeLimit: true,
                    enforceOutputJsonSizeLimit: true
                };

                // generalize features to 10 meters for better performance
                params.generalize = true;
                params.maxAllowableOffset = 10;
                params.reducePrecision = true;
                params.numberOfDigitsAfterDecimal = 0;

                var formdata = new FormData();
                formdata.append('filetypebackward-diagonal', 'shapefile');
                formdata.append('publishParameters', JSON.stringify(params));
                formdata.append('f', 'json');
                formdata.append('file', file);

                const myContent = {
                    filetype: "shapefile",
                    publishParameters: JSON.stringify(params),
                    f: "json"
                };

                await request(portalUrl + "/sharing/rest/content/features/generate", {
                    query: myContent,
                    body: formdata,
                    responseType: "json"
                })
                    .then((response) => {
                        const featureCollection = response.data.featureCollection;
                        let graphics = [];
                        state.graphicsLayer.removeAll()
                        featureCollection.layers.map((layer) => {
                            const graphic = layer.featureSet.features.map((feature) => {
                                return Graphic.fromJSON(feature);
                            });
                            state.graphicsLayer.addMany(graphic);
                            graphics.push(graphic)
                        });
                        // console.log(graphics)
                        let drawedArea = featureCollection.layers[0].featureSet.features[0]
                        setDrawedArea(drawedArea)
                        saveGraphic()
                        console.log('asd')
                        state.view.goTo(graphics, {
                            duration: 2000, // Animation duration in milliseconds
                            easing: "in-out-expo"  // Easing for a smooth start and end
                        }).catch((error) => {
                            if (error.name != "AbortError") {
                                console.error(error);
                            }
                        });
                    })
                    .catch();


            })
    }

    const handleCloseSketchBar = () => {
        setDrawedArea()
        state.graphicsLayer.removeAll()
        state.sketchViewModel.cancel()
        setMeasurePanel({})
    }

    const handleSketchOp = (sketchOp) => {
        console.log(state);
        setDrawedArea()
        if (sketchOp == "clear") {
            state.graphicsLayer.removeAll()
        } else {
            console.log(state);
            state.graphicsLayer.removeAll()
            state.sketchViewModel.create(sketchOp)
        }
        console.log(state);
    }

    const saveGraphic = () => {
        if (drawedArea) {
            if (drawedArea.hasParent) {
                setSectorAddModal(true)
            } else {
                setAreaAddModal(true)
            }
        }
    }

    const handleSaveSector = async (data) => {
        if (drawedArea) {
            let objectId;
            setSaveLoading(true)
            drawedArea.area.attributes = { parent_id: drawedArea.parentId }
            await state.baseLayer.applyEdits({
                addFeatures: [drawedArea.area]
            })
                .then(async res => {
                    objectId = res?.addFeatureResults[0].objectId
                    console.log(data)
                    await agros.post("Parcel/Sector", { ...data, GISID: objectId, ParcelId: drawedArea.ParcelId })
                        .then(async res => {
                            const area_data = res.data
                            const { features } = await state.baseLayer.queryFeatures({ where: `objectid=${objectId}`, outFields: ["*"] });
                            let updatedFeature = features[0]
                            updatedFeature.attributes["area_id"] = area_data.id
                            updatedFeature.attributes["archive_date"] = new Date(area_data.startDate).getTime()
                            updatedFeature.attributes["end_date"] = new Date(area_data.endDate).getTime()
                            updatedFeature.attributes["plants"] = data.CropSortIds[0]
                            await state.baseLayer.applyEdits({
                                updateFeatures: [updatedFeature]
                            })
                            notify(t("areaIsAdded"), true);
                            state.graphicsLayer.removeAll()
                            setSectorAddModal(false)
                            setSaveLoading(false)
                            handleCloseSketchBar()
                        })

                })
                .catch(ex => {
                    return false
                })
            setSaveLoading(false)
        }
    }


    const handleSaveGraphic = async (area) => {
        let objectId;

        await state.baseLayer.applyEdits({
            addFeatures: [area]
        })
            .then(res => {
                objectId = res?.addFeatureResults[0].objectId
            })
            .catch(ex => {
                return false
            })
        return objectId

    }

    const handleSaveArea = async (data) => {
        if (drawedArea) {
            let objectId;
            const token = localStorage.getItem("access_token")
            const decoded = jwtDecode(token);
            const enddate = new Date('2050-01-01')
            setSaveLoading(true)
            let area = drawedArea.area
            area.attributes = {
                sirket: data.createdCompany,
                erazi_kategoriyasi: data.parcelCategoryId,
                region_id: data.parcelRegionId,
                respondent_id: data.respondentId,
                sektorun_adi: data.fieldName,
                sektorun_sahesi: data.area,
                agac_sayi: data?.TreeCount,
                yaranma_tarixi: data.createdDate.toDate().getTime(),
                startdate: data.createdDate.toDate().getTime(),
                enddate: enddate.getTime(),
                companyid: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality"]
            }
            await state.baseLayer.applyEdits({
                addFeatures: [area]
            })
                .then(res => {
                    objectId = res?.addFeatureResults[0].objectId
                    setAreaAddModal(false)
                    setSaveLoading(false)
                })
                .catch(ex => {
                    return false
                })

        }
    }

    const handleZoomArea = async (objectid) => {
        let results = await state?.baseLayer.queryFeatures({
            where: `objectid=${objectid}`,
            returnGeometry: true,
        })

        if (results.features.length !== 0) {
            let feature = results.features[0]
            state.view.goTo(feature.geometry, {
                duration: 2000, // Animation duration in milliseconds
                easing: "in-out-expo"  // Easing for a smooth start and end
            })
        }

    }

    const handleClickKMZLayer = (graphic) => {
        setDrawedArea({ area: graphic, parentId: 0 })
        setAreaAddModal(true)
    }

    const handleClickArea = (data) => {
        const attributes = data?.attributes
        setEditing(false)
        if (attributes?.parent_id != null) {
            setPanel({ sectorDetails: true })
            setSelectedSector(data)

        } else {
            setPanel({ areaDetails: true })
            setSelectedArea(data)
        }

    }

    const handleCloseAreaDetailsPanel = () => {
        state.baseLayer.featureEffect = {}
        setPanel({})
        state.removeNdviLayer()
    }

    const handleCloseSectorDetailsPanel = () => {
        setEditing(false)
        state.baseLayer.featureEffect = {}
        setPanel({})
        state.removeNdviLayer()
    }

    const handleClickExpandArea = async (area_id) => {
        let results = await state?.baseLayer.queryFeatures({
            where: `objectid=${area_id}`,
            returnGeometry: true,
        })

        if (results.features.length !== 0) {
            let feature = results.features[0]
            state.view.goTo(feature.geometry, {
                duration: 2000, // Animation duration in milliseconds
                easing: "in-out-expo"  // Easing for a smooth start and end
            })
        }
        setZommedArea(area_id)
    }

    const handleCloseSectorEditing = () => {
        setEditing(false)
        state.graphicsLayer.removeAll()

    }

    const handleEditSector = async () => {
        state.graphicsLayer.removeAll()
        setEditing(true)
        selectedSector.symbol = {
            type: "simple-fill",
            // color: [13, 148, 136, 0.70],
            outline: {
                color: '#5eead4',
                width: 1
            }
        }
        state.graphicsLayer.add(selectedSector)
    }

    const handleOpenAreas = () => {
        setPanel({ areas: true })
    }

    const handleSubmitSectorEditing = async () => {
        setEditing(false)
        state.graphicsLayer.removeAll()

        drawedArea.attributes = { ...selectedSector.attributes, start_date: new Date().getTime(), end_date: new Date().getTime() }

        selectedSector.attributes["end_date"] = new Date().getTime()

        await state.baseLayer.applyEdits({
            updateFeatures: [selectedSector]
        })

        await state.baseLayer.applyEdits({
            addFeatures: [drawedArea]
        })
    }

    const changeBaseMaps = (baseMap) => {
        if (baseMap.map == "google-satellite") {
            state.map.add(state.google_base_layer, 0);
        } else {
            state.map.remove(state.google_base_layer);
            state.view.map.basemap = baseMap.map
        }
        setSelectedBaseMap(baseMap.title)
    }

    const handleOpenMeasure = () => {
        handleCloseSketchBar()
        setMeasurePanel({ "measure": true })
    }

    const handleDrawNewAreaMeasure = (type) => {
        state.graphicsLayer.removeAll()
        state.measureSketch.create(type)
    }

    const handleCloseMeasure = () => {
        state.graphicsLayer.removeAll()
        state.measureSketch.cancel()
        setMeasurePanel({})
    }

    const handleOpenSwipe = () => {
        state.view.ui.remove(state.swipe);
        state.swipe.postInitialize()
        state.baseLayer.visible = false
        state.map.add(state.leadingLayers)
        state.swipe.leadingLayers = [state.leadingLayers]
        state.swipe.trailingLayers = [state.leadingLayers]
        state.view.ui.add(state.swipe)
        setMeasurePanel({ swipe: true })
    }

    const handleChangeSwipeDate = (type, date) => {

        let date_ = date.format(dateFormat)
        console.log(type)
        state[type].definitionExpression = "startdate <= date'" + date_ + "' and date'" + date_ + "'  < enddate "
        state.swipe[type] = [state[type]]
        state.baseLayer.visible = false
        state.map.add(state[type])
    }

    const handleCloseSwipe = () => {
        state.map.remove(state.trailingLayers)
        state.map.remove(state.leadingLayers)
        state.view.ui.remove(state.swipe)
        state.baseLayer.visible = true
        setMeasurePanel(false)
    }


    const handleDeleteSector = async (area_id) => {
        const { features } = await state.baseLayer.queryFeatures({ where: `area_id=${area_id}`, outFields: ["*"] });
        let updatedFeature = features[0]
        updatedFeature.attributes["enddate"] = Date.now()

        await state.baseLayer.applyEdits({
            updateFeatures: [updatedFeature]
        })
    }

    const handleChangeLayerVisible = () => {
        state.baseLayer.visible = !layerVisible
        setLayerVisible(!layerVisible)
    }

    const handleChangeMapServer = () => {
        state.map.remove(state.baseLayer)
        state.map.add(state.baseLayer)
    }


    const handleAddNDVi = () => {
        state.addNDVIToMap(selectedSector)
    }

    const handleAddNDViForArea = () => {
        state.addNDVIToMap(selectedArea)
    }


    const handleCloseVegetation = () => {
        setStatisticModal(false)
    }


    const handleGetStatistics = async () => {
        setStatLoading(true)
        setStatisticModal(true)
        await state.getStatistics(selectedSector)
        setStatLoading(false)
    }

    const handleOpenVegetationForArea = async () => {
        setStatLoading(true)
        setStatisticModal(true)
        await state.getStatistics(selectedArea)
        setStatLoading(false)
    }

    const handleDeleteArea = async () => {
        await agros.delete("Parcel/AccessDelete/1")
            .then(async res => {
                const canDelete = res?.data?.canDelete
                if (canDelete) {
                    const { features } = await state.baseLayer.queryFeatures({ where: `objectid=${selectedArea?.attributes?.objectid}`, outFields: ["*"] });
                    let updatedFeature = features[0]
                    updatedFeature.attributes["enddate"] = Date.now()
                    updatedFeature.attributes["deleted"] = "deleted"
                    await state.baseLayer.applyEdits({
                        updateFeatures: [updatedFeature]
                    })
                    setPanel({})
                } else {
                    alert("Bu ərazini silmək üçün prosesi sonlandırmalısınız!")
                }
            })

    }

    const handleChangeArea = () => {
    }

    const handleUpdateArea = async (data) => {

        selectedArea.attributes = {
            ...selectedArea.attributes,
            sirket: data.createdCompany,
            erazi_kategoriyasi: data.parcelCategoryId,
            region_id: data.parcelRegionId,
            respondent_id: data.respondentId,
            sektorun_adi: data.fieldName,
            sektorun_sahesi: data.area,
            agac_sayi: data?.TreeCount,
            yaranma_tarixi: data.createdDate.toDate().getTime(),
            startdate: data.createdDate.toDate().getTime(),
        }
        await state.baseLayer.applyEdits({
            updateFeatures: [selectedArea]
        })
    }




    useEffect(() => {
        loadModules([
            "esri/Map",
            "esri/views/MapView",
            "esri/layers/GraphicsLayer",
            "esri/layers/FeatureLayer",
            "esri/widgets/Search",
            "esri/widgets/Locate",
            "esri/identity/IdentityManager",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/widgets/Expand",
            "esri/layers/WebTileLayer",
            "esri/geometry/geometryEngine",
            "esri/widgets/Swipe",
            "esri/layers/MapImageLayer",
            "esri/layers/support/ImageElement",
            "esri/layers/MediaLayer",
            "esri/layers/support/ExtentAndRotationGeoreference",
            "esri/geometry/Extent",
            "esri/Graphic",
            "esri/PopupTemplate",
        ], { css: true }).then(
            async ([Map, MapView, GraphicsLayer, FeatureLayer, Search, Locate, esriId, SketchViewModel, Expand, WebTileLayer, geometryEngine, Swipe, MapImageLayer, ImageElement, MediaLayer, ExtentAndRotationGeoreference, Extent, Graphic, PopupTemplate]) => {
                //init arcgis
                await agros.get("Map")
                    .then(res => {
                        let result = JSON.parse(res.data.result)
                        if (result) {
                            esriId.registerToken({
                                server: "https://gisserveriis.gis.az/gisserver/rest/services",
                                token: result.token,
                            })
                        }
                    })

                const clientId = "ee2b889e-8203-4156-965d-066da71d26e9";
                const clientSecret = "bKNcrrxDDtZRbAwnHWvoQw1C7nXa5oW9";
                const authToken = await requestAuthToken(clientId, clientSecret);
                setAuthToken(authToken);

                const token = localStorage.getItem("access_token")
                const decoded = jwtDecode(token);

                const map = new Map({
                    basemap: "hybrid",
                });

                const view = new MapView({
                    id: "viewDiv",
                    container: "viewDiv",
                    map: map,
                    extent: {
                        xmin: 44.650,
                        ymin: 38.35,
                        xmax: 50.43,
                        ymax: 41.92,
                        spatialReference: 4326
                    },
                    ui: {
                        components: ["zoom"]
                    },
                    zoom: 8
                });

                //  layers
                let graphicsLayer = new GraphicsLayer()
                let carLayer = new GraphicsLayer()
                let station = new GraphicsLayer()

                const base_layer = new FeatureLayer({
                    url: `https://gisserveriis.gis.az/gisserver/rest/services/Orders/Sector_information/FeatureServer/0`,
                    outFields: ["*"],
                    definitionExpression: `companyid =${decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality']} and deleted IS NULL`,
                })


                const mapServer = new MapImageLayer({
                    url: "https://gisserveriis.gis.az/gisserver/rest/services/ForTesting/Agros/MapServer"
                });

                const lakes = new FeatureLayer({
                    url: "https://gisserveriis.gis.az/gisserver/rest/services/Ecology/Lakes/MapServer/0",
                    visible: false
                })

                const kmzLayer = new FeatureLayer({
                    url: 'https://gisserveriis.gis.az/gisserver/rest/services/ForTesting/ParcelAgros/FeatureServer/0',
                    visible: false
                })



                const google_base_layer = new WebTileLayer({
                    urlTemplate: "https://mt1.google.com/vt/lyrs=s&x={col}&y={row}&z={level}",
                })

                const swipe_bar = new Swipe({ view })

                const swipe_layer1 = new FeatureLayer({
                    url: `https://gisserveriis.gis.az/gisserver/rest/services/Orders/Sector_information/FeatureServer/0`,
                    outFields: ["*"],

                })

                const swipe_layer2 = new FeatureLayer({
                    url: `https://gisserveriis.gis.az/gisserver/rest/services/Orders/Sector_information/FeatureServer/0`,
                    outFields: ["*"],
                })
                const kanallar = new FeatureLayer({
                    url: 'https://gisserveriis.gis.az/gisserver/rest/services/ForTesting/AgrosChannel/MapServer/0',
                    visible: false
                })

                base_layer
                    .when(() => {
                        return base_layer.queryExtent();
                    })
                    .then((response) => {
                        view.goTo(response.extent, {
                            duration: 3000,
                            easing: "in-out-expo"
                        });
                    });

                let mediaLayer;
                // 41.2670305858178, 46.79637088115864



                agros.get("weatherlink/v2")
                    .then(res => {
                        console.log(res)

                        res.data.map(p => {
                            const point = { //Create a point
                                type: "point",
                                longitude: p.longitude,
                                latitude: p.latitude
                            };
                            const simpleMarkerSymbol = {
                                type: "simple-marker",
                                color: [226, 119, 40],
                                outline: {
                                    color: [255, 255, 255],
                                    width: 1
                                }
                            };
                            var popupTemplate = new PopupTemplate({
                                title: `Metroloji stansiya ${p.id}`,
                                content: `<p class=' p-1 bg-gray-200'>Barometr : ${p.barometer}</p>
                                <p class='p-1 '>Nəmlik : ${p.humidity}</p>
                                <p class=' p-1 bg-gray-200 '>Yağış : ${p.rain}</p>
                                <p class=' p-1 '>Temperatur : ${p.temperature}</p>
                                <p class=' p-1 bg-gray-200 '>Külək : ${p.wind}</p>
                                <p class='flex w-full justify-center items-center '>
                                <a href='https://agros.az/integratons/analyzes?id=${p.id}' class='p-1 text-underline w-full rounded' >Daha ətraflı</a>
                                </p>
                                `
                            });

                            const pointGraphic = new Graphic({
                                geometry: point,
                                symbol: simpleMarkerSymbol,
                                attributes: p,
                                popupTemplate
                            });
                            station.add(pointGraphic);
                        })

                    })

                function loadCarService() {

                    axios.get("https://ulu-agro.gps.az/get-all-units")
                        .then(res => {
                            carLayer.removeAll()
                            res.data.map(p => {
                                const point = { //Create a point
                                    type: "point",
                                    longitude: p.position.lon,
                                    latitude: p.position.lat
                                };
                                const simpleMarkerSymbol = {
                                    type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                                    // url: p.icon,
                                    url: 'https://cdn-icons-png.flaticon.com/512/4736/4736213.png',
                                    width: "40px",
                                    height: "35px"
                                }

                                var popupTemplate = new PopupTemplate({
                                    title: `Məlumatlar`,
                                    content: `<p class=' p-1 bg-gray-200'>Maşının nömrəsi : ${p.plate}</p>
                                <p class='p-1 '>Sürəti : ${p.position.speed_text}</p>
                                `
                                });

                                const pointGraphic = new Graphic({
                                    geometry: point,
                                    symbol: simpleMarkerSymbol,
                                    attributes: p,
                                    popupTemplate
                                });
                                carLayer.add(pointGraphic);
                            })

                        })
                }
                loadCarService()
                setInterval(loadCarService, 150000);
                let areas = []
                let query = base_layer.createQuery()
                query.where = `companyid =${decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality']} and deleted IS NULL`
                query.returnGeometry = false;
                const { features } = await base_layer.queryFeatures(query)

                let cropSortUniqueValues = []
                features.map(feature => {
                    let attributes = feature.attributes
                    areas.push(attributes)
                    let cropSort = {
                        value: attributes.color,
                        symbol: {
                            type: "simple-fill",  // autocasts as new SimpleFillSymbol()
                            color: attributes.color
                        }
                    }
                    cropSortUniqueValues.push(cropSort)
                })

                let renderer = {
                    type: "unique-value",
                    field: "color",
                    defaultSymbol: {
                        type: "simple-fill",
                        style: 'solid',
                        color: 'rgba(191, 201, 202,0.8)',
                        outline: {
                            color: 'white',
                            width: 1
                        }
                    },  // autocasts as new SimpleFillSymbol()
                    uniqueValueInfos: cropSortUniqueValues
                };



                const kanalTemplate = {
                    // autocasts as new PopupTemplate()
                    title: "Kanal məlumatı",
                    content: [
                        {
                            type: "fields",
                            fieldInfos: [
                                {
                                    fieldName: "name",
                                    label: "Adı"
                                },
                                {
                                    fieldName: "water_capa",
                                    label: "Su səviyyəsi"
                                }
                            ]
                        }
                    ]
                };

                kanallar.popupTemplate=kanalTemplate
                base_layer.renderer = renderer
                swipe_layer1.renderer = renderer
                swipe_layer2.renderer = renderer

                map.add(kmzLayer)

                map.add(graphicsLayer)

                map.add(mapServer)
                map.add(lakes)
                map.add(base_layer)
                map.add(station)
                map.add(carLayer)
                map.add(kanallar)
                //  end layers

                // tools

                const locateBtn = new Locate({
                    view: view
                });

                let search = new Search({
                    view: view,
                    allPlaceholder: 'Axtarış edin',
                    popupEnabled: false
                })

                const sketchViewModel = new SketchViewModel({
                    view: view,
                    layer: graphicsLayer,
                    polygonSymbol: {
                        type: "simple-fill",
                        color: [13, 148, 136, 0.70],
                        outline: {
                            color: '#5eead4',
                            width: 1
                        }
                    }
                });

                const measureSketch = new SketchViewModel({
                    view: view,
                    layer: graphicsLayer,
                    polygonSymbol: {
                        type: "simple-fill",
                        color: [13, 148, 136, 0.70],
                        outline: {
                            color: '#5eead4',
                            width: 1
                        }
                    }
                });


                view.ui.add(search, { position: 'top-left', index: 0 })
                view.ui.add(locateBtn, "top-left")

                // end tools

                // view operations
                view.on('click', function (event) {
                    view.hitTest(event).then(async function (response) {
                        console.log(response)
                        if (response.results.length > 0 && response.results[0].graphic) {
                            let graphic = response.results[0].graphic
                            if (response.results[0].graphic.layer.url == base_layer.url) {


                                base_layer.featureEffect = {
                                    filter: {
                                        objectIds: [graphic.attributes.objectid]
                                    }
                                    ,
                                    excludedEffect: "blur(1px) brightness(30%)"
                                }

                                handleClickArea(graphic)
                            } else if (response.results[0].graphic.layer.url == kmzLayer.url) {
                                kmzLayer.featureEffect = {
                                    filter: {
                                        objectIds: [graphic.attributes.objectid]
                                    }
                                    ,
                                    includedEffect: "brightness(120%)"
                                }
                                handleClickKMZLayer(graphic)
                            } else if (response.results[0].graphic.layer.url == null) {
                                console.log('lololo')
                                view.popup.open({
                                    features: [graphic],
                                    location: event.mapPoint
                                });
                            }
                        }
                    })
                })

                view.watch("scale", function (scale) {
                    let roundedScale = Math.round(scale)
                    if (roundedScale) {
                        let km = roundedScale / 39370
                        if (km < 1) {
                            let metr = roundedScale / 39.370
                            setScale(Math.round(metr) + " m")
                        } else {
                            setScale(Math.round(km) + " km")
                        }
                    }
                });

                sketchViewModel.on('create', async event => {
                    if (event.state === 'complete') {

                        let drawedArea = { area: event.graphic, hasParent: false }

                        setDrawedArea(drawedArea)
                        console.log(drawedArea)
                        graphicsLayer.removeAll()
                        graphicsLayer.add(event.graphic)

                    }
                })

                sketchViewModel.on("update", async event => {
                    console.log(event)
                    if (event.state === 'complete') {
                        graphicsLayer.removeAll()
                        graphicsLayer.add(event?.graphics[0])

                        let query = base_layer.createQuery()
                        query.geometry = event?.graphics[0].geometry
                        query.spatialRelationship = "intersects";  // this is the default
                        query.returnGeometry = true;
                        const { features } = await base_layer.queryFeatures(query)
                        let drawedArea = { area: event?.graphics[0], hasParent: false }
                        if (features.length > 0) {
                            drawedArea['hasParent'] = true
                            drawedArea['parentId'] = features[0].attributes.objectid
                            drawedArea['ParcelId'] = features[0].attributes.area_id
                        }
                        setDrawedArea(drawedArea)
                        console.log(drawedArea)
                    }
                    else if (event.state === 'active') {
                        setDrawedArea()
                    }
                })

                measureSketch.on("create", async event => {
                    if (event.state == "complete") {
                        setMeasuredArea(event.graphic)
                    }
                })

                measureSketch.on("update", event => {
                    if (event.state == "complete") {
                        setMeasuredArea(event?.graphics[0])
                    }
                })

                const baseLayerView = await view.whenLayerView(base_layer);
                baseLayerView.highlightOptions = {
                    color: "#39ff14",
                    haloOpacity: 0.9,
                    fillOpacity: 0
                };

                const addNDVIToMap = async (graphic) => {
                    let extent = graphic.geometry.extent
                    const layer = new S2L2ALayer({
                        evalscript: evalscripts.ndvi,
                        maxCloudCoverPercent: 1,
                        mosaickingOrder: 'leastCC',
                    });

                    const fromDate = new Date()
                    fromDate.setMonth(new Date().getMonth() - 2);
                    const toDate = new Date()



                    let coordinates = graphic.geometry.rings[0]
                    let coorLen = coordinates.length - 1;
                    if ((coordinates[0][0] !== coordinates[coorLen][0]) || coordinates[0][1] !== coordinates[coorLen][1]) {
                        coordinates.push(coordinates[0])
                    }
                    const getMapParams = {
                        bbox: new BBox(CRS_EPSG3857, extent.xmin, extent.ymin, extent.xmax, extent.ymax),
                        fromTime: fromDate,
                        toTime: toDate,
                        width: 512,
                        height: 343.697,
                        format: MimeTypes.PNG,
                        geometry: { "type": "Polygon", "coordinates": [coordinates] }
                    }



                    layer.getMap(getMapParams, ApiType.PROCESSING).then(response => {
                        // loop through images and set up ImageElement object for MediaLayer.
                        const href = window.URL.createObjectURL(response);
                        const imageElement = new ImageElement({
                            image: href,
                            georeference: new ExtentAndRotationGeoreference({
                                extent: new Extent({
                                    spatialReference: {
                                        wkid: 102100
                                    },
                                    xmin: extent.xmin,
                                    ymin: extent.ymin,
                                    xmax: extent.xmax,
                                    ymax: extent.ymax
                                })
                            })
                        });
                        // MediaLayer - add imageElements
                        map.remove(mediaLayer)

                        mediaLayer = new MediaLayer({
                            source: [imageElement]
                        })


                        map.add(mediaLayer)
                    });

                }

                const getStatistics = async (graphic) => {
                    let extent = graphic.geometry.extent
                    const layer = new S2L2ALayer({
                        evalscript: evalscripts.ndvi,
                       
                    });

                    const fromDate = new Date()
                    fromDate.setMonth(new Date().getMonth() - 2);
                    const toDate = new Date()

                    const getMapParams = {
                        bbox: new BBox(CRS_EPSG3857, extent.xmin, extent.ymin, extent.xmax, extent.ymax),
                        fromTime: fromDate.toISOString(),
                        toTime: toDate.toISOString(),
                        width: 512,
                        height: 343.697,
                        format: MimeTypes.PNG,
                        geometry: { "type": "Polygon", "coordinates": [graphic.geometry.rings[0]] }
                    }

                    await axios.post(
                        'https://services.sentinel-hub.com/api/v1/statistics',
                        {
                            'input': {
                                'bounds': {
                                    'bbox': [
                                        extent.xmin, extent.ymin, extent.xmax, extent.ymax
                                    ],
                                    "properties": {
                                        "crs": "http://www.opengis.net/def/crs/EPSG/0/3857"
                                    }
                                },
                                'data': [
                                    {
                                        'dataFilter': {},
                                        'type': 'sentinel-2-l2a'
                                    }
                                ]
                            },
                            'aggregation': {
                                'timeRange': {
                                    'from': fromDate.toISOString(),
                                    'to': toDate.toISOString()
                                },
                                'aggregationInterval': {
                                    'of': 'P10D'
                                },
                                'width': 512,
                                'height': 343.697,
                                'evalscript': evalscripts.ndvi
                            },
                            'calculations': {
                                'default': {}
                            }
                        },
                        {
                            headers: {
                                'Authorization': 'Bearer ' + authToken,
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            }
                        }
                    )
                        .then(res => {
                            setStats(res.data)
                        })
                }

                const removeNdviLayer = () => {
                    map.remove(mediaLayer)
                }


                // end view operation
                setState({
                    ...state,
                    baseLayer: base_layer,
                    graphicsLayer,
                    sketchViewModel,
                    measureSketch,
                    geometryEngine,
                    map,
                    view,
                    google_base_layer,
                    swipe: swipe_bar,
                    leadingLayers: swipe_layer1,
                    trailingLayers: swipe_layer2,
                    mapServer,
                    lakes,
                    kmzLayer,
                    areas,
                    addNDVIToMap,
                    removeNdviLayer,
                    getStatistics,
                    loadCarService,
                    kanallar
                })

                getInit()
                return () => {
                    if (view) {
                        view.destroy();
                    }
                };
            }
        );
    }, []);

    return (
        <div className="App">
            <div className="arcgis">
                <div id="viewDiv" className="viewDiv" style={{ height: '100vh' }} />
            </div>

            {/* map buttons */}
            <div className="absolute top-[1rem] right-5 w-8 h-8 bg-white rounded " >
                <Tooltip placement="left" mouseEnterDelay={0.5} title="Ərazilər"><Button onClick={handleOpenAreas} icon={<MenuOutlined />} /></Tooltip>
            </div>

            <div className="absolute top-[4rem] right-5 w-8 h-8 bg-white rounded " >
                <Tooltip placement="left" mouseEnterDelay={0.5} title="Ərazi çək"><Button onClick={() => setMeasurePanel({ areaDraw: true })} icon={<EditOutlined />} ></Button></Tooltip>
            </div>

            <div className="absolute top-[7rem] right-5 w-8 h-8 bg-white rounded " >
                <UploadContent handleAddFile={handleAddFile} />
            </div>

            <div className="absolute top-[10rem] right-5 w-8 h-8 bg-white rounded " >
                <Tooltip placement="left" mouseEnterDelay={0.5} title="Ölçü aləti">
                    <Button onClick={handleOpenMeasure} icon={<FontAwesomeIcon icon={faRuler} className='' />} />
                </Tooltip>
            </div>
            <div className="absolute top-[13rem] right-5 w-8 h-8 bg-white rounded " >
                <Tooltip placement="left" mouseEnterDelay={0.5} title="Ölçü aləti">
                    <Button onClick={measurePanel.swipe ? handleCloseSwipe : handleOpenSwipe} icon={measurePanel.swipe ? <CloseCircleOutlined /> : <SwapOutlined />} />
                </Tooltip>
            </div>

            <div className="absolute top-[16rem] right-5 w-8 h-8 bg-white rounded " >
                <Tooltip placement="left" mouseEnterDelay={0.5} title={layerVisible ? "Bağla" : 'Aç'}>
                    <Button onClick={handleChangeLayerVisible} icon={layerVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} />
                </Tooltip>
            </div>

            <div className="absolute top-[19rem] right-5 w-8 h-8 bg-white rounded " >
                <Tooltip placement="left" mouseEnterDelay={0.5} title="Laylar" >
                    <Popover placement="leftTop" content={<LayerContent handleChange={handleChangeMapServer} mapServer={state?.mapServer} kanallar={state?.kanallar} lakes={state?.lakes} kmzLayer={state?.kmzLayer} />} trigger='click'>
                        <Button onClick={() => setPanel({})} icon={<ApartmentOutlined />} ></Button>
                    </Popover>
                </Tooltip>
            </div>

            <div className="absolute bottom-[2rem] right-5 w-8 h-8 bg-white rounded " >
                <Tooltip placement="left" mouseEnterDelay={0.5} title="Altlıq xəritələr" >
                    <Popover placement="rightBottom" content={<BaseMapContent changeBaseMaps={changeBaseMaps} selectedBaseMap={selectedBaseMap} />} trigger='click'>
                        <Button icon={<AppstoreOutlined />} ></Button>
                    </Popover>
                </Tooltip>
            </div>

            {/* end of map buttons */}

            <Footer selectedBaseMap={selectedBaseMap} scale={scale} />

            <Measurement
                measuredArea={measuredArea}
                handleDrawNewArea={handleDrawNewAreaMeasure}
                geometryEngine={state?.geometryEngine}
                visible={measurePanel?.measure}
                close={handleCloseMeasure}
            />

            <SwipePanel
                visible={measurePanel.swipe}
                handleChangeSwipeDate={handleChangeSwipeDate}
            />

            <Areas
                areas={state?.areas}
                visible={panels?.areas}
                handleClickExpandArea={handleClickExpandArea}
                close={() => setPanel({})}
                zommedArea={zommedArea}
            />

            <AreaDetailsPanel
                close={handleCloseAreaDetailsPanel}
                visible={panels?.areaDetails}
                selectedArea={selectedArea?.attributes}
                handleZoomArea={handleZoomArea}
                handleOpenVegetation={handleOpenVegetationForArea}
                handleAddNDViForArea={handleAddNDViForArea}
                handleDeleteArea={handleDeleteArea}
                handleUpdateArea={handleUpdateArea}
            />

            <SectorDetailsPanel
                close={handleCloseSectorDetailsPanel}
                visible={panels?.sectorDetails}
                selectedArea={selectedSector?.attributes}
                handleZoomArea={handleZoomArea}
                handleEditSector={handleEditSector}
                editing={editing}
                handleCloseSectorEditing={handleCloseSectorEditing}
                handleSubmitSectorEditing={handleSubmitSectorEditing}
                handleDeleteSector={handleDeleteSector}
                handleAddNDVi={handleAddNDVi}
                getStatistics={handleGetStatistics}
                stats={stats}
            />

            <DrawingPanel
                sketcBar={measurePanel?.areaDraw}
                drawedArea={drawedArea}
                handleSketchOp={handleSketchOp}
                saveGraphic={saveGraphic}
                handleCloseSketchBar={handleCloseSketchBar}
            />
            <AreaAddModal
                handleSaveArea={handleSaveArea}
                open={areaAddModal}
                setAreaAddModal={setAreaAddModal}
                handleCloseSketchBar={handleCloseSketchBar}
                saveLoading={saveLoading}
            />

            <StatisticModal
                open={statisticModal}
                onClose={handleCloseVegetation} stats={stats}
                statLoading={statLoading}
            />


        </div>
    );
}
import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select} from "antd";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import {useTranslation} from "react-i18next";
import agros from "../../../../../const/api";
import {notify} from "../../../../../redux/actions";
import {connect} from "react-redux";
import moment from "moment";
const { Option } = Select;

function CreateUser(props) {
    let {id , notify , editing , setEditing} = props
    const [optionsSelected, setOptionsSelected] = useState([]);
    const [users, setUsers]= useState([]);
    const { t } = useTranslation();
    const [form] = Form.useForm();


    const CancelEditing = () => {
        props.setvisibleEdit(false);
        props.setVisibleUser(true) ;
        form.resetFields();
        setEditing(undefined);
    }

    const handleChange = value => {
        setOptionsSelected(value);
    };
    const submitForm = (values) =>{
        if(!editing){
            agros.post('TechniqueAttachment', {
                ...values,
                dateUsing:moment(values.dateUsing).format('YYYY-MM-DD'),
                TechniqueId:id
            }).then((res) => {
                notify('' , true)
                CancelEditing()
                props.getData()
            }).catch((err) => {
                notify('' , false)
            })
        }
        else{
            agros.put(`TechniqueAttachment/${editing}`, {
                ...values,
                id:editing,
                dateUsing:moment(values.dateUsing).format('YYYY-MM-DD'),
                TechniqueId:id
            }).then((res) => {
                notify('' , true)
                CancelEditing()
                props.getData()
            }).catch((err) => {
                notify('' , false)
            })
        }

    }

    const getUsers = async  () => {
        await agros.get('hr').then(users => {
            setUsers(users.data)
        })
    }

    useEffect(() => {
        getUsers()
        if(editing){
            agros.get(`TechniqueAttachment/${editing}`).then(   (response) => {
                form.setFieldsValue({
                    workerId:response.data.workerId,
                    dateUsing: moment(response.data.dateUsing)
                })
            })
        }

    },[editing])

    return (
        <div>
            <div className='w-100'>
                <Form onFinish={submitForm} layout="vertical" form={form}>
                    <div className="commontask">
                        <Row gutter={[16, 16]}>
                            <Col lg={24} md={24}>
                                <Form.Item
                                    label={'Təhkim olunan'}
                                    name="workerId"
                                    validateTrigger="onChange"
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <Select
                                        showSearch
                                        notFoundContent={null}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        size={'small'}
                                        onChange={handleChange}
                                    >
                                        {users.map((user , index) => (
                                            <Option
                                                value={user.id} key={index}>{user.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={24} sm={24}>
                                <Form.Item
                                    label={'Təhkim etmə tarixi'}
                                    name="dateUsing"
                                    validateTrigger="onChange"
                                    rules={[noWhitespace(t("dateError"))]}
                                >
                                    <DatePicker placeholder={t("selectDate")} className="w-100" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div
                            className="modalButtons"
                            style={{
                                position: "absolute",
                                bottom: "20px",
                                right: "40px",
                            }}
                        >
                            <Button onClick={() =>{CancelEditing()}}>
                                {t("cancel")}
                            </Button>
                            <Button type="primary" className="ml-10" htmlType="submit">
                                {t("save")}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default connect(null, { notify })(CreateUser);

import React, { useEffect, useState } from 'react'
import agros from '../../../const/api'
import { AimOutlined, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Modal, Spin } from 'antd'
import SimpleImageSlider from "react-simple-image-slider";

import noData from '../../../assets/img/demo_map_images/no-image.jpg'
import AreaUpdateModal from './AreaUpdateModal';
import DiseaseModal from './DiseaseModal';

export default function AreaDetailsPanel({
  selectedArea,
  visible,
  close,
  handleZoomArea,
  handleAddNDViForArea,
  handleOpenVegetation,
  handleDeleteArea,
  handleChangeArea,
  handleUpdateArea
}) {
  const [parcel, setParcel] = useState()
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  const [openUpdateModal, setUpdateModal] = useState(false)
  const [detailSick, setDetailSick] = useState(false)
  const [diseaseModal,setDiseaseModal]=useState(false)
  const [cropName,setCropName]=useState()


  const getInit = async () => {
    setLoading(true)
    await agros.get(`Data/ParcelInfo?region_id=${selectedArea.region_id}&respondent_id=${selectedArea.respondent_id}&parcelCategory_id=${selectedArea.erazi_kategoriyasi}`)
      .then(res => {
        console.log(res.data)
        setParcel(res.data)

      })
      .catch(ex => {

      })
    let arr = []
    await agros.get("Parcel/ParcelImages/" + selectedArea.objectid)
      .then(res => {

        res.data.map(img => {
          arr.push({ url: img.imageUrl })
        })
      })
    setImages(arr)
    setLoading(false)
  }

  useEffect(() => {
    if (visible) {
      getInit()
    }

  }, [selectedArea?.objectid])
  return (
    <div className={`${visible ? 'scale-100' : 'scale-0'} absolute flex flex-col justify-center w-96  bg-white rounded-lg overflow-hidden top-5 right-20 shadow-lg  `}>
      <div className='flex top-0  w-full h-52 ' >
        {
          images?.length > 0 ?
            <SimpleImageSlider
              width={384}
              height={208}
              images={images}
              showBullets={images?.length > 1}
              showNavs={images?.length > 1}
            /> :
            <img src={noData} className='w-full h-full' />
        }
      </div>
      <div className='flex flex-col w-full h-full p-1'>
        <div className='flex  w-full justify-between p-1'>
          <span>Məlumatlar</span>
          <div className='flex justify-between w-14'>
            <AimOutlined onClick={() => handleZoomArea(selectedArea?.objectid)} size={20} className='cursor-pointer hover:text-teal-600 ' />
            <CloseCircleOutlined onClick={close} size={20} className='cursor-pointer text-red-500 hover:text-red-600' />
          </div>
        </div>
        <Spin spinning={loading} >
          <div>
            <div className='flex  w-full justify-between border-gray-200 border-b p-1'>
              <span className='text-teal-600'>Region adı:</span>
              <span >{parcel?.parcelRegion?.name}</span>
            </div>
            <div className='flex w-full justify-between border-gray-200 border-b p-1'>
              <span className='text-teal-600'>Ərazinin kateqoriyası:</span>
              <span className=''>{parcel?.parcelCategory?.name}</span>
            </div>
            {
              selectedArea?.erazi_kategoriyasi != 1 && <div className='flex w-full justify-between border-gray-200 border-b p-1'>
                <span className='text-teal-600'>Ağac sayı:</span>
                <span className='' >{selectedArea?.agac_sayi}</span>
              </div>
            }

            <div className='flex w-full justify-between border-gray-200 border-b p-1'>
              <span className='text-teal-600'>Ərazinin adı:</span>
              <span className='' >{selectedArea?.sektorun_adi}</span>
            </div>
            <div className='flex w-full justify-between border-gray-200 border-b p-1'>
              <span className='text-teal-600'>Cavabdeh şəxs:</span>
              <span>{parcel?.respondent?.name}</span>
            </div>
            <div className='flex w-full justify-between border-gray- border-b  p-1'>
              <span className='text-teal-600'>Şirkət:</span>
              <span className=''>{selectedArea?.sirket}</span>
            </div>
            {/* <div className='flex w-full justify-between border-gray- border-b  p-1'>
              <span className='text-teal-600'>Yaranma tarixi:</span>
              {selectedArea?.yaranma_tarixi && <span className=''>{new Date(parseInt(selectedArea?.yaranma_tarixi)).toLocaleDateString("en-US")}</span>}
            </div> */}

            {
              selectedArea?.mehsulun_sortu ?
                <div>
                  <div className='flex w-full justify-between border-gray- border-b  p-1'>
                    <span className='text-teal-600'>Məhsulun növü:</span>
                    <span className=''>{selectedArea?.mehsulun_sortu}</span>
                  </div>
                  <div className='flex w-full justify-between border-gray- border-b  p-1'>
                    <span className='text-teal-600'>Məhsul:</span>
                    <span className=''>{selectedArea?.mehsulun_novu}</span>
                  </div>
                  {/* <div className='flex w-full justify-between border-gray- border-b  p-1'>
                    <span className='text-teal-600'>Məhsulun kateqoriyası:</span>
                    <span className=''>{selectedArea?.mehsulun_kategoriyasi}</span>
                  </div> */}
                </div> :
                <div className='flex w-full justify-center border-gray- border-b  p-1'>
                  <span className='text-red-600'>Məhsul əkilməyib !</span>
                </div>
            }
            <div className='flex flex-col w-full p-1 justify-between border-gray-200 border-b ' >

              <div className='flex w-full justify-between mt-1'>
                <Button size='small' id="ndvi_button" onClick={handleAddNDViForArea} >NDVI</Button>
                <Button size='small' onClick={handleOpenVegetation}>Vegetasiya indeksi</Button>
                <Button size='small' onClick={() => setDiseaseModal(true)}>Xəstəlik Praqnozu</Button>
              </div>
            </div>
            <div className='flex w-full justify-between border-gray-200  p-1'>
              <Button onClick={handleDeleteArea} danger icon={<DeleteOutlined />} ></Button>
              <Button onClick={() => setUpdateModal(true)} >Dəyişiklik et</Button>
            </div>
          </div>
        </Spin>
      </div>
      <AreaUpdateModal open={openUpdateModal} areaInfo={selectedArea} setAreaAddModal={setUpdateModal} handleUpdateArea={handleUpdateArea} />
     {diseaseModal &&  <DiseaseModal open={diseaseModal} close={()=>setDiseaseModal(false)} cropName={selectedArea?.mehsulun_novu}/>}
     
    </div>
  )
}

import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Select,
  DatePicker,
  Row,
  Button,
  InputNumber,
  Form,
  Upload
} from "antd";
import agros from "../../../../../const/api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getOptions, notify } from "../../../../../redux/actions";
import { whiteSpace, noWhitespace } from "../../../../../utils/rules";
import dayjs from 'dayjs';
import moment from "moment";
const { RangePicker } = DatePicker;

const { Option } = Select;
const { TextArea } = Input;

const AddImage = (props) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [workers, setWorkers] = useState([]);
  const [fileList,setFileList] = useState([])
  const [file,setFile] = useState(null)
  const [image,setImage] = useState([])
  const [selectedPlan, setSelectedPlan] = useState({});
  const [previewImage,setPreviewImage] = useState('')
  const [previewVisible,setPreviewVisible] = useState(false)
  const [parcel, setParcel] = useState([]);
  const { getOptions, notify } = props;
  const options = props.options[props.lang];
console.log(props.plan);
  useEffect(() => {
    form.resetFields();
    setImage([])
    setFileList([])
    props.plans.map((d, index) => {
        console.log(d.id);
        if (d.id === props.plan) {
            console.log(d);
            setSelectedPlan(d)
    const at = d.parcels.map(elem => elem.parcelId)
        console.log(at);
        agros.get("Data/Parcels").then((res) => {
         
          setParcel(res.data.filter((elem) => at.includes(elem.id)))
        }
        )
    }
  })

  }, [props.plan]);
  useEffect(() => {
    form.resetFields();
    setImage([])
    setFileList([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trigger]);
  const onChangeUpload = ({ fileList: newFileList }) => {
    setFileList(newFileList)
    if (newFileList.length <= 0) {
       setFile(null)
    }
};

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  
};
const setUploadFile = ({ onSuccess, file }) => {
  console.log('slam');
  let form_data = new FormData();
  const filename = Math.random(1, 999999) + Date.now() + file.name;
  form_data.append("_file", file, filename);
  agros
      .post('UploadFile', form_data, {
          headers: {
              "content-type": "multipart/form-data",
          },
      })
      .then((res) => {
        setFile(res.data.url)
        setImage(prevImage => [...prevImage,res.data[0]])
          onSuccess(null, file);
      })
      .catch((err) => {
        console.log('err');
          this.props.notify("Icaze verilmir", true);
      });
};
  const saveTask = (values) => {
    const data = {
        workPlanId: props.plan,
        parcelId: values.ParcelId,
        ParcelImages: image,
        };
       
    agros
    .post("WorkPlan/WorkPlanImage", data)
    .then(() => {
      notify("", true);
      setImage([])
      form.resetFields();
      setFileList([])
      props.setVisibleAddImage(false);
    });
  };
    return (
      <>
      <Form onFinish={saveTask} form={form} layout="vertical">
        <div className="task mt-15 p-1">
          <Row gutter={[16, 16]}>
            <Col lg={6} md={8} sm={12} xs={24}>
              <Form.Item
                label={'Sector'}
                name={"ParcelId"}
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select>
                  {parcel.map((pc) => {
                    return (
                      <Option key={pc.id} value={pc.id}>
                        {pc.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
            <Form.Item
                validateTrigger="onChange"
                name={`photo`}
                required
              >
                <Upload
                  accept=".png, .jpg , .jpeg, .svg"
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChangeUpload}
                  onPreview={handlePreview}
                  customRequest={setUploadFile}
                  beforeUpload={null}
                  multiple={true}
                >
                  {fileList.length < 1 && "+ Yüklə"}
                </Upload>
              </Form.Item>
            </Col>
            
          </Row>
        
        </div>
        <div
          className="modalButtons"
          style={{ position: "absolute", bottom: "20px", right: "40px" }}
        >
          <Button onClick={() => {
            props.setVisibleAddImage(false)
            form.resetFields();
            setImage([])
            setFileList([])
          }}>
            {t("cancel")}
          </Button>

          <Button type="primary" className="ml-10" htmlType="submit">
            {t("save")}
          </Button>
        </div>
      </Form>

    </>
    );
 
};

const mapStateToProps = ({ options, lang }) => {
  return { options, lang };
};

export default connect(mapStateToProps, { getOptions, notify })(AddImage);

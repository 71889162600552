import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {Row, Col, Button, Tooltip, Table, Modal, Popconfirm} from "antd";
import {UnorderedListOutlined, FileTextOutlined, DeleteFilled, CheckCircleFilled} from "@ant-design/icons";
import agros from "../../../../const/api";
import View from "./Modals/View";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import {connect} from "react-redux";
import { notify} from "../../../../redux/actions";

function ReviewHistoryDemands({notify}) {
  const { t } = useTranslation();
  const [demands, setDemands] = useState([]);
  const [selectedDemand, setSelectedDemand] = useState(0);
  let [trigger, setTrigger] = useState(0);
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "taskName", value: t("name"), con: false },
    { key: "taskNumber", value: t("demmandNo"), con: true },
    { key: "checkStatus", value: "", con: false },
    { key: "index", value: "", con: false },
  ];
  useEffect(() => {
    agros.get("TechnicalInspectionTask").then((res) => {
      setDemands(
        res.data.map((r, index) => {
          return {
            ...r,
            key: index + 1,
            index,
            objectCount: r.taskDemands.length,
            tableIndex: index + 1,
          };
        })
      );
    });
  }, [trigger, t]);

  const [visibleViewDemand, setVisibleViewDemand] = useState(false);

  const renderStatus = (val) => {
    switch (val) {
      case 1:
        return { color: "#E49F33", word: t("onWait") };
      case 2:
        return { color: "#7FBFA4", word: t("approved") };
      case 3:
        return { color: "#D24C41", word: t("canceled") };
      default:
        break;
    }
  };

  // table data columns
  const columns = [
    {
      title: "#",
      key: 1,
      dataIndex: "tableIndex",
      width: 60,
    },
    {
      title: t("name"),
      key: 2,
      dataIndex: "taskName",
      render(a){
        return(
            <div className={'line-clamp'}>
              {a}
            </div>
        )
      }
    },
    {
      title: t("demmandNo"),
      key: 3,
      dataIndex: "taskNumber",
    },
    {
      title: 'Texnika kateqoriyası',
      key: 3,
      dataIndex: "techniqueCategory",
      render(a){
        return(
            <div>
              {a.name}
            </div>
        )
      }
    },
    {
      title: 'Texnika',
      key: 4,
      dataIndex: "technique",
      render(a){
        return(
            <div>
              {a.name}
            </div>
        )
      }
    },
    {
      title: 'Texniki baxış növü',
      key: 4,
      dataIndex: "type",
      render(a){
        return(
            <div>
              {a === 1 ? "Daxili" : "Servis"}
            </div>
        )
      }
    },
    {
      title: 'Tələb edən',
      key: 4,
      dataIndex: "requester",
    },
    {
      title: 'Təxmini məbləğ',
      key: 4,
      dataIndex: "approximateAmount",
      render(a){
        return(
            <div>
              {a} AZN
            </div>
        )
      }
    },
    {
      title: "Tələb obyektləri",
      key: 5,
      dataIndex: "objectCount",
      render: (a) => <span>{a} obyekt</span>,
    },
    {
      title: t("status"),
      key: 6,
      dataIndex: "status",
      render: (i) => {
        let options = renderStatus(i);
        return (
          <div className="flex statusTd">
            <p>{t("status")}</p>
            <span
              style={{
                fontSize:'8px',
                backgroundColor: options?.color,
              }}
              className="statusBox"
            >
              {options?.word}
            </span>
          </div>
        );
      },
    },
    {
      title: "",
      key: 7,
      dataIndex: "index",
      width: 20,
      render: (i) => {
        return (
          <div className="flex flex-end">
              {demands[i].status === 1 &&
                <Popconfirm
                    placement="bottomRight"
                    title={'Təsdiqləmək istədiyinizə əminsinizmi ?'}
                    onConfirm={async () =>
                        await agros.put(`TechnicalInspectionTask/${demands[i].id}`, {
                          Id: demands[i].id,
                          Status: 2
                        }).then((res) => {
                          setTrigger(++trigger)
                          notify("", true);
                        })
                    }
                    okText={t("yes")}
                    cancelText={t("no")}
                >
                  <Tooltip className="ml-5" title={'Təsdiqlə'}>
                    <Button className="border-none" type="text" shape="circle">
                      <CheckCircleFilled />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              }
              <Tooltip className="ml-5" title={'Ehtiyyat hissələri'}>
                <Button
                  onClick={() => viewDemand(i)}
                  className="border-none"
                  type="text"
                  shape="circle"
                >
                  <FileTextOutlined />
                </Button>
              </Tooltip>
          </div>
        );
      },
    },
  ];

  const viewDemand = (index) => {
    setSelectedDemand(index);
    setVisibleViewDemand(true);
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="border page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <UnorderedListOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Texniki baxış tələbləri</span>
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <div>
            <Table
              size="small"
              className="bg-white w-100"
              columns={columns}
              dataSource={convertColumns(demands, cols)}
              pagination={{
                pageSizeOptions: ["10", "15", "20", "25", "30"],
                showSizeChanger: true,
                current_page: 1,
              }}
            />
          </div>
        </Col>
      </Row>

      <Modal
        title={
          <div className="status">
            <div className="flex flex-align-center flex-between">
              {demands[selectedDemand] ? (
                <>
                  <h3>
                    {demands[selectedDemand].taskName} / №
                    {demands[selectedDemand].taskNumber}
                  </h3>
                </>
              ) : null}
            </div>
          </div>
        }
        centered
        className="addTaskModal demandModal"
        visible={visibleViewDemand}
        onOk={() => setVisibleViewDemand(false)}
        onCancel={() => setVisibleViewDemand(false)}
        footer={[]}
      >
        <View demand={demands[selectedDemand]} />
      </Modal>

      {/*addnewtask or edit task modal*/}

    </div>
  );
}



export default connect(null, {  notify })(ReviewHistoryDemands);


import React from 'react'
import '../../assets/css/notfound.css'
const Notfound = () => {
console.log('slaam');
   return(
       <section className="page_404">
           <div className="container">
               <div className="row">
                   <div className="col-sm-12 ">
                       <div className="col-sm-10 col-sm-offset-1  text-center">
                           <div className="four_zero_four_bg">
                               <h1 className="text-center ">404</h1>
                           </div>
                           <div className="contant_box_404">
                               <a href="" className="link_404">Ana səhifə</a>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </section>
   )
}


export default Notfound
import React from 'react'
import { Button, Result } from 'antd';
export default function Integration() {
  return (
    <div className='flex flex-align-center justify-center ' style={{height:"100%"}}>
      <Result
    status="500"
    subTitle="Bu səhifə hələ hazırlanır"
  />
    </div>
  )
}

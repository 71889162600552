import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Select,
  DatePicker,
  Row,
  Button,
  InputNumber,
  Form,
} from "antd";
import agros from "../../../../../const/api";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getOptions, notify } from "../../../../../redux/actions";
import { whiteSpace, noWhitespace } from "../../../../../utils/rules";
import dayjs from 'dayjs';
import moment from "moment";
const { RangePicker } = DatePicker;

const { Option } = Select;
const { TextArea } = Input;

const NewTask = (props) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [date, setDate] = useState(undefined);
  const [workers, setWorkers] = useState([]);
  const selectedPlan = props?.plan?.find((p) => p.id === props.id);
  const [tasks, setTasks] = useState([
    { id: 1, medicines: [], workers: [], reserves: [], equipments: [], },
  ]);

  const [parcel, setParcel] = useState([]);
  const [sector, setSector] = useState([]);
  const [period, setPeriod] = useState([]);
  const { getOptions, notify } = props;
  const options = props.options[props.lang];

  const handleMedicineKeyChange = (e, index,bindex, key) => {
    if (key === "fertilizerKindId") {
        form.resetFields([[
          "WorkPlanTasks",
          index,
          "WorkPlanTaskFertilizers",
          bindex,
          "mainIngredientId",
        ]]);
        form.resetFields([[
          "WorkPlanTasks",
          index,
          "WorkPlanTaskFertilizers",
          bindex,
          "productId",
        ]])
      
    
    }
    if (key === "mainIngredientId") {
        form.resetFields([[
          "WorkPlanTasks",
          index,
          "WorkPlanTaskFertilizers",
          bindex,
          "productId",
        ]])
    }
    const all = [...tasks];
    all[index]["medicines"][bindex][key] = e;
    setTasks(all);
  };

  const handleReserveKeyChange = (e, index, dindex ,key) => {

    const all = [...tasks];
    all[index]["reserves"][dindex][key] = e;
   setTasks(all);
  };

  useEffect(() => {
    form.resetFields();
    getOptions(
      [
        "mainIngredients",
        "fertilizerKinds",
        "fertilizers",
        "todos",
        "positions",

        "cropCategories",
        "crops",
        "cropSorts",

        "tools",
        "reserves",
      ],
      props.options,
      i18n.language

    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  useEffect(() => {
    props.plan.map((d, index) => {
      //console.log(d.startDate);
      if (d.id === props.id) {
        console.log(d.parcels);
        const at = d.parcels.map(elem => elem.parcelId)
        console.log(at);
        agros.get("Data/Parcels").then((res) => {
         
          setParcel(res.data.filter((elem) => at.includes(elem.id)))
        }
        )
        if (d.startDate && d.endDate) {
          const part = d.startDate.split('-');
          const part1 = d.endDate.split('-');
          const newStartDate = part[1] + '-' + part[0] + '-' + part[2];
          const newEndDate = part1[1] + '-' + part1[0] + '-' + part1[2];
          const nextDay = new Date(newEndDate);
          const startDate = new Date(newStartDate);
          nextDay.setDate(nextDay.getDate() + 1);
          const date2 = [startDate, nextDay]
          setDate(date2)
        }
        if (!d.endDate && d.startDate) {
          const part = d.startDate.split('-');
          const newStartDate = part[1] + '-' + part[0] + '-' + part[2];
          const nextDay = new Date(d.startDate);
          nextDay.setDate(nextDay.getDate());
          const date2 = [newStartDate, nextDay]
          setDate(date2)
        }
      }
    });
    agros.get(`Data/AnnualWorkPlans`).then((res) => {
      res.data.map((d, index) => {
        if (d.id === selectedPlan.annualWorkPlan.id) {
          setPeriod(d.annualWorkPlanPeriod)
        }
      });
    });

  }, [props.plan]);
  const addMedicine = (index) => {
    const all = [...tasks];
    const id = all[index].medicines.length
      ? all[index].medicines[all[index].medicines.length - 1].id + 1
      : 0;
    all[index].medicines.push({
      id,
      mainIngredientId: undefined,
    });
    tasks.map((p, index) => {
      //p.medicines.map((m, bindex) => {
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "mainIngredientId",
      ]]);
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "productId",
      ]])
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "fertilizerKindId",
      ]])
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "Amount",
      ]])
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "Quantity",
      ]])
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskFertilizers",
        p.medicines.length - 1,
        "Sum",
      ]])
    }
    )
    //})

    setTasks(all);
  };

  const deleteMedicine = (index, bindex) => {
    const all = [...tasks];
    all[index].medicines.splice(bindex, 1);
    setTasks(all);
  };

  const addReserve = (index) => {
    const all = [...tasks];
    const id = all[index].reserves.length
      ? all[index].reserves[all[index].reserves.length - 1].id + 1
      : 0;
    all[index].reserves.push({ id });
    tasks.map((p, index) => {
      // p.reserves.map((m, bindex) => {
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskReserves",
        p.reserves.length - 1,
        "productId",
      ]]);
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskReserves",
        p.reserves.length - 1,
        "quantity",
      ]])
    }
    )
    // })
    setTasks(all);
  };

  const deleteReserve = (index, bindex) => {
    // const all = [...reserves];
    // all.splice(index, 1);
    // setReserves(all);
    const all = [...tasks];
    all[index].reserves.splice(bindex, 1);
    setTasks(all);
  };

  const addEquipent = (index) => {
    // const all = [...equipments];
    // const id = equipments.length ? equipments[equipments.length - 1].id + 1 : 0;
    // all.push({ id });
    // setEquipments(all);
    const all = [...tasks];
    const id = all[index].equipments.length
      ? all[index].equipments[all[index].equipments.length - 1].id + 1
      : 0;
    all[index].equipments.push({ id });
    tasks.map((p, index) => {
      //p.equipments.map((m, bindex) => {
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskEquipments",
        p.equipments.length - 1,
        "equipmentCategory",
      ]]);
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskEquipments",
        p.equipments.length - 1,
        "approximatedFuel",
      ]]);
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskEquipments",
        p.equipments.length - 1,
        "PlannedFuel",
      ]]);
      form.resetFields([[
        "WorkPlanTasks",
        index,
        "WorkPlanTaskEquipments",
        p.equipments.length - 1,
        "PlateNumer",
      ]]);
    }
    )
    //})
    setTasks(all);
  };

  const deleteEquipment = (index, bindex) => {
    const all = [...tasks];
    all[index].equipments.splice(bindex, 1);
    setTasks(all);
  };
  const onChange = (date, dateString) => {
    console.log(date);
    if (date) {
      const nextDay = new Date(date[1]);
      nextDay.setDate(nextDay.getDate() + 1);
      const date2 = [...date, nextDay]
    }
    
  }

  const disabledDate = (current) => {

    return !date || current < new Date(date?.[0]) || current > date[1];
  };
  const addTask = (index) => {
    const all = [...tasks];
    const id = tasks.length ? tasks[tasks.length - 1].id + 1 : 0;
    all.push({
      id,
      medicines: [],
      workers: [],
      reserves: [],
      equipments: [],
    });
    form.resetFields([["WorkPlanTasks", index + 1, "startdate"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "parcelId"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "parcelSectorId"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "enddate"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "Description"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "position"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "respondentId"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "ManWorkerCount"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "AnnualPlanPeriodId"]]);
    form.resetFields([["WorkPlanTasks", index + 1, "ToDoId"]]);
    setTasks(all);
  };
  const deleteTask = (index) => {
    const all = [...tasks];
    all.splice(index, 1);
    setTasks(all);
  };
  const handlePositionChange = (e) => {
    let values = form.getFieldsValue();
    values.worker = undefined;
    form.setFieldsValue({ ...values });
    agros.get(`data/workers/${e}`).then((res) => {
      setWorkers(res.data);
    });
  };

  const saveTask = (values) => {
    const data = values.WorkPlanTasks.map((t) => {
      return {
        ...t,
        startdate: t.startdate[0],
        enddate: t.startdate[1],
        workPlanId: selectedPlan.id,
        TaskParcels: t.parcelId.map((b) => {
          return {
            ParcelId: b,
          };
        }),
      };
    });

    agros
      .post("workplan/createtask", data)
      .then(() => {
        notify("", true);
        props.triggerFetch();
        props.setVisibleAddNewTask(false);
      });
  };

  const handleAmountChange = (e,index, bindex) => {
    const values = form.getFieldsValue();
    let val = +e * 500;
    values.WorkPlanTasks[index].WorkPlanTaskFertilizers[bindex].Quantity = val;
    const all = [...tasks];
    all[index].medicines[bindex]["quantity"] = val;
    setTasks(all);
    form.setFieldsValue({ ...values });
  };

  const handleParcelChange = (e,index) => {
    const all = [...tasks];
    all[index].parcelId = e;
    setTasks(all);

  };
  const filterOption = (input, option) => (option?.children.toLowerCase().includes(input.toLowerCase()))
   // console.log(p);
    return (
      <>
      {tasks.map((p, index) => (
      <Form onFinish={saveTask} form={form} layout="vertical">
        <div className="task border mt-15 p-1">
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <div className="w-100  flex-align-center flex flex-between">
                <h3>
                  {t("task")} {index + 1}
                </h3>
                <div className="flex">
                  {tasks.length > 1 ? (
                    <Button
                      className="mr5-5 btn-danger"
                      onClick={() => deleteTask(index)}
                    >
                      {t("delete")}
                    </Button>
                  ) : null}
                  {index === tasks.length - 1 ? (
                    <Button type="primary" onClick={() => addTask(index)}>
                      {t("addtask")}
                    </Button>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <Form.Item
                label={'Dövr adı'}
                name={["WorkPlanTasks", index, "AnnualPlanPeriodId"]}
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select>
                  {period.map((pc) => {
                    return (
                      <Option key={pc.id} value={pc.id}>
                        {pc.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <Form.Item
                label={'Ediləcək işin növü'}
                name={["WorkPlanTasks", index, "ToDoId"]}
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select>
                  {options.todos.map((pc) => {
                    return (
                      <Option key={pc.id} value={pc.id}>
                        {pc.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <Form.Item
                label={t("sector")}
                name={["WorkPlanTasks", index, "parcelId"]}
                validateTrigger="onChange"
                rules={[noWhitespace(t("selectCrop"))]}
              >
                <Select mode="multiple" onChange={(e) => handleParcelChange(e,index)} >
                  {
                    parcel.map((w, index) => {
                      return (
                        <Option key={index} value={w.id}>
                          {w.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <Form.Item
                label={t("positions")}
                name={["WorkPlanTasks", index, "position"]}
                validateTrigger="onChange"
                rules={[noWhitespace(t("positionMustSelectError"))]}
              >
                <Select onChange={(e) => handlePositionChange(e)}>
                  {options.positions.map((w, index) => {
                    return (
                      <Option key={index} value={w.id}>
                        {w.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <Form.Item
                label={t("Cavabdeh şəxs")}
                name={["WorkPlanTasks", index, "respondentId"]}
                validateTrigger="onChange"
                rules={[noWhitespace(t("personMustSelect"))]}
              >
                <Select>
                  {workers.map((w, index) => {
                    return (
                      <Option key={index} value={w.id}>
                        {w.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <Form.Item
                label={t("date")}
                name={["WorkPlanTasks", index, "startdate"]}
                validateTrigger="onChange"
                rules={[noWhitespace(t("dateError"))]}
              >
                <RangePicker disabledDate={disabledDate} onChange={onChange} placeholder={['Başlama Tarixi', 'Bitmə Tarixi']}
                  format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <Form.Item
                label={t("manWorkerNumber")}
                name={["WorkPlanTasks", index, "ManWorkerCount"]}
                validateTrigger="onChange"
                rules={[noWhitespace(t("numberMustAdd"))]}
              >
                <InputNumber className="w-100" />
              </Form.Item>
            </Col>
            <Col lg={6} md={8} sm={12} xs={24}>
              <Form.Item
                label={t("womanWorkerNumber")}
                name={["WorkPlanTasks", index, "WomanWorkerCount"]}
                validateTrigger="onChange"
                rules={[noWhitespace(t("numberMustAdd"))]}
              >
                <InputNumber className="w-100" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                 name={["WorkPlanTasks", index, "Description"]}
                validateTrigger="onChange"
                rules={[whiteSpace(t("inputError"))]}
              >
                <TextArea placeholder={t("additionalNote")} allowClear rows={4} />
              </Form.Item>
            </Col>
          </Row>

          {p.medicines.map((m, bindex) => {
            return (
              <Col span={24} key={bindex}>
                <div className="medicine1 p-1 mt-5 border">
                  <Row gutter={[8, 16]}>
                    <Col xs={24}>
                      <div className="w-100  flex-align-center flex flex-between">
                        <h3>
                          {t("medicine")} {bindex + 1}
                        </h3>
                        <div className="flex">
                          <Button
                            className="mr5-5 btn-danger"
                            onClick={() => deleteMedicine(index,bindex)}
                          >
                            {t("delete")}
                          </Button>
                          {bindex === p.medicines.length - 1 ? (
                            <Button
                              onClick={() => addMedicine(index)}
                              className="w-100 flex all-center"
                              type="primary"
                            >
                              Dərman əlavə et
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label={t("typeOf")}
                        name={[
                          "WorkPlanTasks",
                          index,
                          "WorkPlanTaskFertilizers",
                          bindex,
                          "fertilizerKindId",
                        ]}
                        validateTrigger="onChange"
                        rules={[noWhitespace(t("inputError"))]}
                      >
                        <Select
                          onChange={(e) => {
                            handleMedicineKeyChange(
                              e,
                              index,
                              bindex,
                              "fertilizerKindId"
                            );
                          }}
                        >
                          {options.fertilizerKinds.map((w, index) => {
                            return (
                              <Option key={index} value={w.id}>
                                {w.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label={t("activeSubstance")}
                        name={[
                          "WorkPlanTasks",
                          index,
                          "WorkPlanTaskFertilizers",
                          bindex,
                          "mainIngredientId",
                        ]}
                        validateTrigger="onChange"
                        rules={[noWhitespace(t("inputError"))]}
                      >
                        <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                          onChange={(e) => {
                            handleMedicineKeyChange(
                              e,
                              index,
                              bindex,
                              "mainIngredientId"
                            );
                          }}
                        >
                          {options.mainIngredients.map((w, index) => {
                            return (
                              <Option key={index} value={w.id}>
                                {w.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label={t("name")}
                        name={[
                          "WorkPlanTasks",
                          index,
                          "WorkPlanTaskFertilizers",
                          bindex,
                          "productId",
                        ]}
                        validateTrigger="onChange"
                        rules={[noWhitespace(t("inputError"))]}
                      >
                        <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                          onChange={(e) => {
                            handleMedicineKeyChange(e,index, bindex, "fertilizerId");
                          }}
                        >
                          {m.mainIngredientId &&
                            options.fertilizers
                              .filter(
                                (f) =>
                                  f.mainIngredientId === m.mainIngredientId &&
                                  f.fertilizerKindId === m.fertilizerKindId
                              )
                              .map((w, index) => {
                                return (
                                  <Option key={index} value={w.id}>
                                    {w.name}
                                  </Option>
                                );
                              })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label={t("quantity")}
                        name={[
                          "WorkPlanTasks",
                          index,
                          "WorkPlanTaskFertilizers",
                          bindex,
                          "Quantity",
                        ]}
                        validateTrigger="onChange"
                        rules={[noWhitespace(t("inputError"))]}
                      >
                        <div className="form-lang">
                          <InputNumber
                            onChange={(e) => handleAmountChange(e,index, bindex)}
                            className="w-100"
                          />
                          <span className="input-lang">
                            {
                              options.fertilizers.find(
                                (f) => f.id === m.fertilizerId
                              )?.measurementUnit
                            }{" "}
                            / m<sup>2</sup>
                          </span>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label={t("finalAmount")}
                        name={[
                          "WorkPlanTasks",
                          index,
                          "WorkPlanTaskFertilizers",
                          bindex,
                          "Amount",
                        ]}
                      >
                        <div className="form-lang">
                          <InputNumber className="w-100" disabled={true} />
                          <span className="input-lang">
                            {
                              options.fertilizers.find(
                                (f) => f.id === m.fertilizerId
                              )?.measurementUnit
                            }
                          </span>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          })}
          {/*reserves*/}
          {p.reserves.map((r, dindex) => {
            return (
              <Col xs={24} key={dindex}>
                <div className="border p-1 mt-5">
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <div className="w-100  flex-align-center flex flex-between">
                        <h3>
                          {t("reserv")} {dindex + 1}
                        </h3>
                        <div className="flex">
                          <Button
                            onClick={() => deleteReserve(index,dindex)}
                            className="mr5-5 btn-danger"
                          >
                            {t("delete")}
                          </Button>
                          {dindex === p.reserves.length - 1 ? (
                            <Button
                              onClick={() => addReserve(index)}
                              className="w-100 flex all-center"
                              type="primary"
                            >
                              Ehtiyat əlavə et
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    </Col>

                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label={'Ehtiyat növü'}
                        name={[  "WorkPlanTasks",index,"WorkPlanTaskReserves", dindex, "productId"]}
                        validateTrigger="onChange"
                        rules={[noWhitespace(t("inputError"))]}
                      >
                        <Select
                          onChange={(e) =>
                            handleReserveKeyChange(e, index,dindex, "reserveId")
                          }
                        >
                          {options.reserves.map((f, findex) => {
                            return (
                              <Option key={findex} value={f.id}>
                                {f.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label={t("quantity")}
                        name={[  "WorkPlanTasks",
                        index,"WorkPlanTaskReserves", dindex, "quantity"]}
                        validateTrigger="onChange"
                        rules={[noWhitespace(t("inputError"))]}
                      >
                        <div className="form-lang">
                          <InputNumber className="w-100" />
                          <span className="input-lang">
                            {
                              options.reserves.find((f) => f.id === r.reserveId)
                                ?.measurementUnit
                            }
                          </span>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          })}
          {/*reserves*/}
          {/*equipments*/}
          {p.equipments.map((e, eindex) => {
            return (
              <Col xs={24} key={eindex}>
                <div className="border p-1">
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <div className="w-100  flex-align-center flex flex-between">
                        <h3>Texnika {eindex + 1}</h3>
                        <div className="flex">
                          <Button
                            onClick={() => deleteEquipment(index,eindex)}
                            className="mr5-5 btn-danger"
                          >
                            {t("delete")}
                          </Button>
                          {p.equipments.length &&
                            eindex === p.equipments.length - 1 ? (
                            <Button
                              onClick={() =>
                                addEquipent(index)
                              }
                              type="primary"
                            >
                              Texnika əlavə et
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    </Col>

                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Texnikanın kateqoriyası"
                        name={[
                          "WorkPlanTasks",
                          index,
                          "WorkPlanTaskEquipments",
                          eindex,
                          "equipmentCategory",
                        ]}
                        validateTrigger="onChange"
                        rules={[
                          noWhitespace(t("inputError")),
                        ]}
                      >
                        <Select>
                          {options.tools.map((f, findex) => {
                            return (
                              <Option
                                key={findex}
                                value={f.id}
                              >
                                {f.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Texnikanın markası"
                        name={[
                          "WorkPlanTasks",
                          index,
                          "WorkPlanTaskEquipments",
                          eindex,
                          "equipmentBrand",
                        ]}
                        validateTrigger="onChange"
                        rules={[
                          noWhitespace(t("inputError")),
                        ]}
                      >
                        <Select>
                          {options.tools.map((f, findex) => {
                            return (
                              <Option
                                key={findex}
                                value={f.id}
                              >
                                {f.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Texnikanın növü"
                        name={[
                          "WorkPlanTasks",
                          index,
                          "WorkPlanTaskEquipments",
                          eindex,
                          "equipmentType",
                        ]}
                        validateTrigger="onChange"
                        rules={[
                          noWhitespace(t("inputError")),
                        ]}
                      >
                        <Select>
                          {options.tools.map((f, findex) => {
                            return (
                              <Option
                                key={findex}
                                value={f.id}
                              >
                                {f.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Texnikanın modeli"
                        name={[
                          "WorkPlanTasks",
                          index,
                          "WorkPlanTaskEquipments",
                          eindex,
                          "equipmentModel",
                        ]}
                        validateTrigger="onChange"
                        rules={[
                          noWhitespace(t("inputError")),
                        ]}
                      >
                        <Select>
                          {options.tools.map((f, findex) => {
                            return (
                              <Option
                                key={findex}
                                value={f.id}
                              >
                                {f.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={8} sm={12} xs={24}>
                      <div className="form-lang">
                        <Form.Item
                          label={'Təxmini yanacaq'}
                          name={[
                            "WorkPlanTasks",
                            index,
                            "WorkPlanTaskEquipments",
                            eindex,
                            "approximatedFuel",
                          ]}
                          validateTrigger="onChange"
                          rules={[
                            noWhitespace(t("inputError")),
                          ]}
                        >
                          <InputNumber className="w-100" />
                        </Form.Item>
                        <div className="input-lang btm">litr</div>
                      </div>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <div className="form-lang">
                        <Form.Item
                          className="mb-5"
                          label={'Planda verilən yanacaq'}
                          name={[
                            "WorkPlanTasks",
                            index,
                            "WorkPlanTaskEquipments",
                            eindex,
                            "PlannedFuel",
                          ]}
                          validateTrigger="onChange"
                          rules={[
                            noWhitespace(t("inputError")),
                          ]}
                        >
                          <InputNumber className="w-100" />
                        </Form.Item>
                        <div className="input-lang btm">litr</div>
                      </div>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <div className="form-lang">
                        <Form.Item
                          className="mb-5"
                          label={'Dövlət nişanı'}
                          name={[
                            "WorkPlanTasks",
                            index,
                            "WorkPlanTaskEquipments",
                            eindex,
                            "PlateNumer",
                          ]}
                          validateTrigger="onChange"
                          rules={[
                            noWhitespace(t("inputError")),
                          ]}
                        >
                          <Input size={'large'} className="w-100" />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          })}
          {/*equipments*/}
          <Col xs={24}>
            <Row gutter={8}>
              {!p.medicines.length ? (
                <Col md={6} sm={12} xs={24}>
                  <div>
                    <Button
                      onClick={() => addMedicine(index)}
                      className="w-100 flex all-center mt-5"
                      type="primary"
                    >
                      {t("addMedicine")}
                    </Button>
                  </div>
                </Col>
              ) : null}
              {!p.reserves.length ? (
                <Col md={6} sm={12} xs={24}>
                  <div>
                    <Button
                      onClick={() => addReserve(index)}
                      className="w-100 flex all-center mt-5"
                      type="primary"
                    >
                      {t("addReserve")}
                    </Button>
                  </div>
                </Col>
              ) : null}
              {!p.equipments.length ? (
                <Col md={6} sm={12} xs={24}>
                  <div>
                    <Button
                      onClick={() => addEquipent(index)}
                      className="w-100 flex all-center mt-5"
                      type="primary"
                    >
                      Texnika əlavə et
                    </Button>
                  </div>
                </Col>
              ) : null}
            </Row>
          </Col>
        </div>
        <div
          className="modalButtons"
          style={{ position: "absolute", bottom: "20px", right: "40px" }}
        >
          <Button onClick={() => {
            props.setVisibleAddNewTask(false)
            form.resetFields();
          }}>
            {t("cancel")}
          </Button>

          <Button type="primary" className="ml-10" htmlType="submit">
            {t("save")}
          </Button>
        </div>
      </Form>
    ))}
    </>
    );
 
};

const mapStateToProps = ({ options, lang }) => {
  return { options, lang };
};

export default connect(mapStateToProps, { getOptions, notify })(NewTask);

import React, { PureComponent } from 'react';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ReferenceArea,
    ResponsiveContainer,
    Legend
} from 'recharts';
import {
    Modal
} from "antd";
import moment from 'moment';
import agros from '../../../const/api';
import history from '../../../const/history';
import { withTranslation } from "react-i18next";

class DiseaseChart extends PureComponent {
    hash = history.location
    urlParams = new URLSearchParams(window.location.search);

    state = {
        plantmentdata: {},
        weatherLinkChart: [],
        riskData: null,
        parametersDiseases: [],
        initialData1: [],
        initialData2: [],
        parametrs: [],
        visibleParameter: [],
        maxParametr1: 0,
        maxParametr2: 0,
        minParametr1: 0,
        minParametr2: 0,
        chooseName: '',
        interval: 3
    };
    initialState = {
        data: this.state.initialData1,
        left: 'dataMin',
        right: 'dataMax',
        refAreaLeft: '',
        refAreaRight: '',
        top: 12,
        bottom: -1,
        top2: 22,
        bottom2: -1,
        animation: true,
    };
    constructor(props) {
        super(props);
        this.state = this.initialState;
    }
    getTableData = async () => {
        let data = [];
        let onlyName = [];
        await agros.get(`PlantMet?_query=${this.props.cropName}`).then((res) => {
          this.setState({ plantmentdata: res?.data?.plantment });
          this.setState({ weatherLinkChart: res?.data?.weatherLinkChart });
          onlyName = res?.data?.plantment.existing_diseases.map((item) => {
            return item.name
          })
          Promise.all([
            agros.get(`PlantMet/1`),
            agros.get(`PlantMet/2`)
          ]).then((res) => {
            data = res[0]?.data[0]?.ipm
            data = [...data, ...res[1]?.data[0]?.ipm]
            const uniqueArray = data.filter((item, index) => {
              return data.findIndex(obj => obj.name === item.name) === index;
            });
            this.setState({ riskData: uniqueArray?.filter((item) => onlyName.includes(item.name)) });
            this.chooseDisaese(uniqueArray?.filter((item) => onlyName.includes(item.name))[0].name)
            this.setState({ chooseName: uniqueArray?.filter((item) => onlyName.includes(item.name))[0].name });
    
          });
        });
      
      };
    zoom() {
        let { refAreaLeft, refAreaRight } = this.state;

        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            this.setState(() => ({
                refAreaLeft: '',
                refAreaRight: '',
            }));
            return;
        }

        if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

        const [bottom, top] = this.getAxisYDomain(refAreaLeft, refAreaRight, this.state.parametrs[0], 1);
        const [bottom2, top2] = this.getAxisYDomain(refAreaLeft, refAreaRight, this.state.parametrs[1], 10);

        const filteredData = this.state.initialData1.filter(item => {
            return new Date(item.name) >= new Date(refAreaLeft) && new Date(item.name) <= new Date(refAreaRight);
        });
        this.setState(() => ({
            refAreaLeft: '',
            refAreaRight: '',
            initialData1: filteredData,
            left: refAreaLeft,
            right: refAreaRight,
            bottom,
            top,
            bottom2,
            top2,
            interval: 0
        }));
    }

    zoomOut() {
        this.setState(() => ({
            initialData1: this.state.initialData2,
            left: 'dataMin',
            right: 'dataMax',
            refAreaLeft: '',
            refAreaRight: '',
            top: this.state.maxParametr1,
            bottom: this.state.minParametr1,
            top2: this.state.maxParametr2,
            bottom2: this.state.minParametr2,
            animation: true,
            interval: 3
        }));
    }
    getAxisYDomain = (from, to, ref, offset) => {
        const fromDate = new Date(from);
        const toDate = new Date(to);
        const refData = this.state.initialData1.filter(item => {
            const itemDate = new Date(item.name);
            return itemDate >= fromDate && itemDate <= toDate;
        });
        let [bottom, top] = [refData[0][ref], refData[0][ref]];
        refData.forEach((d) => {
            if (Number(d[ref]) > Number(top)) {
              top = d[ref]
            }
            if (Number(d[ref]) < Number(bottom)) {
              bottom = d[ref];
            } 
          });

        return [(bottom | 0) - offset, (top | 0) + offset];
    };
    chooseDisaese = (name) => {
        let disease = []
        this.state.plantmentdata.existing_diseases.map((item) => {
            if (item.name === name) {
                disease = item.parameters_check_diseases.map((item) => { return item.name })
            }
        })
        this.setState({ parametrs: disease });
        this.setState({ visibleParameter: disease });
        let max1 = 0;
        let min1 = 0;
        let max2 = 0;
        let min2 = 0;

        this.setState({
            initialData1: this.state.weatherLinkChart.map((item) => {
                const diseaseData = {};
                disease.forEach(diseaseItem => {
                    const value = item[diseaseItem];
                    if (value !== undefined) { // Değer tanımlıysa

                        diseaseData[diseaseItem] = value.toFixed(2); // diseaseData'ya ekle
                    }
                    if (diseaseItem === disease[0]) {
                        console.log(value);
                        console.log(this.state.maxParametr1);
                        if (value > max1) {
                            console.log(value)
                            max1 = value;
                        }
                        if (value < min1) {
                            min1 = value;
                        }
                    }
                    if (diseaseItem === disease[1]) {
                        if (value > max2) {
                            max2 = value;
                        }
                        if (value < min2) {

                        }
                    }
                });
                const newData = {
                    name: item.time,
                    ...diseaseData
                };
                return newData;
            })
        });
        this.setState({ top: Math.ceil(max1) + 2 });
        this.setState({ maxParametr1: Math.ceil(max1) + 2 });
        this.setState({ bottom: Math.floor(min1) - 2 });
        this.setState({ minParametr1: Math.floor(min1) - 2 });
        this.setState({ top2: Math.ceil(max2) + 2 });
        this.setState({ maxParametr2: Math.ceil(max2) + 2 });
        this.setState({ bottom2: Math.floor(min2) - 2 });
        this.setState({ minParametr2: Math.floor(min2) - 2 });
        this.setState({
            initialData2: this.state.weatherLinkChart.map((item) => {
                const diseaseData = {};
                disease.forEach(diseaseItem => {
                    const value = item[diseaseItem]; // Anahtarın karşılık gelen değerini bul
                    if (value !== undefined) { // Değer tanımlıysa

                        diseaseData[diseaseItem] = value.toFixed(2); // diseaseData'ya ekle
                    }
                });
                const newData = {
                    name: item.time,
                    ...diseaseData
                };

                return newData;
            })
        });
    }
    componentDidMount() {
        this.getTableData();
    }
    toggleParameterVisibility = (dataKey) => {
        const { visibleParameter, initialData1, initialData2 } = this.state;
        const index = visibleParameter.length !== 0 && visibleParameter.indexOf(dataKey)
        if (index === -1 || visibleParameter.length === 0) {
            this.setState({
                visibleParameter: [...visibleParameter, dataKey],
                initialData1: initialData2.map(data => ({
                    ...data,
                    [dataKey]: data[dataKey]
                }))
            });
        }
        else {
            this.setState({
                visibleParameter: visibleParameter.filter(p => p !== dataKey),

                initialData1: initialData2.map(data => ({
                    ...data,
                    [dataKey]: undefined
                }))
            });
        }
    }
    changeLang = (value) => {
        const { t } = this.props;
        return t(value);
    }
    customTooltipFormatter = (value, name) => {
        const formattedValue = `${this.props.t(name)}: ${value}`;
        return [formattedValue];
    };
    customLabelFormatter = (name) => {
        return `${moment(name).format('YYYY-MM-DD')}`;
    };
    render() {
        const { data, barIndex, left, right, refAreaLeft, refAreaRight, top, bottom, top2, bottom2 } = this.state;
        console.log(this.state.interval);
        console.log(this.hash.search.split('=')[1]);
        const { t } = this.props;

        return (
            <Modal open={this.props.open} onCancel={this.props.close} width={1200} bodyStyle={{ maxHeight: 750 }} footer={null} >
                <div className='flex w-full h-full justify-between '>
                    <div className="highlight-bar-charts border p-2 mt-0 bg-white flex " style={{ userSelect: 'none', width: '100%' }}>

                        {
                            this.state.initialData1 && (
                                <ResponsiveContainer width="100%" height={400}>

                                    <LineChart
                                        width={800}
                                        height={400}
                                        data={this.state.initialData1}
                                        onMouseDown={(e) => this.setState({ refAreaLeft: e.activeLabel })}
                                        onMouseMove={(e) => this.state.refAreaLeft && this.setState({ refAreaRight: e.activeLabel })}
                                        //eslint-disable-next-line react/jsx-no-bind
                                        onMouseUp={this.zoom.bind(this)}
                                    >

                                        <CartesianGrid strokeDasharray="3 3" domain={[left, right]} type="date" />

                                        <XAxis tickFormatter={(tick) => moment(tick).format('YYYY-MM-DD')} allowDataOverflow textAnchor="end" interval={this.state.interval === 0 ? 0 : 3} dataKey="name" angle={-45} />
                                        <YAxis allowDataOverflow domain={[bottom, top]} label={{ value: t(this.state.parametrs[0]), angle: -90, position: 'insideLeft' }} yAxisId="1" />
                                        <YAxis allowDataOverflow domain={[bottom2, top2]} label={{ value: t(this.state.parametrs[1]), angle: -90, position: 'insideRight' }} orientation="right" yAxisId="2" />
                                        <Tooltip labelFormatter={this.customLabelFormatter} formatter={this.customTooltipFormatter} />
                                        <Legend formatter={this.changeLang} onClick={(e) => this.toggleParameterVisibility(e.dataKey)} />
                                        <Line yAxisId="1" type="monotone" dataKey={this.state.parametrs[0]} stroke="#8884d8" animationDuration={300} />
                                        <Line yAxisId="2" type="monotone" dataKey={this.state.parametrs[1]} stroke="#82ca9d" animationDuration={300} />

                                        {refAreaLeft && refAreaRight ? (
                                            <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                                        ) : null}
                                    </LineChart>
                                </ResponsiveContainer>
                            )}
                    </div>

                    <div className='bg-white overflow-scroll flex flex-col h-full'>
                        <div className='mb-2 pl-2 pt-2 '>Tus listesi</div>
                        <div className='flex p-2  gap-5 flex-align-center justify-center border-bottom ' >
                            <div className='flex dir-column gap-3'>
                                <div className='circle-0'>
                                </div>
                                Risk Yoxdur
                            </div>
                            <div className='flex dir-column gap-3'>
                                <div className='circle-1'>
                                </div>
                                Aşağı risk
                            </div>
                            <div className='flex dir-column gap-3'>
                                <div className='circle-2'>
                                </div>
                                Orta risk
                            </div>
                            <div className='flex dir-column gap-3'>
                                <div className='circle-3'>
                                </div>
                                Yüksək risk
                            </div>
                        </div>
                        <div>
                        <div className='flex p-1  dir-column gap-3'>
                  {
                    this.state.riskData?.map((item) => (

                      <div onClick={() => {
                        this.chooseDisaese(item.name);
                        this.setState({ chooseName: item.name });
                      }} className={this.state.chooseName === item.name ? 'flex gap-3 p-1 cursor-pointer choose' : 'flex gap-3 p-1 cursor-pointer'}>
                        <div className={'circle-' + item.riskLevel}></div>
                        <div>{item.name}</div>
                      </div>
                    ))
                  }

                </div>

                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
const exp = withTranslation()(DiseaseChart);
export default exp

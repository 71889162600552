import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import agros from "../../../../const/api";
import moment from "moment";

const View = (props) => {
  const { t } = useTranslation();
  const [task, setTask] = useState(null);
  const [parcel,setParcel] = useState([])
  useEffect(() => {
   
  }, [])
  useEffect(() => {
    agros.get("workplan/task/" + props.task).then((res) => {
      console.log(res.data);
      agros.get("Data/Parcels").then((res2) => {
        const at = res.data.parcels.map(elem => elem.parcelId)
        setParcel(res2.data.filter((elem) => at.includes(elem.id)))
      }
      )
      setTask({...res.data, date:moment(res?.data?.startDate).format("DD-MM-YYYY") + ' - ' + moment(res?.data?.endDate).format("DD-MM-YYYY")});
    });
  }, [props.task]);
  console.log(task);
  return  (
    <>
      {/* {alltasks?.map((task , i)=> ( */}
          <div  className={'fadeIn animated'}>
            <Row>
              <Col lg={12} md={24} xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">{t("heading")}</td>
                    <td>{task?.toDoName}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              {/* <Col xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">Cəmi sahə</td>
                    <td>
                      {task.sectorsArea} ha
                    </td>
                  </tr>
                  </tbody>
                </table>
              </Col> */}
              <Col lg={12} md={24} xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">{t("status")}</td>
                    <td>
                      <span className="text-primary">{task?.workStatus}</span>{" "}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col lg={12} md={24} xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">{t("shortStory")}</td>
                    <td>{task?.description}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col lg={12} md={24} xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">{t("manWorkerNumber")}</td>
                    <td>{task?.manWorkerCount} nəfər</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col lg={12} md={24} xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">{t("womanWorkerNumber")}</td>
                    <td>{task?.womanWorkerCount} nəfər</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col lg={12} md={24} xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">{t("Məsul şəxsin adı")}</td>
                    <td>{task?.respondent}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col lg={12} md={24} xs={12}>
                <table className="customtable">
                  <tbody>
                    {
                      parcel.map((parcel, index) => {
                        return (
                          <tr key={index}>
                            <td className="bold">{t("sectors")}</td>
                            <td>{parcel.name}</td>
                          </tr>
                        )
                      })
                    }
                   
                  </tbody>
                </table>
              </Col>
              <Col lg={12} md={24} xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">{t("Başlama tarixi - Bitmə tarixi")}</td>
                    <td>{task?.date}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
            
            </Row>
            {
                  task?.workPlanTaskFertilizers.length !==0 || task?.workPlanTaskReserves.length !==0  ? (
            <div className="border mt-15 p-1">
              <Row gutter={[16, 16]}>
            
                
                 
                 <Col xs={24}>
                   <div className="p-1 border">
                     <h3 className={'mb-10 font-weight-bold'}>İş planlamasında</h3>
                     <Row gutter={[16,16]}>
                       {task?.workPlanTaskFertilizers?.length ? (
                           <Col span={24}>
                             <div className={'p-1 w-100 border'}>
                               <p className="font-weight-bold mb-10">{t('drugandFertilizers')} </p>
                               {task?.workPlanTaskFertilizers?.map((f , i)=>{
                                 return <div key={i} className="p-1 border">
                                   <p className="font-weight-bold mb-10">{t('drugAndFertilizer') + ' ' + (i + 1)} </p>
                                   <Row gutter={[16,16]}>
                                     <Col lg={4} md={12}  span={4} >
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">Məhsul:</td>
                                           <td>{f.name}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                     <Col lg={4} md={12} span={4} >
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">Növü:</td>
                                           <td>{f.fertilizerKind}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                     <Col lg={4} md={12} span={6} >
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">Əsas inqredient:</td>
                                           <td>{f.mainIngredient}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                     {/* <Col span={6} >
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">100l nisbəti:</td>
                                           <td>{f.quantityPerHundred}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col> */}
                                     <Col lg={4} md={12} span={4} >
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">Miqdar:</td>
                                           <td>
                                             {f.quantity} {f.measurementUnit}
                                           </td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                   </Row>
                                 </div>
                               })}
                             </div>

                           </Col>
                       ) : null}
                       {task?.workPlanTaskReserves?.length ? (
                           <Col lg={4}  md={12} xs={24}>
                             <div className={'p-1 w-100 border'}>
                               <p className="font-weight-bold mb-10">{t('reserves')} </p>
                               {task?.workPlanTaskReserves?.map((f, findex) => {
                                 return (
                                     <div className={'border p-1'} key={findex}>
                                       <p className="font-weight-bold mb-10">{t('Ehtiyat') + (findex + 1)} </p>
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td>{f.name}</td>
                                           <td>{f.quantity} {f.measurementUnit}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </div>
                                 );
                               })}
                             </div>
                           </Col>
                       ) : null}
                     </Row>
                   </div>
                 </Col>
               
              </Row>
            </div>
                  ) : null
            }
          </div>
    </>
  ) ;
};

export default View;
import React from "react";
import {Col, Row, Tabs} from "antd";
import Drugs from "./Statistics/Drugs";
 import Reserves from "./Statistics/Reserves";
import Workers from "./Statistics/Workers";
import Products from "./Statistics/Products";
import Todos from "./Statistics/Todos";
import Equipments from "./Statistics/Equipments";
import { connect } from "react-redux";
import { getOptions } from "./../../../redux/actions";
import "./stats.css";
import {UnorderedListOutlined} from "@ant-design/icons";
const { TabPane } = Tabs;

const Statistics = (props) => {
  const perms = props.perms.workplan.subs.statistics;
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="border page-heading flex p-2 mt-0 bg-white">
            <div className="page-name small-name">
              <UnorderedListOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Statistika</span>
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <div className="page border">
            <Tabs defaultActiveKey="1" type="card">
              {
                perms.subs.drugAndFertilizers.perms.read &&
                <TabPane tab="Dərman və gübrələr" key="1">
                <Drugs/>
              </TabPane>
              }
              {
                perms.subs.products.perms.read &&
                <TabPane tab="Məhsullar" key="2">
                <Products/>
              </TabPane>
              }
              {
                perms.subs.reserves.perms.read &&
                <TabPane tab="Ehtiyatlar" key="4">
                <Reserves/>
              </TabPane>
              }
               {
                perms.subs.workers.perms.read &&
                <TabPane tab="İşçilər" key="5">
                <Workers/>
              </TabPane>
              }
            
            {
                perms.subs.todos.perms.read &&
                <TabPane tab="Görülən işlər" key="6">
                <Todos/>
              </TabPane>
              }
            
            {
                perms.subs.technique.perms.read &&
                <TabPane tab="Texnika" key="7">
                <Equipments/>
              </TabPane>
              }
            
          
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = ({ options, lang,user }) => {
  return { options, lang, perms: user.data.userPermissions };
};
export default connect(mapStateToProps,{getOptions})(Statistics);

import { DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'

export default function SwipePanel({ visible, close, handleChangeSwipeDate }) {
    const [dateTime1, setDateTime1] = useState()
    const [dateTime2, setDateTime2] = useState()


    const handleChangeDate = (side, data) => {
        if (data) {
            handleChangeSwipeDate(side, data)
        }
        if (side == "leadingLayers") {
            setDateTime1(data)
        } else {
            setDateTime2(data)
        }
    }

    useEffect(() => {
        console.log("ok")
        setDateTime1()
        setDateTime2()
    }, [visible])

    return (
        <div className={`${visible ? 'scale-100' : 'scale-0'} absolute flex flex-row  w-full justify-between rounded-lg bottom-[5rem]  shadow-lg px-5 t shadow-none `}>
            <div >
                <DatePicker value={dateTime1} onChange={d => handleChangeDate("leadingLayers", d)} />
            </div>
            <div >
                <DatePicker value={dateTime2} onChange={d => handleChangeDate("trailingLayers", d)} />
            </div>
        </div>

    )
}

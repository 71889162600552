import React, { useEffect } from 'react'

export default function Footer({ selectedBaseMap, scale }) {
  useEffect(() => {

  }, [selectedBaseMap])
  return (
    <div className='absolute flex w-full h-6 bg-white opacity-75 bottom-0 items-center justify-center'>
      <div className='flex w-full justify-between items-center px-1'>
        <span className=' text-xs text-gray-400'>Map data @{selectedBaseMap}</span>
        <div className='flex w-72 items-center justify-end pr-1'>
          <span className='text-xs text-gray-500 mr-2'>{scale}   </span>
          <div className='flex w-20 border-black border-x border-b  h-2'  ></div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Modal } from "antd/lib";
import { connect } from "react-redux";

import { getOptions,notify } from "../../../redux/actions";
import EditArea from "./EditArea";


 function AreaUpdateModal(props) {
    const { t, i18n } = useTranslation();
   
    const handleUpdate=(data)=>{
        props.setAreaAddModal(false)
        props.handleUpdateArea(data)
    }

  return (
    <Modal open={props?.open} onCancel={()=>props.setAreaAddModal(false)} title={t("addNewArea")} footer={[]} width={1200} >
        <EditArea areaInfo={props.areaInfo} editing={true} saveLoading={props.saveLoading} handleUpdateArea={handleUpdate}  setVisibleAddArea={props.setAreaAddModal} handleCloseSketchBar={props.handleCloseSketchBar}  />
    </Modal>
  )
}

const mapStateToProps = ({ options, lang, user }) => {
    return { options, lang, perms: user.data.userPermissions };
  };
  
export default connect(mapStateToProps, { getOptions, notify })(AreaUpdateModal)

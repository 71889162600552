import React, { PureComponent } from 'react';
import agros from "../../../../const/api";
import {
  Label,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
  Legend
} from 'recharts';
import {
  Row,
  Col
} from "antd";
import moment from 'moment';
import history from "../../../../const/history";
import { withTranslation } from "react-i18next";


class DiseaseChart extends PureComponent {
  hash = history.location
  urlParams = new URLSearchParams(window.location.search);
  cropName = this.urlParams.get('name');

  state = {
    plantmentdata: {},
    weatherLinkChart: [],
    riskData: null,
    initialData1: [],
    initialData2: [],
    parametrs: [],
    visibleParameter: [],
    maxParametr1: 0,
    maxParametr2: 0,
    minParametr1: 0,
    minParametr2: 0,
    chooseName: '',
    interval: 3
  };
  initialState = {
    data: this.state.initialData1,
    left: 'dataMin',
    right: 'dataMax',
    refAreaLeft: '',
    refAreaRight: '',
    top: 12,
    bottom: -1,
    top2: 22,
    bottom2: -1,
    animation: true,
  };
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }
  getTableData = async () => {
    let data = [];
    let onlyName = [];
    await agros.get(`PlantMet?_query=${this.hash.search.split('=')[1]}`).then((res) => {
      this.setState({ plantmentdata: res?.data?.plantment });
      this.setState({ weatherLinkChart: res?.data?.weatherLinkChart });
      onlyName = res?.data?.plantment.existing_diseases.map((item) => {
        return item.name
      })
      Promise.all([
        agros.get(`PlantMet/1`),
        agros.get(`PlantMet/2`)
      ]).then((res) => {
        data = res[0]?.data[0]?.ipm
        data = [...data, ...res[1]?.data[0]?.ipm]
        const uniqueArray = data.filter((item, index) => {
          return data.findIndex(obj => obj.name === item.name) === index;
        });
        console.log(uniqueArray);
        this.setState({ riskData: uniqueArray?.filter((item) => onlyName.includes(item.name)) });
        this.chooseDisaese(uniqueArray?.filter((item) => onlyName.includes(item.name))[0].name)
        this.setState({ chooseName: uniqueArray?.filter((item) => onlyName.includes(item.name))[0].name });
       // console.log(uniqueArray?.filter((item) => onlyName.includes(item.name))[0]);
       // this.setState({ initialData1: uniqueArray?.filter((item) => onlyName.includes(item.name))[0].parameters_check_diseases });
      });
    });

  };
  zoom() {
    let { refAreaLeft, refAreaRight } = this.state;
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      this.setState(() => ({
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // yAxis domain
    console.log(refAreaLeft, refAreaRight, this.state.parametrs[0], 1);
    const [bottom, top] = this.getAxisYDomain(refAreaLeft, refAreaRight, this.state.parametrs[0], 1);
    const [bottom2, top2] = this.getAxisYDomain(refAreaLeft, refAreaRight, this.state.parametrs[1], 10);

    const filteredData = this.state.initialData1.filter(item => {
      return new Date(item.name) >= new Date(refAreaLeft) && new Date(item.name) <= new Date(refAreaRight);
    });
    console.log(bottom);
    this.setState(() => ({
      refAreaLeft: '',
      refAreaRight: '',
      initialData1: filteredData,
      left: refAreaLeft,
      right: refAreaRight,
      bottom,
      top,
      bottom2,
      top2,
      interval: 0
    }));
  }

  zoomOut() {

    this.setState(() => ({
      initialData1: this.state.initialData2,
      left: 'dataMin',
      right: 'dataMax',
      refAreaLeft: '',
      refAreaRight: '',
      top: this.state.maxParametr1,
      bottom: this.state.minParametr1,
      top2: this.state.maxParametr2,
      bottom2: this.state.minParametr2,
      animation: true,
      interval: 3
    }));
  }
  getAxisYDomain = (from, to, ref, offset) => {
    console.log(ref);
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const refData = this.state.initialData1.filter(item => {
      const itemDate = new Date(item.name);
      return itemDate >= fromDate && itemDate <= toDate;
    });
    console.log(refData);
    let [bottom, top] = [refData[0][ref], refData[0][ref]];
    console.log(bottom, top);
    refData.forEach((d) => {
      console.log(typeof d[ref]);
      if (Number(d[ref]) > Number(top)) {
        console.log(d[ref]);
        top = d[ref]
        console.log(top);
      }
      if (Number(d[ref]) < Number(bottom)) {
        bottom = d[ref];
      } 
    });
    console.log(bottom, top);
    return [(bottom | 0) - offset, (top | 0) + offset];
  };
  chooseDisaese = (name) => {
    let disease = []
    this.state.plantmentdata.existing_diseases.map((item) => {
      if (item.name === name) {
        disease = item.parameters_check_diseases.map((item) => { return item.name })
      }
    })
    this.setState({ parametrs: disease });
    this.setState({ visibleParameter: disease });
    let max1 = 0;
    let min1 = 0;
    let max2 = 0;
    let min2 = 0;
    const choose = this.state.riskData.find((item) => item.name === name)
    this.setState({
      initialData1: this.state.weatherLinkChart.map((item) => {
        const diseaseData = {};
        disease.forEach(diseaseItem => {
          const value = item[diseaseItem];
          if (value !== undefined) { // Değer tanımlıysa

            diseaseData[diseaseItem] = value.toFixed(2); // diseaseData'ya ekle
          }
          if (diseaseItem === disease[0]) {
            if (value > max1) {
              max1 = value;
            }
            if (value < min1) {
              min1 = value;
            }
          }
          if (diseaseItem === disease[1]) {
            if (value > max2) {
              max2 = value;
            }
            if (value < min2) {

            }
          }
        });
        console.log(item);
        const newData = {
          name: item.time,
          riskLevel: choose.name === item.time ? choose.riskLevel : 0,
        //  alarmTime: item.alarmTime,
          ...diseaseData
        };
        return newData;
      })
    });
    this.setState({ top: Math.ceil(max1) + 2 });
    this.setState({ maxParametr1: Math.ceil(max1) + 2 });
    this.setState({ bottom: Math.floor(min1) - 2 });
    this.setState({ minParametr1: Math.floor(min1) - 2 });
    this.setState({ top2: Math.ceil(max2) + 2 });
    this.setState({ maxParametr2: Math.ceil(max2) + 2 });
    this.setState({ bottom2: Math.floor(min2) - 2 });
    this.setState({ minParametr2: Math.floor(min2) - 2 });
    this.setState({
      initialData2: this.state.weatherLinkChart.map((item) => {
        // disease alanındaki anahtarları ve karşılık gelen değerleri bul
        const diseaseData = {};
        disease.forEach(diseaseItem => {
          const value = item[diseaseItem]; // Anahtarın karşılık gelen değerini bul
          if (value !== undefined) { // Değer tanımlıysa

            diseaseData[diseaseItem] = value.toFixed(2); // diseaseData'ya ekle
          }
        });

        // initialData'ya eklemek için nesne oluştur
        const newData = {
          name: item.time,
          ...diseaseData
        };

        return newData;
      })
    });
  }
  componentDidMount() {
    this.getTableData();
  }
  toggleParameterVisibility = (dataKey) => {
    const { visibleParameter, initialData2 } = this.state;
    const index = visibleParameter.length !== 0 && visibleParameter.indexOf(dataKey)
    if (index === -1 || visibleParameter.length === 0) {
      this.setState({
        visibleParameter: [...visibleParameter, dataKey],
        initialData1: initialData2.map(data => ({
          ...data,
          [dataKey]: data[dataKey]
        }))
      });
    }
    else {
      this.setState({
        visibleParameter: visibleParameter.filter(p => p !== dataKey),
        initialData1: initialData2.map(data => ({
          ...data,
          [dataKey]: undefined
        }))
      });
    }
  }
  changeLang = (value) => {
    const { t } = this.props;
    return t(value);
  }
  customTooltipFormatter = (value, name) => {
    const formattedValue = `${this.props.t(name)}: ${value}`;
    return [formattedValue];
  };
  customLabelFormatter = (name) => {
    return `${moment(name).format('YYYY-MM-DD')}`;
  };
  render() {
    const { data, barIndex, left, right, refAreaLeft, refAreaRight, top, bottom, top2, bottom2 } = this.state;
    const { t } = this.props;
    console.log(this.state.initialData1);
    return (
      <Row gutter={[10, 10]}>

        <Col xs={24}>
          <div className='flex'>
            <div className="highlight-bar-charts border p-2 mt-0 bg-white" style={{ userSelect: 'none', width: '100%' }}>
              <div className='flex flex-between'>
                <div className="f-20 bold">{this.cropName} üçün xəstəlik riski</div>
                {
                  this.state.initialData1 && (
                    <button type="button" className="btn-zoom" onClick={this.zoomOut.bind(this)}>
                      Zoom Out
                    </button>)
                }

              </div>
              {
                this.state.initialData1 && (
                  <ResponsiveContainer width="100%" height={400}>

                    <LineChart
                      width={800}
                      height={400}
                      data={this.state.initialData1}
                      onMouseDown={(e) => this.setState({ refAreaLeft: e.activeLabel })}
                      onMouseMove={(e) => this.state.refAreaLeft && this.setState({ refAreaRight: e.activeLabel })}
                      //eslint-disable-next-line react/jsx-no-bind
                      onMouseUp={this.zoom.bind(this)}
                    >

                      <CartesianGrid strokeDasharray="3 3" domain={[left, right]} type="date" />

                      <XAxis
                        tickFormatter={(tick) => moment(tick).format('YYYY-MM-DD')}
                        allowDataOverflow textAnchor="end"
                        interval={this.state.interval === 0 ? 0 : 3}
                        dataKey="name" angle={-45}
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis
                        allowDataOverflow
                        domain={[bottom, top]}
                        label={{ value: t(this.state.parametrs[0]), angle: -90, position: 'insideLeft', fontSize: 14 }}
                        yAxisId="1" />
                      <YAxis
                        allowDataOverflow
                        domain={[bottom2, top2]}
                        label={{ value: t(this.state.parametrs[1]), angle: -90, position: 'insideRight', fontSize: 14}}
                        orientation="right"
                        yAxisId="2" />
                      <Tooltip
                        labelFormatter={this.customLabelFormatter}
                        formatter={this.customTooltipFormatter} />
                      <Legend
                        formatter={this.changeLang}
                        onClick={(e) => this.toggleParameterVisibility(e.dataKey)} />
                      <Line
                        format={this.changeLang}
                        yAxisId="1" type="monotone"
                        dataKey={this.state.parametrs[0]}
                        stroke="#8884d8"
                        animationDuration={300} />
                      <Line
                        yAxisId="2"
                        type="monotone"
                        dataKey={this.state.parametrs[1]}
                        stroke="#82ca9d"
                        animationDuration={300} />

                      {refAreaLeft && refAreaRight ? (
                        <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                      ) : null}
                    </LineChart>
                  </ResponsiveContainer>
                )}
            </div>

            <div className='bg-white border'>
              <div className='mb-2 pl-2 pt-2 '>Tus listesi</div>
              <div className='flex p-2  gap-5 flex-align-center justify-center border-bottom ' >
                <div className='flex dir-column gap-3'>
                  <div className='circle-0'>
                  </div>
                 <span className='f-13'>Risk Yoxdur</span> 
                </div>
                <div className='flex dir-column gap-3'>
                  <div className='circle-1'>
                  </div>
                  <span className='f-13'>Aşağı risk</span> 
                  
                </div>
                <div className='flex dir-column gap-3'>
                  <div className='circle-2'>
                  </div>
                  <span className='f-13'>Orta risk</span> 
                </div>
                <div className='flex dir-column gap-3'>
                  <div className='circle-3'>
                  </div>
                  <span className='f-13'> Yüksək risk</span> 
                 
                </div>
              </div>
              <div>
                <div className='flex p-1  dir-column gap-3'>
                  {
                    this.state.riskData?.map((item) => (
                      <div onClick={() => {
                        this.chooseDisaese(item.name);
                        this.setState({ chooseName: item.name });
                      }} className={`flex flex-align-center gap-3 p-5 cursor-pointer ${this.state.chooseName === item.name && 'choose'} `}>
                        <div className={'circle-' + item.riskLevel}></div>
                        <div className='f-13'>{item.name}</div>
                      </div>
                    ))
                  }
                </div>

              </div>
              <div>

              </div>
            </div>
          </div>
        </Col>




      </Row>

    );
  }
}

const exp = withTranslation()(DiseaseChart);
export default exp
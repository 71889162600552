import React, { useEffect, useState } from 'react';
import "@ant-design/compatible/assets/index.css";
import { Tabs, Spin, Row, Col,Select } from 'antd';
import { UnorderedListOutlined } from "@ant-design/icons";
import Charts from "./Charts";
import history from "../../../../const/history";
import agros from "../../../../const/api";
const { TabPane } = Tabs;
const { Option } = Select;




function Analyzes(props) {
    const [stansions, setStansions] = useState([])
    const [stationData, setStationData] = useState({})
    const [spin, setSpin] = useState(false)
    const [tabkey, setTabkey] = useState(undefined)
    let hash = history.location.search.split('=')[1]
    console.log( history.location.search.split('=')[1]);
    useEffect(() => {
        getData()
    }, [hash])

    
    const getData = async () => {
        setSpin(true)
        await agros.get('weatherlink/v2').then((res) => {
            setStansions(res.data)
            if (res.data.length && !hash) {
                getStationData(res.data[0].id)
            }
            else {
                getStationData(hash)
                setTabkey(hash)
            }
        })
    }


    const getStationData = async (id) => {

        setSpin(true)
        await agros.get(`weatherlink/v2/station/${id}`).then((res) => {
            setStationData(res.data)
            console.log(res)
        }).finally(() => {
            setSpin(false)
        })
    }
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <div className="border page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <UnorderedListOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Metroloji stansiyalar </span>
                        </div>
                    
                    </div>
                </Col>
                <Col xs={24}>
                    <Spin spinning={spin} className={'bg-white p-3 mt-15'} style={{maxHeight:'100%'}}>
                        <div className="position-relative mt-15">
                            <div className=" w-100 purchase-tabs tab-section">
                                 <Charts data={stationData} hash={hash} getStationData={getStationData} setTabkey={setTabkey} stansions={stansions}/> 
                            </div>
                        </div>
                    </Spin>
                </Col>
            </Row>
        </div>
    );
}



export default Analyzes;

import React from "react";
import "@ant-design/compatible/assets/index.css";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Tooltip,
    Popconfirm,
    Select,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import agros from "./../../../../const/api";
import { withTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import {connect} from "react-redux";
import {notify} from "../../../../redux/actions";
import TodosCat from "./Forms/TodosCat";
import TodosTypes from "./Forms/TodosTypes";

const { Option } = Select;

const urls = {
    TodosTypes: 'todos',
};
// permissions yazanda yaratmalisan
const mappers = {
    TodosTypes: 'todosTypes',
};

class Todos extends React.Component {
    cols = [
        { key: "index", value: "#", con: true },
        { key: "category", value: this.props.t("typeOf"), con: true },
        { key: "name", value: this.props.t("name"), con: true },
        { key: "buttons", value: "", con: false },
    ];
    // table data columns
    initialColumns = [
        {
            title: "#",
            dataIndex: "index",
            key: "1",
            width: 60,
        },
        {
            title: this.props.t("name"),
            dataIndex: "name",
            key: "2",
        },
        {
            title: "",
            dataIndex: "buttons",
            key: "3",
            render: (b) => <>{b}</>,
        },
    ];
    extraColumns = {
        TodosTypes: [],
    };

    state = {
        forms: {
            TodosTypes:{
                items: [],
                page: 1,
                editing: null,
            },
        },
        columns: this.initialColumns,
        editing: null,
    };

    // notification component
    openNotification = () => {
        this.props.notify("", true);
    };

    insertExtraColumns = () => {
        const columns = [...this.initialColumns];
        this.extraColumns["TodosTypes"].forEach((e, index) => {
            columns.splice(2 + index, 0, e);
        });
        this.setState({ columns });
    };

    // selectHandleChange = (selected) => {
    //     this.setState({ selected }, () => {
    //         this.getTableData();
    //         this.insertExtraColumns();
    //     });
    // };

    cancelEdit = () => {
        const s = "TodosTypes";
        const forms = { ...this.state.forms };
        if (forms[s].editing === null) {
            forms[s].editing = undefined;
        } else {
            forms[s].editing = null;
        }
        this.setState({ forms });
    };

    renderRightSide = () => {
        const s = "TodosTypes";
        switch (s) {
            case "TodosTypes":
                return (
                    <TodosTypes
                        cancelEdit={this.cancelEdit}
                        saveItem={this.saveItem}
                        name={"TodosTypes"}
                        editing={this.state.forms[s].editing}
                    />
                );
            default:
                return null;
        }
    };

    getTableData = async () => {
        await agros.get(urls["TodosTypes"]).then((res) => {
            const forms = this.state.forms;
            forms["TodosTypes"].items = res.data.map((d, index) => ({
                ...d,
                index: index + 1,
                key: d.id,
                buttons: (
                    <div className="flex flex-end">
                            <Popconfirm
                                placement="topRight"
                                title={this.props.t("areYouSure")}
                                onConfirm={() => this.deleteItem(d.id)}
                                okText={this.props.t("yes")}
                                cancelText={this.props.t("no")}
                            >
                                <Tooltip
                                    className="ml-5"
                                    title={this.props.t("delete")}
                                    placement="leftBottom"
                                >
                                    <Button className="border-none" type="text" shape="circle">
                                        <DeleteFilled />
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                            <Tooltip
                                className="ml-5"
                                title={this.props.t("edit")}
                                placement="topRight"
                            >
                                <Button
                                    className="border-none"
                                    type="text"
                                    shape="circle"
                                    onClick={() => this.setEditingObject(d.id)}
                                >
                                    <EditFilled />
                                </Button>
                            </Tooltip>
                    </div>
                ),
            }));
            this.setState({ forms });
        });
    };

    setEditingObject = async (id) => {
        const s = "TodosTypes";
        await agros.get(urls[s] + "/" + id).then((res) => {
            const forms = { ...this.state.forms };
            forms[s].editing = res.data;
            this.setState({ forms }, () => {});
        });
    };

    deleteItem = async (id) => {
        await agros.delete(`${urls["TodosTypes"]}/${id}`).then((res) => {
            this.openNotification();
            this.getTableData();
        });
    };

    componentDidMount() {
        this.getTableData();
        this.insertExtraColumns();
    }

    saveItem = (object) =>
        new Promise(async (resolve, reject) => {
            const s = "TodosTypes";
            if (this.state.forms[s].editing) {
                await agros
                    .put(`${urls[s]}/${object.id}`, object)
                    .then((res) => {
                        const forms = { ...this.state.forms };
                        forms[s].editing = null;
                        this.setState({ forms });
                        this.openNotification();
                        this.getTableData();
                        resolve();
                    })
                    .catch((err) => {
                        this.props.notify("", false);
                    });
            } else {
                await agros
                    .post(urls[s], object)
                    .then((res) => {
                        const forms = { ...this.state.forms };
                        forms[s].editing = undefined;
                        this.setState({ forms });
                        this.openNotification();
                        this.getTableData();
                        resolve();
                    })
                    .catch((err) => {
                        this.props.notify("", false);
                    });
            }
        });

    render() {
        const { t } = this.props;
        return (
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border p-2 mt-0 bg-white">
                        <UnorderedListOutlined className="f-20 mr5-15" />
                        <span className="f-20 bold">Ediləcək iş</span>
                    </div>
                </Col>
                <Col lg={12} xs={24}>
                    {/* <Select
                        className="w-100 mb-5 menu-options"
                        defaultValue="TodosCategories"
                        //onChange={this.selectHandleChange}
                    >
                        <Option value="TodosCategories">Ediləcək işin kateqoriyası</Option>
                        <Option value="TodosTypes">Ediləcək işin növü</Option>
                    </Select> */}
                    <Table
                        size="small"
                        className="bg-white"
                        columns={this.state.columns}
                        dataSource={convertColumns(
                            this.state.forms["TodosTypes"].items,
                            this.cols
                        )}
                        pagination={{
                            pageSize: 10,
                            current_page: 1,
                            total: this.state.forms["TodosTypes"].items.length,
                        }}
                    />
                </Col>
                <Col lg={12} xs={24}>
                    <Card title={t("addTo")}>{this.renderRightSide()}</Card>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return { perms: user.data.userPermissions };
};
const exp = withTranslation()(Todos);
export default connect(mapStateToProps, { notify })(exp);

import "@ant-design/compatible/assets/index.css";
import React, { useEffect, useState } from "react";
import {Tabs, Button, Table, Select, Form, Tooltip, Row, Col, Input, DatePicker, Modal} from "antd";
import {
  UnorderedListOutlined,
  ExclamationCircleOutlined, ClearOutlined, SwapOutlined, EditFilled, ExportOutlined,
} from "@ant-design/icons";
import IncomeModal from "./Modals/IncomeModal";
import agros from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { getStock  , getOptions} from "./../../../../redux/actions";
import moment from "moment";
import { connect } from "react-redux";
import {noWhitespace} from "../../../../utils/rules";
import Edit from  './Modals/Edit'
const { TabPane } = Tabs;
const { Option } = Select;

const Reserves = (props) => {
  const { t  , i18n } = useTranslation();
  const perms = props.perms.warehouse.subs.reservesWarehouse;
  console.log(perms);
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "productName", value: t("productName"), con: true },
    { key: "quantity", value: t("productQuantity"), con: true },
    { key: "documentNumber", value: t("documentNumber"), con: true },
    { key: "measurementUnit", value: t("measurementUnit"), con: true },
    { key: "warehouse", value: t("warehouse"), con: true },
    { key: "acceptDate", value: t("acceptedDate"), con: true },
    { key: "totalPrice", value: t("finalPrice"), con: true },
    { key: "operation", value: "", con: false },
    { key: "productId", value: "", con: false },
    { key: "id", value: "", con: false },
  ];
  //totalPrice
  const [modalType , setModalType]= useState('')
  const [reserves, setReserves] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [reservesFetched, setReservedFetched] = useState(false);
  const [parcelSectors, setparcelSectors] = useState(undefined);
  // const [mainIngridientId , setMainIngridientId] = useState(undefined)
  // const [fertlizerKindId , setFertlizerKindId] = useState(undefined)
  const [parcelCategory , setParcelCategory] = useState(undefined)
  const [quantity , setQuantity] = useState(undefined)
  const [parcel , setParcel] = useState(undefined)
  const [workPlanId, SetWorkPlanId] = useState(undefined);

  const [visibleEdit , setVisibleEdit] = useState(false)
  const [id , setId] = useState(false);
  const [documents, setDocuments] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
  });
  const [type, setType] = useState(0);
  const [isIncome, setIsIncome] = useState(true);

  const { getOptions } = props;
  const options = props.options[props.lang];

  const [form] = Form.useForm();

  const onSearch = (values) =>{
    let obj = {}
    // eslint-disable-next-line no-unused-expressions
    values.ProductId ? obj['ProductId'] = values.ProductId : null
    agros.get('ReserveStock'  , { params: obj}).then((res) => {
      setReserves(
          res.data.map((d, index) => {
            return {
              ...d,
              key: index + 1,
              tableIndex: index + 1,
              quantity: d.quantity + " " + d.measurementUnit,
            };
          })
      );
    });
  }

  const editCrop  = (id , type) =>{
    console.log(id);
    if (type !== 'export' || id === -1 ){
      setId(id)
    }
    if(type === 'export' && id !== -1) {
      setId(tasks.find((t)=>{return t.id === id}).productId)
      setParcelCategory(tasks.find((t)=>{return t.id === id}).parcelCategoryId)
      setparcelSectors(tasks.find((t)=>{return t.id === id}).parcelSectorIds)
      setParcel(tasks.find((t)=>{return t.id === id}).parcelId)
      setQuantity(tasks.find((t)=>{return t.id === id}).quantity)
      SetWorkPlanId(tasks.find((t)=>{return t.id === id}).workPlanId)
    }
    setVisibleEdit(true);
    setModalType(type)
  }

  useEffect(() => {
    getOptions(
        ["reserves"],
        props.options,
        i18n.language
    );

    const getReserves = () => {
      agros.get("reservestock").then((res) => {
        setReserves(
          res.data.map((d, index) => {
            return {
              ...d,
              key: index + 1,
              tableIndex: index + 1,
              quantity: d.quantity + " " + d.measurementUnit,
            };
          })
        );
        setReservedFetched(true);
      });
    };
    const getDocuments = () => {
      agros.get(`reservestock/documents/${type}`).then((res) => {
        const all = { ...documents };
        all[type] = res.data.map((r, index) => {
          return {
            ...r,
            key: index + 1,
            tableIndex: index + 1,
            acceptDate:
              r.acceptDate && moment(r.acceptDate).format("DD-MM-YYYY"),
          };
        });
        setDocuments(all);
      });
    };
    const getTasks = () => {
      agros.get(`reservestock/tasks`).then((res) => {
        const t = res.data.map((r, index) => {
          return {
            ...r,
            key: index + 1,
          };
        });
        setTasks(t);
      });
    };

    if (!reservesFetched) {
      getReserves();
    }

    if (!documents[type].length && reservesFetched) {
      getDocuments();
      getTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, reservesFetched, t]);

  const ClearFilter = () =>{
    triggerFetch()
    form.resetFields()
  }

  const columns = [
    {
      title: "",
      key: 0,
      dataIndex: "tableIndex",
      width: window.innerWidth > 991 ? 60 : "100%",
    },
    {
      title: t("productName"),
      key: 1,
      dataIndex: "productName",
    },
    {
      title: t("productQuantity"),
      key: 2,
      dataIndex: "quantity",
    },
    {
      title: t("finalPrice"),
      key: 2,
      dataIndex: "totalPrice",
    },
    {
      title: "",
      key: 8,
      dataIndex: "productId",
      width:'5px',
      render: (e) => (
        perms.subs.reserves.perms.transfer &&
          <Tooltip className="ml-5" title={t("Transfer")} placement="topRight">
            <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => editCrop(e , 'transfer')}
            >
              <SwapOutlined />
            </Button>
          </Tooltip>
      )
    },
    {
      title: "",
      key: 8,
      dataIndex: "productId",
      width:'5px',
      render: (e) => (
        perms.subs.reserves.perms.update &&
          <Tooltip className="ml-5" title={t("edit")} placement="topRight">
            <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => editCrop(e , 'edit')}
            >
              <EditFilled />
            </Button>
          </Tooltip>
      )
    },
    {
      title: "",
      key: 8,
      dataIndex: "warn",
      render: (e) => (
        <span>
          {e !== 0 ? (
            <Tooltip
              placement="leftBottom"
              className="ml-5"
              title={
                e === 1
                  ? "Anbardakı miqdar bu ehtiyat üçün nəzərdə tutulan minimal miqdara yaxındır"
                  : "Anbarda bu ehtiyatdan qalmayıb"
              }
            >
              <ExclamationCircleOutlined
                className={`pointer ${e === 2 ? "red" : ""}`}
              />
            </Tooltip>
          ) : null}
        </span>
      ),
    },
  ];

  const columnsIncomeExpense = [
    // {
    //   title: t("documentNumber"),
    //   key: 1,
    //   dataIndex: "documentNumber",
    // },
    {
      title: t("productName"),
      key: 2,
      dataIndex: "productName",
    },
    {
      title: t("productQuantity"),
      key: 3,
      dataIndex: "quantity",
    },
    {
      title: t("measurementUnit"),
      key: 4,
      dataIndex: "measurementUnit",
    },
    {
      title: t("warehouse"),
      key: 5,
      dataIndex: "warehouse",
    },
    {
      title: t("operation"),
      key: 6,
      dataIndex: "operation",
      render: (i) => {
        return (
          <div className="flex statusTd">
            <p>{t("operation")}</p>
            {i === 1 ? (
              <Button
                size="small"
                type="primary"
                className="f-12 border-none"
                shape="round"
              >
                {t("import")}
              </Button>
            ) : (
              <Button className="f-12" size="small" type="danger" shape="round">
                {t("export")}
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: t("acceptedDate"),
      key: 7,
      dataIndex: "acceptDate",
      // render: (o) => <span>{o.substring(0, 10)}</span>,
    },
  ];

  const [visibleIncomeModal, setVisibleIncomeModal] = useState(false);

  function selectHandleChange(value) {
    setType(+value);
  }

  const openAddModal = (income) => {
    setIsIncome(income);
    setVisibleIncomeModal(true);
  };

  const triggerFetch = () => {
    props.getStock();
    setReservedFetched(false);
    const all = { ...documents };
    all[type] = [];
    setDocuments(all);
  };

  const columnsTasks = [
    {
      title: t("name"),
      key: 4,
      dataIndex: "productName",
    },
    {
      title: t("quantityOf"),
      key: 5,
      dataIndex: "quantity",
    },
    {
      title: t("measurementUnit"),
      key: 3,
      dataIndex: "measurementUnit",
    },
    {
      title: "",
      key: 8,
      dataIndex: "id",
      width:'5px',
      render: (e) => (
          perms.subs.tasks.perms.export &&
          <Tooltip className="ml-5" title={t("export")} placement="topRight">
            <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => editCrop(e , 'export')}
            >
              <ExportOutlined />
            </Button>
          </Tooltip>
      )
    },
  ];

  return (
    <div>
      <div className="border page-heading flex p-2 mt-0 bg-white">
        <div className="page-name">
          <UnorderedListOutlined className="f-20 mr5-15" />
          <span className="f-20 bold">{t("reserves")}</span>
        </div>
        <div>
          {perms.perms.import && (
            <Button type="primary" onClick={() => openAddModal(true)}>
              {t("import")}
            </Button>
          )}
          {perms.perms.export && (
            <Button className="ml-10" onClick={() => editCrop(-1 , 'export')}>
              {t("export")}
            </Button>
          )}
        </div>
      </div>
      <div className="position-relative mt-15">
        <div className="position-absolute w-100 tab-section">
          <Tabs animated={true} defaultActiveKey="1" className="w-100">
            {perms.subs.reserves.perms.read && (
              <TabPane tab={t("reserves")} key="1">
                <div  className={'w-100'}>
                  <Form onFinish={onSearch} layout="vertical" form={form}>
                    <div className="commontask bg-white px-2 pt-15">
                      <Row className={'mt-5'} gutter={[16, 16]}>

                        <Col md={6} sm={12} xs={24}>
                          <Form.Item
                              name={'ProductId'}
                              validateTrigger="onChange"
                              rules={[noWhitespace(t("inputError"))]}
                          >
                            <Select
                                placeholder={t('product')}
                                showSearch
                                notFoundContent={null}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                              {options.reserves.map((c, index) => {
                                    return (
                                        <Option key={index} value={c.id}>
                                          {c.name}
                                        </Option>
                                    );
                                  })}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col md={4} sm={12} xs={24}>
                          <Form.Item
                              name={" "}
                          >
                            <Button type="primary" size={'large'}  className="w-100 f-13" htmlType="submit">
                              {t("search")}
                            </Button>
                          </Form.Item>
                        </Col>

                        <Col md={4} sm={12} xs={24}>
                          <Form.Item
                              name={" "}
                          >
                            <Button type="primary" size={'large'}  onClick={()=>{ClearFilter()}} className="w-100" htmlType="submit">
                              <ClearOutlined />
                            </Button>
                          </Form.Item>
                        </Col>

                      </Row>
                    </div>
                  </Form>
                </div>
                <Table
                  size="small"
                  className="bg-white   w-100"
                  columns={columns}
                  dataSource={convertColumns(reserves, cols)}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
              </TabPane>
            )}
            {perms.subs.importExportAndDocs.perms.read && (
              <TabPane tab={t("importExportDocuments")} key="2">
                <div className="px-2 pb-1 pt-1 bg-white">
                  <Form layout="vertical" form={form}>
                    <Form.Item className="mb-0" required>
                      <Select defaultValue="0" onChange={selectHandleChange}>
                        <Option value="0">{t("allOf")}</Option>
                        <Option value="1">{t("import")}</Option>
                        <Option value="2">{t("export")}</Option>
                      </Select>
                    </Form.Item>
                  </Form>
                </div>
                <Table
                  size="small"
                  className="bg-white   w-100"
                  columns={columnsIncomeExpense}
                  dataSource={convertColumns(documents[type], cols)}
                  // dataSource={documents[type]}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
              </TabPane>
            )}
            {
               perms.subs.tasks.perms.read && 
               (
                <TabPane tab={t("tasks")} key="3">
                <Table
                  size="small"
                  className="bg-white   w-100"
                  columns={columnsTasks}
                  dataSource={convertColumns(tasks, cols)}
                  pagination={{
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                    showSizeChanger: true,
                    current_page: 1,
                  }}
                />
              </TabPane>
               )
            }
          
          </Tabs>
        </div>
      </div>

      <Modal
          title={
            modalType === 'export' ? t('export')
                : modalType === 'edit' ? t("edit") : ''
          }
          centered
          className="padModal mediumModal"
          onOk={() => setVisibleEdit(false)}
          onCancel={() => setVisibleEdit(false)}
          visible={visibleEdit}
          footer={false}
      >
        <Edit
            parcelCategory={parcelCategory}
            parcelSectors={parcelSectors}
            parcel={parcel}
            workPlanId={workPlanId}
            quantity={quantity}
            modalType={modalType}
            triggerFetch={triggerFetch}
            setVisibleEdit={setVisibleEdit}
            visibleEdit={visibleEdit}
            id={id}
        />
      </Modal>

      <IncomeModal
        isincome={isIncome}
        triggerFetch={triggerFetch}
        visibleIncomeModal={visibleIncomeModal}
        setVisibleIncomeModal={setVisibleIncomeModal}
      />
    </div>
  );
};

const mapStateToProps = ({ user , options, lang  }) => {
  return { perms: user.data.userPermissions  ,options, lang};
};

export default connect(mapStateToProps, { getStock , getOptions })(Reserves);
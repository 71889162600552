import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, InputNumber, Row, Select, Table, } from "antd";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../../utils/columnconverter";
import { getOptions, notify } from "../../../../../redux/actions";
import { connect } from "react-redux";
import agros from "../../../../../const/api";
import { noWhitespace, whiteSpace } from "../../../../../utils/rules";

const { Option } = Select;

const ExportModal = (props) => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    type: props.modalType !== 'edit' ? 'checkbox' : "radio",
    onChange: (selectedRowKeys, selectedRows) => {
      setStock(selectedRows, selectedRowKeys);
    },
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    hideSelectAll: true,
  }
  const { notify } = props;
  const { t, i18n } = useTranslation();
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "productCategory", value: t("productCategory"), con: true },
    { key: "product", value: t("product"), con: true },
    { key: "productSorts", value: t("productType"), con: true },
    { key: "reproductionName", value: t("reproduction"), con: true },
    { key: "quantity", value: t("quantity"), con: true },
    { key: "price", value: t("price"), con: true },
    { key: "warehouse", value: t("warehouse"), con: true },
  ];
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [data, setData] = useState([]);
  const [quantity, setQuantity] = useState(0);

  const [params, setParams] = useState({
    cropCategoryId: undefined,
    cropId: undefined,
    cropSortId: undefined,
  });
  const [vals, setVals] = useState({});
  const mapData = (data) => {
    let formData = [];
    let prods = [];
    console.log(data);
    setQuantity(data.editedQuantity)
    let total  = 0;
    for (const d of data) {
      console.log(d);
    //  const existingEntry = formData.find(o => o.ProductId === d.productId);
     // console.log(existingEntry);
      // if (existingEntry) {
      //   existingEntry['quantity'] = parseInt(existingEntry.quantity) + parseInt(d.editedQuantity)
      //   existingEntry['maxValue'] = parseInt(existingEntry.maxValue) + parseInt(d.editedQuantity)
      //   form.setFieldsValue({ quantity: formData[0].quantity });
      //   console.log(formData);
      //   //setProducts(formData);
      // }
      total = total + parseInt(d.editedQuantity)
    }
    console.log(total);
    //  else {
        console.log(formData);
        let obj = {
          quantity: total
        };

        prods.push({
          quantity: total
        });
        formData.push(obj);
        form.setFieldsValue({ quantity: formData[0].quantity });
        console.log(formData);
        //setProducts(formData);
     // }
    
  };
  const OnValueChange = (value, id) => {
    const arr = [...selectedRows]
    console.log(arr);
    let selectedData = arr.find((d) => d.id === id);
    console.log(selectedData);
    selectedData['editedQuantity'] = value
    mapData(arr)
    console.log(arr);
  }

  const handleKeyChange = (e, key) => {
    console.log(key);
    if (key === "parcelCategoryId") {
      form.setFieldsValue({ parcelId: undefined });
      form.setFieldsValue({ parcelSectorIds: undefined });
    }
    if (key === "parcelId") {
      form.setFieldsValue({ parcelSectorIds: undefined });
    }

    const all = { ...vals };
    all[key] = e;
    setVals(all);
  };

  const [stockId, setStockId] = useState(null);
  const [unit, setUnit] = useState("");

  const handleParamChange = (e, key) => {
    console.log(key);
    if (key === "cropCategoryId") {
      form2.resetFields(["cropId"]);
      form2.resetFields(["cropSortId"]);
    }
    if (key === "cropId") {
      form2.resetFields(["cropSortId"]);
    }
    let par = { ...params };
    par[key] = e;
    setParams(par);
  };

  const { getOptions } = props;
  const options = props.options[props.lang];
  // table data columns
  const columns = [
    {
      title: "#",
      key: 1,
      dataIndex: "key",
      width: window.innerWidth > 991 ? 70 : '100%',
    },
    {
      title: t("productCategory"),
      key: 2,
      dataIndex: "cropCategory",
    },
    {
      title: t("product"),
      key: 3,
      dataIndex: "crop",
    },
    {
      title: t("productType"),
      key: 4,
      dataIndex: "cropSortName",
    },
    {
      title: t("reproduction"),
      key: 6,
      dataIndex: "reproduction",
    },
    {
      title: t("quantity"),
      key: 7,
      dataIndex: "quantity",
    },
    // {
    //   title: t("price"),
    //   key: 8,
    //   dataIndex: "price",
    // },
    {
      title: t("warehouse"),
      key: 9,
      dataIndex: "warehouseName",
    },
  ];

  useEffect(() => {
    setData([]);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    form.resetFields()
    form2.resetFields()
    params.productId = undefined;
    params.mainIngredientId = undefined;
    setParams(params);
    if (props.id) {
      getExport(props.id)
    }
    if (props.modalType === 'selected') {
      form.setFieldsValue({
        parcelCategoryId: props.parcelCategory,
        parcelId: props.parcel,
        parcelSectorIds: props.parcelSectors
      })
    }
    getOptions(
      [
        "cropCategories",
        "cropSorts",
        "crops",
        "parcelCategories",
        "parcels",
        "customers",
        "parcelSectors",
      ],
      props.options,
      i18n.language
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalType, t, props.id, props.visibleExport]);
  useEffect(() => {
    form.resetFields();
    form2.resetFields();
    setStockId(null);
    setData([]);
    params.cropCategoryId = undefined;
    params.cropId = undefined;
    params.cropSortId = undefined;
    setSelectedRowKeys([])
    setSelectedRows([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.trigger]);
  const getData = () => {
    agros.get(`cropstock/export`, { params }).then((res) => {
      setData(
        res.data.map((r, index) => {
          return {
            ...r,
            key: index + 1,
            quantityNumber: r.quantity,
            editedQuantity: r.quantity,
            quantity: r.quantity + " " + r.measurementUnit,
            index: index,
          };
        })
      );
    });
  };

  const getExport = (id) => {
    console.log(id)
    agros.get(`cropstock/export`, {
      params: {
        cropsortId: id
      }
    }).then((res) => {
      setData(
        res.data.map((r, index) => {
          return {
            ...r,
            key: index + 1,
            quantity: r.quantity + " " + r.measurementUnit,
            quantityNumber: r.quantity,
            index: index,
          };
        })
      );
    });
  }

  const setStock = (selectedRows, selectedRowKeys) => {
    setSelectedRows(selectedRows)
    setSelectedRowKeys(selectedRowKeys)
    mapData(selectedRows)
    if (props.modalType === 'edit') {
      setUnit(selectedRows[0].measurementUnit);
      setStockId(selectedRows[0].id);
      console.log(selectedRows[0])
      form.setFieldsValue({
        quantity: selectedRows[0].quantityNumber,
      })
    }
    else {
      setSelectedRows(selectedRows)
    }
  };

  const saveExport = (values) => {
    console.log(stockId);
  
    if (props.modalType !== 'edit') {
      if (selectedRows.length > 0) {
        agros
          .post("cropstock/export", { ...values,   StockInfo: selectedRows.map((r) => ({
            StockId: r.id,
            Quantity: r.editedQuantity
          })) })
          .then((res) => {
            notify("", true);
            form.resetFields();
            form2.resetFields();
            setData([]);
            props.triggerFetch();
            setStockId(null);
            setUnit("");
            setData([]);
            props.setVisibleExport(false);
          })
          .catch((err) => {
            notify(err.response, false);
          });
      } else {
        notify("Məhsulu seçin", false);
      }
    }
    if (props.modalType === 'edit') {
      if (stockId) {
        agros
          .put(`CropStock/${stockId}`, { ...values, stockId })
          .then((res) => {
            notify("", true);
            props.setVisibleExport(false);
            props.triggerFetch();
          })
          .catch((err) => {
            notify(err.response, false);
          });
      } else {
        notify("", false);
      }
    }
  };

  return (
    <>
      {
        props.modalType === 'default' &&
        <Form form={form2} onFinish={getData}>
          <Row gutter={[10, 10]}>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                name="cropCategoryId"
                rules={[noWhitespace(t("inputError"))]}
                
              >
                <Select placeholder={t('productCategory')} onChange={(e) => handleParamChange(e, "cropCategoryId")}>
                  {options.cropCategories.map((c, cindex) => {
                    return (
                      <Option key={cindex} value={c.id}>
                        {c.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item name="cropId" rules={[noWhitespace(t("inputError"))]}>
                <Select placeholder={t('product')} onChange={(e) => handleParamChange(e, "cropId")}>
                  {options.crops
                    .filter((c) => c.categoryId === params.cropCategoryId)
                    .map((c, cindex) => {
                      return (
                        <Option key={cindex} value={c.id}>
                          {c.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item name="cropSortId">
                <Select
                  placeholder={t('productType')}
                  onChange={(e) => handleParamChange(e, "cropSortId")}
                  allowClear
                >
                  {options.cropSorts
                    .filter((c) => c.categoryId === params.cropId)
                    .map((c, cindex) => {
                      return (
                        <Option key={cindex} value={c.id}>
                          {c.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button
                className="w-100"
                size="large"
                type="primary"
                htmlType="submit"
              >
                {t("search")}
              </Button>
            </Col>
          </Row>
        </Form>
      }
      <Form form={form} layout="vertical" onFinish={saveExport}>
        <Row gutter={[16, 0]}>
          <Col className="mt-20" span={24}>
            <Table
              size="small"
              rowSelection={rowSelection}
              className="bg-white  mb-10 w-100"
              columns={columns}
              dataSource={convertColumns(data, cols)}
              pagination={{ pageSize: 5, current_page: 1 }}
            />
          </Col>

          {props.modalType === 'selected' && <h3 className="red mb-5">Tələb olunan miqdar  {props.quantity} dir.</h3>}

          {props.modalType !== 'edit' &&
            <>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  {selectedRows.map((p, i) => (
                    <Col key={i} lg={4} md={12}>
                      <div className={'animated zoomIn p-2 border'}>
                        <Row className={'flex-a'} gutter={[16, 8]}>
                          <Col lg={24} sm={12} xs={24}>
                            <Form.Item
                              className={'d-none'}
                              initialValue={p.stockId}
                              validateTrigger="onChange"
                              name={[
                                "StockInfo",
                                i,
                                "stockId",
                              ]}
                            >
                              <Input type={'hidden'} className="w-100" />
                            </Form.Item>
                            <div className="form-lang">
                              <p className="flex mb-5 flex-between">
                                <p> {t("quantityOf")}</p>
                                <p>{p.warehouseName}</p>
                              </p>
                              <Form.Item
                                initialValue={p.quantity}
                                validateTrigger="onChange"
                                name={[
                                  "StockInfo",
                                  i,
                                  "Quantity",
                                ]}
                                rules={[noWhitespace(t("inputError"))]}
                              >
                                <InputNumber
                                  onChange={(e) => { OnValueChange(e, p.id) }}
                                  max={p.quantity} className="w-100" />
                              </Form.Item>
                              <span className="input-lang btm">
                                {p.measurementUnit}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={8} sm={12} xs={24}>
                <Form.Item
                  label={t("areaCategory")}
                  validateTrigger="onChange"
                  name="parcelCategoryId"
                  rules={[noWhitespace(t("inputError"))]}
                >
                  <Select disabled={props.modalType !== 'default'}
                    onChange={(e) => handleKeyChange(e, "parcelCategoryId")}
                  >
                    {options.parcelCategories.map((pc, pindex) => {
                      return (
                        <Option key={pindex} value={pc.id}>
                          {pc.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} sm={12} xs={24}>
                <Form.Item
                  label={t("area")}
                  validateTrigger="onChange"
                  name="parcelId"
                  rules={[noWhitespace(t("inputError"))]}
                >
                  <Select disabled={props.modalType !== 'default'}
                    onChange={(e) => handleKeyChange(e, "parcelId")}>
                    {
                      props.modalType === 'default' ?
                        options.parcels
                          .filter((p) => p.parcelCategoryId === vals.parcelCategoryId)
                          .map((pc, pindex) => {
                            return (
                              <Option key={pindex} value={pc.id}>
                                {pc.name}
                              </Option>
                            );
                          })
                        :
                        options.parcels
                          .map((pc, pindex) => {
                            return (
                              <Option key={pindex} value={pc.id}>
                                {pc.name}
                              </Option>
                            );
                          })
                    }
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} sm={12} xs={24}>
                <Form.Item
                  label={t("areasSector")}
                  validateTrigger="onChange"
                  name="parcelSectorIds"
                  rules={[noWhitespace(t("inputError"))]}
                >
                  <Select
                    disabled={props.modalType !== 'default'}
                    mode={'multiple'}
                  >
                    {
                      props.modalType === 'default' ?
                        options.parcelSectors
                          .filter((p) => p.parcelId === vals.parcelId)
                          .map((pc, pindex) => {
                            return (
                              <Option key={pindex} value={pc.id}>
                                {pc.name}
                              </Option>
                            );
                          })
                        :
                        options.parcelSectors
                          .map((pc, pindex) => {
                            return (
                              <Option key={pindex} value={pc.id}>
                                {pc.name}
                              </Option>
                            );
                          })
                    }
                  </Select>
                </Form.Item>
              </Col>
            </>
          }

          {props.modalType === 'edit' &&
            <Col lg={8} sm={12} xs={24}>
              <div className="form-lang">
                <Form.Item
                  label={t("quantityOf")}
                  validateTrigger="onChange"
                  name="quantity"
                  rules={[noWhitespace(t("inputError"))]}
                >
                  <InputNumber />
                </Form.Item>
                <span className="input-lang btm">{unit}</span>
              </div>
            </Col>
          }

          {
            props.modalType !== 'edit' &&
            <>
              <Col lg={8} sm={12} xs={24}>
                <Form.Item
                  label={t("quantityOf")}
                  validateTrigger="onChange"
                  name="quantity"
                >
                  <Input size={'large'} disabled />
                </Form.Item>
              </Col>
              <Col lg={8} sm={12} xs={24}>
                <Form.Item
                  label={t("acceptedPerson")}
                  validateTrigger="onChange"
                  name="HandingPerson"
                  rules={[whiteSpace(t("inputError"))]}
                >
                  <Input size={'large'} />
                </Form.Item>
              </Col>
              <Col lg={8} sm={12} xs={24}>
                <Form.Item
                  label={t("acceptedCarNumber")}
                  validateTrigger="onChange"
                  name="HandingCarNumber"
                  rules={[whiteSpace(t("inputError"))]}
                >
                  <Input size={'large'} />
                </Form.Item>
              </Col>
            </>
          }
        </Row>
        <div
          className="modalButtons"
          style={{ position: "absolute", bottom: "20px", right: "25px" }}
        >
          <Button onClick={() => {
            props.setVisibleExport(false)
            form.resetFields();
            }}>
            {t("cancel")}
          </Button>
          <Button type="primary" className="ml-10" htmlType="submit">
            {t("save")}
          </Button>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = ({ options, lang }) => {
  return { options, lang };
};

export default connect(mapStateToProps, { getOptions, notify })(ExportModal);
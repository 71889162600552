import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import agros from "../../../../../const/api";

const ViewTask = (props) => {
  const { t } = useTranslation();
  const [parcel, setParcel] = useState([])
  console.log(props.plan);
  useEffect(() => {
    agros.get("Data/Parcels").then((res) => {
      const at = props.plan.parcels.map(elem => elem.parcelId)
      setParcel(res.data.filter((elem) => at.includes(elem.id)))
    }
    )
  }, [])
  console.log(parcel);
  return (
    <div>
      <Row gutter={[8, 16]}>
        <Col sm={12} md={12} lg={12}>
          <table className="customtable">
            <tbody>
              <tr>
                <td className="bold">{t("heading")}:</td>
                <td>{props.plan.name}</td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col sm={12} md={12} lg={12}>
          <table className="customtable">
            <tbody>
              <tr>
                <td className="bold">{t("sectors")}:</td>
                <td>{
                  parcel.map((p, index) => {
                    return (
                      p.name + (index < parcel.length - 1 ? ", " : "")
                    )
                  }

                  )
                }
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col span={24}>
          {/* <p className="mt-10 mb-10">Məhsullar</p> */}
          <Row gutter={[8, 16]}>
            <Col sm={12} md={12} lg={12} >
              <table className="customtable">
                <tbody>
                  <tr>
                    <td>{t('productName')}:</td>
                    <td>
                      {props.plan.crop.crop}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <table className="customtable">
                <tbody>
                  <tr>
                    <td>{t('productType')}:</td>
                    <td>
                      {props.plan.crop.name}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <table className="customtable">
                <tbody>
                  <tr>
                    <td>{t('productCategory')}:</td>
                    <td>
                      {props.plan.crop.cropCategory}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <table className="customtable">
                <tbody>
                  <tr>
                    <td>{t('Məhsulun miqdarı')}:</td>
                    <td>
                      {props.plan.crop.quantity} ({props.plan.crop.measurementUnit})
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <table className="customtable">
                <tbody>
                  <tr>
                    <td>{t('Məhsulun qiyməti')}:</td>
                    <td>
                      {props.plan.crop.price} (azn)
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>


          </Row>
        </Col>
      </Row>
      <h3 className="mt-15 mb-15 bold">{t("periods")}</h3>
      {props.plan.annualWorkPlanPeriod.map((p, index) => {
        return (
          <div key={index} className="border p-1 mt-25">
            <Row gutter={[8, 16]}>
              <Col md={12} xs={24}>
                <table className="customtable">
                  <tbody>
                    <tr>
                      <td className="bold">{t("Dövr adı")}:</td>
                      <td>
                        {p.name}
                      </td>
                    </tr>
                  </tbody>
                </table>

              </Col>
              <Col md={12} xs={24}>
                <table className="customtable">
                  <tbody>
                    <tr>
                      <td className="bold">{t("startDate")}:</td>
                      <td>
                        <Button shape="round" type="primary" size="small">
                          {moment(p.startDate).format("DD-MM-YYYY")}
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              {
                p.endDate &&
                <Col md={12} xs={24}>
                  <table className="customtable">
                    <tbody>
                      <tr>
                        <td className="bold">{t("startDate")}:</td>
                        <td>
                          <Button shape="round" type="primary" size="small">
                            {moment(p.endDate).format("DD-MM-YYYY")}
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              }

            </Row>
            {p.annualWorkPlanPeriodTask.map((d, tindex) => {
              return (
                <div key={tindex} className="border p-1 mt-5">
                  <h2 className="mt-15 mb-15 pl-1 bold">
                    {t("task")} {tindex + 1}
                  </h2>
                  <Row gutter={[16, 16]}>
                    <Col lg={12} xs={24}>
                      <table className="customtable">
                        <tbody>
                          <tr>
                            <td>{t("name")}:</td>
                            <td>{d.name}</td>
                          </tr>
                          <tr>
                            <td>{t("note")}:</td>
                            <td>{d.description}</td>
                          </tr>

                        </tbody>
                      </table>
                    </Col>
                    <Col lg={12} xs={24}>
                      <table className="customtable">
                        <tbody>
                          <tr>
                            <td>{t("manWorkerNumber")}:</td>
                            <td>
                              {d.manCount} {t("personCount")}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("womanWorkerNumber")}:</td>
                            <td>
                              {d.womanCount} {t("personCount")}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("İşçilərin təxmini ümumi maaşı")}:</td>
                            <td>{d.priceOfWorkerWork} (azn)</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>

                    {d.annualWorkPlanTaskFertilizer?.length ? (
                      <Col lg={12} xs={24}>
                        {/* <p className="mt-10 mb-10 bold">Dərman və gübrələr</p> */}
                        {d.annualWorkPlanTaskFertilizer.map((f, findex) => {

                          return (
                            <table className="customtable">
                              <tbody>
                                <tr>
                                  <td>{t("Məhsul")}:</td>
                                  <td>
                                    {f.productName}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("Növü")}:</td>
                                  <td>
                                    {f.fertilizerKind}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="bold">
                                    Təsiredici maddə:
                                  </td>
                                  <td>{f.mainIngredient}</td>
                                </tr>
                                <tr>
                                  <td className="bold">Miqdar:</td>
                                  <td>
                                    {f.quantity} {f.measurementUnit}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          );
                        })}
                      </Col>
                    ) : null}
                    {d.annualWorkPlanTaskReserves?.length ? (
                      <Col lg={12} xs={24}>
                        {d.annualWorkPlanTaskReserves.map((f, findex) => {
                          return (
                            <table className="customtable">
                              <tbody>
                                <tr>
                                  <td>{t("Ehtiyat")}:</td>
                                  <td>
                                    {f.name}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("Miqdar")}:</td>
                                  <td>
                                    {f.quantity} {f.measurementUnit}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          );
                        })}
                      </Col>
                    ) : null}
                  </Row>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ViewTask;

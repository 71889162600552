import React from "react";

export const convertColumns = (data, columns) => {
  if (window.innerWidth > 990) {
    return data;
  } else {
    let response = [];
   // console.log(data);
    data.forEach((d, index) => {
     // console.log(d);
      let newob = {};
      console.log(d);
      columns.forEach((c) => {
        if (c.con) {
         // console.log(c);
          const differentData = (s) => { 
            // console.log(s);
            // console.log(d[c.key]);
            switch (s) {
              case "cropSortColor":
                return (
                  <div style={{width: '30px', height: '30px', borderRadius: '50%', backgroundColor: d[c.key]}}></div>
                );
                case "objectCount":
                  return (
                    <span>{d[c.key]} obyekt</span>
                  );
              default:
                return <span className='text-right'>{d[c.key]}</span> ;
            }
        }
          newob[c.key] = (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span className='text-left'>{c.value}</span>
              {
              
                differentData(c.key)
              }
              
            </div>
          );
        } else {
          //console.log(d);
          if(d.canUpdate){
            newob.canUpdate = d[c.key];
          }
          if(d.end){
            newob.end = d[c.key];
          }
          // console.log(c.key);
          // console.log(d[c.key]);
          newob[c.key] = d[c.key];
        }
      });
      //console.log(newob);
      newob.key = index;
      response.push(newob);
    });
    return response;
  }
};


export const createColumns = (columns, initial) => {
  let obj = [];
  columns.forEach((c,index)=>{
    obj.push({title: c.value, dataIndex: c.key, key: (index + 1).toString()})
  })
  return initial;
};
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Modal } from "antd/lib";
import { connect } from "react-redux";

import { getOptions,notify } from "../../../redux/actions";
import EditArea from "./EditArea";


 function AreaAddModal(props) {
    const { t, i18n } = useTranslation();
   

  return (
    <Modal open={props?.open} onCancel={()=>props.setAreaAddModal(false)} title={t("addNewArea")} footer={[]} width={1200} >
        <EditArea  saveLoading={props.saveLoading} editing={null} handleSaveArea={props.handleSaveArea}  setVisibleAddArea={props.setAreaAddModal} handleCloseSketchBar={props.handleCloseSketchBar}  />
    </Modal>
  )
}

const mapStateToProps = ({ options, lang, user }) => {
    return { options, lang, perms: user.data.userPermissions };
  };
  
export default connect(mapStateToProps, { getOptions, notify })(AreaAddModal)

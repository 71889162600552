import React, { useEffect, useState } from "react";
import Images from "../../../assets/img/Images/Images";
import agros from "../../../const/api";
function About() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = () => {
      agros
        .get("root/OurHistory")
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, []);
  return (
    <div className="about fadeIn animated">
      {data.map((item, index) => (
        <div className={`content ${index % 2 === 0 ? " words" : "yellow"}`} key={item.id}>
          <img className={index % 2 !== 0 && "logo"} src={item?.imageUrl} alt="" />
          <span
            dangerouslySetInnerHTML={{
              __html: item?.text
            }} />
          {index % 2 !== 0 && (
            <div className="lists">
              <img src={Images.list21} alt="" />
              <img src={Images.list11} alt="" />
              <img src={Images.list12} alt="" />
            </div>
          )}
        </div>
      ))}

    </div>
  );
}

export default About;

import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {Button, Col, DatePicker, Row, Select, InputNumber, Form, Input} from "antd";
import { getOptions, notify } from "../../../../../redux/actions";
import { connect } from "react-redux";
import agros from "../../../../../const/api";
import { useTranslation } from "react-i18next";
import { noWhitespace } from "../../../../../utils/rules";
import moment from "moment";
const { Option } = Select;

const PurchaseDocument = (props) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [parts, setParts] = useState([]);

  // const [transportDuties , setTransportDuties] = useState(0)
  const [formObject , setObjectForm] = useState({})
  const [totalCost , setTotalCost] = useState(0)
  const [lastPrice , setLastPrice] = useState(0)
  const { notify , purchase} = props;
  const { getOptions } = props;
  const options = props.options[props.lang];

  const mapData = () => {
    let formData = [];
    let prods = [];
    console.log(purchase)
      let obj = {
        Count: purchase.count,
        VINCODE: purchase.vin,
        TechniqueSparePart: purchase.techniqueSpareParts.id,
        TechnicalDemandId: purchase.id
      };
      prods.push(obj);
      formData.push(obj);
    console.log(prods);
    setProducts(prods);
    form.setFieldsValue({ SparePartList: formData });
  };

  const handleTypeChange = (e, index) => {
    const all = [...products];
    all[index].type = e;
    setProducts(all);
  };

  const handleKeyChange = (e, index, key) => {
    const all = [...products];
    all[index][key] = e;
    setProducts(all);
  };

  const getData = async () => {
    await agros.get("TechniqueSparePart").then((res) => {
      setParts(
          res.data.map((p, index) => {
            return { key: index + 1, ...p, index: index + 1 };
          })
      );
    });
  };

  useEffect(() => {
    form.resetFields();
    mapData();
    setObjectForm(form.getFieldsValue())
    getData()
    getOptions(
      [
        "parcelCategories",
        "crops",
        "cropSorts",
        "customers",
        "cropCategories",
        "reserves",
        "parcels",
        "paymentKinds",
        "fertilizers",
        "paymentTerms",
        "countries",
        "deliveryTerms",
        "fertilizerKinds",
        "mainIngredients",
      ],
      props.options,
      i18n.language
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.purchase, t , form]);


  const Calculate = () =>{
    let quantityData = [];
    let newTotalCost = 0;
    console.log(formObject)
    form.getFieldsValue().SparePartList &&  form.getFieldsValue().SparePartList.forEach((d) =>{
      let lastprice = parseInt(
          (
              ((d.PriceOfOne  !== undefined ? d.PriceOfOne: 0)*(d.Count  !== undefined ? d.Count: 0))+
              (
                  ((d.PriceOfOne  !== undefined ? d.PriceOfOne : 0)*(d.Count  !== undefined ? d.Count: 0))*
                  ((d.VATPercentage  !== undefined ? d.VATPercentage : 0)/100)
              )
              - (d.Discount  !== undefined ? d.Discount : 0)
              + (d.CustomsCost  !== undefined ? d.CustomsCost : 0)
              + (form.getFieldsValue().TransportCost !== undefined ? form.getFieldsValue().TransportCost : 0)
          ).toFixed(1)
      );
      let obj = {
        TechnicalDemandId: purchase.id,
        VINCODE: d.VINCODE,
        PriceOfOne:d.PriceOfOne,
        Count: d.Count,
        CustomsCost:d.CustomsCost,
        Discount:d.Discount,
        VATPercentage:d.VATPercentage,
        VAT: (((d.PriceOfOne !== undefined ? d.PriceOfOne : 0)*(d.Count  !== undefined ? d.Count : 0))
            *(d.VATPercentage  !== undefined ? d.VATPercentage: 0)/100),
        LastPriceOfOne: lastprice
      };
      newTotalCost+= lastprice;
      console.log(obj);
      quantityData.push(obj);
    })
  
    form.setFieldsValue({
      SparePartList: quantityData,
      TotalCost:newTotalCost
    });
    setTotalCost(newTotalCost)
  }

  const addProduct = () => {
    const all = [...products];
    all.push({ type: "dg" });
    setProducts(all);
  };

  const removeProduct = (index) => {
    const all = [...products];
    all.splice(index, 1);
    setProducts(all);
  };

  const saveDocument = (values) => {
    console.log(values)
    agros
      .post("TechniqueSpacePartPurchase", { ...values,
        PaymentPeriod: "after 20 day",
        DeliveryPeriod: moment(values.PaymentPeriod).format("YYYY-MM-DD") ,
        PaymentLastDate: moment(values.PaymentLastDate).format("YYYY-MM-DD"),
        SparePartList: values.SparePartList.map((s)=> { return {
          ...s ,
          TechnicalDemandId: purchase.id
        }})
      })
      .then((res) => {
        notify(t("newDocumentCreated"), true);
        hideModal();
      })
      .catch((err) => {
        notify(err.response, false);
      });
  };

  const hideModal = () => {
    form.resetFields();
    props.setVisibleAPurchase(false);
  };

  const setMeasurementUnit = (e, index, obj) => {
    const all = [...products];
    all[index]["measurementUnit"] = options[obj].find(
      (o) => o.id === e
    ).measurementUnit;
    setProducts(all);
  };

  return (
    <Form layout="vertical" form={form} onFinish={saveDocument}>
      <div className="commontask">
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Form.Item
              label={t("supplier")}
              name="CustomerId"
              validateTrigger="onChange"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Select>
                {options.customers.map((c, index) => {
                  return (
                    <Option key={index} value={c.id}>
                      {c.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item
              label={t("transportCosts")}
              name="TransportInclude"
              validateTrigger="onChange"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Select>
                <Option value="true">{t("thereIs")}</Option>
                <Option value="false">{t("thereNo")}</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <div className="form-lang">
              <Form.Item
                label={t("transportDuties")}
                name="TransportCost"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <InputNumber min={0} onChange={(value)=>{Calculate()}} className="w-100" />
              </Form.Item>
              <span className="input-lang btm">azn</span>
            </div>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item
              label={t("paymentType")}
              name="PaymentKindId"
              validateTrigger="onChange"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Select>
                {options.paymentKinds.map((c, index) => {
                  return (
                    <Option key={index} value={c.id}>
                      {c.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item
              label={t("paymentTerm")}
              name="PaymentTermId"
              validateTrigger="onChange"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Select>
                {options.paymentTerms.map((c, index) => {
                  return (
                    <Option key={index} value={c.id}>
                      {c.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item
              label={t("deliveryTern")}
              name="DeliveryTermId"
              validateTrigger="onChange"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Select>
                {options.deliveryTerms.map((c, index) => {
                  return (
                    <Option key={index} value={c.id}>
                      {c.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item
              label={t("mustDeliverDate")}
              name="DeliveryPeriod"
              validateTrigger="onChange"
              rules={[noWhitespace(t("dateError"))]}
            >
              <DatePicker placeholder={t("selectDate")} className="w-100" />
            </Form.Item>
          </Col>

          <Col md={8} sm={12} xs={24}>
            <Form.Item
              label={t("lastPaymentDate")}
              name="PaymentLastDate"
              validateTrigger="onChange"
              rules={[noWhitespace(t("dateError"))]}
            >
              <DatePicker placeholder={t("selectDate")} className="w-100" />
            </Form.Item>
          </Col>


          <Col md={8} sm={12} xs={24}>
            <div className="form-lang">
              <Form.Item
                  label={'Ümumi yekun qiymət'}
                  name={'TotalCost'}
                  validateTrigger="onChange"
                  rules={[noWhitespace(t("inputError"))]}
              >
                <InputNumber className="w-100"   readOnly/>
              </Form.Item>
              <div className="input-lang btm">azn</div>
            </div>
          </Col>


        </Row>

        {products.map((pr, index) => {
          return (
            <div key={index} className="task1 border mt-20  p-2">
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <div className="w-100  flex-align-center flex flex-between">
                    <h3>
                      {t("product")} - {purchase.techniqueSpareParts.name}
                    </h3>
                    {/*<div className="flex">*/}
                    {/*  {products.length > 1 && (*/}
                    {/*    <Button*/}
                    {/*      className="mr5-5 btn-danger"*/}
                    {/*      onClick={() => removeProduct(index)}*/}
                    {/*    >*/}
                    {/*      {t("delete")}*/}
                    {/*    </Button>*/}
                    {/*  )}*/}
                    {/*  <Button type="primary" onClick={addProduct}>*/}
                    {/*    {t("addTo")}*/}
                    {/*  </Button>*/}
                    {/*</div>*/}
                  </div>
                </Col>



                <Col md={6} sm={12} xs={24}>
                  <div className="form-lang">
                    <Form.Item
                        label={'Vin code'}
                        name={["SparePartList", index, "VINCODE"]}
                        validateTrigger="onChange"
                        rules={[noWhitespace(t("inputError"))]}
                    >
                      <Input size={'large'} readOnly disabled onChange={()=>{Calculate()}}  className="w-100" />
                    </Form.Item>
                  </div>
                </Col>


                <Col md={6} sm={12} xs={24}>
                  <div className="form-lang">
                    <Form.Item
                        label={t("customsDuties")}
                        name={["SparePartList", index, "CustomsCost"]}
                        validateTrigger="onChange"
                        rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber  onChange={()=>{Calculate()}}  className="w-100" />
                    </Form.Item>
                    <span className="input-lang btm">azn</span>
                  </div>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <div className="form-lang">
                    <Form.Item
                      label={t("quantity")}
                      name={["SparePartList", index, "Count"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber onChange={()=>{Calculate()}}  className="w-100" />
                    </Form.Item>
                    <div className="input-lang btm">{pr.measurementUnit}</div>
                  </div>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <div className="form-lang">
                    <Form.Item
                      label={t("price")}
                      name={["SparePartList", index, "PriceOfOne"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber onChange={()=>{Calculate()}}  className="w-100" />
                    </Form.Item>
                    <div className="input-lang btm">azn</div>
                  </div>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <div className="form-lang">
                    <Form.Item
                      label={t("discount")}
                      name={["SparePartList", index, "Discount"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber  onChange={()=>{Calculate()}}  className="w-100" />
                    </Form.Item>
                    <div className="input-lang btm">azn</div>
                  </div>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <div className="form-lang">
                    <Form.Item
                        label='Ədv faizi'
                        name={["SparePartList", index, "VATPercentage"]}
                        validateTrigger="onChange"
                        rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber onChange={()=>{Calculate()}}  className="w-100" />
                    </Form.Item>
                    <div className="input-lang btm">%</div>
                  </div>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <div className="form-lang">
                    <Form.Item
                        label={t("AdditionalTax")}
                        name={["SparePartList", index, "VAT"]}
                        validateTrigger="onChange"
                        rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber readOnly className="w-100" />
                    </Form.Item>
                    <div className="input-lang btm">azn</div>
                  </div>
                </Col>

                <Col md={6} sm={12} xs={24}>
                  <div className="form-lang">
                    <Form.Item
                      label={t("finalPrice")}
                      name={["SparePartList", index, "LastPriceOfOne"]}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber readOnly className="w-100" />
                    </Form.Item>
                    <div className="input-lang btm">azn</div>
                  </div>
                </Col>

              </Row>
            </div>
          );
        })}
        <div
          className="modalButtons"
          style={{ position: "absolute", bottom: "20px", right: "40px" }}
        >
          <Button onClick={() => props.setVisibleAPurchase(false)}>
            {t("cancel")}
          </Button>
          <Button type="primary" className="ml-10" htmlType="submit">
            {t("save")}
          </Button>
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = ({ options, lang }) => {
  return { options, lang };
};

export default connect(mapStateToProps, { getOptions, notify })(
  PurchaseDocument
);

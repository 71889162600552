import React, {useState , useEffect} from 'react';
import {Button, Row, Col, Modal, Table, Tooltip, Form, Input, Select, Popconfirm} from "antd";
import AddModal from "../Modals/AddModal";
import CreateUser from "../Modals/CreateUser";
import View from "../Modals/View";
import Gps from "../Modals/Gps";
import {convertColumns} from "../../../../../utils/columnconverter";
import {useTranslation} from "react-i18next";
import {
    EyeFilled,
    EditFilled,
    CompassOutlined,
    DeleteFilled,
    UsergroupAddOutlined,
    UnorderedListOutlined, FlagFilled
} from "@ant-design/icons";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import ViewUser from "../Modals/ViewUser";
import agros from "../../../../../const/api";
import {connect} from "react-redux";
import {notify} from "../../../../../redux/actions";
import NormativeTemplate from "./NormativeTemplate";
// import Authorize from "../../../../Elements/Authorize";

const { Option } = Select;
const BasicTemplate = (props) => {
    const [id , setId] = useState(undefined);
    const [user , setVisibleUser] = useState(false);
    const [view ,  setvisibleView] = useState(false);
    const [visibleGps , setvisibleGps] =  useState(false);
    const [addModal , setVisibleAddModal] =  useState(false);
    const [visibleNormatives , setVisibleNormatives] = useState(false);
    const [tableData , setData] =  useState([]);
    const [detailed , setDetailed] = useState({});
    const [editingData , setEditingData] = useState({})
    const { t } = useTranslation();
    const { notify , data , techs , getTech } = props;

   const setDetailedData = (i) =>  {
       setDetailed(tableData[i-1])
       setvisibleView(true)
    }

    const deletePosition = async (i) => {
      let d = tableData[i-1].id
        await agros.delete(`Technique/${d}`).then(() => {
            notify("", true);
        })
        .catch((err) => {
            notify(err.response, false);
        })
        .finally(() => {
            getTech();
        })
    }

    const setNormatives =  (i) => {
        setDetailed(tableData[i-1])
        setVisibleNormatives(true)
    }

    const setUser = (i) => {
        let d = tableData[i-1].id
        setVisibleUser(true)
        setId(d)
    }

    const cols = [
        { key: "key", value: "#", con: true },
        { key: "brand", value: "Markası", con: true },
        { key: "model", value: "Modeli", con: true },
        { key: "driver", value: "Sürücü", con: true },
        { key: "plateNumber", value: "Dövlət nişanı", con: true },
        { key: "index", value: "", con: false },
    ];
    const commonColumns = [
        {
            title: "#",
            key: "index",
            dataIndex: "key",
        },
        {
            title: 'Markası',
            key:  "mark",
            dataIndex: "mark",
        },
        {
            title: 'Modeli',
            key: "model",
            dataIndex: "model",
        },
        {
            title: 'Vin',
            key:  "vin",
            dataIndex: "vin",
        },
        {
            title: 'Dövlət nişanı',
            key: "stateRegistrationPlate",
            dataIndex: "stateRegistrationPlate",
        },
        {
            title: "",
            key: 6,
            dataIndex: "index",
            render: (i) => {
                return (
                    <div className="flex flex-end">

                        <Tooltip className="ml-5" title={'Təhkim'}>
                            <Button
                                onClick={() => setUser(i)}
                                className="border-none"
                                type="text"
                                shape="circle"
                            >
                                <UsergroupAddOutlined />
                            </Button>
                        </Tooltip>

                        <Tooltip className="ml-5" title={t("edit")}>
                            <Button
                                onClick={()=>{ setAllProcessForSave(i)  }}
                                // onClick={() => setvisibleEdit(true)}
                                className="border-none"
                                type="text"
                                shape="circle"
                            >
                                <EditFilled  />
                            </Button>
                        </Tooltip>

                        <Tooltip className="ml-5" title={t('delete')}>
                            <Popconfirm
                                placement="topRight"
                                title={t("areYouSure")}
                                onConfirm={() => deletePosition(i)}
                                okText={t("yes")}
                                cancelText={t("no")}
                            >
                                <Tooltip className="ml-5" title={t("delete")}>
                                    <Button className="border-none" type="text" shape="circle">
                                        <DeleteFilled />
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        </Tooltip>



                        <Tooltip className="ml-5" title={'Normativlər'}>
                            <Button
                                onClick={() => setNormatives(i)}
                                className="border-none"
                                type="text"
                                shape="circle"
                            >
                                <FlagFilled />
                            </Button>
                        </Tooltip>


                        <Tooltip className="ml-5" title={t("detailed")}>
                            <Button
                                onClick={() => setDetailedData(i)}
                                className="border-none"
                                type="text"
                                shape="circle"
                            >
                                <EyeFilled />
                            </Button>
                        </Tooltip>

                    </div>
                );
            },
        },
    ];


    useEffect(() => {
        let d = techs.filter((s) => { return s.techniqueCategory.id === data.id}).map((s, i) => { return {
            ...s,
            index:i+1
        }})
        setData(d)
    } , [data, techs])


    const setAllProcessForSave = (index) => {
        if(index){
            setId(tableData[index-1].id)
            let editing = tableData[index-1].id
            agros.get(`Technique/${editing}`).then((res) => {
                console.log(res.data);
                setEditingData(res.data)
                setVisibleAddModal(true)
            }).catch((err) => {

            })
        }
        else{
            setId(undefined)
            setVisibleAddModal(true)
        }
    }


    return (
        <div className="w-100">
            <Row gutter={16}>
                <Col xs={24}>
                    <div className="flex flex-between border bg-white p-2 mb-10 mt-10">
                        <div className="">
                            <UnorderedListOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">{data.name}</span>
                        </div>
                        <div className="  bg-white flex all-center">
                            <Button onClick={()=>{ setAllProcessForSave()  }} className="w-100" type={'primary'}>Yeni texnika əlavə et</Button>
                        </div>
                    </div>
                </Col>

            </Row>
            <Table
                size="small"
                className="bg-white"
                dataSource={convertColumns(tableData, cols)}
                columns={commonColumns}
                pagination={{
                    pageSize: 10,
                    current_page: 1,
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                }}
            />

            <Modal
                title={'Yeni texnika və təhkim /Redaktə'}
                centered
                visible={addModal}
                className="addTaskModal padModal"
                onOk={() => setVisibleAddModal(false)}
                onCancel={() => setVisibleAddModal(false)}
                footer={null}
            >
                <AddModal
                    editing={id}
                    id={data.id}
                    getTech={getTech}
                    setVisibleAddModal={setVisibleAddModal}
                    editingData={editingData}
                />
            </Modal>



            <Modal
                title={'Təhkim'}
                centered
                visible={user}
                onOk={() => setVisibleUser(false)}
                onCancel={() => setVisibleUser(false)}
                footer={null}
                className="padModal mediumModal"
            >
                <ViewUser id={id} visibleUser={user}  setVisibleUser={setVisibleUser}/>
            </Modal>

            <Modal
                title={'Gps'}
                centered
                visible={visibleGps}
                onOk={() => setvisibleGps(false)}
                onCancel={() => setvisibleGps(false)}
                footer={null}
                className="padModal"
            >
                <Gps setvisibleGps={setvisibleGps}/>
            </Modal>

            <Modal
                title={t('detailed')}
                centered
                visible={view}
                onOk={() => setvisibleView(false)}
                onCancel={() => setvisibleView(false)}
                footer={null}
                className="addTaskModal padModal"
            >
                <View detailed={detailed} setvisibleView={setvisibleView}/>
            </Modal>

            <Modal
                title={'  '}
                centered
                visible={visibleNormatives}
                onOk={() => setVisibleNormatives(false)}
                onCancel={() => setVisibleNormatives(false)}
                footer={null}
                className="addTaskModal padModal"
            >
                <NormativeTemplate detailed={detailed}  setVisibleNormatives={setVisibleNormatives}/>
            </Modal>
        </div>
    );
}

export default connect(null, { notify })(BasicTemplate);
